
import React, { useState, useRef } from "react";

import styles from "./CreateUser.module.css";
import UserLayout from "../../components/userLayout/UserLayout";
import TextInput from "../../components/_base/TextInput";
import Button from "../../components/_base/button";
import { sweetAlert } from "../../components/_base/alerts/sweetAlert";
import { createUser } from '../../api/user/requests';
import ChooseServicesModal from '../../components/popups/users/chooseServiceModal';
import { FaTimesCircle } from "react-icons/fa";


const CreateUser = () => {

	const [userState, setUserState] = useState({
		first_name: "",
		last_name: "",
		email: "",
		// password: "",
		user_type: "member",
		notify_email: false,
		description: ""
	});

	const [selectedServices, setSelectedServices] = useState([]);

	const [notify_email, setNotifyEmail] = useState(false);


	const servicesData = [
		{ id: 1, label: "HMC Master", docCounts: 978, selected: true },
		{ id: 2, label: "North Nowra Preschool", docCounts: 88, selected: false },
		{ id: 3, label: "Wattle Grove Long Daycare", docCounts: 12, selected: false },
		{ id: 4, label: "Innocent MInds Preschool Academy", docCounts: 621, selected: true },
		{ id: 5, label: "Little Academy Elc", docCounts: 5, selected: false },
		{ id: 6, label: "Kingdom of Angels Childcare", docCounts: 978, selected: false },
		{ id: 7, label: "he Little Kids School", docCounts: 401, selected: true },
		{ id: 8, label: "he Little Kids School", docCounts: 119, selected: false },
	];

	const renderSelectedServices = () => {
		return selectedServices.map((s, i) => {
			return (
				<li key={s.id}>
					{s.label}
					<FaTimesCircle className={`ml-2`} size={16} />
				</li>
			);
		})
	}

	const [showChooseServicesModal, setChooseServicesModal] = useState(false);

	const [errors, setErrors] = useState({});

	const handleChange = (fieldName, type, e) => {

		e.preventDefault();

		setErrors({});

		let userData = Object.assign({}, userState);

		type === "checkbox" ? userData[fieldName] = e.target.checked : userData[fieldName] = e.target.value;

		setUserState(userData);
	}


	const resetFields = () => {
		setUserState({
			first_name: "",
			last_name: "",
			email: "",
			// password: "",
			user_type: "member",
			notify_email: false,
			description: ""
		});
	}

	const handleCreateNewUser = async () => {
		try {

			userState.notify_email = notify_email;

			const { status, data } = await createUser(userState);

			if (status == 200) {

				sweetAlert("success", "User successfully created.");

				resetFields();
			}

		} catch (error) {

			if (error.response.data.errors) {

				// sweetAlert("error", "Some fields are missing!");

				setErrors(error.response.data.errors);
			}
		}
	};


	const renderTextInput = (fieldName, value, label, placeholder) => {
		return (
			<TextInput
				onChange={(e) => handleChange(fieldName, "text", e)}
				value={value}
				name={fieldName}
				textLabel={`${label}`}
				style={styles.inputGroup}
				placeholder={`${placeholder}`}
				errors={errors}
				errorStyle={styles.inputGroupLabel}
			/>
		);
	}

	return (

		<UserLayout headerText={`Create a new user`}>

			<div className={styles.createUser}>

				{showChooseServicesModal && (
					<ChooseServicesModal
						visibility={showChooseServicesModal}
						onOpenModal={() => setChooseServicesModal(true)}
						onCloseModal={() => setChooseServicesModal(false)}
					/>
				)}

				<div className={styles.createUserHeaderContent}>

					<h2>User details</h2>

					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

				</div>

				<div className={styles.inputContainer}>
					<div className={styles.inputRow}>
						{renderTextInput("first_name", userState.first_name, "First name", "Lorem Epsum")}
						{renderTextInput("last_name", userState.last_name, "Last name", "Lorem Epsum")}
					</div>

					<div className={styles.inputRow}>
						{renderTextInput("email", userState.email, "Email", "example@website.com")}
						{renderTextInput("description", userState.description, "Something else", "Or do I just stretch the email field all the way?")}
					</div>

				</div>

				<div className={styles.chooseServices}>

					<p>Choose one or more services for this user</p>

					<ul className={styles.selectedServices}>
						{renderSelectedServices()}
					</ul>

					<div className={styles.inputActionButton}>
						<button
							onClick={(e) => setChooseServicesModal(!showChooseServicesModal)}
							className={`${styles.chooseServicesBtn} ${styles.blueButton}`}>
							CHOOSE SERVICES
						</button>
						<button className={styles.removeFile}>
							CLEAR SELECTION
							<FaTimesCircle size={19} />
						</button>
					</div>

				</div>

				<div className={styles.inputNotify}>

					<label className={styles.switch}>

						<input type="checkbox"
							onChange={(e) => setNotifyEmail(!notify_email)}
						/>
						<span className={`${styles.slider} ${styles.round}`}></span>
					</label>

					<h3>Notify this user with the provided e-mail address</h3>

					<p>Tip before center update: Check this option only if you are adding a new document or editing an existing one.</p>

				</div>

			</div>

			<div className={styles.btnSave}>
				<Button
					onClick={handleCreateNewUser}
					children={`CREATE USER`}
				/>
			</div>
		</UserLayout>
	)
};

export default CreateUser;
