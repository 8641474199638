import React, { useState, useEffect } from "react";
import styles from "./RemoveDoumentsFromServicesModal.module.css";

import {
	FaTimes,
	FaSearch,
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import { selectServicesDocuments } from '../../../../api/services/requests';

const RemoveDoumentsFromServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	uploaded_documents,
	fetchAllDocs
}) => {

	// const [selectedServices, setSelectedServices] = useState([]);
	// const [oldCount, setCount] = useState(count);

	useEffect(() => {

		filterServices();

	}, []);

	const filterServices = async () => {

		try {

			let selectedDocIds = [];

			let services = [];

			let selectedDocs = uploaded_documents.filter(d => d.selected == true);

			selectedDocs.forEach((item, index) => {

				console.log(item)

				// for (let index = 0; index < array.length; index++) {
				// 	const element = array[index];
					
				// }

			});

			selectedDocIds = selectedDocs.map(function (item) {
				return item.id;
			});

			let payload = {
				document_id: selectedDocIds,

			}

			// const data = await selectServicesDocuments();

			// console.log(data)

			// let newData = [];

			// data.forEach(i => {
			// 	let obj = {
			// 		id: i.id,
			// 		service_name: i.service_name,
			// 		document_count: i.document_count,
			// 		selected: false
			// 	}
			// 	newData.push(obj);
			// });

			// setServices(newData);

			// setBackUp(newData);

			// setLoading(false);

		} catch (error) {

			console.log(error);
		}
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>
			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`${23} services containing selected documents}`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.search}`}>
						<input type="text" value="" name="search" id="s" placeholder="Type the service name" />
						<button type="submit" className="flex items-center justify-center">
							<FaSearch size={17} />
						</button>
					</div>

					<ul>
						<li className={styles.selected}>The Little Kids School <span>720 docs
							<label className={styles.checkbox}>
								<input type="checkbox" />
								<span className={styles.checkmark}>
								</span>
							</label>
						</span>
						</li>
					</ul>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => console.log(e)}
							className={`${styles.btnGreen}`}>UNLINK DOCUMENT
						</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default RemoveDoumentsFromServicesModal;