import React, { useState, useEffect } from "react";
import styles from "./FilterByServicesModal.module.css";

import { fetchServicesWithDocumentCount } from '../../../../api/services/requests';

import LargeSpinner from '../../../spinner/LargeSpinner';

import {
	FaTimes,
	FaSearch,
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

const FilterByServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	filterDocuments,
	selectedCollection,
	setSelectedCollection,
	setAllServices
}) => {

	const [services, setServices] = useState([]);
	const [loading, setLoading] = useState(true);
	const [keyword, setKeyword] = useState('');
	const [backup, setBackUp] = useState([]);

	useEffect(() => {

		filterServices();

	}, []);


	const filterServices = async () => {

		try {

			const data = await fetchServicesWithDocumentCount();

			let newData = [];

			data.forEach(i => {
				let obj = {
					id: i.id,
					service_name: i.service_name,
					document_count: i.document_count,
					selected: false
				}
				newData.push(obj);
			});

			setServices(newData);

			setBackUp(newData);

			setLoading(false);

		} catch (error) {

			// console.log(error);
		}
	}


	const addToSelectedCollection = (item, e) => {

		e.preventDefault();

		let newArr = [...selectedCollection.collectionItems];

		if (newArr.indexOf(item.id) > -1) 
		{
			let i = newArr.indexOf(item.id);
			newArr.splice(i, 1);
		} 
		else 
		{
			newArr = [...newArr, item];
		}
 
		services.forEach(function (s, i) 
		{
			if (s.id == item.id) {
				s.selected = !s.selected
			}
		});

		setAllServices([...services])
		setSelectedCollection({ ...selectedCollection, collectionItems: newArr });
	}

	const onFilterDocuments = (e) =>{
		let payload = services.filter(s => s.selected).map(i => {
			return i.id;
		});

		filterDocuments(payload)
	}

	const search = (e) => {

		e.preventDefault();

		if (!keyword && keyword.length === 0) {

			setServices([...backup]);;

			return;
		}

		let searchResult = [];

		backup.forEach(item => {

			if (item.service_name && item.service_name.length > 0)
			{
				let n = item.service_name.toLowerCase();
				let k = keyword.toLowerCase();
				if (n.search(k) > -1) {
					searchResult.push(item);
				}
			}
		});

		setServices([...searchResult]);

	}

	const mapServices = () => {
		return services.map((item, i) => {
			return (
				<li key={item.id}
					className={item.selected ? styles.selected : ""}
					onClick={(e) => addToSelectedCollection(item, e)}
				>{item.service_name}
					<span>{`${item.document_count} docs`}<label className={`${styles.checkbox}`}>
						<input type="checkbox" /><span className={`${styles.checkmark}`}>
						</span>
					</label>
					</span>
				</li>
			)
		})
	}


	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Choose a service to filter documents by</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.search}`}>
						<input type="text" 
							value={keyword}
							name="search"
							id="s"
							placeholder="Type the service name"
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<button type="submit"
							onClick={(e) => search(e)}
							className="flex items-center justify-center">
							<FaSearch size={17} />
						</button>
					</div>

					{
					loading && <div>
						<LargeSpinner />
					</div>
					}

					{!loading && services.length > 0 && <ul>
						{mapServices()}
					</ul>
					}

					{!loading && services.length == 0 &&
						<span
							className={`flex justify-center py-4 ml-2 text-2xl text-gray-400`}>No service available</span>
					}

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => {
								onFilterDocuments(e)
								onCloseModal()
							}}
							className={`${styles.btnGreen}`}>FILTER BY SERVICE
						</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default FilterByServicesModal;