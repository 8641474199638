import React from "react";
import styles from "./RemoveFromServicesOKModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';


const RemoveFromServicesOKModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	documentIds,
}) => {

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`${documentIds.length} ${documentIds.length > 1 ? "documents" : "document"} retrieved!`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							 onClick={(e) => onCloseModal(e)}
							className={`${styles.btnOk}`}>OK</Button>
					</div>

				</div>

			</div>


		</Modal>
	)
}

export default RemoveFromServicesOKModal;