import React, { useState, useEffect } from "react";
import styles from "./AddNewService.module.css";
import { TextInput, TextArea } from "../_base";
import Button from "../_base/button";

import LongDayCare from "../_base/svg/LongDayCare";
import FamilyDayCare from "../_base/svg/FamilyDayCare";
import OOSH from "../_base/svg/OOSH";
import PreSchool from "../_base/svg/PreSchool";

import Spinner from "../spinner/Spinner";

import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const ServiceDetails = ({
  serviceState,
  setServiceState,
  mapServiceType,
  serviceTypeId,
  serviceDetailsChevron,
  setServiceDetailsChevron,
  loading,
  cb,
}) => {
  const [errors, setErrors] = useState({});
  const [typeId, setTypeId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setTypeId(serviceTypeId);
  }, [serviceTypeId]);

  const handleServicesInputChange = (fieldName, type, e) => {
    e.preventDefault();

    setErrors({});

    let serviceData = Object.assign({}, serviceState);

    type === "checkbox"
      ? (serviceData[fieldName] = e.target.checked)
      : (serviceData[fieldName] = e.target.value);

    setServiceState(serviceData);
  };

  const renderServiceTextInput = (
    fieldName,
    value,
    label,
    placeholder,
    type = "text"
  ) => {
    return (
      <TextInput
        disabled={fieldName === "primary_email" ? true : false}
        onChange={(e) => handleServicesInputChange(fieldName, "text", e)}
        value={value}
        extra={
          fieldName === "primary_email" ? (
            <span className={`text-red-500`}>(account-owner)</span>
          ) : (
            ""
          )
        }
        name={fieldName}
        textLabel={`${label}`}
        style={styles.inputGroup}
        placeholder={`${placeholder}`}
        errors={errors}
        errorStyle={styles.inputGroupLabel}
        type={type}
      />
    );
  };

  return (
    <div className={`${styles.serviceDetails} ${styles.addNewService}`}>
      <div
        className={`${styles.accordionHeader}`}
        onClick={(e) => setServiceDetailsChevron(!serviceDetailsChevron)}
      >
        <h2>
          <span className={`${styles.accordionArrow} ${styles.arrowUp}`}>
            {serviceDetailsChevron ? (
              <FaChevronUp size={20} />
            ) : (
              <FaChevronDown size={20} />
            )}
          </span>
          Service details
        </h2>
      </div>
      <div
        className={`${styles.accordionContent} ${
          serviceDetailsChevron ? "h-0" : "h-auto"
        }`}
      >
        <div className={styles.serviceDetailsFilter}>
          <p>
            Create a new service by completing all the necessary service details
            below.
          </p>

          <div className={styles.servicesFilter}>
            <span className={styles.label}>Choose service type</span>
            <ul className={styles.fourColumns}>
              <li
                className={typeId == 1 ? styles.active : ""}
                onClick={(e) => {
                  setTypeId(1);
                  mapServiceType(1, e);
                }}
              >
                <span>
                  {" "}
                  <LongDayCare />
                </span>
                Long daycare
              </li>
              <li
                className={typeId == 2 ? styles.active : ""}
                onClick={(e) => {
                  setTypeId(2);
                  mapServiceType(2, e);
                }}
              >
                <span>
                  {" "}
                  <FamilyDayCare />
                </span>
                Family Daycare
              </li>

              <li
                className={typeId == 3 ? styles.active : ""}
                onClick={(e) => {
                  setTypeId(3);
                  mapServiceType(3, e);
                }}
              >
                <span>
                  {" "}
                  <PreSchool />
                </span>
                Pre-school
              </li>
              <li
                className={typeId == 4 ? styles.active : ""}
                onClick={(e) => {
                  setTypeId(4);
                  mapServiceType(4, e);
                }}
              >
                <span>
                  {" "}
                  <OOSH />
                </span>
                OOSH
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputRow}>
            {renderServiceTextInput(
              "name",
              serviceState.name,
              "Service name",
              "Enter the business’ exact name"
            )}
            {renderServiceTextInput(
              "website_link",
              serviceState.website_link,
              "Website",
              "Please include your full URL"
            )}
          </div>

          <div className={styles.inputRow}>
            {renderServiceTextInput(
              "phone",
              serviceState.phone,
              "Phone",
              "Your telephone number"
            )}
            {renderServiceTextInput(
              "primary_email",
              serviceState.primary_email,
              "Primary email",
              "Your primary email"
            )}
          </div>

          <div className={styles.inputRow}>
            {renderServiceTextInput(
              "address",
              serviceState.address,
              "Address",
              "Enter the street and humber"
            )}
            {renderServiceTextInput(
              "city",
              serviceState.city,
              "Suburb",
              "City name"
            )}
          </div>

          <div className={styles.inputRow}>
            {renderServiceTextInput(
              "state",
              serviceState.state,
              "State/Territory",
              "Enter your state name"
            )}
            {renderServiceTextInput(
              "postal",
              serviceState.postal,
              "Postal Code",
              "Enter postcode"
            )}
          </div>

          {/* {renderServiceTextInput("description", serviceState.description, "Service description", "Describe your business and services")} */}

          <div className={styles.inputFull}>
            <div className={styles.inputGroup}>
              <TextArea
                onChange={(e) =>
                  handleServicesInputChange("description", "text", e)
                }
                className={styles.inputDesc}
                placeholder="Describe your business and services"
                textLabel={`Statement of Philosophy`}
                value={serviceState.description}
              />
            </div>
          </div>

          <div className={styles.inputActionButton}>
            <Button
              className={styles.blueButton}
              disable={loading}
              onClick={(e) => cb(e)}
            >
              {!isLoading ? (
                !serviceState.id ? (
                  "CREATE SERVICE"
                ) : (
                  "UPDATE SERVICE"
                )
              ) : (
                <Spinner />
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
