export const CATEGORIES = [
  {
    label: "Monthly Newsletters",
    value: "monthly_newsletter",
  },
  {
    label: "EL Newsletters",
    value: "el_newsletter",
  },
  {
    label: "WHS Bulletins",
    value: "whs_bulletins",
  },
  {
    label: "Wellbeing Magazines",
    value: "wellbeing_magazines",
  },
];
