import React, { useState, useEffect } from "react";
import styles from "./Services.module.css";
import UserLayout from "../../components/userLayout/UserLayout";

import { FaEraser, FaFile, FaSearch } from "react-icons/fa";
import Button from "../../components/_base/button";
import TextInput from "../../components/_base/TextInput";

import ServiceList from "../../components/services/ServiceList";

import DeleteServicesConfirmationModal from "../../components/popups/services/deleteServicesConfirmation";
import DeleteServicesSuccessModal from "../../components/popups/services/deleteServicesSuccess";
import UndoDeleteServicesModal from "../../components/popups/services/undoDeletedServices";

import { useHistory } from "react-router-dom";
import { sweetAlert } from "../../components/_base/alerts/sweetAlert";

import {
  softDeleteService,
  undoSoftDeleteService,
  fetchServices,
} from "../../api/services/requests";
import LargeSpinner from "../../components/spinner/LargeSpinner";

import isEmpty from "lodash/isEmpty";

const Services = () => {
  const [serviceTypeId, setServiceTypeId] = useState(3);
  const [keyWord, setKeyWord] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [showDeleteServicesConfirmation, setShowDeleteServicesConfirmation] =
    useState(false);
  const [
    showDeleteServicesSuccessConfirmation,
    setShowDeleteServicesSuccessConfirmation,
  ] = useState(false);
  const [showUndoDeleteServicesSuccess, setShowUndoDeleteServicesSuccess] =
    useState(false);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [servicesBckup, setServicesBckup] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [currPage, setCurrentPage] = useState(1);

  const history = useHistory();

  useEffect(() => {
    filterServices("");
  }, []);

  const filterServices = async (keyword) => {
    try {
      const data = await fetchServices(keyword);

      setServices(!isEmpty(data) ? data.filter((s) => s.has_portal === 1) : []);
      setServicesBckup(
        !isEmpty(data) ? data.filter((s) => s.has_portal === 1) : []
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshList = (id) => {
    let newServiceList = services.filter((u) => u.id != id);
    setServices(newServiceList);
  };

  const onSearchServiceByName = (e) => {
    e.preventDefault();

    if (keyword === "" || keyword == null) {
      setServices([...servicesBckup]);
      return;
    }

    let searchResult = [];

    searchResult = servicesBckup.filter((s) => {
      if (!s.name) return;
      return s.name.toLowerCase().includes(keyword.toLowerCase());
    });

    setCurrentPage(1);
    setServices([...searchResult]);
  };

  const onRemove = async () => {
    setShowDeleteServicesConfirmation(false);

    let serviceIds = selectedServices.map((s) => s.id);

    try {
      const isSuccess = await softDeleteService(serviceIds);

      if (isSuccess) {
        setShowDeleteServicesSuccessConfirmation(true);
        filterServices("");
      }
    } catch (error) {}
  };

  const onUndo = async () => {
    setShowDeleteServicesSuccessConfirmation(false);

    let serviceIds = selectedServices.map((s) => s.id);

    try {
      const isSuccess = await undoSoftDeleteService(serviceIds);

      if (isSuccess) {
        setShowUndoDeleteServicesSuccess(true);
        filterServices("");
      }
    } catch (error) {}
  };

  return (
    <UserLayout headerText={`All services`}>
      {showDeleteServicesConfirmation && (
        <DeleteServicesConfirmationModal
          itemCount={selectedServices.length}
          visibility={showDeleteServicesConfirmation}
          onOpenModal={() => setShowDeleteServicesConfirmation(true)}
          onCloseModal={() => setShowDeleteServicesConfirmation(false)}
          onRemove={onRemove}
        />
      )}

      {showDeleteServicesSuccessConfirmation && (
        <DeleteServicesSuccessModal
          itemCount={selectedServices.length}
          visibility={showDeleteServicesSuccessConfirmation}
          onOpenModal={() => setShowDeleteServicesSuccessConfirmation(true)}
          onCloseModal={() => setShowDeleteServicesSuccessConfirmation(false)}
          onUndo={onUndo}
          onClickOk={() => setSelectedServices([])}
        />
      )}

      {showUndoDeleteServicesSuccess && (
        <UndoDeleteServicesModal
          itemCount={selectedServices.length}
          visibility={showUndoDeleteServicesSuccess}
          onOpenModal={() => setShowUndoDeleteServicesSuccess(true)}
          onCloseModal={() => setShowUndoDeleteServicesSuccess(false)}
        />
      )}

      <div className={styles.documentsList}>
        <div className={`${styles.filterContainer}`}>
          <div className={`${styles.searchActions} flex justify-end`}>
            <div className={`${styles.searchContainer}`}>
              <div
                className={`${styles.search} flex items-center justify-between`}
              >
                <input
                  type="text"
                  value={keyword}
                  name="search"
                  id="s"
                  placeholder="Type service name"
                  onChange={(e) => setKeyword(e.target.value)}
                />
                <button
                  onClick={(e) => onSearchServiceByName(e)}
                  type="submit"
                  className={`flex items-center justify-center`}
                >
                  <FaSearch size={17} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className={`p-16 flex justify-center h-screen`}>
            <LargeSpinner />
          </div>
        )}
        {!loading && !isEmpty(services) && (
          <>
            <ServiceList
              showContact={false}
              filterServices={filterServices}
              services={services}
              refreshList={refreshList}
              setServices={setServices}
              currPage={currPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}
        {!loading && isEmpty(services) && (
          <div
            className={`${styles.fileList} bg-white flex h-screen items-center justify-center px-10`}
          >
            <span className={`text-gray-400 text-2xl py-6`}>
              No services found
            </span>
          </div>
        )}
      </div>
    </UserLayout>
  );
};

export default Services;
