import React from 'react'
import styles from '../EditNQADocument.module.css'

export default function ChooseNQA({
    nqaArea,
    setNqaArea
    }) {

    return (
        <div className={styles.chooseNQADocument}>
            <span className={styles.label}>Choose NQA area for this document</span>
            <ul>
                <li 
                    onClick={(e) => setNqaArea(e, 1)} 
                    className={nqaArea.indexOf(1) > -1 ? styles.active : null}>NQA1</li>
                <li 
                    onClick={(e) => setNqaArea(e, 2)} 
                    className={nqaArea.indexOf(2) > -1 ? styles.active : null}>NQA2</li>
                <li 
                    onClick={(e) => setNqaArea(e, 3)} 
                    className={nqaArea.indexOf(3) > -1 ? styles.active : null}>NQA3</li>
                <li 
                    onClick={(e) => setNqaArea(e, 4)} 
                    className={nqaArea.indexOf(4) > -1 ? styles.active : null}>NQA4</li>
                <li 
                    onClick={(e) => setNqaArea(e, 5)} 
                    className={nqaArea.indexOf(5) > -1 ? styles.active : null}>NQA5</li>
                <li 
                    onClick={(e) => setNqaArea(e, 6)} 
                    className={nqaArea.indexOf(6) > -1 ? styles.active : null}>NQA6</li>
                <li 
                    onClick={(e) => setNqaArea(e, 7)} 
                    className={nqaArea.indexOf(7) > -1 ? styles.active : null}>NQA7</li>
            </ul>
        </div>
    )
}
