import React from "react";
import styles from "../../pages/AddNewService/AddNewService.module.css";

import {
    FaFilePdf,
    FaFileWord,
    FaFileExcel,
    FaFilePowerpoint,
    FaImage
} from "react-icons/fa";


const FileIcon = ({ selectedFileType }) => {

    const setIcon = () => {
        let icon = <></>;

        switch (selectedFileType) {
            case "pdf":
            case "application/pdf":
                icon =
                    <div className={`${styles.fileIcon}`}
                        style={{ backgroundColor: "#B71C1C" }}
                    >
                        <FaFilePdf size={24} />
                    </div>
                break;

            case "jpg":
            case "jpeg":
            case "jfif":
            case "png":
                icon =
                    <div className={`${styles.fileIcon}`}
                        style={{ backgroundColor: "#B71C1C" }}
                    >
                        <FaImage size={24} />
                    </div>
                break;

            case "docx":
            case "document":
                icon =
                    <div className={`${styles.fileIcon}`}
                        style={{ backgroundColor: "#01579B" }}
                    >
                        <FaFileWord size={24} />
                    </div>
                break;

            case "xls":
            case "xlsx":
                icon =
                    <div className={`${styles.fileIcon}`}
                        style={{ backgroundColor: "#61A447" }}
                    >
                        <FaFileExcel size={24} />
                    </div>
                break;

            case "pptx":
                icon =
                    <div className={`${styles.fileIcon}`}
                        style={{ backgroundColor: "#EEFF41" }}
                    >
                        <FaFilePowerpoint size={24} />
                    </div>
                break;

            default:
                break;
        }

        return icon;
    }

    return (
        setIcon()
    )
}


export default FileIcon;