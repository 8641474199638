import React, { useEffect, useState } from 'react';

import styles from "./DocumentList.module.css";

import { Modal, Progress } from "semantic-ui-react";


import Button from '../../_base/button';

import {
    FaAngleDown,
    FaFileAlt,
    FaFileWord,
    FaFileExcel,
    FaFilePdf,
    FaFilePowerpoint,
    FaTimes,
    FaImage
} from "react-icons/fa";

import Pagination from '../../_base/Pagination';

import { getCollection } from '../../../api/documents/requests';

const DocumentListModal = ({
    serviceTypeId,
    visibility,
    onOpenModal,
    onCloseModal,
    serviceName
}) => {

    const [uploaded_documents, setUploaded_Documents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [increment, setIncrement] = useState(0);

    const [rpp, setRpp] = useState(8);
    const [currPage, setCurrentPage] = useState(1);
    const [trimmedDocuments, setTrimmedDocuments] = useState([]);
    
    const [data, resetData] = useState([]);

    useEffect(() => {

        getCollectionByServiceId();

    }, []);

    const getCollectionByServiceId = async () => {

        setLoading(true);

        try {

            let data = await getCollection(serviceTypeId);

            setCount(data.length);

            await convertUrlToFile(data);

            setLoading(false);

        } catch (error) {

            setLoading(false);

        }
    }

    const convertUrlToFile = async (docs) => {

        if (docs.length == 0) return;

        let newDocArray = [];

        for (let index = 0; index < docs.length; index++) {

            const element = docs[index];

            try {

                if (element.document_url != null) {

                    let fileObject = {
                        fileName: "",
                        fileSize: 0,
                        fileType: "",
                        file: "",
                        upload_or_generate: docs[index].upload_or_generate,
                        id: docs[index].id,
                        // availability: docs.availability,
                        document_id: docs[index].document_id,
                        label: docs[index].label
                    };

                    let response = await fetch(element.document_url);
                    let data = await response.blob();
                    let metadata = {
                        type: 'image/jpeg'
                    };

                    let file = new File([data], element.document_name, metadata);

                    let lastStr = file.name.lastIndexOf(".");

                    let fileType = file.name.substring(lastStr + 1);

                    fileObject.fileName = file.name;
                    fileObject.fileSize = file.size;
                    fileObject.fileType = fileType;

                    const reader = new FileReader();

                    reader.readAsDataURL(file);

                    reader.onload = (e) =>
                        fileObject.file = e.target.result;


                    newDocArray.push(fileObject);

                    setIncrement(index);

                }

            } catch (error) {

                console.log(error);

            };

        }

        resetData([...newDocArray]);

    }


    const uploadedFileIcon = (fileType) => {

        let icon = null;

        switch (fileType) {
            case "pdf":
                icon = <div className={`${styles.fileIcon} ${styles.pdfType}`}>
                    <FaFilePdf size={24} />
                </div>
                break;

            case "docx":
                icon = <div className={`${styles.fileIcon} ${styles.wordType}`}>
                    <FaFileWord size={24} />
                </div>
                break;

            case "document":
                icon = <div className={`${styles.fileIcon} ${styles.fileType}`}>
                    <FaFileWord size={24} />
                </div>
                break;

            case "xls":
            case "xlsx":
            case "sheet":
                icon = <div className={`${styles.fileIcon} ${styles.xslType}`}>
                    <FaFileExcel size={24} />
                </div>
                break;

            case "pptx":
                icon = <div className={`${styles.fileIcon} ${styles.pptType}`}>
                    <FaFilePowerpoint size={24} />
                </div>
                break;

            default:
                icon = <div className={`${styles.fileIcon} ${styles.default}`}>
                    <FaImage size={24} />
                </div>
                break;
        }

        return icon;

    }


    return (
        <Modal
            centered={true}
            open={visibility}
            onOpen={onOpenModal}
            onClose={onCloseModal}
            closeOnDimmerClick={false}

        >
            <div className={`${styles.modalContainer} w-auto`}>

                <Button
                    onClick={onCloseModal}
                    className={`${styles.closeModal}`}>
                    <FaTimes className={`ml-2`} size={16} />
                </Button>

                <div className={`${styles.collectionHeader}`}>
                    <div className={`${styles.collectionHeaderIcon}`}>
                        <FaFileAlt size={35} />
                    </div>
                    <div>
                        <h2>{uploaded_documents.length} files in {serviceName}</h2>
                        <p>{`You are viewing all custom documents in our pre-defined collection of ${serviceName} collection`}</p>
                    </div>
                </div>

                <div className={`${styles.documentsList}`}>

                    <div className={`${styles.tabHeader}`}>
                        <ul>
                            <li className={`${styles.active}`}>NQA1</li>
                            <li>NQA2</li>
                            <li>NQA3</li>
                            <li>NQA4</li>
                            <li>NQA5</li>
                            <li>NQA6</li>
                            <li>NQA7</li>
                        </ul>
                    </div>

                    {
                        loading &&
                        <div className={`flex py-16 items-center justify-center`}>

                            <Progress className={`flex items-center justify-center progress w-full`} value={Math.trunc(increment)} total={count} color='olive' progress='ratio' />
                        </div>
                    }

                    {
                        !loading && uploaded_documents.length == 0 && <div className={`px-10 flex justify-center`}>
                            <span className={`text-gray-400 text-2xl py-6`}>No documents found</span>
                        </div>
                    }

                    {
                        !loading && trimmedDocuments.length > 0 && <div className={`${styles.fileList}`}>
                            <div className={`${styles.fileListHeader}`}>
                                <ul className={`${styles.fileListCol}`}>
                                    <li className={`h1`}>
                                        File name
                                        <FaAngleDown size={12} />
                                    </li>
                                    <li className={`h2`}>
                                        File type
                                        <FaAngleDown size={12} />
                                    </li>
                                    <li className={`h3`}>
                                        Author
                                        <FaAngleDown size={12} />
                                    </li>
                                    <li className={`h4`}>
                                        Categories
                                        <FaAngleDown size={12} />
                                    </li>
                                </ul>
                            </div>

                            <div className={`${styles.fileListBody}`}>
                                {trimmedDocuments.map((doc, i) => {
                                    return (
                                        <ul className={`${styles.fileListCol}`} key={i}>
                                            <li>
                                                <div className={`${styles.serviceName}`}>
                                                    <div className={`${styles.fileIconContainer} mr-2`}>
                                                        {uploadedFileIcon(doc.fileType)}
                                                    </div>
                                                    <div className={`${styles.fileContent}`}>
                                                        <span>{doc.label}</span>
                                                        <span>{doc.fileType} / {doc.fileSize} Kb</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>{"Uploaded"}</li>
                                            <li>{`Author`}</li>
                                            <li>{`Resources`}</li>
                                        </ul>
                                    )
                                })}
                            </div>
                            <Pagination
                                rpp={rpp}
                                dataCount={data.length}
                                currPage={currPage}
                                data={data}
                                setCurrPage={setCurrentPage}
                                setTrimmedData={setTrimmedDocuments}
                            />
                        </div>
                    }
                </div>
            </div>
        </Modal>
    );
}

export default DocumentListModal;