import React, { useState } from "react";
import styles from "./FilterByFileTypeModal.module.css";

import {
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaPlay,
  FaTimes,
  FaFile,
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from "../../../_base/button";

const FilterByFileTypeModal = ({
  visibility,
  onOpenModal,
  onCloseModal,
  filterDocuments,
  selectedCollection,
  setSelectedCollection,
}) => {
  console.log("selected c ", selectedCollection);
  const addToSelectedCollection = (id, e) => {
    e.preventDefault();

    let newArr = [...selectedCollection.collectionItems];

    const index = newArr.indexOf(id);

    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      newArr = [...newArr, id];
    }

    setSelectedCollection({ ...selectedCollection, collectionItems: newArr });
  };

  return (
    <Modal
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      {console.log("selected co ", selectedCollection)}
      <div className={`${styles.modalContainer} ${styles.medium}`}>
        <Button onClick={onCloseModal} className={`${styles.closeModal}`}>
          <FaTimes size={16} />
        </Button>

        <div className={`${styles.modalTitle}`}>
          <h1>Choose one or more file types</h1>
        </div>

        <div className={`${styles.modalContent}`}>
          <ul>
            <li
              className={
                selectedCollection.collectionItems.indexOf(1) > -1
                  ? styles.active
                  : ""
              }
              onClick={(e) => addToSelectedCollection(1, e)}
            >
              <div>
                <FaFile size={24} />
                <span>Generated</span>
              </div>
            </li>
            <li
              className={
                selectedCollection.collectionItems.indexOf(2) > -1
                  ? styles.active
                  : ""
              }
              onClick={(e) => addToSelectedCollection(2, e)}
            >
              <div>
                <FaFileWord size={24} />
                <span>Word</span>
              </div>
            </li>
            <li
              className={
                selectedCollection.collectionItems.indexOf(3) > -1
                  ? styles.active
                  : ""
              }
              onClick={(e) => addToSelectedCollection(3, e)}
            >
              <div>
                <FaFileImage size={24} />
                <span>Image</span>
              </div>
            </li>
            <li
              className={
                selectedCollection.collectionItems.indexOf(4) > -1
                  ? styles.active
                  : ""
              }
              onClick={(e) => addToSelectedCollection(4, e)}
            >
              <div>
                <FaPlay size={24} />
                <span>Video</span>
              </div>
            </li>
            <li
              className={
                selectedCollection.collectionItems.indexOf(5) > -1
                  ? styles.active
                  : ""
              }
              onClick={(e) => addToSelectedCollection(5, e)}
            >
              <div>
                <FaFileExcel size={24} />
                <span>Excel</span>
              </div>
            </li>
            <li
              className={
                selectedCollection.collectionItems.indexOf(6) > -1
                  ? styles.active
                  : ""
              }
              onClick={(e) => addToSelectedCollection(6, e)}
            >
              <div>
                <FaFilePdf size={24} />
                <span>PDF</span>
              </div>
            </li>
          </ul>

          <div className={`${styles.actionBtn}`}>
            <Button
              onClick={(e) => {
                setSelectedCollection({
                  ...selectedCollection,
                  collectionId: 2,
                });
                filterDocuments(e);
                onCloseModal();
              }}
              className={`${styles.btnGreen}`}
            >
              FILTER BY FILE TYPE
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterByFileTypeModal;
