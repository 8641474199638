import { FaDownload, FaTimesCircle } from "react-icons/fa";
import styles from "./Newsletter.module.css";

export type IThumbnail = {
  thumbnail_base64: string | ArrayBuffer | null;
  thumbnail_label: string;
  thumbnail_type: string;
  thumbnail_size: number;
  thumbnail_url?: string;
};

type ThumbnailProps = {
  thumbnail?: IThumbnail;
  onChange: (thumbnail?: IThumbnail) => void;
};

const Thumbnail = (props: ThumbnailProps) => {
  const onDownload = () => {
    if (!props.thumbnail) return;

    let a = document.createElement("a");
    a.download = props.thumbnail?.thumbnail_label;
    a.href = (props.thumbnail?.thumbnail_base64 ||
      props.thumbnail.thumbnail_url) as string;
    a.click();
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) return;

    try {
      const files = event.target.files;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        props.onChange?.({
          thumbnail_base64: e.target?.result || null,
          thumbnail_label: files[0].name,
          thumbnail_type: files[0].type.split("/")[1],
          thumbnail_size: files[0].size,
        });
      };
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`${styles.thumbnail} mt-10 pt-14 border-t border-gray-200`}>
      <h3 className="mb-5">
        Upload a thumbnail (400x285px) <span className="text-red-400">*</span>
      </h3>
      {!props.thumbnail ? (
        <div className={styles.uploadFile}>
          <label className={styles.blueButton}>
            <input onChange={onFileChange} type="file" accept="image/*" />
            BROWSE COMPUTER
          </label>
        </div>
      ) : (
        <div className="flex justify-between border border-gray-200 p-10 mx-10">
          <div className="flex gap-x-5">
            <div
              className={`bg-cover bg-center`}
              style={{
                width: "95px",
                height: "130px",
                backgroundImage: `url(${
                  props.thumbnail.thumbnail_base64 ||
                  props.thumbnail.thumbnail_url
                })`,
              }}
            ></div>
            <div className="flex flex-col items-start justify-center">
              <p className={styles.photoName}>
                {props.thumbnail.thumbnail_label}
              </p>
              <p className={styles.photoSize}>
                {`${props.thumbnail.thumbnail_type?.toUpperCase()} / ${
                  props.thumbnail.thumbnail_size
                }`}{" "}
                Kb
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="flex gap-x-3 my-auto">
              <button className={styles.blueButton} onClick={onDownload}>
                DOWNLOAD
                <FaDownload size={19} className="ml-2" />
              </button>
              <button
                className={`${styles.removeFile} ${styles.blueButton}`}
                onClick={() => props.onChange(undefined)}
              >
                REMOVE FILE
                <FaTimesCircle size={19} className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Thumbnail;
