import React, { useState, useRef, useEffect } from "react";
import styles from "./AddNewService.module.css";

import { Editor } from '@tinymce/tinymce-react';
import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { sweetAlert } from "components/_base/alerts/sweetAlert";
import { exportPdf } from "api/requests";
import { FaSpinner } from "react-icons/fa";
import {  useLocation } from "react-router-dom";

const plugins = [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
];

const toolbar = ['undo redo | formatselect | ' +
    'image | table |' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat | help'];

const docDefinition = (pdfFile) => {
    return {
        content: [
            pdfFile
        ],
        styles: {
            b: { bold: false },
            strong: { bold: false },
        }
    }
}

const TextEditor = ({ editorState,
    setEditorState,
    specificDocState,
    setSpecificDocState,
    setGeneratedFiles,
    generatedFiles
}) => {
    const location = useLocation();
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const inputRef = useRef();
    const editorRef = useRef(null);

    const [loaded, setLoaded] = useState(false);
    const [htmlRaw, setHtmlRaw] = useState("");
    const [isLoaded, scriptLoaded] = useState(false);
    const [initialValue, setInitialValue] = useState("");

    useEffect(() => {
        console.log(specificDocState);
        if (specificDocState?.content) {
            setInitialValue(specificDocState.content);
        }

    }, [specificDocState?.fileType]);


    const onChange = (e) => {
        setEditorState(e.target.getContent());
        setHtmlRaw(e.target.getContent());
    }

    const convertHTMLToPdf = async () => {

        if (!specificDocState.file_title) {
            sweetAlert("error", "Please provide file title");
            return;
        }

        let pdfFile = htmlToPdfmake(htmlRaw, {
            tableAutoSize: true
        });

        let pdfDocGenerator = pdfMake.createPdf(docDefinition(pdfFile));

        pdfDocGenerator.getBlob((blob) => {
            console.log(blob);
            const newFile = new File([blob], specificDocState.file_title, {
                type: blob.type,
            });

            let fileObject = {
                fileName: "",
                fileSize: 0,
                fileType: "",
                file: ""
            };

            fileObject.fileName = newFile.name;
            fileObject.fileSize = newFile.size;
            fileObject.fileType = newFile.type;

            const reader = new FileReader();

            reader.readAsDataURL(newFile);

            let attachment = {
                base64_image: "",
                file_name: newFile.name,
            }

            reader.onload = (e) => {
                fileObject.file = e.target.result;
                attachment.base64_image = e.target.result;
            }

            let existingData = specificDocState.attachments || [];

            existingData.push(attachment);

            setGeneratedFiles([...generatedFiles, fileObject]);

            setSpecificDocState({ ...specificDocState, attachments: existingData });
        });
    }

    useEffect(() => {

    }, []);

    return (
        <div>
            <Editor
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={`${initialValue}`}
                init={{
                    height: 500,
                    menubar: true,
                    plugins: plugins,
                    toolbar: toolbar,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
                onChange={(e) => onChange(e)}
            />
        </div>
    );

}

export default TextEditor;