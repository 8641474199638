import React from 'react'
import styles from '../EditNQADocument.module.css'
import { FaFileAlt, FaInfoCircle, FaPlayCircle, FaLayerGroup } from "react-icons/fa";

export default function ChooseDocumentType({
    docTypeID,
    setDocType,
    disableDocTypes = []
    }) {

    const setHandler = (e, type) => {
        if (disableDocTypes?.includes(type)) return;
        setDocType(e, type);
    };

    return (
        <div className={styles.chooseDocumentType}>
            <span className={styles.label}>Choose document type</span>
                <ul>
                    <li onClick={(e) => setHandler(e, 1)}
                        style={ disableDocTypes?.includes(1) ? { pointerEvents: 'none' }: {}}
                        className={docTypeID == 1 ? styles.active : null}>
                         <FaInfoCircle size={18} />
                         Policy
                    </li>
                    <li onClick={(e) => setHandler(e, 2)}
                        style={ disableDocTypes?.includes(2) ? { pointerEvents: 'none' }: {}}
                        className={docTypeID == 2 ? styles.active : null}>
                        <FaFileAlt size={18} />
                        Procedure
                    </li>
                    <li onClick={(e) => setHandler(e, 3)}
                        style={ disableDocTypes?.includes(3) ? { pointerEvents: 'none' }: {}}
                        className={docTypeID == 3 ? styles.active : null}>
                        <FaPlayCircle size={18} />
                        Webinar
                    </li>
                    <li onClick={(e) => setHandler(e, 4)}
                        style={ disableDocTypes?.includes(4) ? { pointerEvents: 'none' }: {}}
                        className={docTypeID == 4 ? styles.active : null}>
                        <FaLayerGroup size={18} />
                        Training modules
                    </li>
                </ul>
        </div>
    )
}
