import React, { useEffect, useState } from "react";
import styles from "./NQADocuments.module.css";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import UserLayout from "../../components/userLayout/UserLayout";

import LargeSpinner from "../../components/spinner/LargeSpinner";

import Button from "../../components/_base/button";

import FilterByCollectionsModal from "../../components/popups/documents/filterByCollectionsModal";
import FilterByFileTypeModal from "../../components/popups/documents/filterByFileTypeModal";
import FilterByServicesModal from "../../components/popups/documents/filterByServicesModal";
import DeleteDocumentConfirmationModal from "../../components/popups/documents/deleteDocumentConfirmation";
import LinkToServicesModal from "../../components/popups/documents/linkToServiceModal";
import RemoveFromCollectionModal from "../../components/popups/documents/removeFileFromACollection";
import DeleteMultipleDocumentConfirmationModal from "../../components/popups/documents/deleteMultipleDocumentConfirmation";

import DeleteMultipleDocumentSuccessModal from "../../components/popups/documents/deleteMultipleDocumentSuccess";
import DeleteMultipleDocumentUndoSuccessModal from "../../components/popups/documents/deleteMultipleDocumentUndoSuccess";
import RemoveDoumentsFromServicesModal from "../../components/popups/documents/removeDocumentsFromServices";
import AddDocumentsToServiceModal from "../../components/popups/documents/addDocumentToService";
import AddFileToACollectionModal from "../../components/popups/documents/addFileToACollection";
import RemoveFileFromACollectionModal from "../../components/popups/documents/removeFileFromACollection";
import AddDocumentToACollectionModal from "../../components/popups/documents/addDocumentToACollection";

import RemoveFromServicesModal from "../../components/popups/documents/removeFromServicePopup";
import AddToServicesModal from "../../components/popups/documents/addToServiceModal";

import RemoveFromServicesConfirmationModal from "../../components/popups/documents/removeFromServicesConfirmationModal";
import RemoveOKUndoFromServicesModal from "../../components/popups/documents/removeOKUndoFromServicesModal";
import RemoveFromServicesOKModal from "../../components/popups/documents/removeFromServicesOKModal";

import AddDocumentsToServicesConfirmationModal from "../../components/popups/documents/addDocumentsToServicesConfirmationModal";
import AddOKUndoToServicesModal from "../../components/popups/documents/addOKUndoToServicesModal";
import AddDocumentsToServicesOKModal from "../../components/popups/documents/addDocumentsToServicesOKModal";

import UnlinkToServicesModal from "../../components/popups/documents/unLinkToServiceModal";

import axios from "axios";
import isEmpty from "lodash/isEmpty";

import LongDayCare from "../../components/_base/svg/LongDayCare";
import FamilyDayCare from "../../components/_base/svg/FamilyDayCare";
import OOSH from "../../components/_base/svg/OOSH";
import PreSchool from "../../components/_base/svg/PreSchool";

import { headerSort } from "../../helpers/headerSort";

import { sweetAlert } from "../../components/_base/alerts/sweetAlert";

import {
  mapDocument,
  mapDocTypeAndCategory,
  fileOrigin,
} from "../../helpers/document/documentHelper";

import {
  DOC_CATEGORY,
  SERVICE_TYPE,
  FILTER_ID,
  GLOBAL_WEBINAR,
  DOCUMENT_TYPE,
} from "../../helpers/document/constant";

import UploadFileIcon from "../../components/document/documentIcon/UploadFileIcon";

import fileDownload from "js-file-download";

import moment from "moment";

import {
  getAllDocument,
  deleteMultipleDocument,
  filterDocumentByServices,
  filterDocumentByCollection,
  filterDocumentByFileType,
  documentSoftRemoveMultiple,
  documentPermanentRemoveMultiple,
  documentUndoSoftRemoveMultiple,
} from "../../api/documents/requests";
import {
  FaAngleDown,
  FaCog,
  FaEdit,
  FaEye,
  FaFolderMinus,
  FaFolderPlus,
  FaTimesCircle,
  FaBuffer,
  FaFile,
  FaFilter,
  FaDownload,
  FaPlay,
  FaFileExcel,
  FaFilePowerpoint,
  FaFilePdf,
  FaFileWord,
  FaEraser,
  FaSadCry,
} from "react-icons/fa";

const itemsPerPage = 10;

const NQADocuments = () => {
  const history = useHistory();

  const [uploaded_documents, setUploaded_Documents] = useState([]);
  const [uploaded_documentsBckup, setUploaded_DocumentsBckup] = useState([]);
  const [selectedNQA, setSelectedNQA] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [trimmedData, setTrimmedData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [asc, setAsc] = useState(true);

  const [showFilterByCollectionsModal, setShowFilterByCollectionsModal] =
    useState(false);
  const [showFilterByFileTypeModal, setShowFilterByFileTypeModal] =
    useState(false);
  const [showFilterByServicesModal, setShowFilterByServicesModal] =
    useState(false);
  const [showDeleteDocumentModal, setShowDeleteDocumentConfirmationModal] =
    useState(false);
  const [showLinkToServiceModal, setShowLinkToServiceModal] = useState(false);
  const [showRemoveFromAcollectionModal, setShowRemoveFromACollectionModal] =
    useState(false);
  const [
    showDeleteMultipleDocumentConfirmationModal,
    setShowDeleteMultipleDocumentConfirmationModal,
  ] = useState(false);
  // const [showAddToCollectionModal, setShowAddToCollectionModal] = useState(false);
  const [
    showDeleteMultipleDocumentSuccessModal,
    setShowDeleteMultipleDocumentSuccessModal,
  ] = useState(false);
  const [
    showDeleteMultipleDocumentUndoSuccessModal,
    setShowDeleteMultipleDocumentUndoSuccessModal,
  ] = useState(false);
  const [
    showRemoveDocumentsFromServicesModal,
    setShowRemoveDocumentsFromServicesModal,
  ] = useState(false);
  const [showAddDocumentsToServiceModal, setShowAddDocumentsToServiceModal] =
    useState(false);
  const [showAddFileToACollectionModal, setShowAddFileToACollectionModal] =
    useState(false);
  const [
    showRemoveFileFromACollectionModal,
    setShowRemoveFileFromACollectionModal,
  ] = useState(false);
  const [
    showAddDocumentToACollectionModal,
    setShowAddDocumentToACollectionModal,
  ] = useState(false);

  const [showRemoveFromServicesModal, setShowRemoveFromServicesModal] =
    useState(false);
  const [showAddToServicesModal, setShowAddToServicesModal] = useState(false);

  const [showUnlinkServicesModal, setShowUnlinkServicesModal] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});

  const [selectAll, setSelectAll] = useState(false);

  const [headerShowDocumentActions, setHeaderShowDocumentActions] =
    useState(false);
  const [showDocumentActions, setShowDocumentActions] = useState(false);

  const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);

  const [
    showRemoveFromServicesConfirmationModal,
    setShowRemoveFromServicesConfirmationModal,
  ] = useState(false);
  const [showRemoveOKUndoFromServicesModal, setRemoveOKUndoFromServicesModal] =
    useState(false);
  const [showRemoveFromServicesOKModal, setShowRemoveFromServicesOKModal] =
    useState(false);
  const [
    showAddDocumentsToServicesConfirmationModal,
    setShowAddDocumentsToServicesConfirmationModal,
  ] = useState(false);
  const [showAddOKUndoToServicesModal, setShowAddOKUndoToServicesModal] =
    useState(false);
  const [
    showAddDocumentsToServicesOKModal,
    setShowAddDocumentsToServicesOKModal,
  ] = useState(false);

  const [headerActionsPayload, setHeaderActionsPayloadPayload] = useState({});
  const [services, setServices] = useState([]);

  const [selectedCollection, setSelectedCollection] = useState({
    collectionId: null,
    collectionItems: [],
  });

  useEffect(() => {
    fetchAllDocs();
  }, []);

  useEffect(() => {
    mapDocumentsToActiveTab(selectedNQA || 1);
  }, [uploaded_documentsBckup]);

  useEffect(() => {}, [services]);

  const fetchAllDocs = async () => {
    setLoading(true);

    try {
      let data = await getAllDocument();

      let docArr = await mapDocument(data);

      setUploaded_Documents([...docArr]);

      setUploaded_DocumentsBckup([...docArr]);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onDownloadFile = (fileUrl, fileName, fileType) => {
    // axios
    //   .get(fileUrl, {
    //     responseType: `blob`,
    //   })
    //   .then((res) => {
    //     fileDownload(res.data, fileName);
    //   });

    fetch(fileUrl).then((response) => {
      response.blob().then((blob) => {
        let Url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = Url;
        a.download = fileName + "." + fileType;
        a.click();
      });
    });
  };

  const setMenu = (doc) => {
    setSelectedDoc({ ...doc });
    setShowDocumentActions(true);
  };

  const filterDocumentsByServicesId = async (idArr) => {
    if (idArr.length <= 0) return;

    setLoading(true);

    try {
      let payload = { service_id: idArr };

      let data = await filterDocumentByServices(payload);

      if (data.length > 0) {
        let docArr = await mapDocument(data);
        setUploaded_DocumentsBckup(docArr);
      } else {
        sweetAlert("info", " No result found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const addToSelected = (doc) => {
    let docArr = uploaded_documents;

    docArr.forEach((d, i) => {
      if (d.id == doc.id) {
        d.selected = !d.selected;
      }
    });

    setUploaded_Documents([...docArr]);
  };

  const filterDocumentsByCollectionId = async (idArr) => {
    if (idArr.length <= 0) return;

    setLoading(true);

    try {
      let payload = { service_type_id: idArr };

      let data = await filterDocumentByCollection(payload);

      if (data.length > 0) {
        let docArr = await mapDocument(data);

        setUploaded_DocumentsBckup([...docArr]);
      } else {
        sweetAlert("info", " No result found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onFilterByServicesId = (id) => {
    if (selectedCollection.collectionItems.length <= 0) return;

    let newArr = [...selectedCollection.collectionItems];

    let index = -1;

    let serviceIds = selectedCollection.collectionItems?.map((item) => {
      return item.id;
    });

    index = serviceIds?.indexOf(id);

    if (index > -1) {
      newArr = newArr.filter((service) => service.id != id);
    } else {
      let newService = services.filter((service) => service.id == id);
      newArr = [...newArr, newService];
    }

    if (newArr.length <= 0) return;

    setSelectedCollection({ ...selectedCollection, collectionItems: newArr });

    filterDocumentsByServicesId(newArr.map((service) => service.id));
  };

  const onFilterByCollectionId = (id) => {
    if (selectedCollection.collectionItems.length <= 0) return;

    let newArr = [...selectedCollection.collectionItems];

    let index = -1;

    index = newArr.indexOf(id);

    if (index > -1) {
      newArr.splice(index, 1);
    } else {
      newArr = [...newArr, id];
    }

    if (newArr.length <= 0) return;

    setSelectedCollection({ ...selectedCollection, collectionItems: newArr });

    filterDocumentsByCollectionId(newArr);
  };

  const onFilterByFileType = async () => {
    if (selectedCollection.collectionItems.length <= 0) return;
    let newArr = [...selectedCollection.collectionItems];

    setLoading(true);

    try {
      let data = await filterDocumentByFileType(newArr);

      if (data.length > 0) {
        let docArr = await mapDocument(data);

        setUploaded_Documents([...docArr]);

        setUploaded_DocumentsBckup([...docArr]);
      } else {
        sweetAlert("info", " No result found");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const sorting = (headerName) => {
    let { data, toAsc } = headerSort(headerName, uploaded_documents, asc);

    setAsc(toAsc);

    setUploaded_Documents(data);
  };

  const selectAllDocs = () => {
    let docArr = uploaded_documents;

    docArr.forEach((d, i) => {
      if (!selectAll) {
        d.selected = true;
      } else {
        d.selected = false;
      }
    });

    setSelectAll(!selectAll);

    setUploaded_Documents([...docArr]);
  };

  const multipleDelete = async (e) => {
    e.preventDefault();

    try {
      let result = null;

      let selectedDocsId = uploaded_documents
        .filter((i) => i.selected)
        .map((a) => {
          return a.id;
        });

      result = await documentSoftRemoveMultiple(selectedDocsId);

      if (result) {
        setShowDeleteMultipleDocumentSuccessModal(true);

        setShowDeleteMultipleDocumentConfirmationModal(false);
      }
    } catch (error) {}
  };

  const multiplePermanentDelete = async (e) => {
    e.preventDefault();

    try {
      let result = null;

      let selectedDocsId = uploaded_documents
        .filter((i) => i.selected)
        .map((a) => {
          return a.id;
        });

      result = await documentPermanentRemoveMultiple(selectedDocsId);

      if (result) {
        sweetAlert(
          "success",
          `${
            selectedDocsId > 1 ? "Documents" : "Document"
          } permanently deleted!`
        );

        setShowDeleteMultipleDocumentSuccessModal(false);

        setUploaded_Documents([
          ...uploaded_documents.filter((i) => !i.selected),
        ]);

        // fetchAllDocs();
      }
    } catch (error) {}
  };

  const multipleUndoDelete = async (e) => {
    e.preventDefault();

    try {
      let result = null;

      let selectedDocsId = uploaded_documents
        .filter((i) => i.selected)
        .map((a) => {
          return a.id;
        });

      result = await documentUndoSoftRemoveMultiple({
        document_id: selectedDocsId,
      });

      if (result) {
        setShowDeleteMultipleDocumentUndoSuccessModal(true);

        setShowDeleteMultipleDocumentSuccessModal(false);

        fetchAllDocs();
      }
    } catch (error) {}
  };

  const onDeleteForever = async (doc, e) => {
    e.preventDefault();
    setSelectedDoc({ ...doc });
    setShowDeleteDocumentConfirmationModal(true);
  };

  const onAddFileToACollection = async (doc, e) => {
    e.preventDefault();
    setSelectedDoc({ ...doc });
    setShowDocumentActions(false);
    setShowAddFileToACollectionModal(true);
  };

  const onRemoveFileFromACollection = async (doc, e) => {
    e.preventDefault();
    setSelectedDoc({ ...doc });
    setShowDocumentActions(false);
    setShowRemoveFileFromACollectionModal(true);
  };

  const onAddDocumentToACollectionModal = async (doc, e) => {
    e.preventDefault();

    setSelectedDoc({ ...doc });

    setShowDocumentActions(false);

    setShowAddDocumentToACollectionModal(true);
  };

  const onMouseLeave = () => {
    // setSelectedDoc({});
    setShowDocumentActions(false);
    setHeaderShowDocumentActions(false);
  };

  const mapDocumentsToActiveTab = (activeTab) => {
    let docArr = [];

    if (uploaded_documentsBckup.length > 0) {
      docArr = uploaded_documentsBckup.filter(
        (d) => d.nqa_area_id.indexOf(activeTab) > -1
      );

      if (docArr.length > 0) {
        docArr = docArr
          .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
          .reverse();
      }

      setCurrentPage(activeTab == selectedNQA ? currentPage : 1);

      setUploaded_Documents(docArr);
    }

    setSelectedNQA(activeTab);
  };

  const mapDocType = (doc) => {
    let docTypes = mapDocTypeAndCategory(doc, selectedNQA).doctype;

    if (docTypes.length < 1) return "";

    return docTypes.map((item) => {
      return (
        <span>
          {item}
          <br />
        </span>
      );
    });
  };

  return (
    <UserLayout headerText={`NQA1 - Educational Programs and Practice`}>
      {showFilterByCollectionsModal && (
        <FilterByCollectionsModal
          visibility={showFilterByCollectionsModal}
          onOpenModal={() => setShowFilterByCollectionsModal(true)}
          onCloseModal={() => setShowFilterByCollectionsModal(false)}
          setSelectedCollection={setSelectedCollection}
          selectedCollection={selectedCollection}
          filterDocuments={filterDocumentsByCollectionId}
        />
      )}

      {showFilterByFileTypeModal && (
        <FilterByFileTypeModal
          visibility={showFilterByFileTypeModal}
          onOpenModal={() => setShowFilterByFileTypeModal(true)}
          onCloseModal={() => setShowFilterByFileTypeModal(false)}
          setSelectedCollection={setSelectedCollection}
          selectedCollection={selectedCollection}
          filterDocuments={onFilterByFileType}
        />
      )}

      {showFilterByServicesModal && (
        <FilterByServicesModal
          visibility={showFilterByServicesModal}
          onOpenModal={() => setShowFilterByServicesModal(true)}
          onCloseModal={() => setShowFilterByServicesModal(false)}
          // setUploaded_Documents={setUploaded_Documents}
          setSelectedCollection={setSelectedCollection}
          selectedCollection={selectedCollection}
          filterDocuments={filterDocumentsByServicesId}
          setAllServices={setServices}
        />
      )}

      {showUnlinkServicesModal && (
        <UnlinkToServicesModal
          visibility={showUnlinkServicesModal}
          onOpenModal={() => setShowUnlinkServicesModal(true)}
          onCloseModal={() => setShowUnlinkServicesModal(false)}
          selectedDoc={selectedDoc}
          fetchAllDocs={fetchAllDocs}
        />
      )}

      {showDeleteDocumentModal && (
        <DeleteDocumentConfirmationModal
          visibility={showDeleteDocumentModal}
          onOpenModal={() => setShowDeleteDocumentConfirmationModal(true)}
          onCloseModal={() => setShowDeleteDocumentConfirmationModal(false)}
          selectedDoc={selectedDoc}
          uploaded_documents={uploaded_documents}
          setUploaded_Documents={setUploaded_Documents}
        />
      )}

      {showLinkToServiceModal && (
        <LinkToServicesModal
          visibility={showLinkToServiceModal}
          onOpenModal={() => setShowLinkToServiceModal(true)}
          onCloseModal={() => setShowLinkToServiceModal(false)}
          documentId={selectedDoc.id}
        />
      )}
      {showRemoveFromAcollectionModal && (
        <RemoveFromCollectionModal
          visibility={showRemoveFromAcollectionModal}
          onOpenModal={() => setShowRemoveFromACollectionModal(true)}
          onCloseModal={() => setShowRemoveFromACollectionModal(false)}
          documentId={selectedDoc.id}
        />
      )}

      {showDeleteMultipleDocumentConfirmationModal && (
        <DeleteMultipleDocumentConfirmationModal
          visibility={showDeleteMultipleDocumentConfirmationModal}
          onOpenModal={() =>
            setShowDeleteMultipleDocumentConfirmationModal(true)
          }
          onCloseModal={() =>
            setShowDeleteMultipleDocumentConfirmationModal(false)
          }
          multipleDelete={multipleDelete}
          count={uploaded_documents.filter((i) => i.selected == true).length}
        />
      )}

      {showDeleteMultipleDocumentSuccessModal && (
        <DeleteMultipleDocumentSuccessModal
          visibility={showDeleteMultipleDocumentSuccessModal}
          onOpenModal={() => setShowDeleteMultipleDocumentSuccessModal(true)}
          onCloseModal={() => setShowDeleteMultipleDocumentSuccessModal(false)}
          multiplePermanentDelete={multiplePermanentDelete}
          multipleUndoDelete={multipleUndoDelete}
          count={uploaded_documents.filter((i) => i.selected == true).length}
        />
      )}

      {showDeleteMultipleDocumentUndoSuccessModal && (
        <DeleteMultipleDocumentUndoSuccessModal
          visibility={showDeleteMultipleDocumentUndoSuccessModal}
          onOpenModal={() =>
            setShowDeleteMultipleDocumentUndoSuccessModal(true)
          }
          onCloseModal={() =>
            setShowDeleteMultipleDocumentUndoSuccessModal(false)
          }
          count={uploaded_documents.filter((i) => i.selected == true).length}
        />
      )}

      {showRemoveDocumentsFromServicesModal && (
        <RemoveDoumentsFromServicesModal
          visibility={showRemoveDocumentsFromServicesModal}
          onOpenModal={() => setShowRemoveDocumentsFromServicesModal(true)}
          onCloseModal={() => setShowRemoveDocumentsFromServicesModal(false)}
          count={uploaded_documents.filter((i) => i.selected == true).length}
          uploaded_documents={uploaded_documents}
          fetchAllDocs={fetchAllDocs}
        />
      )}

      {!isEmpty(selectedDoc) && showAddFileToACollectionModal && (
        <AddFileToACollectionModal
          visibility={showAddFileToACollectionModal}
          onOpenModal={() => setShowAddFileToACollectionModal(true)}
          onCloseModal={() => setShowAddFileToACollectionModal(false)}
          docId={selectedDoc.id}
          document={selectedDoc}
          fetchAllDocs={fetchAllDocs}
        />
      )}

      {showRemoveFileFromACollectionModal && (
        <RemoveFileFromACollectionModal
          visibility={showRemoveFileFromACollectionModal}
          onOpenModal={() => setShowRemoveFileFromACollectionModal(true)}
          onCloseModal={() => setShowRemoveFileFromACollectionModal(false)}
          document={selectedDoc}
          fetchAllDocs={fetchAllDocs}
          setSelectedDoc={setSelectedDoc}
        />
      )}

      {showAddDocumentToACollectionModal && (
        <AddDocumentToACollectionModal
          visibility={showAddDocumentToACollectionModal}
          onOpenModal={() => setShowAddDocumentToACollectionModal(true)}
          onCloseModal={() => setShowAddDocumentToACollectionModal(false)}
          docId={selectedDoc.id}
          fetchAllDocs={fetchAllDocs}
        />
      )}

      {showRemoveFromServicesModal && (
        <RemoveFromServicesModal
          visibility={showRemoveFromServicesModal}
          onOpenModal={() => setShowRemoveFromServicesModal(true)}
          onCloseModal={() => setShowRemoveFromServicesModal(false)}
          uploaded_documents={uploaded_documents}
          setHeaderActionsPayloadPayload={setHeaderActionsPayloadPayload}
          setShowRemoveFromServicesModal={() =>
            setShowRemoveFromServicesModal()
          }
          setShowRemoveFromServicesConfirmationModal={
            setShowRemoveFromServicesConfirmationModal
          }
          services={services}
          setServices={setServices}
        />
      )}

      {showRemoveFromServicesConfirmationModal && (
        <RemoveFromServicesConfirmationModal
          visibility={showRemoveFromServicesConfirmationModal}
          onOpenModal={() => setShowRemoveFromServicesConfirmationModal(true)}
          onCloseModal={() => setShowRemoveFromServicesConfirmationModal(false)}
          showRemoveOKUndoFromServicesModal={() =>
            setRemoveOKUndoFromServicesModal(true)
          }
          documentCount={headerActionsPayload.document_id.length}
        />
      )}

      {showRemoveOKUndoFromServicesModal && (
        <RemoveOKUndoFromServicesModal
          visibility={showRemoveFromServicesConfirmationModal}
          onOpenModal={() => setRemoveOKUndoFromServicesModal(true)}
          onCloseModal={() => setRemoveOKUndoFromServicesModal(false)}
          documentIds={headerActionsPayload.document_id}
          services={services}
          setShowRemoveFromServicesOKModal={setShowRemoveFromServicesOKModal}
          refresh={fetchAllDocs}
        />
      )}

      {showRemoveFromServicesOKModal && (
        <RemoveFromServicesOKModal
          visibility={showRemoveFromServicesOKModal}
          onOpenModal={() => setShowRemoveFromServicesOKModal(true)}
          onCloseModal={() => setShowRemoveFromServicesOKModal(false)}
          documentIds={headerActionsPayload.document_id}
        />
      )}

      {/* Add Services to Documents */}

      {showAddToServicesModal && (
        <AddToServicesModal
          visibility={showAddToServicesModal}
          onOpenModal={() => setShowAddToServicesModal(true)}
          onCloseModal={() => setShowAddToServicesModal(false)}
          uploaded_documents={uploaded_documents}
          setHeaderActionsPayloadPayload={setHeaderActionsPayloadPayload}
          showAddDocumentsToServicesConfirmationModal={() =>
            setShowAddDocumentsToServicesConfirmationModal(true)
          }
          services={services}
          setServices={setServices}
        />
      )}

      {showAddDocumentsToServicesConfirmationModal && (
        <AddDocumentsToServicesConfirmationModal
          visibility={showAddDocumentsToServicesConfirmationModal}
          onOpenModal={() =>
            setShowAddDocumentsToServicesConfirmationModal(true)
          }
          onCloseModal={() =>
            setShowAddDocumentsToServicesConfirmationModal(false)
          }
          documentIds={headerActionsPayload.document_id}
          showAddOKUndoToServicesModal={() =>
            setShowAddOKUndoToServicesModal(true)
          }
        />
      )}

      {showAddOKUndoToServicesModal && (
        <AddOKUndoToServicesModal
          visibility={showAddOKUndoToServicesModal}
          onOpenModal={() => setShowAddOKUndoToServicesModal(true)}
          onCloseModal={() => setShowAddOKUndoToServicesModal(false)}
          documentIds={headerActionsPayload.document_id}
          services={services}
          showAddToServicesOKModal={() =>
            setShowAddDocumentsToServicesOKModal(true)
          }
          refresh={fetchAllDocs}
        />
      )}

      {showAddDocumentsToServicesOKModal && (
        <AddDocumentsToServicesOKModal
          visibility={showAddDocumentsToServicesOKModal}
          onOpenModal={() => setShowAddDocumentsToServicesOKModal(true)}
          onCloseModal={() => setShowAddDocumentsToServicesOKModal(false)}
          documentIds={headerActionsPayload.document_id}
        />
      )}

      <div className={styles.documentsList}>
        <div className={styles.tabHeader}>
          <ul>
            <li
              className={selectedNQA == 1 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(1)}
            >
              NQA1
            </li>
            <li
              className={selectedNQA == 2 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(2)}
            >
              NQA2
            </li>
            <li
              className={selectedNQA == 3 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(3)}
            >
              NQA3
            </li>
            <li
              className={selectedNQA == 4 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(4)}
            >
              NQA4
            </li>
            <li
              className={selectedNQA == 5 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(5)}
            >
              NQA5
            </li>
            <li
              className={selectedNQA == 6 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(6)}
            >
              NQA6
            </li>
            <li
              className={selectedNQA == 7 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(7)}
            >
              NQA7
            </li>
            {/* <li          
              className={selectedNQA == 8 ? styles.active : null}
              onClick={() => mapDocumentsToActiveTab(8)}
            >
              Reports
            </li> */}
          </ul>
        </div>

        <div className={`${styles.filterContainer}`}>
          <div className={`${styles.filterActions}`}>
            <div className={`${styles.actions}`}>
              <span className={`${styles.label} mb-3.5`}>Filters</span>
              <div className={`flex`}>
                <Button
                  className={`${styles.chooseCollection} flex justify-center items-center`}
                  onClick={() => {
                    setSelectedCollection({
                      collectionId: FILTER_ID.FILTER_BY_SERVICE,
                      collectionItems: [],
                    });
                    setShowFilterByServicesModal(true);
                  }}
                >
                  <FaFilter className={`mr-1.5`} size={15} />
                  Filter by service
                </Button>
                <Button
                  className={`${styles.chooseCollection} flex justify-center items-center`}
                  onClick={() => {
                    setSelectedCollection({
                      collectionId: FILTER_ID.FILTER_BY_FILE_TYPE,
                      collectionItems: [],
                    });
                    setShowFilterByFileTypeModal(true);
                  }}
                >
                  <FaFile className={`mr-1.5`} size={15} />
                  Choose file type
                </Button>
                <Button
                  className={`${styles.chooseCollection} flex justify-center items-center`}
                  onClick={() => {
                    setSelectedCollection({
                      collectionId: FILTER_ID.FILTER_BY_COLLECTION,
                      collectionItems: [],
                    });
                    setShowFilterByCollectionsModal(true);
                  }}
                >
                  <FaBuffer className={`mr-1.5`} size={15} />
                  Choose collection
                </Button>
              </div>
            </div>
          </div>

          {selectedCollection.collectionId == FILTER_ID.FILTER_BY_SERVICE &&
            selectedCollection.collectionItems.length > 0 && (
              <div className={styles.filterByServices}>
                <span className={` ${styles.label} mb-3.5`}>
                  Filtered by selected service/s:
                </span>
                <ul>
                  {selectedCollection.collectionItems.map((items) => {
                    return (
                      <li
                        onClick={() => onFilterByServicesId(items.id)}
                        key={items.id}
                      >
                        {items.service_name}
                        <FaTimesCircle size={15} />
                      </li>
                    );
                  })}
                  <li
                    onClick={() => {
                      setSelectedCollection({
                        collectionId: 0,
                        collectionItems: [],
                      });
                      fetchAllDocs();
                    }}
                    className={styles.clearAll}
                  >
                    Clear all
                    <FaEraser size={15} />
                  </li>
                </ul>
              </div>
            )}

          {selectedCollection.collectionId == 2 &&
            selectedCollection.collectionItems.length > 0 && (
              <div className={styles.filterByType}>
                <span className={`${styles.label} mb-3.5`}>
                  Filtered by file type
                </span>

                <ul>
                  {selectedCollection.collectionItems.indexOf(1) > -1 && (
                    <li className={styles.active}>
                      <div>
                        <FaTimesCircle size={14} />
                        <FaFile size={24} />
                        <span>Generated</span>
                      </div>
                    </li>
                  )}
                  {selectedCollection.collectionItems.indexOf(2) > -1 && (
                    <li className={styles.active}>
                      <div>
                        <FaTimesCircle size={14} />
                        <FaFileWord size={24} />
                        <span>Word</span>
                      </div>
                    </li>
                  )}
                  {selectedCollection.collectionItems.indexOf(3) > -1 && (
                    <li className={styles.active}>
                      <div>
                        <FaFileWord size={14} />
                        <FaFileWord size={24} />
                        <span>Image</span>
                      </div>
                    </li>
                  )}
                  {selectedCollection.collectionItems.indexOf(4) > -1 && (
                    <li className={styles.active}>
                      <div>
                        <FaTimesCircle size={14} />
                        <FaPlay size={24} />
                        <span>Video</span>
                      </div>
                    </li>
                  )}
                  {selectedCollection.collectionItems.indexOf(5) > -1 && (
                    <li className={styles.active}>
                      <div>
                        <FaTimesCircle size={14} />
                        <FaFileExcel size={24} />
                        <span>Excel</span>
                      </div>
                    </li>
                  )}
                  {selectedCollection.collectionItems.indexOf(6) > -1 && (
                    <li className={styles.active}>
                      <div>
                        <FaTimesCircle size={14} />
                        <FaFilePdf size={24} />
                        <span>PDF</span>
                      </div>
                    </li>
                  )}

                  <li
                    onClick={() => {
                      setSelectedCollection({
                        collectionId: 0,
                        collectionItems: [],
                      });
                      fetchAllDocs();
                    }}
                    className={styles.clearAll}
                  >
                    <div>
                      <FaTimesCircle size={24} />
                      <span>Clear</span>
                    </div>
                  </li>
                </ul>
              </div>
            )}

          {selectedCollection.collectionId == FILTER_ID.FILTER_BY_COLLECTION &&
            selectedCollection.collectionItems.length > 0 && (
              <div className={styles.servicesFilter}>
                <span className={` ${styles.label} mb-3.5`}>
                  Filter documents by collection
                </span>
                <ul>
                  <li
                    onClick={() =>
                      onFilterByCollectionId(SERVICE_TYPE.LONG_DAYCARE)
                    }
                    className={
                      selectedCollection.collectionItems.indexOf(
                        SERVICE_TYPE.LONG_DAYCARE
                      ) > -1
                        ? styles.active
                        : ""
                    }
                  >
                    <span>
                      <LongDayCare />
                    </span>
                    Long daycare collection
                  </li>
                  <li
                    onClick={() =>
                      onFilterByCollectionId(SERVICE_TYPE.FAMILY_DAYCARE)
                    }
                    className={
                      selectedCollection.collectionItems.indexOf(
                        SERVICE_TYPE.FAMILY_DAYCARE
                      ) > -1
                        ? styles.active
                        : ""
                    }
                  >
                    <span>
                      <FamilyDayCare />
                    </span>
                    Family Daycare collection
                  </li>
                  <li
                    onClick={() =>
                      onFilterByCollectionId(SERVICE_TYPE.PRE_SCHOOL)
                    }
                    className={
                      selectedCollection.collectionItems.indexOf(
                        SERVICE_TYPE.PRE_SCHOOL
                      ) > -1
                        ? styles.active
                        : ""
                    }
                  >
                    <span>
                      <PreSchool />
                    </span>
                    Pre-school collection
                  </li>
                  <li
                    onClick={() => onFilterByCollectionId(SERVICE_TYPE.OOSH)}
                    className={
                      selectedCollection.collectionItems.indexOf(
                        SERVICE_TYPE.OOSH
                      ) > -1
                        ? styles.active
                        : ""
                    }
                  >
                    <span>
                      <OOSH />
                    </span>
                    OOSH collection
                  </li>
                </ul>
              </div>
            )}
        </div>

        {loading && (
          <div
            className={`py-16 bg-white flex h-screen items-center justify-center px-10`}
          >
            <LargeSpinner />
          </div>
        )}
        {}

        {!loading && isEmpty(uploaded_documents) && (
          <div
            className={`${styles.fileList} bg-white flex h-screen items-center justify-center px-10`}
          >
            <span className={`text-gray-400 text-2xl py-6`}>
              No documents found
            </span>
          </div>
        )}

        {!loading && !isEmpty(uploaded_documents) && (
          <div className={styles.fileList}>
            <div className={styles.fileListHeader}>
              <ul className={styles.fileListCol}>
                <li>
                  <label
                    className={`${styles.checkbox} ${
                      selectAll ? styles.selectedAll : ""
                    }`}
                  >
                    <span
                      className={`${styles.checkmark}`}
                      onClick={() => selectAllDocs()}
                    ></span>
                  </label>
                </li>
                <li onClick={() => sorting("filename")}>
                  File name
                  <FaAngleDown size={12} />
                </li>
                <li onClick={() => sorting("upload_or_generate")}>
                  File type
                  <FaAngleDown size={12} />
                </li>
                {/* <li>
                  Author
                  <FaAngleDown size={12} />
                </li> */}
                <li onClick={() => sorting("categoryName")}>
                  Categories
                  <FaAngleDown size={12} />
                </li>
                <li size={12} onClick={() => sorting("services")}>
                  Services
                  <FaAngleDown />
                </li>
                <li size={12} onClick={() => sorting("belongsIn")}>
                  Belongs in
                  <FaAngleDown />
                </li>
                <li>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setHeaderShowDocumentActions(!headerShowDocumentActions);
                      setShowDocumentActions(false);
                    }}
                    className={`${
                      headerShowDocumentActions
                        ? styles.active
                        : styles.notActive
                    }`}
                  >
                    <FaCog size={19} />
                  </Button>
                  <ul
                    className={`${styles.settingMenu} ${
                      headerShowDocumentActions ? "block" : "hidden"
                    }`}
                  >
                    <li>
                      <a onClick={(e) => setShowAddToServicesModal(true)}>
                        <FaEdit size={19} />
                        Add to service
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => setShowRemoveFromServicesModal(true)}>
                        <FaEdit size={19} />
                        Remove from service
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={(e) => {
                          uploaded_documents.filter((i) => i.selected == true)
                            .length == 0
                            ? sweetAlert("error", "Please select document.")
                            : setShowDeleteMultipleDocumentConfirmationModal(
                                true
                              );
                        }}
                      >
                        <FaEdit size={19} />
                        Delete files
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            {console.log("uploaded docs ", uploaded_documents)}
            <div className={`${styles.fileListBody}`}>
              {/* {filteredNqaDocs()
                .slice(
                  currentPage * itemsPerPage - itemsPerPage,
                  currentPage * itemsPerPage
                ) */}
              {uploaded_documents
                .slice(
                  currentPage * itemsPerPage - itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((doc, i) => {
                  return (
                    <ul
                      className={`${styles.fileListCol} ${
                        doc.selected ? styles.selected : ""
                      }`}
                      key={i}
                    >
                      <li>
                        <label className={`${styles.checkbox}`}>
                          <span
                            className={`${styles.checkmark}`}
                            onClick={() => addToSelected(doc)}
                          ></span>
                        </label>
                      </li>
                      <li>
                        <div className={`${styles.serviceName}`}>
                          <div className={`${styles.fileIconContainer} mr-2`}>
                            <UploadFileIcon doc={doc} />
                          </div>
                          <div className={`${styles.fileContent}`}>
                            <span>
                              {doc.document_type_id &&
                              doc.document_type_id >= DOCUMENT_TYPE.WEBINAR &&
                              doc.document_type_id < DOCUMENT_TYPE.FEEDBACK
                                ? doc.webinar_title
                                : doc.file_title}
                            </span>
                            <span
                              className={`${
                                doc.fileType != "" ? "block " : "hidden"
                              }`}
                            >
                              {doc.fileType && doc.fileType + " / "}
                              {doc.fileSize && doc.fileSize + " kb"}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>{fileOrigin(doc)}</li>
                      {/* <li>{""}</li> */}
                      <li>
                        {mapDocTypeAndCategory(doc, selectedNQA).category}
                      </li>
                      <li
                        className={`${
                          doc.services.length > 0 ? "" : "invisible"
                        }`}
                      >
                        {doc.services.length > 0 && (
                          <span>{doc.services[0].name}</span>
                        )}
                        <div className={styles.seeAll}>
                          <a
                            onClick={() => {
                              setSelectedDoc({ ...doc });
                              setShowUnlinkServicesModal(true);
                            }}
                          >
                            {`${doc.servicesCount} Services - See all`}
                            <span>
                              <FaEye size={14} />
                            </span>
                          </a>
                        </div>
                      </li>
                      <li>{mapDocType(doc)}</li>
                      <li
                        data-info={doc}
                        onMouseEnter={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setMenu(doc);
                        }}
                        onMouseLeave={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onMouseLeave();
                        }}
                      >
                        <Button
                          className={`${
                            showDocumentActions &&
                            doc.file_uploaded_id == selectedDoc.file_uploaded_id
                              ? styles.active
                              : styles.notActive
                          }`}
                        >
                          <FaCog size={19} />
                        </Button>
                        <ul
                          data-info={doc}
                          className={`${styles.settingMenu} ${
                            showDocumentActions &&
                            doc.file_uploaded_id == selectedDoc.file_uploaded_id
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          <li>
                            <a
                              onClick={(e) => {
                                history.push({
                                  pathname:
                                    doc.category ===
                                    DOC_CATEGORY.SERVICE_SPECIFIC
                                      ? `/edit-service-document`
                                      : `/edit-document`,
                                  state: { doc: doc },
                                });
                                window.scrollTo({ top: 0 });
                              }}
                            >
                              <FaEdit size={19} />
                              Edit file
                            </a>
                          </li>
                          <li>
                            <a onClick={(e) => onDeleteForever(doc, e)}>
                              <FaTimesCircle size={19} />
                              Delete file
                            </a>
                          </li>
                          <li
                            data-info={doc}
                            className={`${
                              !isEmpty(doc.service_type_id) ||
                              doc.global_webinar == GLOBAL_WEBINAR.GLOBAL
                                ? "hidden"
                                : ""
                            }`}
                          >
                            <a
                              onClick={(e) =>
                                onAddDocumentToACollectionModal(doc, e)
                              }
                            >
                              <FaTimesCircle size={19} />
                              Add to collection
                            </a>
                          </li>
                          <li
                            className={`${
                              doc.category != 3 && !isEmpty(doc.service_type_id)
                                ? ""
                                : "hidden"
                            }`}
                          >
                            <a onClick={(e) => setShowLinkToServiceModal(true)}>
                              <FaFolderPlus size={19} />
                              Add to service
                            </a>
                          </li>
                          <li
                            className={`${
                              !isEmpty(doc.service_type_id) ? "" : "hidden"
                            }`}
                          >
                            <a onClick={(e) => onAddFileToACollection(doc, e)}>
                              <FaFolderPlus size={19} />
                              Add to a collection
                            </a>
                          </li>
                          <li
                            className={`${
                              !isEmpty(doc.service_type_id) ? "" : "hidden"
                            }`}
                          >
                            <a
                              onClick={(e) =>
                                onRemoveFileFromACollection(doc, e)
                              }
                            >
                              <FaFolderMinus size={19} />
                              Remove from a collection
                            </a>
                          </li>

                          <li>
                            <a
                              onClick={(e) => {
                                if (doc.fileUrl) {
                                  onDownloadFile(
                                    doc.fileUrl,
                                    doc.file_title,
                                    doc.fileType
                                  );
                                } else if (doc.webinar_link) {
                                  window.open(doc.webinar_link, "_blank");
                                }
                              }}
                            >
                              <FaDownload size={17} />
                              Download file
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  );
                })}
            </div>
            {!loading && uploaded_documents.length > 0 && (
              <div className={`${styles.pagination} ${styles.flex}`}>
                <Pagination
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                  lastPage={Math.ceil(uploaded_documents.length / itemsPerPage)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </UserLayout>
  );
};

export default NQADocuments;
