import React from 'react';
import styles from "./Pagination.module.css";

const Pagination = ({
    newsLetterPerPage,
    totalNewsLetter,
    paginate,
    currPage
    }) => {

    const pageNumber = [];

    for(let i=1; i <= Math.ceil(totalNewsLetter / newsLetterPerPage); i++){
        pageNumber.push(i);
    }

    const prevPage = (subValue) => subValue > 0 ? paginate(subValue) : null;

    const nextPage = (addValue) => addValue > pageNumber.length ? null : paginate(addValue);


    return (
            
            <div className={`${styles.pagination} flex`}>
                <a onClick={() => prevPage(currPage - 1)} className={"flex items-center justify-center"}>&laquo;</a>
                {   
                    pageNumber.map(number => (
                        <a key={number} onClick={() => paginate(number)} className={`${number == currPage ? styles.active : null} flex items-center justify-center`}>{number}</a>   
                    ))
                }
                <a onClick={() => nextPage(currPage + 1)} className={"flex items-center justify-center"}>&raquo;</a>
            </div>
    )
}

export default Pagination;