import React from 'react'
import styles from "../../../../components/popups/documents/deleteDocumentConfirmation/DeleteDocumentConfirmationModal.module.css";
import { Modal } from 'semantic-ui-react'

import { 
	FaTimes
} from 'react-icons/fa'

import Button from 'components/_base/button/Button'
import { sweetAlert } from 'components/_base/alerts/sweetAlert'

export default function RemovePopUp({
	visibility,
	onShowModal,
	onCloseModal
	}) {

    return (
        <Modal
			centered={true}
			open={visibility}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>
			
			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={() => onCloseModal(false)}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Are you sure you want to permanently delete this document?</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={() => {
								onShowModal(true)
								onCloseModal(false)
							}}
							className={`${styles.btnGreen}`}>
							REMOVE
						</Button>
						<Button
							onClick={() => onCloseModal(false)}
							className={`${styles.btnRed}`}>
							CANCEL
						</Button>

					</div>
				</div>
			</div>
		</Modal>
    )
}
