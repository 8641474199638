import React from "react";
import styles from "./DeleteServiceSuccessModal.module.css";
import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

const DeleteServiceSuccessModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	onUndo,
}) => {
	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<Button
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>This service is now permanently deleted from QIP Portal</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnOk}`}>OK</Button>
						<Button
							// onClick={}
							className={`${styles.btnUndo}`}>UNDO</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default DeleteServiceSuccessModal;