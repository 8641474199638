import React from 'react';
import styles from './DeleteConfirmationModal.module.css';


const DeleteDoc = ({
    showModal,
    deleteDoc,
    closeModal}) =>{


    return(
        <div className={`${styles.modal}`} style={showModal ? {display:"block"} : {display:"none"}}>
            <div className={`${styles.modalContainer} ${styles.small}`}>
                <button className={styles.closeModal} onClick={closeModal}>
                    <svg font-size="16" width="16" height="16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" className="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
                </button>
                <div className={styles.modalTitle}>
                    <h1>Are you sure you want to permanently delete this document?</h1>
                </div>
                <div className={styles.modalContent}>
                    <div className={styles.actionBtn}>
                        <button className={styles.btnGreen} onClick={deleteDoc}>DELETE</button>
                        <button className={styles.btnRed} onClick={closeModal}>CANCEL</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteDoc;