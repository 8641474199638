import React, { useState, useRef } from 'react'
import styles from "../EditNQADocument.module.css";

export default function UploadFiles({
    defaultFile,
    onChangeData,
    removeFile,
    uploadFile,
    handleFileUpload,
    serviceType,
    chooseService,
    services,
    servicesSelected,
    setServicesSelected
    }) {

    const deleteSelectedServices = (e, sId) => {
        setServicesSelected([
            ...servicesSelected.filter((service) => Number(service) !== Number(sId)),
        ]);
        setServicesSelected([
            ...servicesSelected.filter((service) => Number(service) !== Number(sId)),
        ]);
    };

    const handleDownload = (e, dlURL) =>{
        console.log(dlURL)
    }

    return (
        <div className={`${styles.inputContainer} ${styles.uploadFileTabContent}`}>

            <div className={styles.uploadFile} style={ uploadFile.length > 0 ? {display:"none"} : {display:"block"}}>
                <span className={styles.uploadFileText}>Drop files here or browse</span>
                <label className={styles.blueButton}><input type="file" onChange={(e) => handleFileUpload(e.target.files[0])}/> BROWSE COMPUTER</label>
            </div>
            <div className={`${styles.uploadedFile} ${styles.fileOriginUpload}`} style={uploadFile.length > 0 ? {display:"block"} : {display:"none"}}>

                <span>Uploaded file</span>

                    {   
                        uploadFile.map(value =>(                           
                            <ul>
                                {console.log(value)}
                                <li>
                                    <div className={styles.uploadedFileContent}>
                                        <div className={styles.fileIcon}>
                                            <svg aria-hidden="true" focusable="false" font-size="24" height="1em" width="1em" data-prefix="far" data-icon="file-word" className="svg-inline--fa fa-file-word fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z"></path></svg>
                                        </div>
                                        <div className={styles.fileInfo}>
                                            <span className="fileName">{value.label}</span>
                                            <span className="fileSize">{value.type && value.type.toUpperCase()} / {value.size} KB </span>
                                        </div>
                                    </div>
                                    <div className={styles.uploadedFileActionButtons}>
                                        <button 
                                            onClick={(e) =>handleDownload(e, value.url || value.fileUrl)}

                                            className={`${styles.download} ${styles.blueButton}`}>
                                            DOWNLOAD
                                    <svg height="19" width="19" font-size="19" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" className="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg>
                                        </button>
                                        <button 
                                            className={`${styles.removeFile} ${styles.blueButton}`}
                                            onClick={(e) => removeFile(e, value.id)}
                                        >
                                            REMOVE FILE
                                    <svg height="19" width="19" font-size="19" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>
                                        </button>
                                    </div>
                                </li>

                            </ul>
                        ))
                    }

            </div>

            <div className={styles.inputRow}>
                <div className={styles.inputGroup}>
                    <label>File Title</label>
                    <input 
                        value={defaultFile ? defaultFile.file_title : ""}
                        name="file_title"
                        type="text" 
                        placeholder="Type a memorable name for the file"
                        onChange={(e) => onChangeData({
                            ...defaultFile,
                            [e.target.name]: e.target.value
                        })}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label>Version</label>
                    <input 
                        value={defaultFile ? defaultFile.version : ""}
                        name="version"
                        type="text" 
                        placeholder="Enter file version" 
                        onChange={(e) => onChangeData({
                            ...defaultFile,
                            [e.target.name]: e.target.value
                        })}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label>Date</label>
                    <input 
                        value={defaultFile ? defaultFile.date : ""}
                        name="date"
                        type="date" 
                        placeholder="Choose a date" 
                        onChange={(e) => onChangeData({
                            ...defaultFile,
                            [e.target.name]: e.target.value
                        })}
                    />
                </div>
            </div>

            <div className={styles.inputFull}>
                <div className={styles.inputGroup}>
                    <label>Description</label>
                    <textarea 
                        value={defaultFile ? defaultFile.description : ""}
                        name="description"
                        className={styles.inputDesc} 
                        placeholder="Enter a description for the new document"
                        onChange={(e) => onChangeData({
                            ...defaultFile,
                            [e.target.name]: e.target.value
                        })}
                    ></textarea>
                </div>
            </div>
            


            <div className={`${styles.chooseServices} ${styles.fileOriginGenerate} ${styles.standAloneNQAFiles}`}>

                {
                    serviceType.includes(5) 
                    ? serviceType.length > 0 ? <p>Choose one or more services to attach this document to </p> : ""
                    : serviceType.length > 0  ? <p>Choose any service that should NOT see this document</p> : ""
                }

                <ul
                    className={
                        !serviceType.includes(5)
                        ? styles.excludeSelectedServices
                        : styles.selectedServices
                    }
                    >
                    {
                        services
                        .filter((service) =>
                            servicesSelected.some(
                            (selected) => Number(selected) === Number(service.service_id)
                            )
                        )
                        .map((value, index) => (
                            <li
                            key={index}
                            onClick={(e) => deleteSelectedServices(e, value.service_id)}
                            >
                            {value.service_name}
                            <svg
                                height="19"
                                width="19"
                                font-size="19"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="times-circle"
                                className="svg-inline--fa fa-times-circle fa-w-16"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                ></path>
                            </svg>
                            </li>
                        ))
                    }
                </ul>

                <div className={styles.inputActionButton}>

                    <button 
                        onClick={() => chooseService()}
                        className={`${styles.chooseServicesBtn} ${styles.blueButton}`}
                        >CHOOSE SERVICES</button>

                </div>

                
            </div>
            
        </div>
    )
}
