import React, { useState, useRef, useEffect } from "react";
import styles from "../../pages/AddNewService/AddNewService.module.css";
import Button from "../_base/button";
import {
  addServiceSpecificDocument,
  updateServiceSpecificDocument,
  saveDocumentWebinar,
  updateDocumentWebinar,
  deleteWebinarDocument,
  deleteWebinarFile,
} from "../../api/documents/requests";
import { sweetAlert } from "../_base/alerts/sweetAlert";
import Spinner from "../spinner/Spinner";
import fileDownload from "js-file-download";

import isEmpty from "lodash/isEmpty";

import { FaTimesCircle, FaDownload } from "react-icons/fa";

import axios from "axios";

import { requiredValidation } from "../../helpers/service/serviceHelper";

import { TextInput, TextArea } from "../_base";

import FileIcon from "./FileIcon";

import { serviceDocumentPayload } from "../../helpers/document/payloadHelper";
import { DOCUMENT_TYPE } from "../../helpers/document/constant";
import LinkOrDocument from "pages/AddNewDocument/LinkOrDocument";

const UploadServiceSpecificDocument = ({
  serviceStateId,
  setSpecificDocState,
  specificDocState,
  setShowAddServiceSuccess,
  refreshList,
  isEdit,
  setErrors,
  errors,
}) => {
  const inputRef = useRef();

  const [selectedFilename, setSelectedFileName] = useState("");

  const [selectedFileSize, setSelectedFileSize] = useState("");

  const [selectedFileType, setSelectedFileType] = useState("");

  const [isExternalLink, setIsExternalLink] = useState(true);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (specificDocState.id && specificDocState.attachments.length > 0) {
      mapFileData(specificDocState.attachments[0]);
    }
  }, [specificDocState]);

  useEffect(() => {
    if (specificDocState.id) {
      setIsExternalLink(!!specificDocState.webinar_link);
      setSelectedFileType(specificDocState.attachments?.[0]?.file_type);
      setSelectedFileName(specificDocState.attachments?.[0]?.file_label);
      setSelectedFileSize(specificDocState.attachments?.[0]?.file_size);
    }
  }, [specificDocState.id]);

  const mapFileData = (img) => {
    if (!img.file_id) return;

    setSelectedFileName(img.file_label);

    setSelectedFileSize(img.file_size);

    setSelectedFileType(img.file_type);
  };

  const onDownloadFile = (fileUrl, fileName) => {
    axios
      .get(fileUrl, {
        responseType: `blob`,
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  };

  const onSelectFile = (event) => {
    if (!event.target.files || !event.target.files[0]) return;

    try {
      const files = event.target.files;

      const reader = new FileReader();

      reader.readAsDataURL(files[0]);

      let lastStr = files[0].name.lastIndexOf(".");

      let fileType = files[0].name.substring(lastStr + 1);

      setSelectedFileType(fileType);

      setSelectedFileName(files[0].name);

      setSelectedFileSize(files[0].size);

      reader.onload = (e) => {
        let newImgArr = [
          {
            base64_image: e.target.result,
            file_name: files[0].name,
          },
        ];

        setSpecificDocState({
          ...specificDocState,
          attachments: newImgArr,
        });
      };
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddServiceSpecificDocument = async (e) => {
    e.preventDefault();

    if (!serviceStateId) {
      sweetAlert("error", "Please save service first!");
      return;
    }

    if (requiredValidation(specificDocState, setErrors)) return;

    try {
      let payload = serviceDocumentPayload(
        specificDocState,
        serviceStateId,
        ""
      );

      let returnStatus;

      let returnData;
      if (!payload.id) {
        if (payload.document_type_id <= 2 || payload.document_type_id >= 6) {
          console.log("payload add doc ss ", payload);
          const { data, status } = await addServiceSpecificDocument(payload);

          returnStatus = status;

          returnData = data;
        } else if (payload.document_type_id >= 3) {
          const { data, status } = await saveDocumentWebinar(payload);

          returnStatus = status;

          returnData = data;
        }
      } else {
        if (payload.document_type_id <= 2 || payload.document_type_id >= 6) {
          const { data, status } = await updateServiceSpecificDocument(payload);

          returnStatus = status;

          returnData = data;
        } else if (payload.document_type_id >= 3) {
          const { data, status } = await updateDocumentWebinar(payload);

          returnStatus = status;

          returnData = data;
        }
      }

      // setLoading(false);

      if (returnStatus == 200 || returnStatus == 201) {
        if (isEdit) {
          refreshList();
        }
        setShowAddServiceSuccess(true);

        setLoading(false);
      }

      setSpecificDocState({
        ...{
          notify_service: false,
          content: "",
          description: "",
          date: "",
          file_version: "",
          file_title: "",
          service_id: [],
          id: null,
          document_type_id: null,
          nqa_area_id: [],
          service_specific_type: null,
          upload_or_generate: 1,
          attachments: [],
          base64_image: "",
          label: "",
          category: "",
          service_type_id: "",
          webinar_link: "",
          webinar_title: "",
          webinar_description: "",
          services: "",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDocumentInputChange = (fieldName, type, e) => {
    e.preventDefault();

    setErrors({});

    let documentData = Object.assign({}, specificDocState);

    type === "checkbox"
      ? (documentData[fieldName] = e.target.checked)
      : (documentData[fieldName] = e.target.value);

    setSpecificDocState(documentData);
  };

  const renderDocumentTextInput = (
    fieldName,
    value,
    label,
    placeholder,
    type = "text"
  ) => {
    let required =
      label === "File Title" ||
      label === "Version" ||
      label === "Date" ||
      label.includes("Enter a link for the")
        ? true
        : false;

    return (
      <TextInput
        onChange={(e) => handleDocumentInputChange(fieldName, "text", e)}
        value={value}
        name={fieldName}
        textLabel={`${label}`}
        // style={styles.inputGroup}
        placeholder={`${placeholder}`}
        errors={errors}
        // errorStyle={styles.inputGroupLabel}
        type={type}
        required={required}
      />
    );
  };

  const onDeleteFile = async (e) => {
    if (
      specificDocState.id &&
      specificDocState.document_type_id >= DOCUMENT_TYPE.WEBINAR
    ) {
      try {
        const response = await deleteWebinarFile(specificDocState.id);
        console.log(response);
        if (response) {
          sweetAlert("success", "Webinar file deleted!");
        }
      } catch (error) {}
    }

    setSelectedFileName("");
    setSelectedFileSize("");
    setSelectedFileType("");
    setSpecificDocState({ ...specificDocState, attachments: [] });
  };

  return (
    <div className={`${styles.inputContainer}`}>
      {specificDocState.document_type_id === 4 && (
        <div className="px-10 mt-10">
          <LinkOrDocument
            isExternalLink={isExternalLink}
            setIsExternalLink={setIsExternalLink}
            formInputs={{
              webinar_link: specificDocState.webinar_link,
              webinar_title: specificDocState.webinar_title,
              webinar_description: specificDocState.webinar_description,
              date: specificDocState.date,
            }}
            setFormInputs={(data) => {
              setSpecificDocState({
                ...specificDocState,
                ...data,
              });
            }}
            file={
              specificDocState.attachments?.length
                ? {
                    name: selectedFilename,
                    type: selectedFileType,
                    size: selectedFileSize,
                    base64:
                      specificDocState.attachments?.[0]?.base64_image || "",
                  }
                : {}
            }
            onFileChange={(data) => {
              if (data === undefined) {
                onDeleteFile();
                return;
              }
              setSelectedFileType(data.type);
              setSelectedFileName(data.name);
              setSelectedFileSize(data.size);
              setSpecificDocState({
                ...specificDocState,
                attachments: [
                  {
                    base64_image: data.base64,
                    file_name: data.name,
                  },
                ],
              });

              console.log(data.name);
            }}
            showValidation={!isEmpty(errors)}
          />
        </div>
      )}

      {specificDocState.document_type_id === 3 && (
        <>
          <div className={`${styles.inputFull}`}>
            <div className={`${styles.inputGroup}`}>
              {renderDocumentTextInput(
                "webinar_link",
                specificDocState.webinar_link,
                specificDocState.document_type_id == 3
                  ? "Enter a link for the webinar"
                  : "Enter a link for the training module",
                "Paste here",
                "Paste here"
              )}
            </div>
          </div>

          <div className={styles.webinarInputRow}>
            <div className={styles.inputGroup}>
              {renderDocumentTextInput(
                "webinar_title",
                specificDocState.webinar_title,
                "Webinar Title",
                "Type a memorable name for the file"
              )}
            </div>
            <div className={styles.inputGroup}>
              {renderDocumentTextInput(
                "date",
                specificDocState.date,
                "Date",
                "Choose a date",
                "date"
              )}
            </div>
          </div>
        </>
      )}

      {specificDocState.document_type_id === 3 && (
        <div className={`${styles.inputFull}`}>
          <div className={`${styles.inputGroup}`}>
            <TextArea
              className={`${styles.inputDesc}`}
              placeholder="Enter a description for the new document"
              textLabel={`Webinar Description`}
              onChange={(e) =>
                handleDocumentInputChange("webinar_description", "text", e)
              }
              value={specificDocState.webinar_description}
            />
          </div>
        </div>
      )}

      {specificDocState.document_type_id !== 4 && (
        <>
          <div className={`${styles.uploadFile}`}>
            <span className={styles.uploadFileText}>
              Drop files here or browse
            </span>
            <label className={styles.blueButton}>
              <input
                type="file"
                ref={inputRef}
                onChange={onSelectFile}
                type="file"
                accept=".pdf"
              />{" "}
              BROWSE COMPUTER
            </label>
          </div>
          {!isEmpty(errors) && errors["attachments"] && (
            <div className={`-mt-8 ml-10 text-red-400`}>
              {errors["attachments"]}
            </div>
          )}
        </>
      )}

      {specificDocState.document_type_id === 3 && (
        <div className={`${styles.inputFull}`}>
          <div className={`${styles.inputGroup}`}>
            {renderDocumentTextInput(
              "file_title",
              specificDocState.file_title,
              "File Title",
              "Type a memorable name for the file"
            )}
          </div>
        </div>
      )}

      {specificDocState.document_type_id !== 4 && (
        <div
          className={`${styles.uploadedFile} ${styles.fileOriginUpload}`}
          style={{
            display: specificDocState.attachments.length > 0 ? "block" : "none",
          }}
        >
          {specificDocState.document_type_id >= 3 && (
            <span>Upload a supporting document for this</span>
          )}

          <span>Uploaded file</span>

          <ul>
            <li>
              <div className={styles.uploadedFileContent}>
                <FileIcon selectedFileType={selectedFileType} />

                <div className={styles.fileInfo}>
                  <span className={styles.fileName}>{selectedFilename}</span>
                  <span className={styles.fileSize}>
                    {selectedFileType} / {selectedFileSize} Kb
                  </span>
                </div>
              </div>
              <div className={styles.uploadedFileActionButtons}>
                <Button
                  disable={
                    specificDocState.attachments.length < 1 ||
                    !specificDocState.attachments[0].file_url
                  }
                  onClick={(e) =>
                    onDownloadFile(
                      specificDocState.attachments[0].file_url,
                      specificDocState.attachments[0].file_name
                    )
                  }
                  className={`${styles.download} ${styles.blueButton}`}
                >
                  DOWNLOAD
                  <FaDownload size={19} />
                </Button>
                <Button
                  className={`${styles.removeFile} ${styles.blueButton}`}
                  onClick={(e) => onDeleteFile(e)}
                >
                  REMOVE FILE
                  <FaTimesCircle size={19} />
                </Button>
              </div>
            </li>
          </ul>
        </div>
      )}

      {(specificDocState.document_type_id < 3 ||
        specificDocState.document_type_id > 5) && (
        <div className={styles.inputRow}>
          <div className={`${styles.inputGroup}`}>
            {renderDocumentTextInput(
              "file_title",
              specificDocState.file_title,
              "File Title",
              "Type a memorable name for the file"
            )}
          </div>
          <div className={`${styles.inputGroup}`}>
            {renderDocumentTextInput(
              "file_version",
              specificDocState.file_version,
              "Version",
              "Enter file version"
            )}
          </div>
          <div className={`${styles.inputGroup}`}>
            {renderDocumentTextInput(
              "date",
              specificDocState.date,
              "Date",
              "Choose a date",
              "date"
            )}
          </div>
        </div>
      )}

      {(specificDocState.document_type_id < 3 ||
        specificDocState.document_type_id > 5) && (
        <div className={`${styles.inputFull}`}>
          <div className={`${styles.inputGroup}`}>
            <TextArea
              className={`${styles.inputDesc}`}
              placeholder="Enter a description for the new document"
              textLabel={`Description`}
              onChange={(e) =>
                handleDocumentInputChange("description", "text", e)
              }
              value={specificDocState.description}
            />
          </div>
        </div>
      )}

      <div className={`${styles.inputNotify}`}>
        <label className={`${styles.switch}`}>
          <input
            type="checkbox"
            checked={specificDocState.notify_service}
            onChange={(e) =>
              handleDocumentInputChange("notify_service", "checkbox", e)
            }
          />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label>

        <h3>Notify center about new document</h3>

        <p>
          Tip before center update: Check this option only if you are adding a
          new document or editing an existing one.
        </p>
      </div>

      <div className={`${styles.inputActionButton}`}>
        <Button
          className={`${styles.blueButton}`}
          disable={loading}
          onClick={(e) => handleAddServiceSpecificDocument(e)}
        >
          {!loading ? (
            !specificDocState.id ? (
              "ADD DOCUMENT"
            ) : (
              "UPDATE DOCUMENT"
            )
          ) : (
            <Spinner />
          )}
        </Button>
      </div>
    </div>
  );
};

export default UploadServiceSpecificDocument;
