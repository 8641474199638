import React from "react";
 import {
    SketchPicker
} from 'react-color'

import { Modal, Button } from "semantic-ui-react";
import {
    FaTimes
} from "react-icons/fa";

import styles from "./ColorPickerModal.module.css";

const ColorPickerModal = ({
    visibility,
    onOpenModal,
    onCloseModal,
    setBrandColor,
    brandColor
}) => {

    const handleChangeComplete = (color) => {
        setBrandColor(color.hex);
    };

    return (
        <Modal
            size="mini"
            centered={true}
            open={visibility}
            onOpen={onOpenModal}
            onClose={onCloseModal}
            closeOnDimmerClick={false}>

            <div className={`${styles.modalContainer} p-32`}>
                <button className={`${styles.closeModal}`}
                    onClick={onCloseModal}
                >
                    <FaTimes className={`ml-2`} size={16} />
                </button>

                <div className={`${styles.modalTitle}`}>
                    <h1>Select Brand Color</h1>
                </div>

                <SketchPicker color={brandColor} onChange={handleChangeComplete} />

                <div className={styles.modalContent}>
                    <div className={`${styles.actionBtn}`}>
                        <Button
                            className={`select-color w-full`}
                            onClick={onCloseModal}
                            color='olive'>
                            DONE
                        </Button>
                    </div>
                </div>
            </div>
            
        </Modal>
    );
}

export default ColorPickerModal;