import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AddNewService from "../../../pages/AddNewService";

export default function AddNewServiceRoute() {
  const match = useRouteMatch();
 
  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <AddNewService />} />
    </Switch>
  );
}
