
import React, { useState, useRef, useEffect } from "react";
import styles from "./AddNewService.module.css";
import UserLayout from "../../components/userLayout/UserLayout";

import { createService, updateService } from '../../api/services/requests';

import ServiceDetails from "../../components/services/ServiceDetails";
import Branding from "../../components/services/Branding";
import PreDefinedDocumentsCollection from "../../components/services/PreDefinedDocumentsCollection";

import SpecificServiceDocument from '../../components/services/SpecificServiceDocument';
import UploadServiceSpecificDocument from '../../components/services/UploadServiceSpecificDocument';
import GenerateServiceSpecificDocument from '../../components/services/GenerateServiceSpecificDocument';

import AddServiceSuccessModal from '../../components/popups/createService/addServiceSuccessModal';

export const emptySpecificDocState = {
  notify_service: false,
  content: "",
  description: "",
  date: "",
  file_version: "",
  file_title: "",
  service_id: [],
  id: null,
  document_type_id: 0,
  nqa_area_id: [],
  service_specific_type: 0,
  upload_or_generate: 1,
  attachments: [],
  base64_image: "",
  label: "",
  category: "",
  service_type_id: "",
  webinar_link: "",
  webinar_title: "",
  webinar_description: "",
  services: "",
};

const AddNewService = () => {

	const [serviceState, setServiceState] = useState({
		name: "",
		website_link: "",
		primary_email: "",
		phone: "",
		address: "",
		city: "",
		state: "",
		postal: "",
		description: "",
		logo_url: "",
		logo_name: "",
		brand_color: "",
		service_type_id: "",
		is_archive: false,
		id: null,
	});

	const [serviceDetailsChevron, setServiceDetailsChevron] = useState(true);

	const [errors, setErrors] = useState({});

	const [showAddServiceSuccess, setShowAddServiceSuccess] = useState(false);

	const [loading, setLoading] = useState(false);

	const [serviceTypeId, setServiceTypeId] = useState(null);

	const [collectionLoaded, setCollectionLoaded] = useState([]);

	const [specificDocState, setSpecificDocState] = useState(emptySpecificDocState);

	useEffect(() => {

	}, []);

	const handleCreateNewService = async (e) => {

		setLoading(true);

		e.preventDefault();

		setErrors({});	 

		try {

			let returnStatus;

			let returnData;

			if (!serviceState.id)
			{

				const {status, data} = await createService(serviceState);

				returnStatus = status;

				returnData = data;

			} else {

				const {status, data} = await updateService(serviceState);

				returnStatus = status;

				returnData = data;

			}

			if (returnStatus == 200 || returnStatus == 201) {

				setShowAddServiceSuccess(true);

				let newService = Object.assign({}, serviceState);

				newService = { ...newService, id: returnData.id };

				setServiceState(newService);

				setSpecificDocState({
					...specificDocState,
					service_id: [returnData.id],
				});
			}

		} catch (error) {

			if (error.response.data.errors) {

				// sweetAlert("error", "Some fields are missing!");

				setErrors(error.response.data.errors);
			}
		}

		setLoading(false);
	}

	const mapServiceType = (serviceTypeId, e) => {

		e.preventDefault();
		
		setServiceState({ ...serviceState, service_type_id: serviceTypeId });

	}


	return (
		<UserLayout headerText={`Create a new service`}>

			{showAddServiceSuccess && !loading && <AddServiceSuccessModal
				visibility={showAddServiceSuccess}
				onOpenModal={() => setShowAddServiceSuccess(true)}
				onCloseModal={() => setShowAddServiceSuccess(false)}
			/>}

			<ServiceDetails
				serviceState={serviceState}
				setServiceState={setServiceState}
				serviceTypeId={serviceTypeId}
				mapServiceType={mapServiceType}
				serviceDetailsChevron={serviceDetailsChevron}
				setServiceDetailsChevron={setServiceDetailsChevron}
				cb={handleCreateNewService}
				loading={loading}
			/>

			{!loading && <Branding
				serviceId={serviceState.id}
				service={serviceState}
			/>
			}

			{!loading && <PreDefinedDocumentsCollection
				serviceId={serviceState.id}
				collectionLoaded={collectionLoaded}
				refreshList={() => console.log("test")}
			/>
			}

			<div className={`${styles.btnSave} ${styles.generateFileTabContent}`}>
				<button>CREATE THIS SERVICE</button>
			</div>

			<div className={`${styles.addServiceDocu} ${styles.addNewService}`}>

				<SpecificServiceDocument header={specificDocState.upload_or_generate == 1 
					? "Add service-specific documentation" : "Add new documentation"
				}
					setSpecificDocState={setSpecificDocState}
					specificDocState={specificDocState}
				/>

				<div className={styles.fileOrigin}>
					<label className={`${styles.radioBtn} ${styles.inputUploadFile}`}>
						<input type="radio" checked={specificDocState.upload_or_generate == 1 ? true : false} name="inputUpload"
							onChange={(e) => {
								{							 
									setSpecificDocState({...specificDocState, upload_or_generate: 1 })
								}
							}} />
						<span className={styles.checkmark}></span>
						Upload file
					</label>
					<label className={`${styles.radioBtn} ${styles.inputGenerateFile}`}>
						<input type="radio" checked={specificDocState.upload_or_generate == 2 ? true : false} name="inputUpload"
							onChange={(e) => {
								{
									setSpecificDocState({...specificDocState, upload_or_generate: 2 })
								}
							}} />						 
						<span className={styles.checkmark}></span>
						Generate file
					</label>
				</div>

				{
					!loading && specificDocState.upload_or_generate == 1 &&

					<UploadServiceSpecificDocument
						serviceStateId={serviceState.id}
						setSpecificDocState={setSpecificDocState}
						specificDocState={specificDocState}
						setShowAddServiceSuccess={setShowAddServiceSuccess}
						refreshList={() => console.log()}
						isEdit={false}
					/>
				}

				{
					!loading && specificDocState.upload_or_generate == 2 &&
					<GenerateServiceSpecificDocument
						serviceStateId={serviceState.id}
						setSpecificDocState={setSpecificDocState}
						specificDocState={specificDocState}
						setShowAddServiceSuccess={setShowAddServiceSuccess}
						refreshList={() => console.log()}	
						isEdit={false}					 
					/>
				}

			</div>

			{/* <div className={`${styles.btnSave} ${styles.uploadFileTabContent}`}>
				<button>SAVE ALL CHANGES</button>
			</div> */}

		</UserLayout >
	)
};

export default AddNewService;
