import React, { useState, useEffect, useRef } from "react";
import styles from "../CreateDocument.module.css";
import { isEmpty } from "lodash";
import { Popconfirm } from "antd";
import {
  portalGetFomDocuments,
  deleteFomDocument,
  updateFOMAudience,
} from "../../../api/requests";
import moment from "moment";
import { FaCheckCircle, FaCog, FaMinusCircle, FaSpinner } from "react-icons/fa";
import axios from "axios";
import fileDownload from "js-file-download";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { FomDocument } from "../../../types";

const years = [
  {
    year: new Date().getFullYear(),
  },
  {
    year: new Date().getFullYear() + 1,
  },
];

const months = [
  {
    name: "Jan",
  },
  {
    name: "Feb",
  },
  {
    name: "Mar",
  },
  {
    name: "Apr",
  },
  {
    name: "May",
  },
  {
    name: "Jun",
  },
  {
    name: "Jul",
  },
  {
    name: "Aug",
  },
  {
    name: "Sep",
  },
  {
    name: "Oct",
  },
  {
    name: "Nov",
  },
  {
    name: "Dec",
  },
];

const FocusOfTheMonthFiles = ({
  year = new Date().getFullYear(),
  month = "Jan",
  setNew,
  setIdToUpdate,
  setFomDetails,
  fomFetchToggle,
  setToggleRefetchFom,
  fomIdToUpdate,
  fomDocs,
}) => {
  // const [fomDocs, setFomDocs] = useState(foms);
  const [docFile, setDocFile] = useState("");
  const [docName, setDocName] = useState("");
  const [deleting, setDeleting] = useState("");
  const [idToDelete, setIdToDelete] = useState(0);

  const [selectedDocId, setSelectedDocId] = useState(0);
  const [filteredFomDocs, setFilteredFomDocs] = useState([]);

  const docRef = useRef();

  const onDownloadFile = (fileUrl, fileName) => {
    axios
      .get(fileUrl, {
        responseType: `blob`,
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  };

  const onDeleteFomDocument = async (docId) => {
    try {
      setDeleting(true);
      const result = await deleteFomDocument(docId);
      // await onGetFomDocuments();
      setToggleRefetchFom(!fomFetchToggle);
      setDeleting(false);
    } catch (error) {
      setDeleting(false);
      console.log(error);
    }
  };

  // const onGetFomDocuments = async () => {
  //   try {
  //     const result = await portalGetFomDocuments();
  //     setFomDocs(result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const onConfirm = (dId) => {
    onDeleteFomDocument(dId);
  };

  const AudienceOptions = ({ selected = "", id = 0 }) => {
    return (
      <ul className={`${styles.tableMenuAction}`}>
        <li
          onClick={() => {
            if (selected !== "Room leader") {
              onUpdateFomAudience(id, "Room leader");
            }
          }}
          className={`flex items-center justify-start`}
        >
          {selected !== "Room leader" ? (
            <FaMinusCircle className={`mr-1.5`} size={18} />
          ) : (
            <FaCheckCircle className={`mr-1.5`} size={18} />
          )}
          Room leader
        </li>

        <li
          onClick={() => {
            if (selected !== "Educational leader FOM") {
              onUpdateFomAudience(id, "Educational leader FOM");
            }
          }}
          className={`flex items-center justify-start`}
        >
          {selected !== "Educational leader FOM" ? (
            <FaMinusCircle className={`mr-1.5`} size={18} />
          ) : (
            <FaCheckCircle className={`mr-1.5`} size={18} />
          )}
          Educational leader FOM
        </li>
        <li
          onClick={() => {
            if (selected !== "Nominated supervisor") {
              onUpdateFomAudience(id, "Nominated supervisor");
            }
          }}
          className={`flex items-center justify-start`}
        >
          {selected !== "Nominated supervisor" ? (
            <FaMinusCircle className={`mr-1.5`} size={18} />
          ) : (
            <FaCheckCircle className={`mr-1.5`} size={18} />
          )}
          Nominated supervisor
        </li>
      </ul>
    );
  };

  const onFilteredFomDocs = (foms = [], year, month) => {
    const filtered = foms
      .filter((doc) => {
        return doc.detail.year === year.toString();
      })
      .filter((doc) => {
        return doc.detail.month === month;
      })[0];

    if (isEmpty(filtered)) {
      setFilteredFomDocs([]);
      setNew(true);
      setFomDetails({
        year: year,
        month: month,
        focus: "",
        description: "",
      });
    } else {
      setFilteredFomDocs(filtered);
      setNew(false);
      setFomDetails({
        year: year,
        month: month,
        focus: filtered.detail.monthly_focus,
        description: filtered.detail.monthly_focus_description,
      });
      setIdToUpdate(filtered.detail.id);
    }
  };

  const onUpdateFomAudience = async (id, audience) => {
    try {
      const response = await updateFOMAudience(id, audience);
      // await onGetFomDocuments();
      setToggleRefetchFom(!fomFetchToggle);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   onGetFomDocuments();
  // }, []);

  // useEffect(() => {
  //   onGetFomDocuments();
  // }, [fomFetchToggle]);

  useEffect(() => {
    onFilteredFomDocs(fomDocs, year, month);
    setSelectedDocId(0);
  }, [fomDocs, year, month]);

  return (
    <div className={styles.fileList}>
      <h3>Files for this month</h3>

      <div className={styles.fileListHeader}>
        <ul className={styles.fileListCol}>
          <li>File name</li>
          <li>Audience</li>
          <li>Date added</li>
          <li>Download</li>
          <li>Delete</li>
        </ul>
      </div>
      {/* {console.log(" fom files ", fomDocs)} */}
      <div className={styles.fileListBody}>
        {!isEmpty(filteredFomDocs) &&
          filteredFomDocs.uploaded_files.map((doc) => {
            return (
              <ul key={doc.id} className={styles.fileListCol}>
                {" "}
                <li>
                  <div className={styles.serviceName}>
                    <div className={styles.fileIconContainer}>
                      <div className={styles.fileIcon}>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          font-size="24"
                          height="1em"
                          width="1em"
                          data-prefix="far"
                          data-icon="file-pdf"
                          className="svg-inline--fa fa-file-pdf fa-w-12"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path
                            fill="currentColor"
                            d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className={styles.fileContent}>
                      <span>{doc.label}</span>
                      <span>Word document / 1.5Mb</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className={styles.audienceRow}>
                    <button
                      onClick={() => {
                        if (selectedDocId === doc.id) {
                          setSelectedDocId(0);
                        } else {
                          setSelectedDocId(doc.id);
                        }
                      }}
                      className={`relative ${
                        selectedDocId === doc.id
                          ? styles.clicked
                          : styles.unClicked
                      } `}
                    >
                      <FaCog className={`${styles.cogAudience}`} />
                      {/* <svg
                        font-size="14"
                        height="1em"
                        width="1em"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="cog"
                        className="svg-inline--fa fa-cog fa-w-16 "
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
                        ></path>
                      </svg> */}
                      {Number(selectedDocId) === Number(doc.id) && (
                        <AudienceOptions selected={doc.audience} id={doc.id} />
                      )}
                    </button>
                    <ul className={styles.audienceList}>
                      <li className={styles.selected}>
                        <svg
                          height="1em"
                          width="1em"
                          font-size="14"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="minus-circle"
                          className="svg-inline--fa fa-minus-circle fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"
                          ></path>
                        </svg>
                        Room leader
                      </li>
                      <li className={styles.selected}>
                        <svg
                          height="1em"
                          width="1em"
                          font-size="14"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="minus-circle"
                          className="svg-inline--fa fa-minus-circle fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"
                          ></path>
                        </svg>
                        Educational leader FOM
                      </li>
                      <li>
                        <svg
                          height="1em"
                          width="1em"
                          font-size="14"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="check-circle"
                          className="svg-inline--fa fa-check-circle fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                          ></path>
                        </svg>
                        Nominated supervisor
                      </li>
                    </ul>
                    <span>{doc.audience}</span>
                  </div>
                </li>
                <li>
                  {moment(doc.date, "YYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
                </li>
                <li>
                  <div className={`${styles.btnDownload} ${styles.btnAction}`}>
                    <a
                      ref={docRef}
                      href={docFile}
                      download={docName}
                      className={`hidden`}
                    ></a>
                    <button
                      onClick={() => {
                        onDownloadFile(doc.document_url, doc.document_name);
                      }}
                    >
                      <svg
                        font-size="19"
                        height="1em"
                        width="1em"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="download"
                        className="svg-inline--fa fa-download fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                        ></path>
                      </svg>
                      DOWNLOAD
                    </button>
                  </div>
                </li>
                <li>
                  <div className={`${styles.btnDelete} ${styles.btnAction}`}>
                    <Popconfirm
                      title="Are you sure to delete this document"
                      onConfirm={() => {
                        setIdToDelete(doc.id);
                        onConfirm(doc.id);
                      }}
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button disabled={deleting}>
                        {deleting && idToDelete === doc.id ? (
                          <div className={`flex justify-center items-center`}>
                            <FaSpinner className={`animate-spin`} />{" "}
                            <span className={`ml-2`}>Deleting...</span>
                          </div>
                        ) : (
                          <>
                            <svg
                              font-size="19"
                              height="1em"
                              width="1em"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="times-circle"
                              className="svg-inline--fa fa-times-circle fa-w-16"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                              ></path>
                            </svg>
                            DELETE
                          </>
                        )}
                      </button>
                    </Popconfirm>
                  </div>
                </li>
              </ul>
            );
          })}
      </div>
    </div>
  );
};

export default FocusOfTheMonthFiles;
