import axios from "../../services/axios";
import { Service, Brand } from "../../types/service/service";
import { AxiosStatic } from "axios";

let config = {
  headers: {
    Accept: "application/json",
  },
};

export async function fetchServiceById(id: Number): Promise<Service> {
  return await axios.get(`/api/services-get/${id}`);
}

export async function createService(payload: Service): Promise<AxiosStatic> {
  return await axios.post("/api/new-service", payload, config);
}

export async function updateService(payload: Service): Promise<Service> {
  return await axios.put(`/api/services-update/${payload.id}`, payload, config);
}

export async function createServiceBrand(payload: Brand): Promise<Brand> {
  return await axios.put(
    `/api/save-branding/${payload.service_id}`,
    payload,
    config
  );
}

export async function archiveService(payload: Service): Promise<boolean> {
  const response = await axios.post(`/api/archive-service`, payload, config);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteService(serviceId: Number): Promise<boolean> {
  const response = await axios.delete(`/api/services-delete/${serviceId}`);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function softDeleteService(payload: string): Promise<boolean> {
  const response = await axios.post(
    `/api/services-soft-delete`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function undoSoftDeleteService(payload: string): Promise<boolean> {
  const response = await axios.post(
    `/api/services-undo-delete`,
    payload,
    config
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchServices(payload?: any): Promise<Service[]> {
  const response = await axios.get(`/api/services-get-all`);

  console.log("respose services ", response.data);

  if (!response.data) return [];

  return servicesMapper(response);
}

export async function fetchServicesbyDocumentIds(
  payload?: any
): Promise<Service[]> {
  const response = await axios.post(`/api/select-services-documents`, payload);

  if (!response.data) return [];

  return servicesMapper(response.data);
}

const servicesMapper = (response: any) => {
  return response.data.map((service: any) => ({
    id: service.id,
    name: service.name,
    has_qip: service.has_qip,
    has_portal: service.has_portal,
    admin_name: service.admin_name,
    website_link: service.website_link,
    primary_email: service.primary_email,
    postal: service.postal,
    phone: service.phone,
    address: service.address,
    city: service.city,
    state: service.state,
    description: service.description,
    logo_name: service.logo_name,
    logo_url: service.logo_url,
    service_type_id: service.service_type_id,
    brand_color: service.brand_color,
    is_archive: service.is_archive,
    skin: service.skin,
    selected: false,
    collection_loaded: JSON.parse(service.collection_loaded),
  }));
};

export async function fetchServicesWithDocumentCount(): Promise<Service[]> {
  const { status, data } = await axios.get(`/api/services-with-document-count`);

  if (status == 200 || status == 201) {
    return data;
  }
  return [];
}

export async function fetchDocumentsServicesById(
  documentId: Number
): Promise<any> {
  return await axios.get(`/api/get-documents-services-id/${documentId}`);
}

export async function serviceUnlink(payload: any): Promise<boolean> {
  const response = await axios.post(`/api/service-unlink`, payload);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteDocumentFile(documentId: Number): Promise<boolean> {
  const response = await axios.delete(
    `/api/delete-document-file/${documentId}`
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function linkServiceToDocument(payload: any): Promise<boolean> {
  const response = await axios.post(`/api/link-service-to-document`, payload);

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchAvailableServiceToLink(
  documentId: Number
): Promise<any> {
  return await axios.get(`/api/get-available-services-to-link/${documentId}`);
}

export async function selectServicesDocuments(payload: any): Promise<any[]> {
  const response = await axios.post(
    `/api/select-services-documents`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) {
    return response.data;
  }

  return [];
}

export async function addServicesDocuments(payload: any): Promise<boolean> {
  const response = await axios.post(
    `/api/add-services-to-documents`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function removeServicesDocuments(payload: any): Promise<boolean> {
  const response = await axios.put(
    `/api/remove-services-documents`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function confirmRemoveServicesDocuments(
  payload: any
): Promise<boolean> {
  const response = await axios.post(
    `/api/confirm-remove-services-documents`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function confirmAddServicesToDocuments(
  payload: any
): Promise<boolean> {
  const response = await axios.post(
    `/api/confirm-add-services-to-documents`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}
