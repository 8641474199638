import React, { useEffect } from "react";
import styles from "./OKUndoDeleteDocumentModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import { deleteSingleDocument } from '../../../../api/documents/requests';

const OKUndoDeleteDocumentModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	documentId,
	refresh,
	closeOkUndo
}) => {

	useEffect(() => {

		console.log(documentId)

	}, [documentId]);

	const onDeleteForever = async (e) => {

		e.preventDefault();

		try {

			const response = await deleteSingleDocument(documentId);

			if (response) {

				refresh();

				onCloseModal();

				closeOkUndo();
			}

		} catch (error) {

			console.log(error);

		}
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={(e) => onDeleteForever(e)}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`This document is now permanently deleted from HMC Portal`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => onDeleteForever(e)}
							className={`${styles.btnOk}`}>OK</Button>
						<Button
							onClick={() => {
								onCloseModal()
							}}
							className={`${styles.btnUndo}`}>UNDO</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default OKUndoDeleteDocumentModal;