import React, { useState, useEffect, useMemo } from "react";
import styles from "./CreateDocument.module.css";
import UserLayout from "../../components/userLayout/UserLayout";

import NqaDocumentData from "./NQADocument/NqaDocumentData";
import NqaDocumentUploadType from "./NQADocument/NqaDocumentUploadType";
import NqaDocumentGenerateType from "./NQADocument/NqaDocumentGenerateType";
import NqaDocumentWebinarType from "./NQADocument/NqaDocumentWebinarType";

import FocusOfTheMonth from "./FocusOfTheMonth/FocusOfTheMonth";
import FocusOfTheMonthFiles from "./FocusOfTheMonth/FocusOfTheMonthFiles";
import FocusOfTheMonthUpload from "./FocusOfTheMonth/FocusOfTheMonthUpload";

import SpecificService from "./SpecificServiceDocument/SpecificServiceDocument";
import SpecificServiceUploadType from "./SpecificServiceDocument/SpecificDocumentUploadType";
import SpecificServiceGenerateType from "./SpecificServiceDocument/SpecificDocumentGenerateType";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isEmpty } from "lodash";
import { FaSpinner } from "react-icons/fa";
import { Switch } from "antd";

import {
  PortalCreateNewDocument,
  PortalCreateFomDocument,
  PortalFetchSelectedService,
  PortalCreateWebinarDocs,
  updateFOMDetails,
  uploadFOMFile,
  portalGetFomDocuments,
} from "../../api/requests";
import AddDocumentSuccessModal from "./Modal/addDocumentSuccessModal/AddDocumentSuccessModal";

import ServiceModal from "../../components/popups/createNewDocument/specificDocumentChooseServiceModal";

import { sweetAlert } from "../../components/_base/alerts/sweetAlert";
import LinkOrDocument from "./LinkOrDocument";
import { isUrlValid } from "helpers/string";

const CreateDocument = (props) => {
  const [nqaDocUploadGenerate, setNqaDocUploadGenerate] = useState("upload");
  const [servSpecUploadGenerate, setServSpecUploadGenerate] =
    useState("upload_file");
  const [docTabContent, setDocTabContent] = useState(props.docTab);
  const [showModal, setShowModal] = useState(false);
  const [notifyService, setNotifyService] = useState(0);
  const [specificNotifyService, setSpecificNotifyService] = useState(0);

  const [nqaID, setNqaID] = useState([]);
  const [docID, setDocID] = useState(0);
  const [sTypeID, setSTypeID] = useState([]);

  const [fieldsChecked, setFieldsChecked] = useState(false);
  const [saving, setSaving] = useState(false);

  //NQA Document
  const [selectedServices, setSelectedServices] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedInputs, setUploadedInputs] = useState([]);
  const [nqaServices, setNqaServices] = useState([]);

  const [generateFiles, setGenerateFiles] = useState([]);
  const [generateSelectedServices, setGenerateSelectedServices] = useState([]);
  const [generateInputs, setGenerateInputs] = useState([]);
  const [editorValue, setEditorValue] = useState(EditorState.createEmpty());

  // Webinar || Training modules
  const [webinarSelectedServices, setWebinarSelectedServices] = useState([]);
  const [webinarUploadedFiles, setWebinarUploadedFiles] = useState([]);
  const [webinarUploadedInputs, setWebinarUploadedInputs] = useState({});

  const [globalWebinar, setGlobalWebinar] = useState(0);
  const [isExternalLink, setIsExternalLink] = useState(true);

  //Focus of the month
  const [fomInputs, setFomInputs] = useState({
    year: new Date().getFullYear(),
    month: "Jan",
    focus: "",
    description: "",
  });

  const fomDefaults = {
    file: {
      base64: "",
      name: "",
      size: 0,
      type: "",
    },
    title: "",
    date: "",
    audience: "Room leader",
    description: "",
  };

  const [fomUpload, setFomUpload] = useState(fomDefaults);
  const [renderFomUpload, setRenderFomUpload] = useState(true);

  const [newFom, setNewFom] = useState(true);
  const [updateFom, setUpdateFom] = useState(false);
  const [fomDetailsIdToUpdate, setFomDetailsIdToUpdate] = useState(0);
  const [fomDetails, setFomDetails] = useState({});
  const [fomUploadFile, setFomUploadFile] = useState(false);
  const [toggleRefetchFom, setToggleRefetchFom] = useState(false);
  const [fomfileIdToUpdate, setFomFileIdToUpdate] = useState(0);
  const [fomDocs, setFomDocs] = useState([]);

  //specificService
  const [ServiceSpecificType, setServiceSpecificType] = useState(0);
  const [NqaAreaID, setNqaAreaID] = useState([]);
  const [DocumentTypeID, setDocumentTypeID] = useState(0);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [generateFileSpecifc, setGenerateFilesSpecific] = useState([]);
  const [editorValueSpecific, setEditorValueSpecific] = useState(
    EditorState.createEmpty()
  );
  const [SelectedServicesSpecific, setSelectedServicesSpecific] = useState([]);

  const [uploadInputsGenerate, setUploadInputsGenerate] = useState({
    file_title: "",
    file_version: "",
    date: "",
    description: "",
  });

  const [uploadInputs, setUploadInputs] = useState({
    file_title: "",
    file_version: "",
    date: "",
    description: "",
  });

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [showServicesModal, setServicesShowModal] = useState(false);
  const [getName, setGetName] = useState([]);

  ///////////////////////////////////////////////////////////////////////////////////////

  const HandleNotifyServiceChange = (e) => {
    e.target.checked ? setNotifyService(1) : setNotifyService(0);
  };

  const SpecificServiceNotifyService = (e) => {
    e.target.checked
      ? setSpecificNotifyService(1)
      : setSpecificNotifyService(0);
  };

  const HandleSaveChanges = async (e) => {
    setFieldsChecked(true);
    switch (docTabContent) {
      case "NqaDocument":
        if (nqaDocUploadGenerate === "upload" && docID !== 3 && docID !== 4) {
          if (
            isEmpty(nqaID) ||
            docID === 0 ||
            isEmpty(sTypeID) ||
            uploadFiles.base64 === "" ||
            uploadFiles.file_title === "" ||
            uploadFiles.file_version === "" ||
            uploadFiles.date === "" ||
            (sTypeID.includes(5) && isEmpty(selectedServices))
          ) {
            return;
          }

          const includeOrExcludeServices = sTypeID.includes(5)
            ? {
                services: selectedServices,
                excluded_services: nqaServices
                  .filter((s1) => {
                    return selectedServices.some((s2) => s2 !== s1.service_id);
                  })
                  .map((s3) => {
                    return s3.service_id;
                  }),
              }
            : {
                services: nqaServices
                  .filter((s1) => {
                    return selectedServices.some((s2) => s1.service_id !== s2);
                  })
                  .map((s3) => {
                    return s3.service_id;
                  }),
                excluded_services: selectedServices,
              };

          let payload = {
            category: 1,
            upload_or_generate: 1,
            attachments: [
              {
                file_name: uploadedFiles.name,
                base64_image: uploadedFiles.base64,
              },
            ],

            file_title: uploadedInputs.file_title,
            file_version: uploadedInputs.file_version,
            date: uploadedInputs.date,
            description: uploadedInputs.description,

            nqa_area_id: nqaID,
            document_type_id: docID,
            service_type_id: sTypeID,
            notify_service: notifyService,
            ...includeOrExcludeServices,
          };

          SaveChanges(payload);
        } else if (
          nqaDocUploadGenerate === "generate" &&
          docID !== 3 &&
          docID !== 4
        ) {
          const includeOrExcludeServices = sTypeID.includes(5)
            ? {
                services: generateSelectedServices,
                excluded_services: nqaServices
                  .filter((s1) => {
                    return generateSelectedServices.some(
                      (s2) => s2 !== s1.service_id
                    );
                  })
                  .map((s3) => {
                    return s3.service_id;
                  }),
              }
            : {
                services: nqaServices
                  .filter((s1) => {
                    return generateSelectedServices.some(
                      (s2) => s1.service_id !== s2
                    );
                  })
                  .map((s3) => {
                    return s3.service_id;
                  }),
                excluded_services: generateSelectedServices,
              };

          let payload = {
            category: 1,
            upload_or_generate: 2,

            file_title: generateInputs.file_title,
            file_version: generateInputs.file_version,
            date: generateInputs.date,
            content: editorValue,
            description: generateInputs.description,

            nqa_area_id: nqaID,
            document_type_id: docID,
            service_type_id: sTypeID,
            notify_service: specificNotifyService,
            ...includeOrExcludeServices,
          };

          if (
            isEmpty(payload.nqa_area_id) ||
            payload.document_type_id === 0 ||
            isEmpty(payload.service_type_id) ||
            payload.base64_image === "" ||
            payload.file_name === "" ||
            payload.file_version === "" ||
            payload.file_title === "" ||
            payload.date === "" ||
            (sTypeID.includes(5) && isEmpty(payload.services)) ||
            typeof payload.content !== "string" ||
            (typeof payload.content === "string" && !payload.content.length)
          ) {
            return;
          }

          SaveChanges(payload);
        } else if (docID === 3 || docID === 4) {
          const includeOrExcludeServices =
            globalWebinar === 1
              ? {
                  services: nqaServices.map((s) => s.service_id),
                  // services: [],
                  excluded_services: [],
                }
              : {
                  services: sTypeID.includes(5)
                    ? webinarSelectedServices
                    : nqaServices
                        .filter((s1) => {
                          return webinarSelectedServices.some(
                            (s2) => s2 !== s1.service_id
                          );
                        })
                        .map((s3) => {
                          return s3.service_id;
                        }),
                  excluded_services: sTypeID.includes(5)
                    ? nqaServices
                        .filter((s1) => {
                          return webinarSelectedServices.some(
                            (s2) => s2 !== s1.service_id
                          );
                        })
                        .map((s3) => {
                          return s3.service_id;
                        })
                    : webinarSelectedServices,
                };

          let payload = {
            base64_image: webinarUploadedFiles.base64,
            category: 1,
            nqa_area_id: nqaID,
            document_type_id: docID,
            service_type_id: globalWebinar === 1 ? null : sTypeID,
            webinar_link:
              docID === 3 || isExternalLink
                ? webinarUploadedInputs.webinar_link
                : "",
            webinar_title: webinarUploadedInputs.webinar_title,
            label: webinarUploadedFiles.name,
            date: webinarUploadedInputs.date,
            webinar_description: webinarUploadedInputs.webinar_description,
            file_title: webinarUploadedInputs.webinar_file_title,
            notify_service: notifyService,
            global_webinar: globalWebinar,
            ...includeOrExcludeServices,
          };

          if (docID === 3) {
            if (
              isEmpty(nqaID) ||
              docID === 0 ||
              (globalWebinar === 1 ? false : isEmpty(sTypeID)) ||
              webinarUploadedInputs.webinar_link === "" ||
              !isUrlValid(webinarUploadedInputs.webinar_link) ||
              webinarUploadedInputs.webinar_title === "" ||
              webinarUploadedInputs.date === "" ||
              (globalWebinar === 1
                ? false
                : sTypeID.includes(5) && isEmpty(payload.services))
            ) {
              return;
            }
          }

          if (docID === 4) {
            if (
              isEmpty(nqaID) ||
              docID === 0 ||
              (globalWebinar === 1 ? false : isEmpty(sTypeID)) ||
              webinarUploadedInputs.webinar_link === "" ||
              (isExternalLink &&
                !isUrlValid(webinarUploadedInputs.webinar_link)) ||
              webinarUploadedInputs.webinar_title === "" ||
              webinarUploadedFiles.base64 === "" ||
              webinarUploadedFiles.webinar_file_title === "" ||
              webinarUploadedInputs.date === "" ||
              (globalWebinar === 1
                ? false
                : sTypeID.includes(5) && isEmpty(payload.services))
            ) {
              return;
            }
          }

          saveWebinarDocs(payload);
        }
        break;

      case "SpecificDocument":
        if (
          servSpecUploadGenerate === "upload_file" &&
          DocumentTypeID !== 3 &&
          DocumentTypeID !== 4
        ) {
          if (
            uploadFiles.base64 === "" ||
            uploadInputs.file_title === "" ||
            uploadInputs.file_version === "" ||
            uploadInputs.date === "" ||
            isEmpty(SelectedServicesSpecific) ||
            ServiceSpecificType === 0
          ) {
            return;
          } else {
            if (ServiceSpecificType === 4 && isEmpty(NqaAreaID)) {
              return;
            }
          }

          if (
            ServiceSpecificType === 5 &&
            (DocumentTypeID === 0 || isEmpty(NqaAreaID))
          ) {
            return;
          }

          let payload = {
            category: 3,
            upload_or_generate: 1,
            attachments: [
              {
                base64_image: uploadFiles.base64 ? uploadFiles.base64 : null,
                file_name: uploadFiles.name ? uploadFiles.name : null,
              },
            ],

            file_title: uploadInputs.file_title,
            file_version: uploadInputs.file_version,
            date: uploadInputs.date,
            description: uploadInputs.description,

            service_specific_type: ServiceSpecificType,
            nqa_area_id: NqaAreaID,
            document_type_id: DocumentTypeID,

            services: SelectedServicesSpecific,
            notify_service: notifyService,
          };

          SaveChanges(payload);
        } else if (
          servSpecUploadGenerate === "generate_file" &&
          DocumentTypeID !== 3 &&
          DocumentTypeID !== 4
        ) {
          let payload = {
            category: 3,
            upload_or_generate: 2,

            file_title: uploadInputsGenerate.file_title,
            file_version: uploadInputsGenerate.file_version,
            date: uploadInputsGenerate.date,
            description: uploadInputsGenerate.description,
            content: editorValueSpecific,

            service_specific_type: ServiceSpecificType,
            nqa_area_id: NqaAreaID,
            document_type_id: DocumentTypeID,

            services: SelectedServicesSpecific,
            notify_service: notifyService,
          };

          if (
            payload.file_title === "" ||
            payload.file_version === "" ||
            payload.date === "" ||
            isEmpty(payload.services) ||
            payload.service_specific_type === 0 ||
            typeof payload.content !== "string" ||
            (typeof payload.content === "string" && !payload.content.length)
          ) {
            return;
          } else {
            if (
              payload.service_specific_type === 4 &&
              (payload.document_type_id === 0 || isEmpty(payload.nqa_area_id))
            ) {
              return;
            }
          }

          SaveChanges(payload);
        } else if (DocumentTypeID === 3 || DocumentTypeID === 4) {
          let payload = {
            base64_image: webinarUploadedFiles.base64,
            category: 3,
            nqa_area_id: NqaAreaID,
            document_type_id: DocumentTypeID,
            service_type_id: [],
            service_specific_type: ServiceSpecificType,
            webinar_link:
              docID === 3 || isExternalLink
                ? webinarUploadedInputs.webinar_link
                : "",
            webinar_title: webinarUploadedInputs.webinar_title,
            label: webinarUploadedFiles.name,
            date: webinarUploadedInputs.date,
            webinar_description: webinarUploadedInputs.webinar_description,
            file_title: webinarUploadedInputs.webinar_file_title,
            services: SelectedServicesSpecific,
            notify_service: notifyService,
            global_webinar: 0,
          };

          if (docID === 3) {
            if (
              isEmpty(NqaAreaID) ||
              DocumentTypeID === 0 ||
              webinarUploadedInputs.webinar_link === "" ||
              !isUrlValid(webinarUploadedInputs.webinar_link) ||
              webinarUploadedInputs.webinar_title === "" ||
              webinarUploadedInputs.date === "" ||
              (isEmpty(ServiceSpecificType) && isEmpty(payload.services))
            ) {
              return;
            }
          }

          if (docID === 4) {
            if (
              isEmpty(NqaAreaID) ||
              DocumentTypeID === 0 ||
              webinarUploadedInputs.webinar_link === "" ||
              (isExternalLink &&
                !isUrlValid(webinarUploadedInputs.webinar_link)) ||
              webinarUploadedInputs.webinar_title === "" ||
              webinarUploadedFiles.base64 === "" ||
              webinarUploadedFiles.webinar_file_title === "" ||
              webinarUploadedInputs.date === "" ||
              (isEmpty(ServiceSpecificType) && isEmpty(payload.services))
            ) {
              return;
            }
          }

          saveWebinarDocs(payload);
        }

        break;
      case "FocusOfTheMonth":
        setFieldsChecked(false);
        if (newFom) {
          setFieldsChecked(true);
          if (
            !fomInputs.focus ||
            !fomUpload.title ||
            !fomUpload.date ||
            // !fomUpload.audience ||
            !fomUpload.file.base64
          ) {
            return;
          }

          let payload = {
            base64_image: fomUpload.file.base64,
            size: fomUpload.file.size,
            label: fomUpload.file.name,
            year: String(fomInputs.year),
            month: fomInputs.month,
            date: fomUpload.date,
            monthly_focus: fomInputs.focus,
            monthly_focus_description: fomInputs.description,
            file_title: fomUpload.title,
            audience: fomUpload.audience,
            file_description: fomUpload.description,
          };

          await SaveFomChanges(payload);
          setToggleRefetchFom(!toggleRefetchFom);
          break;
        } else if (updateFom && !fomUploadFile) {
          setFieldsChecked(false);
          setSaving(true);
          let payload = {
            id: fomDetailsIdToUpdate,
            monthly_focus: fomInputs.focus,
            monthly_focus_description: fomInputs.description,
          };
          if (fomInputs.focus && fomInputs.description) {
            await UpdateFomDetails(payload);
          }
          setToggleRefetchFom(!toggleRefetchFom);
          setSaving(false);
          break;
        } else if (updateFom && fomUploadFile) {
          setSaving(true);
          let payload = {
            id: fomDetailsIdToUpdate,
            monthly_focus: fomInputs.focus,
            monthly_focus_description: fomInputs.description,
          };
          if (fomInputs.focus && fomInputs.description) {
            await UpdateFomDetails(payload);
          }

          let filePayload = {
            id: fomDetailsIdToUpdate,
            base64_image: fomUpload.file.base64,
            size: fomUpload.file.size,
            file_title: fomUpload.title,
            audience: fomUpload.audience,
            file_description: fomUpload.description,
            date: fomUpload.date,
            label: fomUpload.file.name,
          };

          await UpdateFomFile(filePayload);
          setToggleRefetchFom(!toggleRefetchFom);
          setSaving(false);
          break;
        } else if (!updateFom && fomUploadFile) {
          setSaving(true);
          let filePayload = {
            id: fomDetailsIdToUpdate,
            base64_image: fomUpload.file.base64,
            size: fomUpload.file.size,
            file_title: fomUpload.title,
            audience: fomUpload.audience,
            file_description: fomUpload.description,
            date: fomUpload.date,
            label: fomUpload.file.name,
          };

          await UpdateFomFile(filePayload);
          setToggleRefetchFom(!toggleRefetchFom);
          setSaving(false);
          break;
        }

      default:
        break;
    }
  };

  const resetFomFields = () => {
    setFieldsChecked(false);
    setRenderFomUpload(false);
    setFomUpload(fomDefaults);
    setRenderFomUpload(true);
  };

  const UpdateFomFile = async (payload) => {
    try {
      const response = await uploadFOMFile({
        id: payload.id,
        base64_image: payload.base64_image,
        file_title: payload.file_title,
        date: payload.date,
        audience: payload.audience,
        file_description: payload.file_description,
        label: payload.label,
        size: payload.size,
      });
      resetFomFields();
      setFomUploadFile(false);
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateFomDetails = async (payload) => {
    try {
      const response = await updateFOMDetails({
        id: payload.id,
        monthly_focus: payload.monthly_focus,
        monthly_focus_description: payload.monthly_focus_description,
      });
      setUpdateFom(false);
      // sweetAlert("success", "Document successfully updated.");
    } catch (error) {
      console.log(error);
    }
  };

  const saveWebinarDocs = async (payload) => {
    try {
      setSaving(true);
      const { status, data } = await PortalCreateWebinarDocs(payload);
      setSaving(false);

      sweetAlert("success", "Document successfully created.", () => {
        props.resetDocTab && props.resetDocTab(docTabContent);
      });
      // setShowSuccessModal(true);

      // setShowSuccessModal(true);
    } catch (e) {
      setSaving(false);
      console.log(e);
    }
  };

  const SaveFomChanges = async (payload) => {
    try {
      setSaving(true);
      const { status, data } = await PortalCreateFomDocument(payload);
      setSaving(false);
      if (status === 201 || status === 200) {
        sweetAlert("success", "Document successfully created.");
        resetFomFields();
        // setShowSuccessModal(true);
      }
    } catch (e) {
      setSaving(false);
      console.log(e);
    }
  };

  const SaveChanges = async (payload) => {
    try {
      setSaving(true);
      const { status, data } = await PortalCreateNewDocument(payload);
      setSaving(false);
      if (status === 201 || status === 200) {
        sweetAlert("success", "Document successfully created.", () => {
          props.resetDocTab && props.resetDocTab(docTabContent);
        });
      }
    } catch (e) {
      setSaving(false);
      console.log(e);
    }
  };

  const onGetFomDocuments = async () => {
    try {
      const result = await portalGetFomDocuments();
      setFomDocs(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onGetFomDocuments();
  }, [toggleRefetchFom]);

  return (
    <UserLayout headerText={`Create a new document`}>
      <div className={styles.nqaDocumenTabHeader}>
        <div
          onClick={() => setDocTabContent("NqaDocument")}
          className={
            docTabContent === "NqaDocument"
              ? `${styles.tabActive} ${styles.nqaDocumentTab}`
              : null
          }
        >
          NQA document
        </div>
        <div
          onClick={(e) => setDocTabContent("FocusOfTheMonth")}
          className={
            docTabContent == "FocusOfTheMonth"
              ? `${styles.tabActive} ${styles.nqaDocumentTab}`
              : null
          }
        >
          Focus of the month
        </div>
        <div
          onClick={() => setDocTabContent("SpecificDocument")}
          className={
            docTabContent === "SpecificDocument"
              ? `${styles.tabActive} ${styles.specificDocumentTab}`
              : null
          }
        >
          Service specific document
        </div>
      </div>

      <div
        className={
          docTabContent === "NqaDocument"
            ? `${styles.nqaDocumentContent} ${styles.nqaDocumentTabContent}`
            : null
        }
        style={
          docTabContent === "NqaDocument"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <NqaDocumentData
          fieldsChecked={fieldsChecked}
          setNqaAreaID={setNqaID}
          nqaAreaID={nqaID}
          setDocTypeID={setDocID}
          docTypeID={docID}
          setServiceTypeID={setSTypeID}
          serviceTypeID={sTypeID}
          setNqaSelectedServices={(selections) => {
            setSelectedServices(selections);
            setGenerateSelectedServices(selections);
          }}
          globalWebinar={globalWebinar}
          setGlobalWebinar={setGlobalWebinar}
        />

        {docID !== 3 && docID !== 4 && (
          <div className={styles.addServiceDocu}>
            <div className={styles.fileOrigin} style={{ display: "flex" }}>
              <label className={`${styles.radioBtn} ${styles.inputUploadFile}`}>
                <input
                  type="radio"
                  id="radiobutton1"
                  name="nqaDocument"
                  checked={nqaDocUploadGenerate === "upload" ? "checked" : null}
                  value="upload"
                  onChange={() => setNqaDocUploadGenerate("upload")}
                />
                <span className={styles.checkmark}></span>
                Upload file
              </label>
              <label
                className={`${styles.radioBtn} ${styles.inputGenerateFile}`}
              >
                <input
                  type="radio"
                  id="radiobutton2"
                  name="nqaDocument"
                  value="generate"
                  checked={
                    nqaDocUploadGenerate === "generate" ? "checked" : null
                  }
                  onChange={() => setNqaDocUploadGenerate("generate")}
                />
                <span className={styles.checkmark}></span>
                Generate file
              </label>
            </div>

            <div
              className={
                nqaDocUploadGenerate === "upload"
                  ? `${styles.inputContainer} ${styles.uploadFileTabContent}`
                  : null
              }
              style={
                nqaDocUploadGenerate === "upload"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <NqaDocumentUploadType
                fieldsChecked={fieldsChecked}
                SelectedService={setSelectedServices}
                UploadFiles={setUploadedFiles}
                UploadInputs={setUploadedInputs}
                setNqaServices={setNqaServices}
                serviceTypeID={sTypeID}
                globalWebinar={globalWebinar}
              />
            </div>

            <div
              className={
                nqaDocUploadGenerate === "generate"
                  ? `${styles.inputContainer} ${styles.generateFileTabContent}`
                  : null
              }
              style={
                nqaDocUploadGenerate === "generate"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <NqaDocumentGenerateType
                fieldsChecked={fieldsChecked}
                editData={{ generateFiles, generateInputs }}
                GenerateFiles={setGenerateFiles}
                SelectedService={setGenerateSelectedServices}
                GenerateInputs={setGenerateInputs}
                EditorValue={setEditorValue}
                setNqaServices={setNqaServices}
                serviceTypeID={sTypeID}
                globalWebinar={globalWebinar}
              />
            </div>

            {/* <div className={`${styles.inputNotify} `}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                onChange={(e) => HandleNotifyServiceChange(e)}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>

            <h3>Notify service about this document</h3>

            <p>
              Toggle this option only if you are adding a new document or
              editing an existing one
            </p>
          </div> */}
          </div>
        )}

        {(docID === 3 || docID === 4) && (
          <div className={`${styles.addServiceDocu} mt-10`}>
            <div
              className={`${styles.inputContainer} ${styles.uploadFileTabContent}`}
            >
              <div
                className={`webinar-global-toggle flex justify-start items-center pb-4 ${styles.webinarToggle}`}
              >
                <Switch
                  className={``}
                  checked={globalWebinar === 0 ? false : true}
                  onChange={(checked) => {
                    setGlobalWebinar(checked ? 1 : 0);
                  }}
                />
                <div
                  className={`ml-5 flex flex-col items-start justify-center`}
                >
                  <span className={`${styles.headerText}`}>
                    Make this {docID === 3 ? "webinar" : "training module"}{" "}
                    global
                  </span>
                  <span className={`${styles.subHeaderText}`}>
                    If you choose this, this webinar will be public and visible
                    to all users.
                  </span>
                </div>
              </div>
              {docID === 3 ? (
                <NqaDocumentWebinarType
                  fieldsChecked={fieldsChecked}
                  SelectedService={setWebinarSelectedServices}
                  docId={docID}
                  UploadFiles={setWebinarUploadedFiles}
                  UploadInputs={setWebinarUploadedInputs}
                  serviceTypeID={sTypeID}
                  setNqaServices={setNqaServices}
                  globalWebinar={globalWebinar}
                />
              ) : (
                <div className="px-10 mt-10">
                  <LinkOrDocument
                    isExternalLink={isExternalLink}
                    setIsExternalLink={setIsExternalLink}
                    formInputs={webinarUploadedInputs}
                    setFormInputs={setWebinarUploadedInputs}
                    file={webinarUploadedFiles}
                    onFileChange={setWebinarUploadedFiles}
                    showValidation={fieldsChecked}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div
        className={
          docTabContent === "SpecificDocument"
            ? `${styles.nqaDocumentContent} ${styles.specificDocumentTabContent}`
            : null
        }
        style={
          docTabContent === "SpecificDocument"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <SpecificService
          fieldsChecked={fieldsChecked}
          setServiceSpecificType={setServiceSpecificType}
          ServiceSpecificType={ServiceSpecificType}
          setNqaAreaID={setNqaAreaID}
          NqaAreaID={NqaAreaID}
          setDocumentTypeID={setDocumentTypeID}
          DocumentTypeID={DocumentTypeID}
        />

        <div className={styles.addServiceDocu}>
          {(DocumentTypeID === 3 || DocumentTypeID === 4) && (
            <div className={`${styles.addServiceDocu} mt-10`}>
              <div
                className={`${styles.inputContainer} ${styles.uploadFileTabContent}`}
              >
                {DocumentTypeID === 3 ? (
                  <NqaDocumentWebinarType
                    fieldsChecked={fieldsChecked}
                    SelectedService={setWebinarSelectedServices}
                    docId={docID}
                    UploadFiles={setWebinarUploadedFiles}
                    UploadInputs={setWebinarUploadedInputs}
                    serviceTypeID={sTypeID}
                    setNqaServices={setNqaServices}
                    DocumentTypeID={DocumentTypeID}
                  />
                ) : (
                  <div className="px-10 mt-10">
                    <LinkOrDocument
                      isExternalLink={isExternalLink}
                      setIsExternalLink={setIsExternalLink}
                      formInputs={webinarUploadedInputs}
                      setFormInputs={setWebinarUploadedInputs}
                      file={webinarUploadedFiles}
                      onFileChange={setWebinarUploadedFiles}
                      showValidation={fieldsChecked}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {DocumentTypeID !== 3 && DocumentTypeID !== 4 && (
            <>
              <div className={styles.fileOrigin} style={{ display: "flex" }}>
                <label
                  className={`${styles.radioBtn} ${styles.inputUploadFile}`}
                >
                  <input
                    type="radio"
                    id="radiobutton3"
                    name="sService"
                    value="upload_file"
                    checked={
                      servSpecUploadGenerate === "upload_file"
                        ? "checked"
                        : null
                    }
                    onChange={(e) => setServSpecUploadGenerate("upload_file")}
                  />
                  <span className={styles.checkmark}></span>
                  Upload file
                </label>
                <label
                  className={`${styles.radioBtn} ${styles.inputGenerateFile}`}
                >
                  <input
                    type="radio"
                    id="radiobutton4"
                    name="sService"
                    value="generate_file"
                    checked={
                      servSpecUploadGenerate === "generate_file"
                        ? "checked"
                        : null
                    }
                    onChange={(e) => setServSpecUploadGenerate("generate_file")}
                  />
                  <span className={styles.checkmark}></span>
                  Generate file
                </label>
              </div>

              <div
                className={`${styles.inputContainer} ${styles.uploadFileTabContent}`}
                style={
                  servSpecUploadGenerate === "upload_file"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <SpecificServiceUploadType
                  fieldsChecked={fieldsChecked}
                  setUploadFiles={setUploadFiles}
                  uploadFiles={uploadFiles}
                  setUploadInputs={setUploadInputs}
                  uploadInputs={uploadInputs}
                />
              </div>

              <div
                className={`${styles.inputContainer} ${styles.uploadFileTabContent}`}
                style={
                  servSpecUploadGenerate === "generate_file"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <SpecificServiceGenerateType
                  fieldsChecked={fieldsChecked}
                  setEditorValueSpecific={setEditorValueSpecific}
                  editorValueSpecific={editorValueSpecific}
                  setUploadInputsGenerate={setUploadInputsGenerate}
                  uploadInputsGenerate={uploadInputsGenerate}
                  setGenerateFilesSpecific={setGenerateFilesSpecific}
                  generateFileSpecifc={generateFileSpecifc}
                />
              </div>
            </>
          )}

          <div
            className={`${styles.chooseServices} ${styles.fileOriginGenerate}`}
          >
            <p>Choose one service to attach this document</p>

            <ul className={styles.selectedServices}>
              <li
                onClick={() => setSelectedServicesSpecific([])}
                style={
                  SelectedServicesSpecific.length > 0
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                {getName}
                <svg
                  height="19"
                  width="19"
                  font-size="19"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times-circle"
                  className="svg-inline--fa fa-times-circle fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                  ></path>
                </svg>
              </li>
            </ul>

            <div className={styles.inputActionButton}>
              <button
                onClick={() => setServicesShowModal(true)}
                className={`${styles.chooseServicesBtn} ${styles.blueButton}`}
              >
                CHOOSE SERVICE
              </button>
              {fieldsChecked &&
                docTabContent === "SpecificDocument" &&
                isEmpty(SelectedServicesSpecific) && (
                  <div className={`text-red-400 `}>
                    Please select a service to attach this document
                  </div>
                )}

              <ServiceModal
                setServicesShowModal={setServicesShowModal}
                showServicesModal={showServicesModal}
                SelectedServicesSpecific={SelectedServicesSpecific}
                setSelectedServicesSpecific={setSelectedServicesSpecific}
                setGetName={setGetName}
              />

              <button
                className={`${styles.removeFile} ${styles.blueButton}`}
                style={{ display: "none" }}
              >
                CLEAR SELECTION
                <svg
                  height="19"
                  width="19"
                  fontSize="19"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="times-circle"
                  className="svg-inline--fa fa-times-circle fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          {/* 
          <div className={styles.inputNotify}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                onChange={(e) => SpecificServiceNotifyService(e)}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>

            <h3>Notify service about this document</h3>
            <p>
              Toggle this option only if you are adding a new document or
              editing an existing one
            </p>
          </div> */}
        </div>
      </div>

      <div
        className={
          docTabContent === "FocusOfTheMonth"
            ? `${styles.nqaDocumentContent} ${styles.specificDocumentTabContent}`
            : null
        }
        style={
          docTabContent === "FocusOfTheMonth"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <FocusOfTheMonth
          fieldsChecked={fieldsChecked}
          fomInputs={fomInputs}
          setFomDataInputs={setFomInputs}
          setToUpdateFomDetails={setUpdateFom}
          setToUpdateFomFile={setFomUploadFile}
          setFomFieldsChecked={setFieldsChecked}
        />

        <FocusOfTheMonthFiles
          year={fomInputs.year}
          month={fomInputs.month}
          setNew={setNewFom}
          fieldsChecked={fieldsChecked}
          setIdToUpdate={setFomDetailsIdToUpdate}
          setFomDetails={setFomInputs}
          fomFetchToggle={toggleRefetchFom}
          setToggleRefetchFom={setToggleRefetchFom}
          fomIdToUpdate={fomDetailsIdToUpdate}
          fomDocs={fomDocs}
        />

        {renderFomUpload && (
          <FocusOfTheMonthUpload
            fieldsChecked={fieldsChecked}
            fomUpload={fomUpload}
            setFomUpload={setFomUpload}
            setFomUploadFile={setFomUploadFile}
          />
        )}
      </div>

      <div className={styles.btnSave}>
        <button
          className={styles.uploadFileTabContent}
          onClick={(e) => HandleSaveChanges(e)}
          disabled={saving}
        >
          {saving ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />{" "}
              <span className={`ml-2`}> Please wait...</span>{" "}
            </div>
          ) : (
            <> SAVE ALL CHANGES </>
          )}
        </button>
        <button className={styles.generateFileTabContent}>GENERATE FILE</button>
      </div>

      <AddDocumentSuccessModal
        visibility={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />
    </UserLayout>
  );
};

export default CreateDocument;
