import React, { useState } from "react";
import styles from "../CreateDocument.module.css";
import FileBase64 from "react-file-base64";

import DeleteFileModal from "../Modal/DeleteConfirmationModal";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML, convertFromHTML } from "draft-convert";

import { PortalFetchSelectedService } from "../../../api/requests";
import { isEmpty } from "lodash";

import TextEditor from "../../../components/services/TextEditor";

const SpecificDocumentGenerateType = ({
  fieldsChecked,
  setEditorValueSpecific,
  editorValueSpecific,

  setUploadInputsGenerate,
  uploadInputsGenerate,

  setGenerateFilesSpecific,
  generateFileSpecifc,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [valueToDelete, setValueToDelete] = useState(0);

  const ServiceSpecificRemoveDoc = () => {
    let newItem = generateFileSpecifc.splice(valueToDelete, 1);
    setGenerateFilesSpecific([...generateFileSpecifc]);
    setShowDeleteModal(false);
  };

  const SpecificServiceGenerateTabInputChange = (e) => {
    let { name, value } = e.target;
    setUploadInputsGenerate({
      ...uploadInputsGenerate,
      [name]: value,
    });
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const GenerateAddFiles = (files) => {
    setGenerateFilesSpecific(files);
  };

  const confirmDelete = (e, x) => {
    setValueToDelete(x);
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <label>File Title</label>
          <input
            type="text"
            name="file_title"
            placeholder="Type a memorable name for the file"
            onChange={(e) => SpecificServiceGenerateTabInputChange(e)}
          />
          {fieldsChecked && !uploadInputsGenerate.file_title && (
            <text className={`text-red-400 text-xs py-1 px-2`}>
              This field is required.
            </text>
          )}
        </div>
        <div className={styles.inputGroup}>
          <label>Version</label>
          <input
            type="text"
            name="file_version"
            placeholder="Enter file version"
            onChange={(e) => SpecificServiceGenerateTabInputChange(e)}
          />
          {fieldsChecked && !uploadInputsGenerate.file_version && (
            <text className={`text-red-400 text-xs py-1 px-2`}>
              This field is required.
            </text>
          )}
        </div>
        <div className={styles.inputGroup}>
          <label>Date</label>
          <input
            type="date"
            name="date"
            placeholder="Choose a date"
            onChange={(e) => SpecificServiceGenerateTabInputChange(e)}
          />
          {fieldsChecked && !uploadInputsGenerate.date && (
            <text className={`text-red-400 text-xs py-1 px-2`}>
              This field is required.
            </text>
          )}
        </div>
      </div>

      <div className={`${styles.inputFull}`}>
        <div className={styles.inputGroup}>
          <label>Content</label>
          {/* <div className={styles.contentEditor}>
            <Editor
              editorState={editorValueSpecific}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditorValueSpecific}
            />
          </div> */}
          <TextEditor
            editorState={editorValueSpecific}
            setEditorState={setEditorValueSpecific}
            specificDocState={{}}
            setSpecificDocState={() => {}}
            setGeneratedFiles={() => {}}
            generatedFiles={[]}
          />
          {fieldsChecked &&
            ((typeof editorValueSpecific === "string" &&
              !editorValueSpecific.length) ||
              typeof editorValueSpecific !== "string") && (
              <text className={`py-1 px-2 text-red-400`}>
                This field is required{" "}
              </text>
            )}
        </div>
      </div>

      {/* <div className={`${styles.inputFull}`}>
        <div className={styles.inputGroup}>
          <label>Description</label>
          <textarea
            className={styles.inputDesc}
            name="description"
            placeholder="Enter a description for the new document"
            onChange={(e) => SpecificServiceGenerateTabInputChange(e)}
          ></textarea>
        </div>
      </div> */}

      {/* <div className={`${styles.uploadedFile} ${styles.fileOriginGenerate}`}>
        <div className={`${styles.addFiles}`}>
          <span>Upload files to this document</span>
          <label className={styles.blueButton}>
            <div style={{ display: "none" }}>
              <FileBase64 multiple={true} onDone={GenerateAddFiles} />
            </div>
            ADD FILE/S
          </label>
        </div>
        <ul>
          {generateFileSpecifc.map((value, x) => (
            <li key={x}>
              <div className={styles.uploadedFileContent}>
                <div className={styles.fileIcon}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    fontSize="24"
                    height="1em"
                    width="1em"
                    data-prefix="far"
                    data-icon="file-word"
                    className="svg-inline--fa fa-file-word fa-w-12"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      fill="currentColor"
                      d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z"
                    ></path>
                  </svg>
                </div>
                <div className={styles.fileInfo}>
                  <span className={styles.fileName}>
                    {value.name ? value.name.split(".")[0] : null}
                  </span>
                  <span className={styles.fileSize}>
                    {value.type.split("/")[1].toUpperCase()} / {value.size}
                  </span>
                </div>
              </div>

              <div className={styles.uploadedFileActionButtons}>
                <button className={`${styles.download} ${styles.blueButton}`}>
                  DOWNLOAD
                  <svg
                    height="19"
                    width="19"
                    fontSize="19"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="download"
                    className="svg-inline--fa fa-download fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                    ></path>
                  </svg>
                </button>
                <button
                  className={`${styles.removeFile} ${styles.blueButton}`}
                  onClick={(e) => confirmDelete(e, x)}
                >
                  REMOVE FILE
                  <svg
                    height="19"
                    width="19"
                    fontSize="19"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="times-circle"
                    className="svg-inline--fa fa-times-circle fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                    ></path>
                  </svg>
                </button>
              </div>
            </li>
          ))}
          {fieldsChecked && isEmpty(generateFileSpecifc) && (
            <div className={`text-red-400 py-1 px-2`}>Please select file.</div>
          )}
        </ul>
      </div> */}

      <DeleteFileModal
        deleteDoc={ServiceSpecificRemoveDoc}
        showModal={showDeleteModal}
        closeModal={closeModal}
      />
    </>
  );
};

export default SpecificDocumentGenerateType;
