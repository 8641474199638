import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EditServiceDocument from "pages/EditServiceDocument";

export default function EditServiceDocumentRoute() {
  const match = useRouteMatch();
 
  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <EditServiceDocument />} />
    </Switch>
  );
}