import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Services from "../../../pages/Services/Services";

export default function ServicesRoute() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <Services />} />
    </Switch>
  );
}
