import React from "react";
import styles from "./UndoDeleteServicesModal.module.css";
import {
	FaTimes
} from "react-icons/fa";
import Button from '../../../_base/button';
import { Modal } from "semantic-ui-react";

const UndoDeleteServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	itemCount
}) => {
	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					{
						itemCount == 1 ?
							<h1>{`${itemCount} service is now retrieved back to QIP Portal`}</h1> :
							<h1>{`${itemCount} services are now retrieved back to QIP Portal`}</h1>
					}
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnOk}`}>OK</Button>
					</div>

				</div>
			</div>

		</Modal>
	)
}

export default UndoDeleteServicesModal;
