import swal from "sweetalert2";

export const sweetAlert = (type, title, onClose) => {
  swal.fire({
    icon: `${type}`,
    title: `${title}`,
    showConfirmButton: false,
    timer: 3000,
    didClose: !onClose ? undefined : onClose,
  });
};

export const sweetAlertConfirm = (title, text, cb) => {
  return swal
    .fire({
      title: `${title}`,
      text: `${text}`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes!",
      cancelButtonText: "No",
    })
    .then((result) => {
      if (result.value) {
        cb();
      }
    });
};
