import React, { useState, useEffect, createRef } from "react";
import styles from "./Users.module.css";
import Pagination from '../../components/_base/table/Pagination';
import UserLayout from '../../components/userLayout/UserLayout';
import Button from "../../components/_base/button";
import {
	fetchUsers,
	deleteUser,
	approveAccess,
	usersAccessApprove,
	usersAccessRevoke,
	deleteUsers
} from '../../api/user/requests';
import { useHistory } from "react-router-dom";
import ChooseServicesModal from '../../components/popups/users/chooseServiceModal';
import UserConfirmationModal from '../../components/popups/users/UserConfirmationModal';
import DeleteUserSuccessModal from '../../components/popups/users/deleteUserSuccess';
import {
	FaTimesCircle,
	FaEdit,
	FaCog,
	FaFolderPlus,
	FaDownload,
	FaEye,
	FaAngleDown,
	FaEraser,
	FaUserShield,
	FaUser,
	FaFilter,
	FaFolder,
} from "react-icons/fa";
import { sweetAlert } from "../../components/_base/alerts/sweetAlert";
import { useStoreState } from "easy-peasy";
import isEmpty from 'lodash/isEmpty';
import DropDownMenuItem from '../../components/_base/dropDown';


const Users = () => {

	const [fetchLimitError, setFetchLimitError] = useState(false);
	const [users, setUsers] = useState([]);
	const [paramFilter, setParamFilter] = useState("");
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const [showUserActions, setShowUserActions] = useState(false);
	const [showHeaderUserActions, setHeaderShowUserActions] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});
	const [checkedUser, setCheckedUser] = useState({});
	const [selectedUsers, setSelectedUsers] = useState([]);
 

	const nodeRef = createRef();

	const [showChooseServicesModal, setChooseServicesModal] = useState(false);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	const [showDeleteUserSuccessModal, setShowDeleteUserSuccessModal] = useState(false);
	const [showApproveAccessConfirmationModal, setShowApproveAccessConfirmationModal] = useState(false);
	const [showRevokeAccessConfirmationModal, setShowRevokeAccessConfirmationModal] = useState(false);
	const [mulitpleUsers, setMultipleUsers] = useState(false);

	useEffect(() => {
		filterUser("all");
	}, []);

	const filterUser = async (keyword) => {
		setParamFilter(keyword);
		try {
			const data = await fetchUsers(keyword);
			setUsers(data);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	}

	const refreshList = () => {
		let newUserList = users.filter(u => u.id != selectedUser.id);
		setUsers(newUserList);
		setShowUserActions(false);
	}

	const addSelectedUsers = (userId) => {

		let idArr = [
			...selectedUsers,
			userId,
		];

		console.log(idArr);

		setSelectedUsers(idArr);
	}

	const onDeleteUser = async () => {

		try {
			setLoading(true);
			const response = await deleteUser(
				Number(selectedUser.id)
			);

			if (response) {
				refreshList();
				setShowDeleteConfirmationModal(false);
				setShowDeleteUserSuccessModal(true);
			}

			setShowUserActions(false);
			setLoading(false);

		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const onUpdateUserAccess = async (approve) => {

		try {
			setLoading(true);
			const response = await approveAccess({
				userId: Number(selectedUser.id),
				access_approve: approve
			});

			sweetAlert("success", `The user access is now ${approve ? "approve" : "revoke"}.`);

			if (response) {
				setShowRevokeAccessConfirmationModal(false);
				setShowRevokeAccessConfirmationModal(false);
			}

			setShowUserActions(false);
			setLoading(false);

		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	}

	const onUndoDeletedUser = async () => {

		try {
			setLoading(true);
			const response = await deleteUser(
				Number(selectedUser.id)
			);
			setLoading(false);
			setShowUserActions(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const onDeleteUsers = async () => {

		try {
			setLoading(true);
			const response = await deleteUsers(
				Number(selectedUsers)
			);
			setLoading(false);
			setSelectedUsers([]);

		} catch (error) {
			setLoading(false);
			setSelectedUsers([]);
		}
	}

	const onUsersAccessRevoke = async () => {

		try {
			setLoading(true);
			const response = await usersAccessRevoke(
				Number(selectedUser.id)
			);
			setLoading(false);
			setSelectedUsers([]);

		} catch (error) {
			setLoading(false);
			setSelectedUsers([]);
		}
	}

	const onUnoDeleteUsers = () => {

	}

	const onUsersAccessApprove = async () => {

		try {
			setLoading(true);
			const response = await usersAccessApprove(
				Number(selectedUser.id)
			);
			setLoading(false);
			setSelectedUsers([]);
		} catch (error) {
			setLoading(false);
			setSelectedUsers([]);
		}
	}

	const setMenu = (user) => {

		let currSelected = Object.assign({}, selectedUser);
		setShowUserActions(false);

		if (!isEmpty(currSelected) && currSelected.id != user.id) {
			setSelectedUser(user);
			setShowUserActions(true);
		}

		if (!isEmpty(currSelected) && currSelected.id == user.id) {
			setShowUserActions(!showUserActions);
		}

		if (isEmpty(currSelected)) {
			setSelectedUser(user);
			setShowUserActions(true);
		}
	}

	const onMenuClick = (name, data, isMultiple, e) => {

		e.preventDefault();

		switch (name) {

			case "delete":
				setShowDeleteConfirmationModal(true);
				break;

			case "revoke":
				setShowRevokeAccessConfirmationModal(true);
				break;

			case "approve":
				setShowApproveAccessConfirmationModal(true);
				break;

			default:
				break;
		}

		if (isMultiple) {

			setShowUserActions(false);
			setMultipleUsers(true);

		} else {

			setHeaderShowUserActions(false);
			setSelectedUser(data);
			setMultipleUsers(false);
		}
	}

	return (
		// <div className="main-content">

		// 	<div className="topHeaderContainer flex items-center justify-between">
		// 		<h1 className="contentHeader">User management</h1>
		// 		<div className="search flex items-center justify-center">
		// 			<input type="text" value="" name="search" id="s" placeholder="Search users" />
		// 			<button type="submit" className="flex items-center justify-center">
		// 				<svg height="17" width="17" font-size="17" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path></svg>
		// 			</button>
		// 		</div>
		// 	</div>

		<UserLayout headerText={`User management`}>

			<div className={styles.documentsList}>

				{showChooseServicesModal && (
					<ChooseServicesModal
						visibility={showChooseServicesModal}
						onOpenModal={() => setChooseServicesModal(true)}
						onCloseModal={() => setChooseServicesModal(false)}
					/>
				)}

				{showDeleteUserSuccessModal && (
					<DeleteUserSuccessModal
						visibility={showDeleteUserSuccessModal}
						// onOpenModal={() => setShowDeleteConfirmationModal(true)}
						onConfirm={() => {
							setShowDeleteUserSuccessModal(false);
							{
								mulitpleUsers ? onUnoDeleteUsers() : onUndoDeletedUser()
							}
						}}
						onCloseModal={() => setShowDeleteUserSuccessModal(false)}
					/>
				)}

				{showDeleteConfirmationModal && (
					<UserConfirmationModal
						visibility={showDeleteConfirmationModal}
						onConfirm={() => {
							setShowDeleteConfirmationModal(false);
							{
								mulitpleUsers ? onDeleteUsers() : onDeleteUser()
							}
						}}
						onCloseModal={() => setShowDeleteConfirmationModal(false)}
					/>
				)}

				{showApproveAccessConfirmationModal && (
					<UserConfirmationModal
						visibility={showApproveAccessConfirmationModal}
						onConfirm={() => {
							setShowApproveAccessConfirmationModal(false);
							{
								mulitpleUsers ? onUsersAccessApprove() : onUpdateUserAccess(true)
							}
						}}
						onCloseModal={() => setShowApproveAccessConfirmationModal(false)}
						message={`Are you sure you want to approve access to this user?`}
						OkButtonText={`APPROVE`}
					/>
				)}

				{showRevokeAccessConfirmationModal && (
					<UserConfirmationModal
						visibility={showRevokeAccessConfirmationModal}
						onConfirm={() => {
							setShowRevokeAccessConfirmationModal(false);
							{
								mulitpleUsers ? onUsersAccessRevoke() : onUpdateUserAccess(false)
							}
						}}
						onCloseModal={() => setShowRevokeAccessConfirmationModal(false)}
						message={`Are you sure you want to revoke access to this user?`}
						OkButtonText={`REVOKE`}
					/>
				)}

				<div className={styles.filterContainer}>

					<div className={styles.filterActions}>

						<div className={styles.actions}>
							<span className={styles.label}>Filters</span>
							<Button
								onClick={(e) => setChooseServicesModal(!showChooseServicesModal)}
								className={`${styles.usersFilter}`}
							>
								<FaFilter className={`mr-1.5`} size={15} />
								Filter by service
							</Button>

							<Button
								onClick={(e) => filterUser("member")}

								className={`${styles.usersFilter}`}>
								<FaUser className={`mr-1.5`} size={15} />
								Member users
							</Button>

							<Button
								onClick={(e) => filterUser("admin")}
								className={`${styles.usersFilter}`}
							>
								<FaUserShield className={`mr-1.5`} size={15} />
								Admin users
							</Button>
						</div>

					</div>

					<div className={styles.filterByServices} style={{ display: "none" }}>

						<span className={styles.label}>Filtered by selected service/s:</span>

						<ul>
							<li>
								HMC Master
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								Little Academy Elc
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								Kingdom of Angels Childcare Centre
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								Innocent MInds Preschool Academy
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								North Nowra Preschool
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								Little Coasties
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								North Nowra Preschool
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								HMC Master
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								Innocent MInds Preschool
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li>
								North Nowra Preschool
								<FaTimesCircle className={`mr-1.5`} size={15} />
							</li>
							<li className="showMore">
								Show 37 more
								<FaEye className={`mr-1.5`} size={15} />
							</li>
							<li className="clearAll">
								Clear all
								<FaEraser className={`mr-1.5`} size={15} />
							</li>
						</ul>

					</div>

				</div>

				<div className={styles.fileList}>
					<div className={styles.fileListHeader}>
						<ul className={styles.fileListCol}>
							<li>
								<label className={styles.checkbox}>
									{/* <!-- add className selectedAll--> */}
									<span className={styles.checkmark}></span>
								</label>
							</li>
							<li>
								User name
								<FaAngleDown className={`mr-1.5`} size={12} />
							</li>
							<li>
								User last name
								<FaAngleDown className={`mr-1.5`} size={12} />
							</li>
							<li>
								User type
								<FaAngleDown className={`mr-1.5`} size={12} />
							</li>
							<li>
								E-mail address
								<FaAngleDown className={`mr-1.5`} size={12} />
							</li>
							<li>
								Services
								<FaAngleDown className={`mr-1.5`} size={12} />
							</li>
							<li>
								<Button
									onClick={(e) => {
										e.preventDefault()
										setHeaderShowUserActions(!showHeaderUserActions)
									}}>
									<FaCog className={`mr-1.5`} size={19} />
								</Button>

								<ul className={`${styles.settingMenu} ${showHeaderUserActions ? "block" : "hidden"}`}>

									<DropDownMenuItem
										description={`Delete user`}
										onClick={(e) => onMenuClick("delete", null, true, e)}
									>
										<FaTimesCircle className={`mr-1.5`} size={19} />
									</DropDownMenuItem>

									<DropDownMenuItem
										description={`Approve access`}
										onClick={(e) => onMenuClick("approve", null, true, e)}
									>
										<FaFolderPlus className={`mr-1.5`} size={19} />
									</DropDownMenuItem>

									<DropDownMenuItem
										description={`Revoke access`}
										onClick={(e) => onMenuClick("revoke", null, true, e)}
									>
										<FaDownload className={`mr-1.5`} size={19} />
									</DropDownMenuItem>

								</ul>
							</li>
						</ul>
					</div>

					<div className={styles.fileListBody}>
						{!loading && users.map((user, i) => {
							return (
								<ul className={`${styles.selected} ${styles.fileListCol}`} key={user.id}>
									<li onClick={(e) => addSelectedUsers(user.id, e)}>
										<label className={styles.checkbox}>
											<span className={styles.checkmark}></span>
										</label>
									</li>
									<li>
										<div className={styles.serviceName}>
											<div className={styles.iconContainer}>
												<div className={styles.fileIcon}>
													<FaUser className={`mr-1.5`} size={24} />
												</div>
											</div>
											<span>{user.first_name}</span>
										</div>
									</li>
									<li>{user.last_name}</li>
									<li>{user.user_type}</li>
									<li>{user.email}</li>
									<li>
										The Little Kids School, HMC Master, Little Coasties...
										<div className={styles.seeAll}>
											<a data-toggle="modal" data-target="#seeAllServicesModal">
												12 Services - See all
												<span>
													<FaEye className={`mr-1.5`} size={14} />
												</span>
											</a>
										</div>
									</li>
									<li>
										<Button
											onClick={(e) => {
												setMenu(user)
												setHeaderShowUserActions(false);
											}}
										>
											<FaCog className={`mr-1.5`} size={19} />
										</Button>
										<ul className={`${styles.settingMenu} ${showUserActions &&
											selectedUser.id == user.id ? "block" : "hidden"}`
										}>

											<DropDownMenuItem
												description={`Update user`}
												onClick={(e) => {
													history.push({
														pathname: `/update-user`,
														state: { user: user }
													});
													window.scrollTo({ top: 0 })
												}}
											>
												<FaEdit className={`mr-1.5`} size={19} />
											</DropDownMenuItem>


											<DropDownMenuItem
												description={`Delete user`}
												onClick={(e) => onMenuClick("delete", user, false, e)}
											>
												<FaTimesCircle className={`mr-1.5`} size={19} />
											</DropDownMenuItem>

											<DropDownMenuItem
												description={`Approve access`}
												onClick={(e) => onMenuClick("approve", user, false, e)}
											>
												<FaFolderPlus className={`mr-1.5`} size={19} />
											</DropDownMenuItem>

											<DropDownMenuItem
												description={`Revoke access`}
												onClick={(e) => onMenuClick("revoke", user, false, e)}
											>
												<FaDownload className={`mr-1.5`} size={19} />
											</DropDownMenuItem>

										</ul>
									</li>
								</ul>
							)
						})}
					</div>
				</div>

				<Pagination />

			</div>

		</UserLayout >
	)
};

export default Users;
