const DOC_CATEGORY = {
    SERVICE_SPECIFIC: 3,
    NQA_DOCUMENT: 1,
    FOCUS_OF_THE_MONTH: 2
};

const SERVICE_TYPE = {
    LONG_DAYCARE: 1,
    FAMILY_DAYCARE: 2,
    PRE_SCHOOL: 3,
    OOSH: 4
}

const FILTER_ID = {
    FILTER_BY_SERVICE: 1,
    FILTER_BY_FILE_TYPE: 2,
    FILTER_BY_COLLECTION: 3
}

const REPORTS = {
    REPORT: 1,
    AP_REPORT: 2,
    MENTOR_REPORT: 3,
    FOM: 6
}

const REPORT_STRING = {
    REPORT: "Report",
    AP_REPORT: "AP Report",
    MENTOR_REPORT: "Mentor Report",
    FOM: "FOM Report"
}

const DOCUMENT_TYPE = {
    POLICY: 1,
    PROCEDURE: 2,
    WEBINAR: 3,
    TRAINING_MODULE: 4,
    RISK_ASSESSMENT: 5,
    FEEDBACK: 6,
    MISC: 7
}

const DOCUMENT_TYPE_STRING = {
    POLICY: "Policy",
    PROCEDURE: "Procedure",
    WEBINAR: "Webinar",
    TRAINING_MODULE: "Training Modules",
    FEEDBACK: "Feeback",
    MISC: "Miscellaneous"
}

const GLOBAL_WEBINAR = {
    GLOBAL: 1
}

const RISK_ASSESSMENT = {
    NQA_2: "NQA2 Risk Assessment",
    NQA_3: "NQA3 Risk Assessment"
}

const SERVICE_SPECIFIC_TYPE = {
    REPORTS: 1,
    AP_REPORT: 2,
    MENTOR_REPORT: 3,
    RISK_ASSESSMENT: 4,
    NQA: 5,
}

const FILE_UPLOAD_TYPE = {
    UPLOADED: 1,
    GENERATED: 2,
    WEBINAR: 3
}


export {
    DOC_CATEGORY,
    SERVICE_TYPE,
    FILTER_ID,
    DOCUMENT_TYPE,
    REPORTS,
    GLOBAL_WEBINAR,
    REPORT_STRING,
    RISK_ASSESSMENT,
    SERVICE_SPECIFIC_TYPE,
    FILE_UPLOAD_TYPE,
    DOCUMENT_TYPE_STRING
}