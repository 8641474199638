import * as React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AdminDashboard from "../AdminDashboard";
import ServicesRoute from "../ServicesRoute/ServiceList";
import NQADocumentsRoute from "../NQADocuments/NQADocumentsRoute";

import AddNewDocumentRoute from "../CreateDocument/AddNewDocumentRoute";
import AddNewServiceRoute from "../ServicesRoute/AddNewService/AddNewServiceRoute";
import AddNewuserRoute from "../UserManagement/AddNewUser/AddNewUserRoute";
import UserManagement from "../UserManagement/UserList/UserList";
import UpdateUserRoute from "../UserManagement/UpdateUser/UpdateUserRoute";
import UpdateServiceRoute from "../ServicesRoute/UpdateService/UpdateServiceRoute";
import EditNQADocuments from "routes/EditNQADocuments";
import EditServiceDocument from "routes/EditServiceDocument";
import Newsletter from "../Newsletter";
import EditNewsletterRoute from "routes/Newsletter/EditNewsletterRoute";

const { useEffect } = React;

export default function LoggedInPages() {
  const match = useRouteMatch();

  useEffect(() => {}, []);

  return (
    <Switch>
      <Route path={`/services`} component={ServicesRoute} />
      <Route path={`/newsletter`} component={Newsletter} />
      <Route path={`/edit-newsletter`} component={EditNewsletterRoute} />
      <Route path={`/nqa-documents`} component={NQADocumentsRoute} />
      <Route path={`/user-management`} component={UserManagement} />
      <Route path={`/create-new-document`} component={AddNewDocumentRoute} />
      <Route path={`/create-new-service`} component={AddNewServiceRoute} />
      <Route path={`/create-new-user`} component={AddNewuserRoute} />
      <Route path={`/update-user`} component={UpdateUserRoute} />
      <Route path={`/update-service`} component={UpdateServiceRoute} />
      <Route path={`/edit-document`} component={EditNQADocuments} />
      <Route path={`/edit-service-document`} component={EditServiceDocument} />
      <Route exact path={`/`} component={AdminDashboard} />

      <Route
        path={`${match.path}`}
        render={() => {
          return <Redirect to={`/`} />;
        }}
      />
    </Switch>
  );
}
