import React from "react";
import styles from "./RemoveOKUndoFromServicesModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import { confirmRemoveServicesDocuments } from '../../../../api/services/requests';

import { sweetAlert } from "components/_base/alerts/sweetAlert";


const RemoveOKUndoFromServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	documentIds,
	services,
	setShowRemoveFromServicesOKModal,
	refresh
}) => {

	const handleConfirmRemoveServicesDocuments = async (e) => {

		e.preventDefault();

		try {

			let payload = {
				document_id: documentIds,
				services_to_remove: [],
				confirm_remove: true,
			}

			services.forEach(s => {
				if (!s.selected) {
					payload.services_to_remove.push(s.id);
				}
			})

			let response = await confirmRemoveServicesDocuments(payload);

			if (response) {
				// sweetAlert("success", "Documents successfully remove from services.");

				refresh();

			}

			console.log(response);

		} catch (error) {

		}

	}

	const onTriggerRemove = (e) => {

		e.preventDefault();

		onCloseModal();

		handleConfirmRemoveServicesDocuments(e);
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={(e) => onTriggerRemove(e)}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`All ${documentIds.length} ${documentIds.length > 1 ? "documents" : "document"} are now removed.`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => handleConfirmRemoveServicesDocuments(e)}
							className={`${styles.btnOk}`}>OK</Button>
						<Button
							onClick={(e) => onTriggerRemove(e)}
							className={`${styles.btnUndo}`}>UNDO</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default RemoveOKUndoFromServicesModal;