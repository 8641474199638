import React from 'react';
import styles from "./UploadFileIcon.module.css";

import {
    FaFileExcel,
    FaFilePdf,
    FaFilePowerpoint,
    FaFileWord,
    FaImage,
    FaPlay,
    FaQuestionCircle
} from "react-icons/fa";

const UploadFileIcon = ({ doc }) => {
    
    const mapIcon = () => {

        let icon = null;

        if (doc.webinar_link) {
            return <div className={`${styles.fileIcon} ${styles.pdfType}`}>
                <FaPlay size={24} />
            </div>
        }

        switch (doc.fileType) {
            case "pdf":
            case "application/pdf":

                icon = <div className={`${styles.fileIcon} ${styles.pdfType}`}>
                    <FaFilePdf size={24} />
                </div>
                break;

            case "document":
            case "docx":
                icon = <div className={`${styles.fileIcon} ${styles.fileType}`}>
                    <FaFileWord size={24} />
                </div>
                break;

            case "xls":
            case "xlsx":
            case "sheet":
                icon = <div className={`${styles.fileIcon} ${styles.xslType}`}>
                    <FaFileExcel size={24} />
                </div>
                break;

            case "pptx":
                icon = <div className={`${styles.fileIcon} ${styles.pptType}`}>
                    <FaFilePowerpoint size={24} />
                </div>
                break;

            case "tiff":
            case "png":
            case "eps":
            case "jpeg":
            case "jpg":
            case "gif":
            case "img":
            case "ima":
            case "image/jpeg":
            case "image/png":
            case "image/jpg":
            case "image/img":
            case "image/gif":
            case "image/tiff":
            case "image/eps":
            case "image/ima":

                icon = <div className={`${styles.fileIcon} ${styles.image}`}>
                    <FaImage size={24} />
                </div>
                break;

            default:
                icon = <div className={`${styles.fileIcon} ${styles.default}`}>
                    <FaQuestionCircle size={24} />
                </div>
                break;
        }
        return icon;
    }

    return (
        mapIcon()
    )
}

export default UploadFileIcon;