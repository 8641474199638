import React from "react";
import styles from "./successModal.module.css";
import { useHistory } from "react-router-dom";
import Button from '../../../components/_base/button';
import { Modal } from "semantic-ui-react";
import {
	FaTimes
} from "react-icons/fa";

const SuccessModal = ({
	visibility,
	setShowSuccessModal,
	customText = "",
}) => {


	const onCloseModal = () =>{
		setShowSuccessModal(false);
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>
			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{customText || "Newsletter is now added to QIP portal" }</h1>
				</div>

				<div className={styles.modalContent}>
					<div className={styles.actionBtn}>
						<Button className={`${styles.btnOk}`} onClick={onCloseModal}>CLOSE</Button>
					</div>
				</div>
			</div>

		</Modal>
	)
}

export default SuccessModal;