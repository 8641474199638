import React from 'react';
import { ImSpinner8 } from "react-icons/im";

const LargeSpinner = ({text = "Loading..."}) => {

    return (
        <div className={`flex items-center justify-center flex-col`}>
            <ImSpinner8
                size={28}
                style={{ color: "#7BAE3E" }}
                className={`animate-spin`} />{" "}
            <span                
                className={`ml-2 text-2xl text-gray-400`}>{text}</span>
        </div>
    )
}

export default LargeSpinner;
