import React, { useState, useEffect } from "react";
import styles from "./chooseServicesModal.module.css";
import { PortalFetchServices } from "../../../api/requests";
import { Modal } from "semantic-ui-react";

const ChooseServicesModal = ({
  setServicesShowModal,
  showServicesModal,
  setSelectedServicesSpecific,
  SelectedServicesSpecific,
  setGetName,
  preselectService = null,
}) => {
  const [services, setServices] = useState([]);
  const [sServ, setSserv] = useState([]);
  const [sServName, setServName] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);

  useEffect(() => {
    const fetchServices = async () => {
      const response = await PortalFetchServices();
      setServices(response);
    };
    fetchServices();
  }, []);

  useEffect(() => {
    if (!showServicesModal || preselectService === null || !services.length) {
      return;
    }
    const serviceIndex = services.findIndex(
      (s) => s.service_id === preselectService
    );
    setActiveIndex(serviceIndex);
  }, [showServicesModal, services, preselectService]);

  const onClickCloseModal = (event) => {
    setActiveIndex(-1);
    setServicesShowModal(false);
  };

  const ChooseService = (e, sValue, sName, i) => {
    setServName([sName]);
    setActiveIndex(i);
    setSserv([sValue]);
  };

  const handleSubmit = () => {
    setGetName(sServName);
    setSelectedServicesSpecific(sServ);
    setServicesShowModal(false);
  };

  return (
    <Modal open={showServicesModal}>
      {/* <div
        className={styles.modal}
        style={showServicesModal ? { display: "block" } : { display: "none" }}
      > */}
      <div className={`${styles.modalContainer} ${styles.medium}`}>
        <button
          className={`${styles.closeModal} flex justify-center items-center`}
          onClick={onClickCloseModal}
        >
          <svg
            fontSize="16"
            width="16"
            height="16"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="times"
            className="svg-inline--fa fa-times fa-w-11"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 352 512"
          >
            <path
              fill="currentColor"
              d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
            ></path>
          </svg>
        </button>

        <div className={styles.modalTitle}>
          <h1>Choose one service for this file</h1>
        </div>

        <div className={styles.modalContent}>
          <div className={styles.search}>
            <input type="text" placeholder="Type the service name" />
            <button type="submit" className="flex items-center justify-center">
              <svg
                height="17"
                width="17"
                fontSize="17"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="search"
                className="svg-inline--fa fa-search fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                ></path>
              </svg>
            </button>
          </div>
          <ul>
            {services.map((value, index) => (
              <li
                key={index}
                onClick={(e) =>
                  ChooseService(e, value.service_id, value.service_name, index)
                }
                className={`${index == activeIndex ? styles.selected : null}`}
              >
                {" "}
                {value.service_name}
                <span>
                  0 docs{" "}
                  <label className={styles.checkbox}>
                    <input type="checkbox" />
                    <span className={styles.checkmark}></span>
                  </label>
                </span>
              </li>
            ))}
          </ul>
          <div className={styles.actionBtn}>
            <button onClick={handleSubmit}>ASSIGN DOCUMENT</button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default ChooseServicesModal;
