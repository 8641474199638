import React from "react";
import styles from "./AddDocumentsToServicesConfirmationModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';


const AddDocumentsToServicesConfirmationModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	showAddOKUndoToServicesModal,
	documentIds
}) => {

	const onAddServices = (e) => {

		e.preventDefault();

		showAddOKUndoToServicesModal();

		// onCloseModal();
	}


	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`Are you sure you want to add ${documentIds.length} ${documentIds.length > 1 ? "documents" : "document"} to these services?`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => onAddServices(e) }
							className={`${styles.btnGreen}`}>
							ADD
						</Button>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnRed}`}>
							CANCEL
						</Button>

					</div>

				</div>

			</div>


		</Modal>
	)
}

export default AddDocumentsToServicesConfirmationModal;