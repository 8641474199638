import React, { useState, useRef, useEffect } from "react";
import styles from "./AddNewService.module.css";
import Button from "../_base/button";

import headerImg from '../../static/images/header.jpg';
import colorImg from '../../static/images/color.png';

import { createServiceBrand } from '../../api/services/requests';
import { sweetAlert } from '../_base/alerts/sweetAlert';

import isEmpty from 'lodash/isEmpty';

import ColorPickerModal from '../popups/colorPickerModal';

import Spinner from "../spinner/Spinner";

import {
    FaChevronUp,
    FaChevronDown,
    FaTimesCircle
} from "react-icons/fa";

const Branding = ({
    serviceId,
    service = {}
}) => {

    const inputRef = useRef();

    const [brand_color, setBrandColor] = useState("#000000");
    const [logo_name, setSelectedLogoName] = useState("");
    const [base64_image, setBase64_image] = useState("");
    const [logoSize, setLogoSize] = useState("");
    const [logoFileType, setLogoFileType] = useState("");
    const [brandingChevron, setBrandingChevron] = useState(true);
    const [toogleDarkSkin, setToogleDarkSkin] = useState(service.skin === "#ffffff" ? false : true);
    const [showColorPickerModal, setShowColorPickerModal] = useState(false);
    const [loading, setLoading] = useState(false);


    const onSelectLogo = event => {

        if (!event.target.files || !event.target.files[0]) return;

        try {

            const files = event.target.files;

            const reader = new FileReader();

            reader.readAsDataURL(files[0]);

            reader.onload = (e) =>
                setBase64_image(e.target.result);

            setSelectedLogoName(files[0].name);

            setLogoSize(files[0].size);

            setLogoFileType(files[0].type.split("/")[1]);

        } catch (error) {

            console.log(error);

        };
    }

    const saveServiceBrand = async (e) => {

        e.preventDefault();

        if (!serviceId) {
            sweetAlert("error", "Please save service first!");
            return;
        }

        setLoading(true);

        let payload = {
            brand_color: brand_color,
            base64_image_logo: base64_image,
            logo_name: logo_name,
            service_id: serviceId,
            skin: toogleDarkSkin ? "#000000" : "#ffffff"
        }

        try {

            const { status, data } = await createServiceBrand(payload);

            if (status == 200) {

                sweetAlert("success", "Service brand successfully created.");

            }

            setLoading(false);

        } catch (error) {

            sweetAlert("error", "Error while trying to save branding!");

            setLoading(false);
        }
    }

    useEffect(() => {

        convertUrlToFile();

    }, []);

    const convertUrlToFile = async () => {

        if (!service.logo_url) return;

        try {

            let response = await fetch(service.logo_url);
            let data = await response.blob();
            let metadata = {
                type: 'image/jpeg'
            };

            let file = new File([data], service.logo_name, metadata);

            const reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = (e) => (setBase64_image(e.target.result));                

            setSelectedLogoName(file.name);

            setLogoSize(file.size);

            setLogoFileType(file.type.split("/")[1]);

        } catch (error) {

            console.log(error);

        };
    }

    return (
        <div className={`${styles.branding} ${styles.addNewService}`}>

            {showColorPickerModal && (
                <ColorPickerModal
                    visibility={showColorPickerModal}
                    onOpenModal={() => setShowColorPickerModal(true)}
                    onCloseModal={() => setShowColorPickerModal(false)}
                    setBrandColor={setBrandColor}
                    brandColor={brand_color}
                />
            )}

            <div
                onClick={(e) => setBrandingChevron(!brandingChevron)}
                className={styles.accordionHeader}>
                <h2>
                    <span className={`${styles.accordionArrow} ${styles.arrowUp}`}>
                        {
                            brandingChevron ? <FaChevronUp size={20} /> :
                                <FaChevronDown size={20} />
                        }
                    </span>
                    {/* Branding */}
                    Personalise your dashboard
                </h2>
            </div>

            <div className={`${styles.accordionContent} ${brandingChevron ? "h-0" : "h-auto"}`}>

                {/* <p>Upload the service's logo here and choose their primary colour which will personalise the services dashboards.</p> */}
                <p>Upload your logo here</p>

                <div className={`${styles.uploadFile} ${base64_image ? "hidden": ""}`}>
                    {/* <span className={styles.uploadFileText}>Drop image here or browse</span> */}
                    <label className={styles.blueButton}>
                        <input type="file"
                            ref={inputRef}
                            onChange={onSelectLogo}
                            type="file"
                            accept="image/jpeg"
                        /> UPLOAD LOGO
                    </label>
                </div>

                <div className={styles.photoUploaded} style={{ display: !base64_image ? "none" : "" }}>
                    <div className={styles.photoUploadedContent}>
                        <img
                            src={base64_image}
                            className={`bg-cover bg-center h-80 w-auto`} />
                        <div className={styles.photoInfo}>
                            <span className={styles.photoName}>{logo_name}</span>
                            <span className={styles.photoSize}>{`${logoFileType} / ${logoSize}`} Kb</span>
                        </div>
                    </div>
                    <div className={styles.btnRemove}>
                        <Button
                            className={`${styles.removeFile} ${styles.blueButton}`}
                            onClick={() => setBase64_image("")}
                        >
                            REMOVE FILE
                            <FaTimesCircle size={19} />
                        </Button>
                    </div>
                </div>

                <div className={`${styles.btnSaveColor} mb-11`}
                    style={{ display: !base64_image ? "none" : "" }}
                >
                    <Button
                        disable={loading}
                        onClick={(e) => saveServiceBrand(e)}
                        className={styles.blueButton}>
                        {
                            !loading ? "SAVE LOGO" : <Spinner />
                        }
                    </Button>
                </div>

                <div className={styles.colorBranding}>

                    <div className={styles.colorRow}>
                        {/* <div className={styles.chooseColor}>
                            <span>Choose your brand colours</span>
                            <label onClick={() => setShowColorPickerModal(true)}>
                                <img src={colorImg} />
                                Choose primary colour
                            </label>
                        </div> */}
                        <div className={`flex flex-col items-center`}>
                            <span>Turn dark skin off</span>
                            <label className={styles.switch}>
                                <input type="checkbox"
                                    onChange={(e) => setToogleDarkSkin(!toogleDarkSkin)}
                                />
                                <span className={`${styles.slider} ${styles.round}`}></span>
                            </label>
                        </div>
                    </div>


                    <div className={`${styles.colorResult} justify-center items-center flex`}>
                        <img src={headerImg} />
                    </div>

                    <div className={styles.btnSaveColor}>
                        <Button
                            disable={loading}
                            onClick={(e) => saveServiceBrand(e)}
                            className={styles.blueButton}>
                            {
                                !loading ? "SAVE BRANDING COLOURS" : <Spinner />
                            }
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Branding;