import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.css";
import UserLayout from "../../components/userLayout/UserLayout";
import Pagination from "../../components/_base/table/Pagination";
import {
  FaChild,
  FaFile,
  FaPlusCircle,
  FaSearch,
  FaUserPlus,
} from "react-icons/fa";

import ServiceList from "../../components/services/ServiceList";
import {
  softDeleteService,
  undoSoftDeleteService,
  fetchServices,
} from "../../api/services/requests";

import DeleteServicesConfirmationModal from "../../components/popups/services/deleteServicesConfirmation";
import DeleteServicesSuccessModal from "../../components/popups/services/deleteServicesSuccess";
import UndoDeleteServicesModal from "../../components/popups/services/undoDeletedServices";

import LargeSpinner from "../../components/spinner/LargeSpinner";

import { useHistory } from "react-router-dom";

import { Dropdown } from "semantic-ui-react";

import isEmpty from "lodash/isEmpty";

const Dashboard = () => {
  const history = useHistory();
  const [services, setServices] = useState([]);
  const [servicesBckup, setServicesBckup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [showDeleteServicesConfirmation, setShowDeleteServicesConfirmation] =
    useState(false);
  const [
    showDeleteServicesSuccessConfirmation,
    setShowDeleteServicesSuccessConfirmation,
  ] = useState(false);
  const [showUndoDeleteServicesSuccess, setShowUndoDeleteServicesSuccess] =
    useState(false);
  const [keyword, setKeyword] = useState("");
  const [currPage, setCurrentPage] = useState(1);

  const [searchType, setSearchType] = useState("service");

  useEffect(() => {
    filterServices("all");
  }, []);

  const filterServices = async (keyword) => {
    setLoading(true);
    // setFilterParam(keyword);
    try {
      const data = await fetchServices(keyword);
      setServices(data);
      setServicesBckup(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const refreshList = (id) => {
    let newServiceList = services.filter((u) => u.id != id);
    setServices(newServiceList);
  };

  const onSearchServiceByName = (e) => {
    e.preventDefault();
    if (keyword === "" || keyword == null) {
      setServices([...servicesBckup]);
      return;
    }

    let searchResult = [];

    searchResult = servicesBckup.filter((s) => {
      if (!s.name) return;
      return s.name.toLowerCase().includes(keyword.toLowerCase());
    });

    setCurrentPage(1);
    setServices([...searchResult]);
  };

  const onSearchServiceByEmail = (e) => {
    e.preventDefault();
    if (keyword === "" || keyword == null) {
      setServices([...servicesBckup]);
      return;
    }

    let searchResult = [];

    searchResult = servicesBckup.filter((s) => {
      if (!s.primary_email) return;
      return s.primary_email.toLowerCase().includes(keyword.toLowerCase());
    });

    setCurrentPage(1);
    setServices([...searchResult]);
  };

  const onDynamicSearch = (keyword) => {
    if (keyword === "" || keyword == null) {
      setServices([...servicesBckup]);
      return;
    }

    let searchResult = [];

    if (searchType === "service") {
      searchResult = servicesBckup.filter((s) => {
        if (!s.name) return;
        return s.name.toLowerCase().includes(keyword.toLowerCase());
      });
    }

    if (searchType === "email") {
      searchResult = servicesBckup.filter((s) => {
        if (!s.primary_email) return;
        return s.primary_email.toLowerCase().includes(keyword.toLowerCase());
      });
    }

    setCurrentPage(1);
    setServices([...searchResult]);
  };

  const onRemove = async () => {
    setShowDeleteServicesConfirmation(false);

    let serviceIds = selectedServices.map((s) => s.id);

    try {
      const isSuccess = await softDeleteService(serviceIds);

      if (isSuccess) {
        setShowDeleteServicesSuccessConfirmation(true);
        filterServices("");
      }
    } catch (error) {}
  };

  const onUndo = async () => {
    setShowDeleteServicesSuccessConfirmation(false);

    let serviceIds = selectedServices.map((s) => s.id);

    try {
      const isSuccess = await undoSoftDeleteService(serviceIds);

      if (isSuccess) {
        setShowUndoDeleteServicesSuccess(true);

        filterServices("");
      }
    } catch (error) {}
  };

  return (
    <UserLayout headerText={`Dashboard`}>
      {showDeleteServicesConfirmation && (
        <DeleteServicesConfirmationModal
          itemCount={selectedServices.length}
          visibility={showDeleteServicesConfirmation}
          onOpenModal={() => setShowDeleteServicesConfirmation(true)}
          onCloseModal={() => setShowDeleteServicesConfirmation(false)}
          onRemove={onRemove}
        />
      )}

      {showDeleteServicesSuccessConfirmation && (
        <DeleteServicesSuccessModal
          itemCount={selectedServices.length}
          visibility={showDeleteServicesSuccessConfirmation}
          onOpenModal={() => setShowDeleteServicesSuccessConfirmation(true)}
          onCloseModal={() => setShowDeleteServicesSuccessConfirmation(false)}
          onUndo={onUndo}
          onClickOk={() => setSelectedServices([])}
        />
      )}

      {showUndoDeleteServicesSuccess && (
        <UndoDeleteServicesModal
          itemCount={selectedServices.length}
          visibility={showUndoDeleteServicesSuccess}
          onOpenModal={() => setShowUndoDeleteServicesSuccess(true)}
          onCloseModal={() => setShowUndoDeleteServicesSuccess(false)}
        />
      )}

      <div className={styles.quickActions}>
        <h2>Welcome to Holistic Portal</h2>
        <p>
          This is your dashboard where you can do quick actions or find services
          or documents.
        </p>
        <ul>
          <li onClick={() => history.push("/create-new-document")}>
            <FaPlusCircle size={20} className={`absolute right-5 top-2`} />
            <FaFile size={40} />
            <span>New document</span>
          </li>
          {/*<li onClick={() => history.push("/create-new-service")}>
						<FaPlusCircle size={20} className={`absolute right-5 top-2`} />
						<FaChild size={40} />
						<span>New service</span>
					</li>*/}
          <li onClick={() => history.push("/nqa-documents")}>
            <FaFile size={40} />
            <span>View documents</span>
          </li>
          <li onClick={() => history.push("/services")}>
            <FaChild size={40} />
            <span>View services</span>
          </li>
        </ul>
      </div>

      <div className={styles.topHeaderContainer}>
        <h1 className={styles.contentHeader}>List of Services</h1>
      </div>

      <div className={styles.documentsList}>
        <div className={styles.filterContainer}>
          <div className={`${styles.searchActions} flex justify-between`}>
            <div className={styles.searchContainer}>
              <div
                className={`${styles.search} flex items-center justify-between`}
              >
                <input
                  className="flex-1"
                  type="text"
                  value={keyword}
                  name="search"
                  id="s"
                  placeholder="Type service name"
                  autoComplete="off"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                    onDynamicSearch(e.target.value);
                  }}
                />
                <div className="border-l border-hubGrayLt p-1">
                  <div className=" justify-center items-center px-2">
                    <Dropdown
                      value={searchType}
                      options={[
                        { text: "Service", value: "service" },
                        { text: "Email", value: "email" },
                      ]}
                      onChange={(e, { value }) => {
                        setKeyword("");
                        setSearchType(value);
                        setServices([...servicesBckup]);
                      }}
                    />
                  </div>
                </div>
                <button
                  onClick={(e) => {
                    if (searchType === "email") {
                      onSearchServiceByEmail(e);
                    }

                    if (searchType === "service") {
                      onSearchServiceByName(e);
                    }
                  }}
                  // type="submit"
                  className={`flex items-center justify-center`}
                >
                  <FaSearch size={17} />
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading && (
          <div className={`p-16 flex justify-center h-screen`}>
            <LargeSpinner />
          </div>
        )}
        {!loading && !isEmpty(services) && (
          <>
            <ServiceList
              showContact={false}
              setSelectedServices={setSelectedServices}
              filterServices={filterServices}
              services={services.filter((s) => s.has_portal === 1)}
              refreshList={refreshList}
              setServices={setServices}
              currPage={currPage}
              setCurrentPage={setCurrentPage}
            />
          </>
        )}
        {!loading && isEmpty(services) && (
          <div
            className={`${styles.fileList} bg-white flex h-screen items-center justify-center px-10`}
          >
            <span className={`text-gray-400 text-2xl py-6`}>
              No documents found
            </span>
          </div>
        )}
      </div>
    </UserLayout>
  );
};

export default Dashboard;
