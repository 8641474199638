import React from 'react'
import styles from "../../../../components/popups/documents/deleteDocumentConfirmation/DeleteDocumentConfirmationModal.module.css";
import { Modal } from 'semantic-ui-react'

import { 
	FaTimes
} from 'react-icons/fa'
import Button from 'components/_base/button/Button'
import { sweetAlert } from 'components/_base/alerts/sweetAlert'

export default function RemovePermanentPopUp({
	visibility,
	onCloseModal,
	defaultFile,
	setDefaultFile,
	setUploadedFile
	}) {

	const handleOk = async() =>{

		let data = {
			'label': "",
			'size': "",
			'type': "",
			'url': "",
		}

		setDefaultFile(data)
		setUploadedFile([])
		onCloseModal(false)
		
	}

	const handleUndo = () =>{
		onCloseModal(false)
		sweetAlert("success", "Document successfully retrieve");
	}

    return (
        <Modal
			centered={true}
			open={visibility}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>
			<div className={`${styles.modalContainer}`}>

				<Button
					onClick={() => onCloseModal(false)}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>This document is now permanently deleted from the Management Portal</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={() => handleOk()}
							className={`${styles.btnOk}`}>OK</Button>
						<Button
							onClick={() => handleUndo()}
							className={`${styles.btnUndo}`}>UNDO</Button>
					</div>
				</div>

			</div>

		</Modal>
    )
}
