import React from "react";
import styles from "./AddOKUndoToServicesModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import { confirmAddServicesToDocuments } from '../../../../api/services/requests';
import { sweetAlert } from "components/_base/alerts/sweetAlert";


const AddOKUndoToServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	documentIds,
	services,
	showAddToServicesOKModal,
	refresh
}) => {

	const handleConfirmAddServicesDocuments = async (e) => {

		e.preventDefault();

		try {

			let payload = {
				document_id: documentIds,
				services_to_add: [],
				confirm_add: true,
			}

			services.forEach(s => {
				if (s.selected) {
					payload.services_to_add.push(s.id);
				}
			})

			let response = await confirmAddServicesToDocuments(payload);

			if(response)
			{
				// sweetAlert('success', 'Documents successfully added.');

				refresh();
			}

		} catch (error) {

		}
	}

	const onTriggerAdd = (e) => {

		e.preventDefault();

		onCloseModal();

		handleConfirmAddServicesDocuments(e);
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={(e) => onTriggerAdd(e)} 
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`All ${documentIds.length} ${documentIds.length > 1 ? "documents" : "document"} are now added.`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => handleConfirmAddServicesDocuments(e)}
							className={`${styles.btnOk}`}>OK</Button>
						<Button
							onClick={() => {
								showAddToServicesOKModal()
								onCloseModal()
							}}
							className={`${styles.btnUndo}`}>UNDO</Button>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default AddOKUndoToServicesModal;