import React, { useState, useEffect } from "react";

import styles from "../../pages/AddNewDocument/CreateDocument.module.css";

const UploadOrGenerate = ({
    setSpecificDocState,
    specificDocState
}) => {
    const isEditGenerateDoc =
      specificDocState.id && specificDocState.upload_or_generate === 2;

    useEffect(() => {


    }, [specificDocState])

    return (
        <div className={styles.fileOrigin}
            style={isEditGenerateDoc ? { display: "flex", justifyContent: "center" } : {} }>
            {!isEditGenerateDoc && <label className={`${styles.radioBtn} ${styles.inputUploadFile}`}>
                <input
                    type="radio"
                    checked={specificDocState.upload_or_generate == 1}
                    name="inputUpload"
                    onChange={(e) => setSpecificDocState({ ...specificDocState, upload_or_generate: 1 })}
                />
                <span className={styles.checkmark}></span>
                Upload file
            </label>}
            <label className={`${styles.radioBtn} ${styles.inputGenerateFile}`}>
                <input
                    type="radio"
                    checked={specificDocState.upload_or_generate == 2}
                    name="inputUpload"
                    onChange={(e) => {
                        {
                            setSpecificDocState({ ...specificDocState, upload_or_generate: 2 })
                        }
                    }}
                />
                <span className={styles.checkmark}></span>
                Generate file
            </label>
        </div>
    )

}

export default UploadOrGenerate;