import React from "react";
import styles from "./AddDocumentsToServicesOKModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';


const AddDocumentsToServicesOKModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	documentIds,
	refresh
}) => {

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`${documentIds.length} ${documentIds.length > 1 ? "documents" : "document"} are now added!`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							 onClick={(e) => 
								{
									refresh()
									onCloseModal(e)
								}}
							className={`${styles.btnOk}`}>OK</Button>
					</div>

				</div>

			</div>


		</Modal>
	)
}

export default AddDocumentsToServicesOKModal;