
import React from "react";
import styles from "./LoaderModal.module.css";
 
import { Modal } from "semantic-ui-react";
import { ImSpinner8 } from "react-icons/im";

const LoaderModal = ({
	visibility,	 
	count,
}) => {

	return (
		<Modal
			centered={true}
			open={visibility}
			closeOnDimmerClick={false}
			size={`tiny`}
		>

			<div className={`${styles.modalContainer} flex items-center justify-center flex-col`}>

				<ImSpinner8
					size={28}
					style={{ color: "#0288D1" }}
					className={`animate-spin`} />

				<div className={`${styles.modalTitle}`}>
					<h1>{`Loading ${count} documents`}</h1>
				</div>

			</div>

		</Modal>
	)
}

export default LoaderModal;