
import swal from "sweetalert2";

export const sweetAlert = ({ type, title }) => {
    swal.fire({
        type: `${type}`,
        title: `${title}`,
        showConfirmButton: false,
        timer: 3000
    });
};

export const sweetAlertConfirm = ({ title, text, cb }) => {
    return swal
        .fire({
            title: `${title}`,
            text: `${text}`,
            type: "info",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then((result) => {
            if (result.value) {
                cb();
            }
        });
};
