import * as React from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import LoginRoute from "../Login";

const { useEffect } = React;

export default function LoggedOutPages() {
  const match = useRouteMatch();

  useEffect(() => {}, []);

  return (
    <Switch>
      <Route exact path={`/`} component={LoginRoute} />

      <Route
        path={`${match.path}`}
        render={() => {
          return <Redirect to={`/`} />;
        }}
      />
    </Switch>
  );
}
