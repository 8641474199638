import * as React from "react";

import { useHistory, useLocation } from "react-router-dom";

import styles from "./Header.module.css";

import logoImg from "../../static/images/Holistic _Portal_Logo.png";
import {
  FaAngleDown,
  FaChild,
  FaEnvelope,
  FaFileAlt,
  FaHeart,
  FaSearch,
  FaSignOutAlt,
  FaTimes,
} from "react-icons/fa";

import NavSubLinks from "./NavSubLinks";
import NavLinks from "./NavLinks";
import LogoutModal from "../Modals/LogoutModal/LogoutModal";
import { isUserLoggedIn } from "../../services/auth";
// import HelpModal from '../Modals/HelpModal'

const { useState, useCallback } = React;

const Header = () => {
  const history = useHistory();

  const [showMenu, setShowMenu] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  const hub_admin_url = process.env.REACT_APP_HUB_URL;

  const location = useLocation();

  return (
    <div className={`${styles.header}`}>
      {logoutModal && (
        <LogoutModal
          visibility={logoutModal}
          onCloseModal={() => setLogoutModal(false)}
        />
      )}
      <div
        className={`${styles.topHeader} ${isUserLoggedIn() ? "" : "hidden"}`}
      >
        <div className={`${styles.headerRow} flex items-center`}>
          <div className={`${styles.topHeaderMenu} flex`}>
            <ul className={`${styles.topHeaderMenu} flex items-center`}>
              <li>
                <a
                  href={`${hub_admin_url}messages`}
                  className="flex items-center"
                  target="_blank"
                >
                  <i className="fa fa-envelope mr-2"></i>
                  Messages
                </a>
              </li>
              {/* <li>
                <a href={`${process.env.REACT_APP_HUB_URL}users`} className="flex items-center" target="_blank" rel="noreferrer">
                  <i className="fa fa-users mr-2"></i>
                  Users
                </a>
              </li> */}
              <li>
                <a
                  href={`${process.env.REACT_APP_HUB_URL}`}
                  className="flex items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-list mr-2"></i>
                  Subscriptions
                </a>
              </li>
              <li>
                <a
                  href="https://holisticmanagement.net.au/get-help/"
                  className="flex items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaHeart size={13} className={`mr-3`} />
                  Help
                </a>
              </li>
            </ul>

            <div className={`${styles.headerButtons}`}>
              <div className={`${styles.LogoutBtn}`}>
                <button onClick={() => setLogoutModal(true)}>Logout</button>
              </div>
              {/*<div className={`${styles.searchBtn}`}>
                <button>
                  <FaSearch size={17} />
                </button>
              </div>*/}
            </div>
          </div>

          <div
            className={`${styles.searchBar} flex items-center justify-center`}
          >
            <div
              className={`${styles.searchRow} flex items-center justify-between`}
            >
              <input
                type="text"
                value=""
                name="search"
                id="s"
                placeholder="| Type your search term here &amp; hit enter"
              />
              <button
                type="submit"
                className="flex items-center justify-center"
              >
                <FaSearch size={17} />
              </button>
            </div>
            <div className={`${styles.closeSearch}`}>
              <FaTimes size={17} />
            </div>
          </div>
        </div>
      </div>

      <div className={`${styles.bottomHeader}`}>
        <div
          className={`${styles.headerRow} flex items-center justify-between`}
        >
          <div className={`${styles.logo}`}>
            <a href="">
              <img
                src={logoImg}
                alt="HM&C Portal logo"
                onClick={() => {
                  history.push("/");
                  window.scrollTo({ top: 0 });
                }}
              />
            </a>
          </div>

          <div
            className={`${styles.headerMenu} ${
              isUserLoggedIn() ? "visible" : "invisible"
            }`}
          >
            <ul className={`${styles.mainHeaderMenu} flex items-center`}>
              <li
                className={`${styles.menuItem} ${
                  location.pathname === "/" ? styles.active : ""
                }`}
              >
                <span
                  className={`${styles.menuItemText}`}
                  onClick={() => {
                    history.push("/");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  DASHBOARD
                </span>
              </li>
              <li
                className={`${styles.menuItem} ${
                  location.pathname === "/create-new-document"
                    ? styles.active
                    : ""
                }`}
              >
                <span
                  className={`${styles.menuItemText}`}
                  onClick={() => {
                    history.push("/create-new-document");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  CREATE DOCUMENT
                  {/* <FaAngleDown size={20} className={`mt-0.5`} /> */}
                </span>

                {/* <ul
                  className={`${styles.settingMenu} ${
                    showMenu ? "block" : "hidden"
                  }`}
                >
                  <li>
                    <a
                      onClick={() => {
                        history.push("/create-new-document");
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      <FaFileAlt size={14} />
                      New Document
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history.push("/create-new-service");
                        window.scrollTo({ top: 0 });
                      }}
                    >
                      <FaChild size={14} />
                      New Service
                    </a>
                  </li>                 
                </ul> */}
              </li>
              <li
                className={`${styles.menuItem} ${
                  location.pathname === "/services" ? styles.active : ""
                }`}
              >
                <span
                  className={`${styles.menuItemText}`}
                  onClick={() => {
                    history.push("/services");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  SERVICES
                </span>
              </li>
              <li
                className={`${styles.menuItem} ${
                  location.pathname === "/newsletter" ? styles.active : ""
                }`}
              >
                <span
                  onClick={() => {
                    history.push("/newsletter");
                    window.scrollTo({ top: 0 });
                  }}
                  className={`${styles.menuItemText}`}
                >
                  NEWSLETTERS
                </span>
              </li>
              <li
                className={`${styles.menuItem} ${
                  location.pathname === "/nqa-documents" ? styles.active : ""
                }`}
              >
                <span
                  className={`${styles.menuItemText}`}
                  onClick={() => {
                    history.push("/nqa-documents");
                    window.scrollTo({ top: 0 });
                  }}
                >
                  {" "}
                  DOCUMENTS
                </span>
              </li>
            </ul>

            <div className={`${styles.mobileMenu}`}>
              <button
                className={` ${styles.menuBars} flex items-center justify-center`}
              >
                <div className={`${styles.bars}`}></div>
              </button>
              <ul className={`${styles.mainHeaderMobileMenu}`}>
                <li className={`${styles.menuItem}`}>
                  <span
                    className={`${styles.menuItemText}`}
                    onClick={() => {
                      history.push("/");
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    DASHBOARD
                  </span>
                </li>
                <li className={`${styles.menuItem} ${styles.subMenuContainer}`}>
                  <span className={`${styles.menuItemText}`}>
                    CREATE NEW
                    <FaAngleDown size={20} />
                  </span>
                  <ul className={`${styles.subMenu}`}>
                    <li>
                      <span>
                        <FaFileAlt size={20} />
                        New document
                      </span>
                    </li>
                    <li>
                      <span>
                        <FaChild size={20} />
                        Service
                      </span>
                    </li>
                    <li>
                      <span>
                        <FaEnvelope size={20} />
                        Newsletter
                      </span>
                    </li>
                  </ul>
                </li>
                <li className={`${styles.menuItem}`}>
                  <span className={`${styles.menuItemText}`}>SERVICES</span>
                </li>
                <li className={`${styles.menuItem}`}>
                  <span className={`${styles.menuItemText}`}>NEWSLETTERS</span>
                </li>
                <li className={`${styles.menuItem}`}>
                  <span
                    className={`${styles.menuItemText}`}
                    onClick={() => {
                      history.push("/nqa-documents");
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    {" "}
                    DOCUMENTS
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
