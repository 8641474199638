import React, { useState, useEffect } from "react";

import styles from "../../pages/Services/Services.module.css";
import isEmpty from "lodash/isEmpty";

import DropDownMenuItem from "../_base/dropDown/DropDownMenuItem";
import LongDayCare from "../_base/svg/LongDayCare";
import FamilyDayCare from "../_base/svg/FamilyDayCare";
import OOSH from "../_base/svg/OOSH";
import PreSchool from "../_base/svg/PreSchool";

import DeleteServiceConfirmationModal from "../popups/services/deleteServiceConfirmation";
import DeleteServiceSuccessModal from "../popups/services/deleteServiceSuccess";
import ArchiveSuccessModal from "../popups/services/archiveSuccess";
import CancelSubscriptionModal from "../popups/services/cancelSubscription";
import { fetchCenterUsers } from "../../api/requests";

import { headerSort } from "../../helpers/headerSort";

import {
  // fetchServices,
  deleteService,
  // updateService,
  archiveService,
} from "../../api/services/requests";

import Button from "../_base/button";
import { useHistory } from "react-router-dom";
import { sweetAlert } from "../_base/alerts/sweetAlert";

import {
  FaAngleDown,
  FaCog,
  FaEdit,
  FaEye,
  FaTimesCircle,
} from "react-icons/fa";
import Pagination from "../../components/Pagination/Pagination";

import redirect from "../../helpers/redirect";

const ServiceList = ({
  showContact,
  filterServices,
  services = [],
  refreshList,
  currPage,
  setCurrentPage,
}) => {
  const [showServiceActions, setShowServiceActions] = useState(false);
  const [showHeaderServiceActions, setHeaderShowServiceActions] =
    useState(false);
  const [showServiceTypeMenu, setShowServiceTypeMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [services, setServices] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [
    showDeleteServicesConfirmationModal,
    setShowDeleteServicesConfirmationModal,
  ] = useState(false);
  // const [selectedServices, setSelectedServices] = useState([]);
  const [selectedService, setSelectedService] = useState({});
  const [showDeleteServiceSuccessModal, setShowDeleteServiceSuccessModal] =
    useState(false);
  const [showArchiveServiceSuccessModal, setShowArchiveServiceSuccessModal] =
    useState(false);

  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);

  const [data, resetData] = useState([]);

  const history = useHistory();

  //pagination config
  const [rpp, setRpp] = useState(8);

  const [asc, setAsc] = useState(true);

  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {
    //filterServices("all");

    resetData([...services]);
  }, [services]);

  const onArchive = async (status) => {
    try {
      let serviceData = Object.assign({}, selectedService);

      serviceData.is_archive = status;

      const response = await archiveService(serviceData);

      if (response) {
        if (status) {
          setShowArchiveServiceSuccessModal(true);
        } else {
          sweetAlert("success", "Service is now remove from archive");
          setShowArchiveServiceSuccessModal(false);
          setShowDeleteConfirmationModal(false);
        }
      }

      filterServices("all");
    } catch (error) {
      if (error.response.data.errors) {
        console.log(error.response.data.errors);
      }
    }
  };

  const renderServiceType = (serviceTypeId) => {
    let data = {};

    switch (serviceTypeId) {
      case 1:
        data = { logo: <LongDayCare />, label: "Long Daycare" };
        break;
      case 2:
        data = { logo: <FamilyDayCare />, label: "Family Daycare" };
        break;
      case 3:
        data = { logo: <PreSchool />, label: "Pre-school" };
        break;
      case 4:
        data = { logo: <OOSH />, label: "OOSH" };
        break;
      default:
        data = { logo: <LongDayCare />, label: "Long Daycare" };
        break;
    }

    return data;
  };

  const onDeleteForever = async () => {
    try {
      setLoading(true);
      const response = await deleteService(Number(selectedService.id));

      if (response) {
        refreshList();
        setShowDeleteConfirmationModal(false);
        setShowDeleteServiceSuccessModal(true);
      }

      setShowServiceActions(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onMenuClick = (name, data, isMultiple, e) => {
    e.preventDefault();

    switch (name) {
      case "delete":
        setShowDeleteConfirmationModal(true);
        break;

      case "cancel":
        setShowCancelSubscriptionModal(true);
        break;

      case "view":
        onGetUserCenters(data.id);
        break;

      default:
        break;
    }

    if (isMultiple) {
      setShowServiceActions(false);
      // setMultipleUsers(true);
    } else {
      setHeaderShowServiceActions(false);
      setSelectedService(data);
      // setMultipleUsers(true);
    }
  };

  const onGetUserCenters = async (centerId) => {
    try {
      setRedirecting(true);

      const response = await fetchCenterUsers(centerId);

      const user = response.filter((u) => u.type.toLowerCase() === "admin");

      if (user) {
        setRedirecting(false);
        redirect(user[0]);
      }
      setRedirecting(false);
    } catch (error) {
      setRedirecting(false);

      console.error(error);
    }
  };

  const onMouseLeave = () => {
    setShowServiceActions(false);
    setSelectedService({});
  };

  const setMenu = (service) => {
    setSelectedService({ ...service });
    setShowServiceActions(true);
  };

  const sorting = (headerName) => {
    let { data, toAsc } = headerSort(headerName, services, asc);

    setAsc(toAsc);

    resetData(data);
  };

  return (
    <>
      {showDeleteConfirmationModal && (
        <DeleteServiceConfirmationModal
          visibility={showDeleteConfirmationModal}
          onOpenModal={() => setShowDeleteConfirmationModal(true)}
          onCloseModal={() => setShowDeleteConfirmationModal(false)}
          onDeleteForever={onDeleteForever}
          onArchive={onArchive}
        />
      )}

      {showDeleteServicesConfirmationModal && (
        <DeleteServiceConfirmationModal
          visibility={showDeleteConfirmationModal}
          onOpenModal={() => setShowDeleteConfirmationModal(true)}
          onCloseModal={() => setShowDeleteConfirmationModal(false)}
          onDeleteForever={onDeleteForever}
          onArchive={onArchive}
        />
      )}

      {showDeleteServiceSuccessModal && (
        <DeleteServiceSuccessModal
          visibility={showDeleteServiceSuccessModal}
          onOpenModal={() => setShowDeleteServiceSuccessModal(true)}
          onCloseModal={() => setShowDeleteServiceSuccessModal(false)}
          // onUndo={onArchive}
        />
      )}

      {showArchiveServiceSuccessModal && (
        <ArchiveSuccessModal
          visibility={showArchiveServiceSuccessModal}
          onOpenModal={() => setShowArchiveServiceSuccessModal(true)}
          onCloseModal={() => setShowArchiveServiceSuccessModal(false)}
          onUndo={onArchive}
        />
      )}

      {showCancelSubscriptionModal && (
        <CancelSubscriptionModal
          visibility={showCancelSubscriptionModal}
          onOpenModal={() => setShowCancelSubscriptionModal(true)}
          onCloseModal={() => setShowCancelSubscriptionModal(false)}
        />
      )}

      <div className={styles.fileList}>
        <div className={styles.fileListHeader}>
          <ul className={styles.fileListCol}>
            <li className={`flex flex-row items-center`}>
              Service Type
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li>
            <li
              className={`flex flex-row items-center`}
              onClick={() => sorting("name")}
            >
              Service name
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li>
            <li className={`flex flex-row items-center`}>
              Account Owner
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li>

            <li className={`flex flex-row items-center`}>
              Admin Email
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li>
            {/* <li className={`${styles.headerFilter} flex flex-row items-center`}>
                         
                            <div className={`flex flex-row items-center`}
                                onClick={() => setShowServiceTypeMenu(!showServiceTypeMenu)}
                            >
                                Service type
                                <FaAngleDown className={`mr-1.5`} size={12} />
                            </div>

                            <ul className={`${styles.serviceTypeFilter} ${showServiceTypeMenu ? "block" : "hidden"}`}>
                                <li className={styles.selected}>
                                    Long Daycare
                                    <FaCheckCircle size={20} />
                                </li>
                                <li className={styles.selected}>
                                    Family Daycare
                                    <FaCheckCircle size={20} />
                                </li>
                                <li>
                                    Pre-school
                                    <FaCheckCircle size={20} />
                                </li>
                                <li>
                                    OOSH
                                    <FaCheckCircle size={20} />
                                </li>
                                <li>
                                    <button>FILTER  SERVICES</button>
                                </li>
                            </ul>
                        </li> */}
            {/* <li className={`flex flex-row items-center`}>
              Subscription
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li>
            <li className={`flex flex-row items-center`}>
              End Date
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li>
            <li className={`flex flex-row items-center`}>
              Status
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li>
            <li className={`flex flex-row items-center`}>
              Payment
              <FaAngleDown className={`mr-1.5`} size={12} />
            </li> */}
            <li></li>
          </ul>
        </div>
        {console.log("services ", services)}
        <div className={styles.fileListBody}>
          {!loading &&
            services.length > 0 &&
            services
              .slice(currPage * rpp - rpp, currPage * rpp)
              .map((service, i) => {
                return (
                  <ul key={service.id} className={`${styles.fileListCol}`}>
                    <li>
                      <div className={styles.serviceName}>
                        <div className={styles.fileIconContainer}>
                          <div
                            className={`${styles.fileIcon} ${styles.active}`}
                          >
                            {renderServiceType(service.service_type_id).logo}
                          </div>
                        </div>
                        <div className={styles.fileContent}>
                          <span>
                            {" "}
                            {renderServiceType(service.service_type_id).label}
                          </span>
                          {/* <span>{service.description}</span> */}
                        </div>
                      </div>
                    </li>

                    <li className={` `}>
                      <div className={styles.fileContent}>
                        {" "}
                        <span> {service.name}</span>
                      </div>
                    </li>
                    <li>{service.admin_name}</li>
                    <li>{service.primary_email}</li>
                    {/* <li>31/12/3021</li>
                    <li>Active</li>
                    <li>$977 year</li> */}
                    <li
                      onMouseEnter={() => {
                        setMenu(service);
                      }}
                      onMouseLeave={() => onMouseLeave()}
                    >
                      <Button
                        className={`${
                          showServiceActions && selectedService.id == service.id
                            ? styles.active
                            : styles.notActive
                        }`}
                      >
                        <FaCog size={19} />
                      </Button>
                      <ul
                        className={`${styles.settingMenu} ${
                          showServiceActions && selectedService.id == service.id
                            ? "block"
                            : "hidden"
                        }`}
                      >
                        <DropDownMenuItem
                          description={`Edit service details`}
                          onClick={(e) => {
                            history.push({
                              pathname: `/update-service`,
                              state: { service: service },
                            });
                            window.scrollTo({ top: 0 });
                          }}
                        >
                          <FaEdit className={`mr-1.5`} size={19} />
                        </DropDownMenuItem>

                        {/* <DropDownMenuItem
                                            description={`Cancel subscription`}
                                            onClick={(e) => onMenuClick("cancel", service, false, e)}
                                        >
                                            <FaTimesCircle className={`mr-1.5`} size={19} />
                                        </DropDownMenuItem> */}

                        <DropDownMenuItem
                          className={
                            redirecting
                              ? " cursor-wait"
                              : "pointer-events-auto cursor-pointer"
                          }
                          description={`View as service`}
                          onClick={(e) => {
                            if (!redirecting) {
                              onMenuClick("view", service, false, e);
                            }
                          }}
                        >
                          <FaEye className={`mr-1.5`} size={19} />
                        </DropDownMenuItem>
                      </ul>
                    </li>
                  </ul>
                );
              })}
        </div>
      </div>
      {!loading && services.length > 0 && (
        <div className={`${styles.pagination} ${styles.flex}`}>
          <Pagination
            currentPage={currPage}
            onPageChange={setCurrentPage}
            lastPage={Math.ceil(services.length / rpp)}
          />
        </div>
      )}
    </>
  );
};

export default ServiceList;
