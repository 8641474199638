import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom";

import styles from './EditNQADocument.module.css'
import createDocStyles from "pages/AddNewDocument/CreateDocument.module.css";
import { sweetAlert } from 'components/_base/alerts/sweetAlert';
import UserLayout from 'components/userLayout/UserLayout'
import ChooseNQA from './components/ChooseNQA'
import ChooseDocumentType from './components/ChooseDocumentType'
import ChooseServiceType from './components/ChooseServiceType'
import UploadFiles from './components/UploadFiles'

import { updateNqaDoc, documentDeleteFile } from 'api/documents/requests';

import { FaSpinner } from 'react-icons/fa';
import WebinarAndTMType from './components/WebinarAndTMType';

import UploadFileIcon from 'components/document/documentIcon/UploadFileIcon';
import LargeSpinner from 'components/spinner/LargeSpinner';

import RemovePopUp from './components/modal/RemovePopUp';
import RemovePermanentPopUp from './components/modal/RemovePermanentPopUp';

import ServiceModal from '../../components/popups/createNewDocument/chooseServicesModal';
import NqaDocumentWebinarType from "pages/AddNewDocument/NQADocument/NqaDocumentWebinarType";

import { PortalFetchServices } from 'api/requests';
import NqaDocumentGenerateType from 'pages/AddNewDocument/NQADocument/NqaDocumentGenerateType';
import { EditorState } from "draft-js";
import LinkOrDocument from 'pages/AddNewDocument/LinkOrDocument';

const DOC_TYPE = {
    POLICY: 1,
    PROCEDURE: 2,
    WEBINAR: 3,
    TRAINING: 4,
  };
export default function EditNQADocument() {

    const history = useHistory();

    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [nqaArea, setNqaArea] = useState([])
    const [docType, setDocType] = useState(0)
    const [serviceType, setServiceType] = useState([])

    const [uploadedFile, setUploadedFile] = useState([])
    
    const [notifyService, setNotifyService] = useState(0)
    const [defaultFile, setDefaultFile] = useState({})

    const [showRemovePopUp, setShowRemovePopUp] = useState(false)
    const [showRemoveForeverPopUp, setShowRemoveForeverPopUp] = useState(false)
    
    const [showServicesModal, setServicesShowModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [services, setServices] = useState([])
    const [servicesSelected, setServicesSelected] = useState([]);

    const [docId, setDocId] = useState(0)
    const [storId, setStorId] = useState(0)

    const [nqaServices, setNqaServices] = useState([]);
    const [webinarInputs, setWebinarInputs] = useState({});
    const [webinarUpload, setWebinarUpload] = useState({});
    const [originalDocRemoved, setOriginalDocRemoved] = useState(false);

  const [generateFiles, setGenerateFiles] = useState([]);
  const [generateInputs, setGenerateInputs] = useState([]);
  const [editorValue, setEditorValue] = useState(EditorState.createEmpty());


  const [isExternalLink, setIsExternalLink] = useState(true);

    const isTypeMedia = docType  === DOC_TYPE.WEBINAR || docType === DOC_TYPE.TRAINING;

    useEffect(() =>{
        
    
        setLoading(true)

        onFetchData()
        fetchServices()

        setTimeout(() =>{

            setLoading(false)

        }, 3000)
        
        
    }, [])

    const handleNotifChange = () =>{
        if(notifyService === 0){
            setNotifyService(1)
        }else{
            setNotifyService(0)
        }
    }

    const fetchServices= async () =>{
        const response = await PortalFetchServices();
        setServices(response);
    }

    const handleCallBack = () => {
        setShowModal(false);
    };

    
     
    const onFetchData = async() => {
        setNqaArea(location.state.doc.nqa_area_id)
        setDocType(location.state.doc.document_type_id)
        setStorId(location.state.doc.id)
        setDocId(location.state.doc.document_id)
        setNotifyService(location.state.doc.notify_service)
        setServiceType(location.state.doc.service_type_id)

        const { doc } = location.state;
        const { webinar_link, webinar_title, webinar_description } = doc.raw_uploaded_file;
        setWebinarInputs({
          webinar_link,
          webinar_title,
          date: location.state.doc.date,
          webinar_description,
          file_title: location.state.doc.file_title,
        });
        setWebinarUpload({
            name: doc.label,
            type: doc.fileType,
            size: doc.fileSize,
            base64: doc.file,
        });

        setGenerateInputs({
            file_title: doc.file_title,
            file_version: doc.file_version,
            date: doc.date,
        });

        setGenerateFiles([{
            name: doc.fileName,
            type: doc.fileType,
            size: doc.fileSize,
            url: doc.fileUrl,
        }]);

        setIsExternalLink(!!webinar_link);

        const serviceTypes = location.state.doc.service_type_id.map(
          (value) => value.id
        );
        setServiceType(serviceTypes);

        const serviceBasis = !serviceTypes.includes(5)
          ? "excluded_services"
          : "services";
        const servicesSelected =
          location.state.doc[serviceBasis]?.map((value) => value.id) || [];
        setServicesSelected(servicesSelected);

        let data = {
            'file_title': location.state.doc.file_title,
            'version': location.state.doc.file_version,
            'date': location.state.doc.date,
            'description': location.state.doc.description
        }
        
        let fileData = {
            'id': location.state.doc.id,
            'document_id': location.state.doc.document_id,
            'label': location.state.doc.label,
            'type': location.state.doc.fileType,
            'size': location.state.doc.fileSize,
            'url': location.state.doc.document_url || location.state.doc.fileUrl
        }

        setDefaultFile(data)

        setUploadedFile([fileData])
    }


    const OnNqaChange = (e, nqaValue) =>{
        e.preventDefault()
        let nqa = nqaArea;
        const index = nqa.indexOf(nqaValue);
    
        if (index > -1) {
          nqa.splice(index, 1);
        } else {
          nqa.splice(nqa.length, 0, nqaValue);
        }
    
        setNqaArea([...nqaArea]);
    }

    const onDocTypeChange = (e, docValue) =>{
        e.preventDefault()
        setDocType(docValue)
    }

    const OnServiceTypeChange = (e, sValue) =>{
        let sType = serviceType;

        if(sValue === 5){
            if(!sType.includes(sValue)){
                setServiceType([])
            }
            setServiceType([5])
        }else{
            if(sType.includes(5)) {
                
            }
            let curTypes = sType.filter((type) => {
                return type !== 5;
            });
            if (curTypes.includes(sValue)) {
                setServiceType([
                    ...curTypes.filter((t) => {
                    return t !== sValue;
                    }),
                ]);
            } else {
                setServiceType([...curTypes, sValue]);
            }
        }
    }

    const handeRemoveFile = (e, id) =>{
        


        setShowRemovePopUp(true)

    }

    const chooseService = () =>{

       if(serviceType.length === 0){

            sweetAlert("warning", "Please select service type");

       }else{

            setShowModal(true)

       }

    }

    const getBase64 = (file, data) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

        let fileData = {
            ...data,
            base64: reader.result
        };

            const g = data.label.split(".")
            const t = data.type.split("/")

            setDefaultFile({
                ...defaultFile,
                base64: reader.result,
                label: g[0],
                size: data.size,
                type: t[1],
                url: reader.result
            });

            setUploadedFile([fileData]);
        };

        reader.onerror = function (error) {
            console.log("Error: ", error);
        };

        
    }


    const handleFileUpload = (e) => {
        let fileData = {
            label: e.name,
            size: e.size,
            type: e.type
        }
        getBase64(e,fileData)
    }

    const SaveChanges = async() =>{
        const apiMethod = isTypeMedia
          ? "updateDocWebinarOrTm"
          : "documentUpdate";

        const id = location.state.doc.id ?? docId;

        const includeOrExcludeServices = serviceType.includes(5)
          ? {
              services: servicesSelected,
            }
          : {
              services: [],
              excluded_services: servicesSelected,
            };

        if(location.state.doc.upload_or_generate === "Upload") {
            if(uploadedFile.length > 0 && originalDocRemoved){
                let payload = {
                    id,
                    file_title: defaultFile.file_title,
                    file_version: defaultFile.version,
                    date: defaultFile.date,
                    description: defaultFile.description,
                    notify_service: notifyService,
                    service_type_id: serviceType,
                    nqa_area_id: nqaArea,
                    document_type_id: docType,
                    attachments: [{
                        base64_image: defaultFile.base64,
                        file_name: defaultFile.label
                    }],
                    ...includeOrExcludeServices
                }
    
                const payloadWebinar = {
                    id,
                    file_title: defaultFile.file_title,
                    date: defaultFile.date,
                    document_type_id: docType,
                    nqa_area_id: nqaArea,
                    base64_image: defaultFile.base64,
                    label: defaultFile.label,
                    service_type_id: serviceType,
                    notify_service: notifyService,
                    webinar_link: webinarInputs.webinar_link,
                    webinar_title: webinarInputs.webinar_title,
                    webinar_description: webinarInputs.webinar_description,
                    ...includeOrExcludeServices,
                };
    
                setSaveLoading(true);
                const { status } = await updateNqaDoc[apiMethod](isTypeMedia ? payloadWebinar: payload)
                setSaveLoading(false);
    
                if (status === 200) {
                  sweetAlert("success", "Document updated succesfully");
                } else {
                  sweetAlert("error", "Error on updating");
                }
            } else {
                
                let payload = {
                    id,
                    file_title: defaultFile.file_title,
                    file_version: defaultFile.version,
                    date: defaultFile.date,
                    description: defaultFile.description,
                    notify_service: notifyService,
                    service_type_id: serviceType,
                    nqa_area_id: nqaArea,
                    document_type_id: docType,
                    ...includeOrExcludeServices
                }
    
                const payloadWebinar = {
                    id,
                    file_title: defaultFile.file_title,
                    date: defaultFile.date,
                    document_type_id: docType,
                    nqa_area_id: nqaArea,
                    service_type_id: serviceType,
                    notify_service: notifyService,
                    webinar_link: webinarInputs.webinar_link,
                    webinar_title: webinarInputs.webinar_title,
                    webinar_description: webinarInputs.webinar_description,
                    ...includeOrExcludeServices
                };
        
                setSaveLoading(true);
                const { status } = await updateNqaDoc[apiMethod](
                  isTypeMedia ? payloadWebinar : payload
                );
    
                setSaveLoading(false);
    
                if (status === 200) {
                  sweetAlert("success", "Document updated succesfully");
                } else {
                  sweetAlert("error", "Error on updating");
                }
        
            
            }
        } else if (location.state.doc.upload_or_generate === "Generate") {
            let payload = {
                id,
                upload_or_generate: 2,
                file_title: generateInputs.file_title,
                file_version: generateInputs.file_version,
                date: generateInputs.date,
                content: typeof editorValue === "string" ? editorValue : location.state.doc.content,
                notify_service: notifyService,
                service_type_id: serviceType,
                nqa_area_id: nqaArea,
                document_type_id: docType,
                ...includeOrExcludeServices
            }

    
            setSaveLoading(true);
            const { status, data } = await updateNqaDoc.documentUpdate(payload);

            setSaveLoading(false);

            if (status === 200) {
                setGenerateFiles([{
                    name: data?.attachments?.document_name,
                    type: data?.attachments?.file_type,
                    size: data?.attachments?.file_size,
                    url: data?.attachments?.document_url,
                }]);
                sweetAlert("success", "Document updated succesfully");
            } else {
              sweetAlert("error", "Error on updating");
            }
        }
        
        

    }
    
    
    return (
        <UserLayout headerText={`Edit an NQA Document`}>
            
            <RemovePermanentPopUp 
                visibility={showRemoveForeverPopUp}
                onCloseModal={() => setShowRemoveForeverPopUp(false)}
                defaultFile={defaultFile}
                setDefaultFile={() => undefined}
                setUploadedFile={setUploadedFile}
            />

            <RemovePopUp 
                visibility={showRemovePopUp}
                onShowModal={() => {
                    setOriginalDocRemoved(true);
                    setShowRemoveForeverPopUp(true);

                    setDefaultFile({});
                    setUploadedFile([]);
                    setWebinarUpload({});
                }}
                onCloseModal={() => setShowRemovePopUp(false)}
            />

            { loading && (
                <div className={`py-16 bg-white flex h-screen items-center justify-center px-10`}>
                    <LargeSpinner />
                </div>
            )}

            <div className={`${styles.nqaDocumentContent} ${styles.nqaDocumentTabContent}`} style={loading ? {display:"none"} : {display:"block"}}>

                <div className={styles.nqaDocument}>
                    <h2>Edit an NQA document</h2>

                    <p>
                    Upload documents from <i>your computer</i> or <i>generate completely</i>{" "}
                    new documents and assign them to a collection.
                    </p>

                    <ChooseNQA 
                        nqaArea={nqaArea}
                        setNqaArea={(e, nqaValue) => OnNqaChange(e, nqaValue)}
                    />

                    <ChooseDocumentType
                        docTypeID={docType}
                        setDocType={(e, docValue) => onDocTypeChange(e, docValue)}
                        disableDocTypes={
                            isTypeMedia
                              ? [DOC_TYPE.POLICY, DOC_TYPE.PROCEDURE]
                              : [DOC_TYPE.TRAINING, DOC_TYPE.WEBINAR]
                          }
                    />

                    <ChooseServiceType 
                        docTypeID={docType}
                        serviceType={serviceType}
                        setServiceType={(e, sValue) => OnServiceTypeChange(e, sValue)}
                    />

                    <div className={`${styles.addServiceDocu} ${isTypeMedia ? styles.webinar : ""}`}>

                        {
                            isTypeMedia ? (
                            <div className={`${createDocStyles.addServiceDocu} mt-10`}>
                                {docType === DOC_TYPE.WEBINAR ?
                                    <div className={`${createDocStyles.inputContainer}`}>
                                        <NqaDocumentWebinarType
                                            docId={docType}
                                            UploadFiles={(data) => {
                                                const uploaded = { ...defaultFile, ...data, label: data.name };
                                                setDefaultFile(uploaded);
                                                setUploadedFile([uploaded]);
                                            }}
                                            UploadInputs={(data) => 
                                                setWebinarInputs({
                                                ...webinarInputs,
                                                webinar_link: data.webinar_link,
                                                webinar_title: data.webinar_title,
                                                date: data.date,
                                                webinar_description: data.webinar_description,
                                                file_title: data.webinar_file_title,
                                                })}
                                            SelectedService={setServicesSelected}
                                            serviceTypeID={serviceType}
                                            setNqaServices={setNqaServices}
                                            fillInputs={webinarInputs}
                                            fillUploadFile={webinarUpload}
                                            fillServices={servicesSelected}
                                            onRemoveDoc={()=> setOriginalDocRemoved(true)}
                                        />
                                    </div> : (
                                    <div className="px-10">
                                        <LinkOrDocument
                                            isExternalLink={isExternalLink}
                                            setIsExternalLink={setIsExternalLink}
                                            formInputs={webinarInputs}
                                            setFormInputs={setWebinarInputs}
                                            file={webinarUpload}
                                            onFileChange={(data) => {
                                                if (data === undefined) {
                                                    setShowRemovePopUp(true);
                                                } else {
                                                    const uploaded = { ...defaultFile, ...data, label: data.name };
                                                    setDefaultFile(uploaded);
                                                    setUploadedFile([uploaded]);
                                                    setWebinarUpload({
                                                        name: data.name,
                                                        type: data.type,
                                                        size: data.size,
                                                        base64: data.base64,
                                                    });
                                                }
                                            }}
                                        />
                                        {console.log({defaultFile, webinarUpload})}
                                    </div>
                                )}
                            </div>)
                            : (location?.state?.doc?.upload_or_generate === "Upload" ?
                                <UploadFiles 
                                    defaultFile={defaultFile}
                                    onChangeData={setDefaultFile}
                                    removeFile={handeRemoveFile}
                                    uploadFile={uploadedFile}
                                    handleFileUpload={(e) => handleFileUpload(e)}
                                    serviceType={serviceType}
                                    chooseService={chooseService}
                                    SelectedServicesSpecific={services}
                                    setSelectedServicesSpecific={setServices}
                                    showServicesModal={showServicesModal}
                                    setServicesShowModal={() => setServicesShowModal(true)}
                                    services={services}
                                    servicesSelected={servicesSelected}
                                    setServicesSelected={setServicesSelected}
                            /> : <div className={createDocStyles.addServiceDocu}
                                    style={{ margin: "0 -30px" }}>
                                    <div className={`${createDocStyles.inputContainer} ${createDocStyles.generateFileTabContent}`}
                                        style={{ display: "block" }}>
                                    <NqaDocumentGenerateType
                                        editData={{ generateFiles, generateInputs, docState: location.state.doc, services: servicesSelected }}
                                        GenerateFiles={setGenerateFiles}
                                        SelectedService={setServicesSelected}
                                        GenerateInputs={setGenerateInputs}
                                        EditorValue={setEditorValue}
                                        setNqaServices={setNqaServices}
                                        serviceTypeID={serviceType}
                                        globalWebinar={location.state.doc.global_webinar}
                                    />
                                    </div>
                                </div>)
                        }

                        {/* <div className={styles.inputNotify} style={docType  === 3 || docType === 4 ? {display:"none"} : {display:"block"}}>

                            <label className={styles.switch}>
                                <input
                                    onClick={() => handleNotifChange()} 
                                    checked={location.state.doc.notify_service === 1 ? true : false}
                                    type="checkbox"
                                />
                                <span className={`${styles.slider} ${styles.round}`}></span>
                            </label>

                            <h3>Notify service about this document</h3>

                            <p>Toggle this option only if you are adding a new document or editing an existing one</p>

                        </div> */}
                       
                    </div>
        
                </div>

            </div>

            <div className="flex flex-wrap max-w-6xl justify-center m-auto">
                {   uploadedFile.length > 0 || location.state.doc.upload_or_generate === "Generate" || isTypeMedia
                 ? <div className={`mx-3 lg:flex-1 ${styles.btnSave}`}>                    
                            <button 
                            className={styles.uploadFileTabContent}
                            onClick={() => SaveChanges()}
                            > 
                            {saveLoading ? (
                                <div className={`flex justify-center items-center`}>
                                <FaSpinner className={`animate-spin`} />{" "}
                                <span className={`ml-2`}> Please wait...</span>{" "}
                                </div>
                            ) : (
                                <> SAVE ALL CHANGES </>
                            )}
                            </button>
                        
                    </div>

                :   <div className={`mx-3 lg:flex-1 ${styles.btnInvalid}`}>
                    <button 
                        className={styles.uploadFileTabContent}
                        >CAN'T SAVE YET. UPLOAD A FILE FIRST</button>
                    </div>
                }
                <div className={`mx-3 lg:flex-1 ${styles.btnExit}`}>
                    <button onClick={history.goBack} className={styles.uploadFileTabContent}>EXIT WITHOUT SAVING</button>
                </div>
            </div>
                <ServiceModal
                    visibility={showModal}
                    hideModal={handleCallBack}
                    services={services}
                    setSelected={(selected) => {
                        setServicesSelected(selected);
                    }}
                    selectedService={servicesSelected}
                    serviceTypeID={serviceType}
                /> 

        </UserLayout>
    )
}
