import React, { useState, useEffect } from "react";
import styles from "../CreateDocument.module.css";
import DeleteFileModal from "../Modal/DeleteConfirmationModal";
import ServiceModal from "../../../components/popups/createNewDocument/chooseServicesModal";
import axios from "axios";
import fileDownload from "js-file-download";

import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { isEmpty } from "lodash";
import TextEditor from "../../../components/services/TextEditor";

import {
  PortalFetchServices,
  PortalFetchSelectedService,
} from "../../../api/requests";
import { FaDownload, FaFilePdf, FaTimesCircle } from "react-icons/fa";

const NqaDocumentGenerateType = ({
  fieldsChecked = false,
  GenerateFiles,
  SelectedService,
  GenerateInputs,
  EditorValue,
  setNqaServices,
  serviceTypeID,
  globalWebinar = 0,
  editData = {},
  hideServiceSelector = false,
}) => {
  const [services, setServices] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [generateFiles, setGenerateFiles] = useState([]);
  const [generateInputs, setGenerateInputs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [valueToDelete, setValueToDelete] = useState(0);

  const [servicesSelected, setServicesSelected] = useState([]);

  useEffect(() => {
    setGenerateFiles(editData.generateFiles || []);
    setGenerateInputs(editData.generateInputs || []);
    setServicesSelected(editData.services || []);
  }, [editData.generateFiles, editData.generateInputs, editData.services]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    EditorValue(editorState);
  };

  const handeleInputFileGenerate = (e) => {
    const { name, value } = e.target;
    setGenerateInputs({
      ...generateInputs,
      [name]: value,
    });
    GenerateInputs({
      ...generateInputs,
      [name]: value,
    });
  };

  const confirmDelete = (e, x) => {
    setShowDeleteModal(true);
    setValueToDelete(x);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const removeFiles = () => {
    let newItem = generateFiles.splice(valueToDelete, 1);
    setGenerateFiles([...generateFiles]);
    setShowDeleteModal(false);
  };

  const getFiles = (files) => {
    setGenerateFiles(files);
    GenerateFiles(files);
  };

  const deleteSelectedServices = (e, sId) => {
    SelectedService([
      ...servicesSelected.filter((service) => Number(service) !== Number(sId)),
    ]);
    setServicesSelected([
      ...servicesSelected.filter((service) => Number(service) !== Number(sId)),
    ]);
  };

  const OnClickChooseService = () => {
    setShowModal(true);
  };

  const handleCallBack = () => {
    setShowModal(false);
  };

  const onDownloadFile = (fileUrl, fileName) => {
    console.log(fileUrl);
    fileName = fileName.replace(/ /g, "_");
    console.log(fileName);
    axios
      .get(fileUrl, {
        responseType: `blob`,
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  };

  // GenerateFiles(generateFiles);
  // SelectedService(selectedService);
  // GenerateInputs(generateInputs);
  // EditorValue(editorState);

  useEffect(async () => {
    const response = await PortalFetchServices();
    setServices(response);
    setNqaServices(response);
  }, []);

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <label>File Title</label>
          <input
            type="text"
            name="file_title"
            placeholder="Type a memorable name for the file"
            value={generateInputs.file_title}
            onChange={(e) => handeleInputFileGenerate(e)}
          />
          {fieldsChecked && !generateInputs.file_title && (
            <text className={`text-red-400 py-1 px-2`}>
              This field is required
            </text>
          )}
        </div>
        <div className={styles.inputGroup}>
          <label>Version</label>
          <input
            type="text"
            name="file_version"
            placeholder="Enter file version"
            value={generateInputs.file_version}
            onChange={(e) => handeleInputFileGenerate(e)}
          />
          {fieldsChecked && !generateInputs.file_version && (
            <text className={`text-red-400 py-1 px-2`}>
              This field is required
            </text>
          )}
        </div>
        <div className={styles.inputGroup}>
          <label>Date</label>
          <input
            type="date"
            name="date"
            placeholder="Choose a date"
            value={generateInputs.date}
            onChange={(e) => handeleInputFileGenerate(e)}
          />
          {fieldsChecked && !generateInputs.date && (
            <text className={`text-red-400 py-1 px-2`}>
              This field is required
            </text>
          )}
        </div>
      </div>

      <div className={`${styles.inputFull}`}>
        <div className={styles.inputGroup}>
          <label>Content</label>
          <br />
          <TextEditor
            editorState={editorState}
            setEditorState={onEditorStateChange}
            specificDocState={editData.docState}
            setSpecificDocState={() => {}}
            setGeneratedFiles={() => {}}
            generatedFiles={[]}
          />
          {/* <div className={styles.contentEditor}>
          <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />
          </div> */}
          {fieldsChecked &&
            ((typeof editorState === "string" && !editorState.length) ||
              typeof editorState !== "string") && (
              <text className={`py-1 px-2 text-red-400`}>
                This field is required{" "}
              </text>
            )}
        </div>
      </div>

      {/* <div className={`${styles.inputFull}`}>
        <div className={styles.inputGroup}>
          <label>Description</label>
          <textarea
            className={styles.inputDesc}
            name="description"
            placeholder="Enter a description for the new document"
            onChange={(e) => handeleInputFileGenerate(e)}
          ></textarea>
        </div>
      </div> */}

      <div
        className={`${styles.uploadedFile} ${styles.fileOriginGenerate} border-t-0`}
      >
        {/* <div className={`${styles.addFiles}`}>
          <span>Upload files to this document</span>
          <label className={styles.blueButton}>
            <div style={{ display: "none" }}>
              <FileBase64 multiple={true} onDone={getFiles} />
            </div>
            ADD FILE/S
          </label>
        </div> */}
        <ul>
          {/* {console.log("generated files ", generateFiles)} */}
          {generateFiles.map((value, x) => (
            <li key={x}>
              <div className={styles.uploadedFileContent}>
                <div className={styles.fileIcon}>
                  <FaFilePdf size={24} />
                </div>
                <div className={styles.fileInfo}>
                  <span className={styles.fileName}>
                    {value.name ? value.name.split(".")[0] : null}
                  </span>
                  <span className={styles.fileSize}>
                    {value.type} / {value.size} Kb
                  </span>
                </div>
              </div>

              <div className={styles.uploadedFileActionButtons}>
                <a
                  className={`${styles.download} ${styles.blueButton}`}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onDownloadFile(value.url, `${value.name}`);
                  }}
                >
                  DOWNLOAD&nbsp;
                  <FaDownload size={19} />
                </a>
                <button
                  className={`${styles.removeFile} ${styles.blueButton}`}
                  onClick={(e) => confirmDelete(e, x)}
                >
                  REMOVE FILE&nbsp;
                  <FaTimesCircle size={19} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {!hideServiceSelector && (
        <div
          className={`${styles.chooseServices} ${styles.fileOriginGenerate} ${
            styles.standAloneNQAFiles
          } ${
            globalWebinar === 1
              ? "pointer-events-none opacity-50"
              : "pointer-events-auto opacity-100"
          }`}
        >
          {serviceTypeID.includes(5) ? (
            <p>Choose one or more services to attach this document to</p>
          ) : (
            <p>Choose any service that should NOT see this document</p>
          )}

          <ul
            className={
              !serviceTypeID.includes(5)
                ? styles.excludeSelectedServices
                : styles.selectedServices
            }
            style={{ display: "flex" }}
          >
            {!showModal &&
              services
                .filter((service) =>
                  servicesSelected.some(
                    (selected) =>
                      Number(selected) === Number(service.service_id)
                  )
                )
                .map((value, index) => (
                  <li
                    key={index}
                    onClick={(e) => deleteSelectedServices(e, value.service_id)}
                  >
                    {value.service_name}
                    <svg
                      height="19"
                      width="19"
                      font-size="19"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="times-circle"
                      className="svg-inline--fa fa-times-circle fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                      ></path>
                    </svg>
                  </li>
                ))}
          </ul>

          <div className={styles.inputActionButton}>
            <button
              className={`${styles.chooseServicesBtn} ${styles.blueButton}`}
              onClick={(e) => OnClickChooseService(e)}
            >
              CHOOSE SERVICES
            </button>
            {fieldsChecked &&
              serviceTypeID.includes(5) &&
              isEmpty(servicesSelected) && (
                <div className={`text-red-400 py-1`}>
                  Please select one or more services
                </div>
              )}
            {showModal && services && (
              <ServiceModal
                visibility={showModal}
                hideModal={handleCallBack}
                services={services}
                setSelected={(selected) => {
                  SelectedService(selected);
                  setServicesSelected(selected);
                }}
                selectedService={servicesSelected}
                serviceTypeID={serviceTypeID}
              />
            )}
          </div>
        </div>
      )}

      <DeleteFileModal
        deleteDoc={removeFiles}
        showModal={showDeleteModal}
        closeModal={closeModal}
      />
    </>
  );
};

export default NqaDocumentGenerateType;
