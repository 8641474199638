import React from "react";
import isEmpty from 'lodash/isEmpty';

function TextArea({
  onChange,
  className,
  errors = {},
  name = "",
  errorStyle = "",
  textLabel="",
  style={},
  placeholder = "",

  // row = "3",
  ...rest
}) {
  return (
    <div className={style}>
      <label>{textLabel}</label>
      <textarea
        placeholder={placeholder}
        className={`${className}`}
        // rows={row}
        onChange={onChange}
        {...rest}
      />
      {!isEmpty(errors) && errors[name] && (
        <span className={errorStyle}>
          {errors[name]}
        </span>
      )}
    </div>
  )
}

export default TextArea
