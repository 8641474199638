import React from "react";
import styles from "./AddServiceSuccessModal.module.css";
import { FaTimes } from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import Button from "../../../_base/button";
import { useHistory } from "react-router-dom";

const AddServiceSuccessModal = ({ visibility, onOpenModal, onCloseModal }) => {
  const history = useHistory();

  return (
    <Modal
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={`${styles.modalContainer}`}>
        <Button onClick={onCloseModal} className={`${styles.closeModal}`}>
          <FaTimes className={`ml-2`} size={16} />
        </Button>

        <div className={`${styles.modalTitle}`}>
          <h1>New document is now added to HMC Portal</h1>
        </div>

        <div className={`${styles.modalContent}`}>
          <div className={`${styles.actionBtn}`}>
            <Button onClick={onCloseModal} className={`${styles.btnGreen}`}>
              KEEP EDITING
            </Button>
            <Button
              // onClick={() => history.push("/update-service")}
              onClick={() => {
                window.scrollTo(0, 0);
                onCloseModal();
              }}
              className={`${styles.btnOk}`}
            >
              BACK TO DOCUMENTS
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddServiceSuccessModal;
