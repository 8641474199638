import React from "react";
import styles from "./DeleteMultipleDocumentSuccessModal.module.css";
import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

const DeleteMultipleDocumentSuccessModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	multiplePermanentDelete,
	multipleUndoDelete,
	count
}) => {
	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`All ${count > 1 ? "documents" : "documents are now deleted."}`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => multiplePermanentDelete(e)}
							className={`${styles.btnOk}`}>OK</Button>
						<Button
							onClick={(e) => multipleUndoDelete(e)}
							className={`${styles.btnUndo}`}>UNDO</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default DeleteMultipleDocumentSuccessModal;