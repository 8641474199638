import React from "react";
import Header from "../header/Header.jsx";
import Footer from "../footer/Footer";
import styles from "./UserLayout.module.css";

const UserLayout = ({ children, headerText }) => {
  return (
    <div className={`flex flex-col  h-screen`}>
      <Header />

      <div id={`main-layout`} className={`${styles.mainContent} w-full flex-1`}>
        <div className={styles.topHeaderContainer}>
          <h1 className={styles.contentHeader}>{headerText}</h1>
        </div>

        {children}
      </div>
      <Footer />
    </div>
  );
};

export default UserLayout;
