import React, { useState } from "react";
import styles from "./AddDocumentToACollectionModalSuccess.module.css";
import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

const AddDocumentToACollectionModalSuccess = ({
	visibility,
	onOpenModal,
	onCloseModal,
	count,
	fetchAllDocs 
}) => {

	const onClickOk = (e) => {
		e.preventDefault();
		fetchAllDocs();
		onCloseModal();
		 
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`${count} documents are now assigned.`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => onClickOk(e)}
							className={`${styles.btnOk}`}>OK</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default AddDocumentToACollectionModalSuccess;