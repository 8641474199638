import React, { useState, useEffect } from "react";
import {  FaDownload, FaTimesCircle } from "react-icons/fa";
import styles from "../CreateDocument.module.css";
import FileBase64 from "react-file-base64";

import DeleteFileModal from "../Modal/DeleteConfirmationModal";

import { PortalFetchSelectedService } from "../../../api/requests";

import { isEmpty } from "lodash";

const SpecificDocumentUploadType = ({
  fieldsChecked = false,
  setUploadFiles,
  uploadFiles,
  setUploadInputs,
  uploadInputs,
  fillInputs = false,
  onRemoveDoc
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const getBase64 = (file, data) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let fileData = {
        ...data,
        base64: reader.result,
      };
      setUploadFiles(fileData);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const ServiceSpecificgetFiles = (files) => {
    const fileData = {
      name: files.name,
      size: files.size,
      type: files.type,
    };

    getBase64(files, fileData);
  };

  const ServiceSpecificRemoveDoc = () => {
    onRemoveDoc && onRemoveDoc();
    setUploadFiles([]);
    setShowDeleteModal(false);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = (e) => {
    setShowDeleteModal(true);
  };

  const SpecificServiceUploadTabInputChange = (e) => {
    const { name, value } = e.target;
    setUploadInputs({
      ...uploadInputs,
      [name]: value,
    });
  };

  return (
    <>
      <div
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          ServiceSpecificgetFiles(e.dataTransfer.files[0]);
        }}
        className={styles.uploadFile}
        style={uploadFiles.name ? { display: "none" } : { display: "block" }}
      >
        <span className={styles.uploadFileText}>Drop files here or browse</span>
        <label className={styles.blueButton}>
          {/* <FileBase64 onDone={ServiceSpecificgetFiles} />*/}
          <input
            type={`file`}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              ServiceSpecificgetFiles(e.target.files[0]);
            }}
            hidden
          />
          BROWSE COMPUTER
        </label>
      </div>
      {fieldsChecked && isEmpty(uploadFiles) && (
        <div className={`-mt-8 ml-10 text-red-400`}>Please select file.</div>
      )}

      <div
        className={`${styles.uploadedFile} ${styles.fileOriginUpload}`}
        style={uploadFiles.name ? { display: "block" } : { display: "none" }}
      >
        <span>Uploaded file</span>
        <ul>
          <li>
            <div className={styles.uploadedFileContent}>
              <div className={styles.fileIcon}>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  fontSize="24"
                  height="1em"
                  width="1em"
                  data-prefix="far"
                  data-icon="file-word"
                  className="svg-inline--fa fa-file-word fa-w-12"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm220.1-208c-5.7 0-10.6 4-11.7 9.5-20.6 97.7-20.4 95.4-21 103.5-.2-1.2-.4-2.6-.7-4.3-.8-5.1.3.2-23.6-99.5-1.3-5.4-6.1-9.2-11.7-9.2h-13.3c-5.5 0-10.3 3.8-11.7 9.1-24.4 99-24 96.2-24.8 103.7-.1-1.1-.2-2.5-.5-4.2-.7-5.2-14.1-73.3-19.1-99-1.1-5.6-6-9.7-11.8-9.7h-16.8c-7.8 0-13.5 7.3-11.7 14.8 8 32.6 26.7 109.5 33.2 136 1.3 5.4 6.1 9.1 11.7 9.1h25.2c5.5 0 10.3-3.7 11.6-9.1l17.9-71.4c1.5-6.2 2.5-12 3-17.3l2.9 17.3c.1.4 12.6 50.5 17.9 71.4 1.3 5.3 6.1 9.1 11.6 9.1h24.7c5.5 0 10.3-3.7 11.6-9.1 20.8-81.9 30.2-119 34.5-136 1.9-7.6-3.8-14.9-11.6-14.9h-15.8z"
                  ></path>
                </svg>
              </div>
              <div className={styles.fileInfo}>
                <span className={styles.fileName}>
                  {uploadFiles.name ? uploadFiles.name.split(".")[0] : null}
                </span>
                <span className={styles.fileSize}>
                  {uploadFiles.type
                    ? uploadFiles.type.split("/")[1]?.toUpperCase()
                    : null}{" "}
                  / {uploadFiles.size}
                </span>
              </div>
            </div>
            <div className={styles.uploadedFileActionButtons}>
              <a
                className={`${styles.download} ${styles.blueButton}`}
                href={uploadFiles.base64}
                download={uploadFiles.name}
              >
                DOWNLOAD&nbsp;
                <FaDownload size={19} />
              </a>
              <button
                className={`${styles.removeFile} ${styles.blueButton}`}
                onClick={(e) => confirmDelete(e)}
              >
                REMOVE FILE&nbsp;
                <FaTimesCircle size={19} />
              </button>
            </div>
          </li>
        </ul>
      </div>

      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <label>File Title</label>
          <input
            type="text"
            name="file_title"
            placeholder="Type a memorable name for the file"
            onChange={(e) => SpecificServiceUploadTabInputChange(e)}
            {...(fillInputs ? { value: uploadInputs.file_title } : {})}
          />
          {fieldsChecked && !uploadInputs.file_title && (
            <text className={`text-red-400 text-xs py-1 px-2`}>
              This field is required.
            </text>
          )}
        </div>
        <div className={styles.inputGroup}>
          <label>Version</label>
          <input
            type="text"
            name="file_version"
            placeholder="Enter file version"
            onChange={(e) => SpecificServiceUploadTabInputChange(e)}
            {...(fillInputs ? { value: uploadInputs.file_version } : {})}
          />
          {fieldsChecked && !uploadInputs.file_version && (
            <text className={`text-red-400 text-xs py-1 px-2`}>
              This field is required.
            </text>
          )}
        </div>
        <div className={styles.inputGroup}>
          <label>Date</label>
          <input
            type="date"
            name="date"
            placeholder="Choose a date"
            onChange={(e) => SpecificServiceUploadTabInputChange(e)}
            {...(fillInputs ? { value: uploadInputs.date } : {})}
          />
          {fieldsChecked && !uploadInputs.date && (
            <text className={`text-red-400 text-xs py-1 px-2`}>
              This field is required.
            </text>
          )}
        </div>
      </div>

      <div className={`${styles.inputFull}`}>
        <div className={styles.inputGroup}>
          <label>Description</label>
          <textarea
            className={styles.inputDesc}
            name="description"
            placeholder="Enter a description for the new document"
            onChange={(e) => SpecificServiceUploadTabInputChange(e)}
            {...(fillInputs ? { value: uploadInputs.description } : {})}
          ></textarea>
        </div>
      </div>

      <DeleteFileModal
        deleteDoc={ServiceSpecificRemoveDoc}
        showModal={showDeleteModal}
        closeModal={closeModal}
      />
    </>
  );
};

export default SpecificDocumentUploadType;
