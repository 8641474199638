import React, { useEffect, useState } from "react";
import styles from "./DeleteDocumentConfirmationModal.module.css";

import { FaSpinner, FaTimes } from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from "../../../_base/button";
import { deleteSingleDocument } from "../../../../api/documents/requests";
import { sweetAlert } from "../../../_base/alerts/sweetAlert";

const DeleteDocumentConfirmationModal = ({
  visibility,
  onOpenModal,
  onCloseModal,
  selectedDoc,
  setUploaded_Documents,
  uploaded_documents,

  // doToggleFetchAllDocs,
}) => {
  const [deleting, setDeleting] = useState(false);

  const onDeleteForever = async (e) => {
    e.preventDefault();

    try {
      setDeleting(true);
      const response = await deleteSingleDocument(Number(selectedDoc.id));

      if (response) {
        // doToggleFetchAllDocs();
        sweetAlert("success", "Document successfully deleted!");
        let dataArr = uploaded_documents;

        dataArr = dataArr.filter((d) => d.id != selectedDoc.id);

        setUploaded_Documents(dataArr);

        onCloseModal();
      }
    } catch (error) {
      console.log(error);
    }
    // finally {
    //   setDeleting(false);
    // }
  };

  return (
    <Modal
      centered={true}
      open={visibility}
      onOpen={onOpenModal}
      onClose={onCloseModal}
      closeOnDimmerClick={false}
    >
      <div className={`${styles.modalContainer}`}>
        {deleting ? (
          <div className={`flex flex-col justify-center items-center py-5`}>
            <FaSpinner className={`text-7xl text-evidenceType animate-spin `} />
            <span className={`text-3xl mt-4`}>Please wait...</span>
          </div>
        ) : (
          <>
            <Button onClick={onCloseModal} className={`${styles.closeModal}`}>
              <FaTimes size={16} />
            </Button>

            <div className={`${styles.modalTitle}`}>
              <h1>
                Are you sure you want to permanently delete this document?
              </h1>
            </div>

            <div className={`${styles.modalContent}`}>
              <div className={`${styles.actionBtn}`}>
                <Button
                  onClick={(e) => onDeleteForever(e)}
                  className={`${styles.btnGreen} mr-2.5`}
                >
                  DELETE
                </Button>
                <Button onClick={onCloseModal} className={`${styles.btnRed}`}>
                  CANCEL
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteDocumentConfirmationModal;
