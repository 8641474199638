import React, { useState, useEffect, useCallback } from "react";
import {
  FaFileAlt,
  FaInfoCircle,
  FaPlayCircle,
  FaLayerGroup,
  FaComment,
} from "react-icons/fa";
import styles from "../../pages/AddNewDocument/CreateDocument.module.css";
import isEmpty from "lodash/isEmpty";

const SpecificServiceDocument = ({
  header,
  // setDocumentTypeId,
  // documentTypeId,
  // setSelectedNQA,
  // selectedNQA,
  // setServiceSpecificType,
  // serviceSpecificTypeId,
  // subHeader
  setSpecificDocState,
  specificDocState,
  hideSubheader,

  errors,
  setErrors,
}) => {
  const [disableDocTypes, setDisableDocTypes] = useState([]);

  useEffect(() => {}, [errors]);

  useEffect(() => {
    if (!specificDocState?.id) return;

    if (
      specificDocState.document_type_id &&
      (specificDocState.document_type_id <= 2 ||
        specificDocState.document_type_id >= 6)
    ) {
      setDisableDocTypes([3, 4]);
    } else if (
      specificDocState.document_type_id &&
      specificDocState.document_type_id >= 3
    ) {
      setDisableDocTypes([1, 2]);
    }
  }, [specificDocState]);

  const specificServiceonClickNqaChange = (nqaValue, e) => {
    e.preventDefault();

    let nqa = [...specificDocState.nqa_area_id];

    const index = nqa.indexOf(nqaValue);

    if (index > -1) {
      nqa.splice(index, 1);
    } else {
      nqa = [...nqa, nqaValue];
    }

    // setSelectedNQA([...nqa]);
    setSpecificDocState({ ...specificDocState, nqa_area_id: [...nqa] });
    setErrors?.({});
  };

  const serviceSpecificDocTypeChange = (id, e) => {
    // setDocumentTypeId(id);
    if (disableDocTypes?.includes(id)) return;
    setSpecificDocState({ ...specificDocState, document_type_id: id });
    setErrors?.({});
  };

  const onDocumentFilterChange = (id, e) => {
    setSpecificDocState({
      ...specificDocState,
      nqa_area_id: [],
      service_specific_type: id,
    });
    setErrors?.({});
    // console.log(id)
    // setServiceSpecificType(id);
    // setServiceSpecificType(id);
  };

  return (
    <>
      <div className={`${styles.nqaDocument}`}>
        <h2>{header}</h2>

        {!hideSubheader && (
          <p>
            You can use this form to upload new documents that will be
            automatically assigned only to this service center.
          </p>
        )}

        <ul className={styles.specificDocumentFilter}>
          <li
            value="1"
            onClick={(e) => onDocumentFilterChange(1, e)}
            className={
              specificDocState.service_specific_type == 1
                ? `${styles.active}`
                : null
            }
          >
            Report
          </li>
          <li
            value="2"
            onClick={(e) => onDocumentFilterChange(2, e)}
            className={
              specificDocState.service_specific_type == 2
                ? `${styles.active}`
                : null
            }
          >
            AP Report
          </li>
          <li
            value="3"
            onClick={(e) => onDocumentFilterChange(3, e)}
            className={
              specificDocState.service_specific_type == 3
                ? `${styles.active}`
                : null
            }
          >
            Mentor Report
          </li>
          <li
            value="6"
            onClick={(e) => onDocumentFilterChange(6, e)}
            className={
              specificDocState.service_specific_type == 6
                ? `${styles.active}`
                : null
            }
          >
            FOM Report
          </li>
          <li
            value="4"
            onClick={(e) => onDocumentFilterChange(4, e)}
            className={
              specificDocState.service_specific_type == 4
                ? `${styles.active}`
                : null
            }
          >
            Risk Assessment
          </li>
          <li
            value="5"
            onClick={(e) => onDocumentFilterChange(5, e)}
            className={
              specificDocState.service_specific_type == 5
                ? `${styles.active}`
                : null
            }
          >
            NQA Document
          </li>
        </ul>

        {!isEmpty(errors) && errors["service_specific_type"] && (
          <div className={`mt-3 text-red-400`}>
            {errors["service_specific_type"]}
          </div>
        )}

        <div
          className={`${styles.chooseNQADocument2} mt-3`}
          style={
            specificDocState.service_specific_type == 4
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <ul>
            <li
              value="2"
              onClick={(e) => specificServiceonClickNqaChange(2, e)}
              className={
                specificDocState.nqa_area_id.indexOf(2) > -1
                  ? styles.active
                  : null
              }
            >
              NQA2
            </li>
            <li
              value="3"
              onClick={(e) => specificServiceonClickNqaChange(3, e)}
              className={
                specificDocState.nqa_area_id.indexOf(3) > -1
                  ? styles.active
                  : null
              }
            >
              NQA3
            </li>
          </ul>
        </div>
        {specificDocState.service_specific_type == 4 &&
          !isEmpty(errors) &&
          errors["nqa_area_id"] && (
            <span
              style={{ paddingLeft: "30px" }}
              className={`text-red-400 text-base py-1 px-2`}
            >
              {errors["nqa_area_id"]}
            </span>
          )}

        <div
          className={`${styles.chooseNQADocument} mt-3`}
          style={
            specificDocState.service_specific_type == 5
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <ul>
            <li
              value="1"
              onClick={(e) => specificServiceonClickNqaChange(1, e)}
              className={
                specificDocState.nqa_area_id.indexOf(1) > -1
                  ? styles.active
                  : null
              }
            >
              NQA1
            </li>
            <li
              value="2"
              onClick={(e) => specificServiceonClickNqaChange(2, e)}
              className={
                specificDocState.nqa_area_id.indexOf(2) > -1
                  ? styles.active
                  : null
              }
            >
              NQA2
            </li>
            <li
              value="3"
              onClick={(e) => specificServiceonClickNqaChange(3, e)}
              className={
                specificDocState.nqa_area_id.indexOf(3) > -1
                  ? styles.active
                  : null
              }
            >
              NQA3
            </li>
            <li
              value="4"
              onClick={(e) => specificServiceonClickNqaChange(4, e)}
              className={
                specificDocState.nqa_area_id.indexOf(4) > -1
                  ? styles.active
                  : null
              }
            >
              NQA4
            </li>
            <li
              value="5"
              onClick={(e) => specificServiceonClickNqaChange(5, e)}
              className={
                specificDocState.nqa_area_id.indexOf(5) > -1
                  ? styles.active
                  : null
              }
            >
              NQA5
            </li>
            <li
              value="6"
              onClick={(e) => specificServiceonClickNqaChange(6, e)}
              className={
                specificDocState.nqa_area_id.indexOf(6) > -1
                  ? styles.active
                  : null
              }
            >
              NQA6
            </li>
            <li
              value="7"
              onClick={(e) => specificServiceonClickNqaChange(7, e)}
              className={
                specificDocState.nqa_area_id.indexOf(7) > -1
                  ? styles.active
                  : null
              }
            >
              NQA7
            </li>
          </ul>
        </div>
        {specificDocState.service_specific_type == 5 &&
          !isEmpty(errors) &&
          errors["nqa_area_id"] && (
            <span
              style={{ paddingLeft: "30px" }}
              className={`text-red-400 text-base py-1 px-2`}
            >
              {errors["nqa_area_id"]}
            </span>
          )}

        <div
          className={`${styles.chooseDocumentType}`}
          style={
            specificDocState.service_specific_type == 5
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <span className={styles.label}>Choose document type</span>
          <ul>
            <li
              value="1"
              onClick={(e) => serviceSpecificDocTypeChange(1, e)}
              style={
                disableDocTypes?.includes(1) ? { pointerEvents: "none" } : {}
              }
              className={
                specificDocState.document_type_id == 1 ? styles.active : null
              }
            >
              <FaInfoCircle size={18} />
              Policy
            </li>
            <li
              value="2"
              onClick={(e) => serviceSpecificDocTypeChange(2, e)}
              style={
                disableDocTypes?.includes(2) ? { pointerEvents: "none" } : {}
              }
              className={
                specificDocState.document_type_id == 2 ? styles.active : null
              }
            >
              <FaFileAlt size={18} />
              Procedure
            </li>
            <li
              value="3"
              onClick={(e) => serviceSpecificDocTypeChange(3, e)}
              style={
                disableDocTypes?.includes(3) ? { pointerEvents: "none" } : {}
              }
              className={
                specificDocState.document_type_id == 3 ? styles.active : null
              }
            >
              <FaPlayCircle size={18} />
              Webinar
            </li>
            <li
              value="4"
              onClick={(e) => serviceSpecificDocTypeChange(4, e)}
              style={
                disableDocTypes?.includes(4) ? { pointerEvents: "none" } : {}
              }
              className={
                specificDocState.document_type_id == 4 ? styles.active : null
              }
            >
              <FaLayerGroup size={18} />
              Training modules
            </li>
            <li
              value="5"
              onClick={(e) => serviceSpecificDocTypeChange(6, e)}
              style={
                disableDocTypes?.includes(6) ? { pointerEvents: "none" } : {}
              }
              className={
                specificDocState.document_type_id == 6 ? styles.active : null
              }
            >
              <FaComment size={18} />
              Feedback
            </li>
            <li
              value="6"
              onClick={(e) => serviceSpecificDocTypeChange(7, e)}
              style={
                disableDocTypes?.includes(7) ? { pointerEvents: "none" } : {}
              }
              className={
                specificDocState.document_type_id == 7 ? styles.active : null
              }
            >
              Miscellaneous
            </li>
          </ul>
        </div>
        {!isEmpty(errors) && errors["document_type"] && (
          <span
            style={{ paddingLeft: "30px" }}
            className={`text-red-400 text-base py-1 px-2`}
          >
            {errors["document_type"]}
          </span>
        )}
      </div>
    </>
  );
};

export default SpecificServiceDocument;
