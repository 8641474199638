import isEmpty from "lodash/isEmpty";
import Required from "components/Required";
import FileIcon from "components/services/FileIcon";
import {
  FaDownload,
  FaExternalLinkAlt,
  FaTimesCircle,
  FaUpload,
} from "react-icons/fa";
import styles from "./LinkOrDocument.module.css";
import { isUrlValid } from "helpers/string";

const defaultInputs = {
  webinar_link: "",
  webinar_title: "",
  webinar_description: "",
  date: "",
};

type Inputs = typeof defaultInputs;

type File = {
  name: string;
  type: string;
  size: number;
  base64: string;
};

type LinkOrDocumentProps = {
  isExternalLink: boolean;
  setIsExternalLink: React.Dispatch<React.SetStateAction<boolean>>;
  formInputs: Inputs;
  setFormInputs: React.Dispatch<React.SetStateAction<Inputs>>;
  file: File;
  onFileChange: (file?: File) => void;
  showValidation?: boolean;
};

export default function LinkOrDocument(props: LinkOrDocumentProps) {
  const { formInputs = defaultInputs, setFormInputs } = props;
  const noFile = isEmpty(props.file);

  const onFormChange = (e: React.BaseSyntheticEvent) => {
    const { name, value } = e.target;
    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || !event.target.files[0]) return;

    try {
      const files = event.target.files;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        props.onFileChange?.({
          name: files[0].name,
          type: files[0].type.split("/")[1],
          size: files[0].size,
          base64: e.target?.result as string,
        });
      };
    } catch (error) {
      console.log(error);
    }
  };

  const onDownload = () => {
    if (noFile) return;

    let a = document.createElement("a");
    a.download = props.file.name;
    a.href = props.file.base64 as string;
    a.click();
  };

  const renderSelectedFile = () => {
    return (
      <div className="flex justify-between border border-gray-200 p-10">
        <div className="flex gap-x-5">
          <div className={styles.fileIcon}>
            <FileIcon selectedFileType={props.file.type} />
          </div>
          <div className={`${styles.fileHint} flex flex-col items-start justify-center`}>
            <p>{props.file.name}</p>
            <p>
              {props.file.type} / {props.file.size} Kb
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="flex gap-x-3 my-auto">
            <button className={styles.blueButton} onClick={onDownload}>
              DOWNLOAD
              <FaDownload size={19} className="ml-2" />
            </button>
            <button
              className={`${styles.removeFile} ${styles.blueButton}`}
              onClick={() => props.onFileChange(undefined)}
            >
              REMOVE FILE
              <FaTimesCircle size={19} className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.moduleTypeBtns}>
        <button
          className={props.isExternalLink ? styles.active : ""}
          onClick={() => props.setIsExternalLink(true)}
        >
          <FaExternalLinkAlt /> External link
        </button>
        <button
          className={!props.isExternalLink ? styles.active : ""}
          onClick={() => props.setIsExternalLink(false)}
        >
          <FaUpload /> Upload document
        </button>
      </div>
      <div className="flex flex-col gap-y-5 mt-10">
        {props.isExternalLink ? (
          <div className="grid grid-cols-1">
            <div className={styles.inputGroup}>
              <label>Enter a link for the training module</label>
              <input
                type="text"
                name="webinar_link"
                placeholder="Paste here"
                value={formInputs.webinar_link}
                onChange={onFormChange}
              />
              {props.showValidation && !formInputs.webinar_link && <Required />}
              {props.showValidation &&
                formInputs.webinar_link &&
                !isUrlValid(formInputs.webinar_link) && (
                  <Required customText="Please enter a valid link." />
                )}
            </div>
          </div>
        ) : (
          <>
            {noFile ? (
              <div className={styles.filePicker}>
                <p>Upload document</p>
                <label className={styles.blueButton}>
                  <input onChange={handleFileChange} type="file" hidden />
                  BROWSE COMPUTER
                </label>
              </div>
            ) : (
              renderSelectedFile()
            )}
            {props.showValidation && noFile && (
              <div className="text-center">
                <Required customText="Document is required." />
              </div>
            )}
          </>
        )}
        <div className="grid grid-cols-2 gap-x-5">
          <div className={styles.inputGroup}>
            <label>Training module title</label>
            <input
              type="text"
              name="webinar_title"
              placeholder="Type a memorable name for the file"
              value={formInputs.webinar_title}
              onChange={onFormChange}
            />
            {props.showValidation && !formInputs.webinar_title && <Required />}
          </div>
          <div className={styles.inputGroup}>
            <label>Date</label>
            <input
              type="date"
              name="date"
              placeholder="Choose a date"
              value={formInputs.date}
              onChange={onFormChange}
            />
            {props.showValidation && !formInputs.date && <Required />}
          </div>
        </div>
        <div>
          <div className={styles.inputGroup}>
            <label>Training module description</label>
            <textarea
              rows={5}
              className={styles.textArea}
              name="webinar_description"
              placeholder="Enter a description for the new document"
              value={formInputs.webinar_description}
              onChange={onFormChange}
            />
          </div>
        </div>
        {props.isExternalLink && (
          <div className="mt-10">
            <div className={`${styles.supportText} text-center`}>
              <h3>Supported document for this training module</h3>
              <p>
                In order to upload a new file for this webinar you need to first
                delete the old file.
              </p>
            </div>
            {noFile ? (
              <div
                className={`${styles.addFile} border-t border-b border-gray-200 px-10 py-8 mt-8 flex justify-between -mx-10`}
              >
                <h3>Upload a supporting file for this link</h3>
                <label className={styles.blueButton}>
                  <input onChange={handleFileChange} type="file" hidden />
                  ADD FILE
                </label>
              </div>
            ) : (
              <div className="mt-5">{renderSelectedFile()}</div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
