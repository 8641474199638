import React, { useState, useCallback, useRef, useEffect } from "react";
import styles from "./FilterByCollectionsModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import LongDayCare from '../../../_base/svg/LongDayCare';
import FamilyDayCare from '../../../_base/svg/FamilyDayCare';
import OOSH from '../../../_base/svg/OOSH';
import PreSchool from '../../../_base/svg/PreSchool';

import {
	getCollectionCount
} from '../../../../api/documents/requests';

import LargeSpinner from '../../../spinner/LargeSpinner';

import { sweetAlert } from "../../../_base/alerts/sweetAlert";

const FilterByCollectionsModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	filterDocuments,
	selectedCollection,
	setSelectedCollection
}) => {

	const [loading, setLoading] = useState(true);

	const [selectedItem, setSelectedItem] = useState([]);

	const [count, setDocCount] = useState({ long_day_care: 0, pre_school: 0, family_day_care: 0, oosh: 0 });

	const mountedRef = useRef(true);

	const addToSelectedCollection = (id, e) => {

		e.preventDefault();

		let newArr = [...selectedCollection.collectionItems];

		const index = newArr.indexOf(id);

		if (index > -1) {

			newArr.splice(index, 1)

		} else {

			newArr = [...newArr, id];
		}

		setSelectedItem([...newArr]);
		setSelectedCollection({ ...selectedCollection, collectionItems: newArr });
	}

	const fetchCollectionCount = useCallback(async () => {

		setLoading(true);

		try {

			let count = await getCollectionCount();
	 
			if (!mountedRef.current) return null;

			setDocCount({
				...count,
				long_day_care: count.long_day_care,
				family_day_care: count.family_day_care,
				pre_school: count.pre_school,
				oosh: count.oosh
			});

			setLoading(false);

		} catch (error) {

			setLoading(false);

		}
	}, [mountedRef]);

	useEffect(() => {

		fetchCollectionCount();
		
		return () => {
			mountedRef.current = false;
		};

	}, [fetchCollectionCount]);

	const onFilterDocuments = (e) =>
	{
		filterDocuments(selectedItem)
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Filter documents by collection</h1>
					<p>Choose which collection of documents you want to filter.</p>
				</div>

				<div className={`${styles.modalContent}`}>

					{loading && <div>
						<LargeSpinner />
					</div>}

					{!loading && <ul>
						<li className={selectedCollection.collectionItems.indexOf(1) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(1, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<LongDayCare />
								<span>Long daycare collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.long_day_care} docs`}</span>
							</div>
						</li>
						<li className={selectedCollection.collectionItems.indexOf(2) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(2, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<FamilyDayCare />
								<span>Family Daycare collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.family_day_care} docs`}</span>
							</div>
						</li>
						<li className={selectedCollection.collectionItems.indexOf(3) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(3, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<PreSchool />
								<span>Pre-school collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.pre_school} docs`}</span>
							</div>
						</li>
						<li className={selectedCollection.collectionItems.indexOf(4) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(4, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<OOSH />
								<span>OOSH collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.oosh} docs`}</span>
							</div>
						</li>
					</ul>}

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => {
								onFilterDocuments(e)
								onCloseModal()
							}}
							className={`${styles.btnGreen}`}>FILTER BY COLLECTION
						</Button>
					</div>

				</div>

			</div>


		</Modal>
	)
}

export default FilterByCollectionsModal;