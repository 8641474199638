import React from 'react';

const ToogleSwitch = ({ className, labelClass, onChange, text, rest }) => {
    return <label class={`${labelClass}`}>
        <input type="checkbox"
            onChange={onChange}
            {...rest}
        />
        <span className={`${className}`}>
            {text}
        </span>
    </label>
}

export default ToogleSwitch;