import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from "../../pages/AddNewService/AddNewService.module.css";
import { TextInput } from "../_base";
import FileIcon from "./FileIcon";
import Button from "../_base/button";
import {
  addServiceSpecificDocument,
  updateServiceSpecificDocument,
  documentDeleteFile,
} from "../../api/documents/requests";
import { sweetAlert } from "../_base/alerts/sweetAlert";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import Spinner from "../spinner/Spinner";

import axios from "axios";

import fileDownload from "js-file-download";

import { requiredValidation } from "../../helpers/service/serviceHelper";

import { serviceDocumentPayload } from "../../helpers/document/payloadHelper";

import isEmpty from "lodash/isEmpty";

import { FaTimesCircle, FaDownload } from "react-icons/fa";

import TextEditor from "./TextEditor";
import { emptySpecificDocState } from "pages/AddNewService/AddNewService";

const GenerateServiceSpecificDocument = ({
  serviceStateId,
  refreshList,
  setSpecificDocState,
  specificDocState,
  setShowAddServiceSuccess,
  isEdit,
  setErrors,
  errors,
}) => {
  const [originalDocState] = useState(specificDocState);
  const location = useLocation();

  const inputRef = useRef();

  const [notify_service, setNotifyService] = useState(false);

  const [editorState, setEditorState] = useState("<p></p>");

  const [generatedFiles, setGeneratedFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (specificDocState.id && specificDocState.attachments.length > 0) {
      mapImages(specificDocState.attachments);
    }

    if (specificDocState.content) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(specificDocState.content)
          )
        )
      );
    }
  }, [specificDocState]);

  const mapImages = (imgs) => {
    let imagesArray = [];

    imgs.forEach((img) => {
      let lastStr = img.file_name.lastIndexOf(".");

      let fileType = img.file_name.substring(lastStr + 1);

      let fileObject = {
        fileName: img.file_label,
        fileSize: img.file_size,
        fileType: fileType,
        fileUrl: img.file_url,
        file_id: img.file_id,
      };

      imagesArray.push(fileObject);
    });

    setGeneratedFiles(imagesArray);
  };

  const renderDocumentTextInput = (
    fieldName,
    value,
    label,
    placeholder,
    type = "text"
  ) => {
    let required =
      label === "File Title" || label === "Version" || label === "Date"
        ? true
        : false;

    return (
      <TextInput
        onChange={(e) => handleDocumentInputChange(fieldName, "text", e)}
        value={value}
        name={fieldName}
        textLabel={`${label}`}
        placeholder={`${placeholder}`}
        errors={errors}
        type={type}
        required={required}
      />
    );
  };

  const generateAddFiles = (event) => {
    if (!event.target.files || !event.target.files[0]) return;

    let fileObject = {
      fileName: "",
      fileSize: 0,
      fileType: "",
      file: "",
    };

    const files = event.target.files;

    const reader = new FileReader();

    reader.readAsDataURL(files[0]);

    let lastStr = files[0].name.lastIndexOf(".");

    let fileType = files[0].name.substring(lastStr + 1);

    fileObject.fileName = files[0].name;
    fileObject.fileSize = files[0].size;
    fileObject.fileType = fileType;

    let attachment = {
      base64_image: "",
      file_name: files[0].name,
    };

    reader.onload = (e) => {
      fileObject.file = e.target.result;
      attachment.base64_image = e.target.result;
    };

    let existingData = specificDocState.attachments || [];

    existingData.push(attachment);

    setGeneratedFiles([...generatedFiles, fileObject]);

    setSpecificDocState({ ...specificDocState, attachments: existingData });
  };

  const handleDocumentInputChange = (fieldName, type, e) => {
    e.preventDefault();

    setErrors({});

    let documentData = Object.assign({}, specificDocState);

    type === "checkbox"
      ? (documentData[fieldName] = e.target.checked)
      : (documentData[fieldName] = e.target.value);

    setSpecificDocState(documentData);
  };

  // const onEditorStateChange = (editorState) => {
  //     setEditorState(editorState);

  // };

  const removeFile = async (item, e, index) => {
    e.preventDefault();

    if (item.file_id) {
      let response = await documentDeleteFile(item.file_id);
    }

    let generate_files = specificDocState.attachments;

    if (generate_files.length > 0) {
      generate_files.splice(index, 1);
      setSpecificDocState({
        ...specificDocState,
        attachments: [...generate_files],
      });
    }

    if (generatedFiles.length > 0) {
      generatedFiles.splice(index, 1);
      setGeneratedFiles([...generatedFiles]);
    }
  };

  const handleAddServiceSpecificDocument = async (e) => {
    if (!serviceStateId) {
      sweetAlert("error", "Please save service first!");
      return;
    }

    if (requiredValidation(specificDocState, setErrors)) return;

    setLoading(true);

    try {
      // let payload = serviceDocumentPayload(specificDocState, serviceStateId, draftToHtml(convertToRaw(editorState.getCurrentContent())));
      let payload = serviceDocumentPayload(
        specificDocState,
        serviceStateId,
        editorState
      );

      const hasDocChanges =
        originalDocState.file_title !== payload.file_title ||
        originalDocState.file_version !== payload.file_version ||
        originalDocState.date !== payload.date ||
        (typeof payload.content === "string" &&
          originalDocState.content !== payload.content);

      console.log({ hasDocChanges });
      if (payload.id && !hasDocChanges) {
        payload = {
          ...payload,
          file_title: undefined,
          file_version: undefined,
          date: undefined,
          content: undefined,
        };
      }

      let returnStatus;

      let returnData;

      if (!payload.id) {
        const { data, status } = await addServiceSpecificDocument(payload);

        returnStatus = status;

        returnData = data;
      } else {
        const { data, status } = await updateServiceSpecificDocument(payload);

        returnStatus = status;

        returnData = data;
      }

      if (returnStatus == 200 || returnStatus == 201) {
        if (isEdit) {
          refreshList();
        }
        setShowAddServiceSuccess(true);
      }

      const generated = {
        fileName: returnData.attachments?.label,
        fileSize: returnData.attachments?.file_size,
        fileType: returnData.attachments?.file_type,
        fileUrl: returnData.attachments?.document_url,
        file_id: returnData.attachments?.document_id,
      };

      setGeneratedFiles([generated]);
      setSpecificDocState({
        ...specificDocState,
        id: payload.id ? payload.id : returnData.data?.id,
        attachments:
          payload.id && !hasDocChanges
            ? specificDocState.attachments
            : returnData.attachments,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  };

  const onDownloadFile = (fileUrl, fileName) => {
    console.log(fileUrl);
    fileName = fileName.replace(/ /g, "_");
    console.log(fileName);
    axios
      .get(fileUrl, {
        responseType: `blob`,
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  };

  const mapFiles = () => {
    return generatedFiles.map((item, index) => {
      return (
        <ul key={index}>
          <li>
            <div className={styles.uploadedFileContent}>
              <FileIcon selectedFileType={item.fileType} />

              <div className={styles.fileInfo}>
                <span className={styles.fileName}>{item.fileName}</span>
                <span className={styles.fileSize}>
                  {item.fileType} / {item.fileSize} Kb
                </span>
              </div>
            </div>
            <div className={styles.uploadedFileActionButtons}>
              <Button
                disable={!item.fileUrl}
                className={`${styles.download} ${styles.blueButton}`}
                onClick={(e) => {
                  onDownloadFile(item.fileUrl, item.fileName);
                }}
              >
                DOWNLOAD
                <FaDownload size={19} />
              </Button>
              <Button
                className={`${styles.removeFile} ${styles.blueButton}`}
                onClick={(e) => removeFile(item, e, index)}
              >
                REMOVE FILE
                <FaTimesCircle size={19} />
              </Button>
            </div>
          </li>
        </ul>
      );
    });
  };

  return (
    <div className={`${styles.inputContainer}`}>
      <div className={`${styles.inputFull}`}>
        {specificDocState.document_type_id == 4 && (
          <div className={`${styles.inputGroup}`}>
            {renderDocumentTextInput(
              "webinar_link",
              specificDocState.webinar_link,
              "Enter a link for the training module",
              "Paste here"
            )}
          </div>
        )}
        {specificDocState.document_type_id == 3 && (
          <div className={`${styles.inputGroup}`}>
            {renderDocumentTextInput(
              "webinar_link",
              specificDocState.webinar_link,
              "Enter a link for the webinar",
              "Paste here"
            )}
          </div>
        )}
      </div>
      <div className={`${styles.inputRow}`}>
        <div className={`${styles.inputGroup}`}>
          {renderDocumentTextInput(
            "file_title",
            specificDocState.file_title,
            "File Title",
            "Type a memorable name for the file"
          )}
        </div>
        <div className={`${styles.inputGroup}`}>
          {renderDocumentTextInput(
            "file_version",
            specificDocState.file_version,
            "Version",
            "Enter file version"
          )}
        </div>
        <div className={`${styles.inputGroup}`}>
          {renderDocumentTextInput(
            "date",
            specificDocState.date,
            "Version",
            "Choose a date",
            "date"
          )}
        </div>
      </div>

      <div className={`${styles.inputFull}`}>
        <div className={`${styles.inputGroup}`}>
          <label>Content</label>
          <TextEditor
            editorState={editorState}
            setEditorState={setEditorState}
            specificDocState={specificDocState}
            setSpecificDocState={setSpecificDocState}
            setGeneratedFiles={setGeneratedFiles}
            generatedFiles={generatedFiles}
          />
        </div>
      </div>

      {/* <div className={`${styles.inputFull}`}>
                <div className={`${styles.inputGroup}`}>
                    <TextArea
                        className={`${styles.inputDesc}`}
                        placeholder="Enter a description for the new document"
                        textLabel={`Description`}
                        onChange={(e) => handleDocumentInputChange("description", "text", e)}
                        value={specificDocState.description}
                    />
                </div>
            </div> */}

      <div className={`${styles.uploadedFile} ${styles.fileOriginGenerate}`}>
        {/* <div className={`${styles.addFiles}`}>
                    <span>Upload files to this document</span>
                    <label className={styles.blueButton}>
                        <input type="file"
                            ref={inputRef}
                            onChange={generateAddFiles}
                            type="file"
                            accept="image/jpeg"
                            className={`hidden`}
                        />   ADD FILE/S
                    </label>
                </div> */}
        {mapFiles()}
        {!isEmpty(errors) && errors["attachments"] && (
          <div>
            <span className={`text-red-400 mb-16 text-base py-1 px-2`}>
              {errors["attachments"]}
            </span>
          </div>
        )}
      </div>

      <div className={`${styles.inputNotify}`}>
        <label className={`${styles.switch}`}>
          <input
            type="checkbox"
            checked={specificDocState.notify_service}
            onChange={(e) =>
              handleDocumentInputChange("notify_service", "checkbox", e)
            }
          />
          <span className={`${styles.slider} ${styles.round}`}></span>
        </label>

        <h3>Notify center about new document</h3>

        <p>
          Tip before center update: Check this option only if you are adding a
          new document or editing an existing one.
        </p>
      </div>

      <div className={`${styles.inputActionButton} flex justify-center`}>
        <Button
          className={`${styles.blueButton}`}
          disable={loading}
          onClick={(e) => handleAddServiceSpecificDocument(e)}
        >
          {!loading ? (
            `${specificDocState.id ? "UPDATE" : "GENERATE"} DOCUMENT`
          ) : (
            <Spinner />
          )}
        </Button>
        {specificDocState.id && (
          <Button
            className={`ml-2 ${styles.removeFile} ${styles.blueButton}`}
            disable={loading}
            onClick={(e) => setSpecificDocState(emptySpecificDocState)}
          >
            EXIT WITHOUT SAVING
          </Button>
        )}
      </div>
    </div>
  );
};

export default GenerateServiceSpecificDocument;
