import React from 'react';
import { FaSpinner } from "react-icons/fa";

const Spinner = () => {

    return (
        <div className={`flex items-center justify-center`}>
            <FaSpinner className={`animate-spin`} />{" "}
            <span className={`ml-2`}>Saving...</span>
        </div>
    )
}

export default Spinner;
