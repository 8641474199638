import header from 'components/header';
import sortBy from 'lodash/sortBy';

const SERVICES = "services";
const BELONGS_IN = "service_type_id";


export const headerSort = (headerName, dataArr, asc) => {

    let data = [];
 
    let toAsc = !asc;

    if (headerName === SERVICES)
    {
        if (toAsc) {
            data = dataArr.sort((a, b) => 
            ((a[headerName].length > 0 ? a[headerName][0].name : '').toLowerCase() > (b[headerName].length > 0 ? b[headerName][0].name : '').toLowerCase())
            ? 1 : -1);
        } else {
            data = dataArr.sort((a, b) =>
            ((b[headerName].length > 0 ? b[headerName][0].name : '').toLowerCase() > (a[headerName].length > 0 ? a[headerName][0].name : '').toLowerCase()) ? 1 : -1);
        }
    }
    else 
    {
        if (toAsc) {
            data = dataArr.sort((a, b) => ((a[headerName] ? a[headerName] : '').toLowerCase() > (b[headerName] ? b[headerName] : '').toLowerCase()) ? 1 : -1);
        } else {
            data = dataArr.sort((a, b) => ((b[headerName] ? b[headerName] : '').toLowerCase() > (a[headerName] ? a[headerName] : '').toLowerCase()) ? 1 : -1);
        }
    }

    return {
        data,
        toAsc
    }
}
