import React, { useState, useEffect, useRef } from "react";
import { FaCog, FaTimesCircle } from "react-icons/fa";
import styles from "./Newsletter.module.css";
import Pagination from "../../components/Pagination/Pagination";
import UserLayout from "../../components/userLayout/UserLayout";
import FileDeleteModal from "./Modal/deleteFileModal";
import NewsletterFileDeleteModal from "./Modal/deleteNewsLetter";
import SuccessModal from "./Modal/successModal";
import {
  UploadNewsLetter,
  FetchNewsletter,
  DeleteFileNewsLetter,
} from "../../api/requests";
import LargeSpinner from "../../components/spinner/LargeSpinner";
import { FaSpinner } from "react-icons/fa";
import { Popup } from "semantic-ui-react";
import CogOptions from "./CogOptions";
import { useHistory } from "react-router-dom";
import { CATEGORIES } from "./categories";
import Thumbnail from "./Thumbnail";
import Required from "components/Required";

const Newsletter = () => {
  const history = useHistory();
  const [inputs, setInputs] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [notify, setNotify] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showNewsLetterDelete, setNewsLetterDelete] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [newsLetter, setNewsLetter] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [newsLetterPerPage] = useState(8);

  const indexOfLastNewsletter = currentPage * newsLetterPerPage;
  const indexOfFirstNewsletter = indexOfLastNewsletter - newsLetterPerPage;
  const currentNewsLetter = newsLetter.slice(
    indexOfFirstNewsletter,
    indexOfLastNewsletter
  );
  const [loading, setLoading] = useState(true);

  const [activeNewsletter, setActiveNewsletter] = useState();

  const [saving, setSaving] = useState(false);
  const inputNewsletterFileRef = useRef();

  const [thumbnail, setThumbnail] = useState();
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const closeDel = () => setNewsLetterDelete(false);

  const confirmDelete = (e) => setShowModal(true);

  const closeModal = () => setShowModal(false);

  const handleNotify = (e) => (e.target.checked ? setNotify(1) : setNotify(0));

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleFileUpload = (e) => {
    let files = e.target.files;

    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
        };

        allFiles.push(fileInfo);

        setUploadFiles(allFiles[0]);
      };
    }
  };

  const confirmDeleteNewsLetterDoc = (iD) => {
    setNewsLetterDelete(true);
    setFileToDelete(iD);
  };

  const DeleteFile = async () => {
    const response = await DeleteFileNewsLetter(fileToDelete);
    if (response.data == "Deleted") {
      setLoading(true);
      fetchData();
      setNewsLetterDelete(false);
    }
  };

  const handeInputChange = (e) => {
    let { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const resetInputFile = () => (inputNewsletterFileRef.current.value = null);
  const resetInputs = () => {
    setShowValidation(false);
    setThumbnail(undefined);
    setNotify(0);
    resetInputFile();
    setUploadFiles([]);
    setInputs({
      ...inputs,
      title: "",
      date: "",
    });
  };

  const handleSave = async () => {
    const incomplete =
      !inputs.title ||
      !inputs.category ||
      !uploadFiles.base64 ||
      !thumbnail?.thumbnail_base64;
    if (incomplete) {
      setShowValidation(true);
      return;
    }

    const payload = {
      base64_image: uploadFiles.base64,
      file_name: uploadFiles.name,
      label: uploadFiles.name,
      title: inputs.title,
      type: uploadFiles.type,
      size: uploadFiles.size,
      date: inputs.date,
      notify_service: notify,
      category: inputs.category,
      ...thumbnail,
    };
    console.log("payload ", payload);

    try {
      setSaving(true);
      const response = await UploadNewsLetter(payload);
      if (response.data == "Created") {
        resetInputs();
        setShowSuccessModal(true);
        await fetchData();
      }
      setSaving(false);
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  const deleteFile = () => {
    resetInputFile();
    setUploadFiles([]);
    setShowModal(false);
  };

  const fetchData = async () => {
    const response = await FetchNewsletter();
    setNewsLetter(response);
    setLoading(false);
  };

  const DownloadFile = (url, label) => {
    fetch(url).then((response) => {
      response.blob().then((blob) => {
        let Url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = Url;
        a.download = label;
        a.click();
      });
    });
  };

  return (
    <UserLayout headerText={`Add a newsletter`}>
      <div className={styles.uploadNewsLetter}>
        <h2>Upload a newsletter</h2>

        <p>
          Upload a newsletter to the portal by adding a PDF file, giving it a
          title and date added to help user’s identify the details quickly.
        </p>

        <div className={styles.inputContainer}>
          <div
            className={styles.uploadFile}
            style={
              uploadFiles.name ? { display: "none" } : { display: "block" }
            }
          >
            <label className={styles.blueButton}>
              <input
                ref={inputNewsletterFileRef}
                type="file"
                accept=".pdf"
                onChange={handleFileUpload}
              />{" "}
              BROWSE COMPUTER
            </label>
          </div>
          {showValidation && !uploadFiles.base64 && (
            <div className="text-center -mt-10 mb-10">
              <Required />
            </div>
          )}

          <div
            className={`${styles.uploadedFile} ${styles.fileOriginUpload}`}
            style={
              uploadFiles.name ? { display: "block" } : { display: "none" }
            }
          >
            <span>Uploaded file</span>
            <ul>
              <li>
                <div className={styles.uploadedFileContent}>
                  <div className={styles.fileIcon}>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      fontSize="24"
                      height="1em"
                      width="1em"
                      data-prefix="far"
                      data-icon="file-pdf"
                      className="svg-inline--fa fa-file-pdf fa-w-12"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
                      ></path>
                    </svg>
                  </div>
                  <div className={styles.fileInfo}>
                    <span className={styles.fileName}>
                      {uploadFiles.name ? uploadFiles.name.split(".")[0] : null}
                    </span>
                    <span className={styles.fileSize}>
                      {uploadFiles.type
                        ? uploadFiles.type.split("/")[1].toUpperCase()
                        : null}{" "}
                      / {uploadFiles.size}
                    </span>
                  </div>
                </div>
                <div className={styles.uploadedFileActionButtons}>
                  <button
                    className={`${styles.removeFile} ${styles.blueButton}`}
                    onClick={confirmDelete}
                  >
                    REMOVE FILE
                    <FaTimesCircle size={19} />
                  </button>
                </div>
              </li>
            </ul>
          </div>

          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <label>
                Newsletter Title <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                name="title"
                value={inputs.title}
                placeholder="Type a memorable name for the file"
                onChange={(e) => handeInputChange(e)}
              />
              {showValidation && !inputs.title && <Required />}
            </div>
            <div className={styles.inputGroup}>
              <label>Date added</label>
              <input
                type="date"
                name="date"
                value={inputs.date}
                placeholder="Choose a date"
                onChange={(e) => handeInputChange(e)}
              />
            </div>
            <div
              className={`${styles.inputGroup} ${styles.selectWrap} flex flex-col`}
            >
              <label>
                Choose a category <span className="text-red-400">*</span>
              </label>
              <select
                className={`flex-1 ${styles.selectCategories} ${
                  inputs.category ? "" : styles.noCategory
                }`}
                name="category"
                value={inputs.category}
                onChange={handeInputChange}
              >
                <option selected disabled>
                  Select
                </option>
                {CATEGORIES.map((c) => (
                  <option value={c.value}>{c.label}</option>
                ))}
              </select>
              {showValidation && !inputs.category && <Required />}
            </div>
          </div>

          <Thumbnail thumbnail={thumbnail} onChange={setThumbnail} />
          {showValidation && !thumbnail && (
            <div className="text-center -mt-10">
              <Required />
            </div>
          )}

          <div className={styles.inputNotify}>
            <label className={styles.switch}>
              <input type="checkbox" checked={notify} onChange={handleNotify} />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>

            <h3>Notify center about new newsletter</h3>

            <p>
              Tip before center update: Check this option only if you are adding
              a new document or editing an existing one.
            </p>
          </div>
        </div>
      </div>

      <div className={`${styles.btnSave} ${styles.uploadFileTabContent}`}>
        <button disabled={saving} onClick={handleSave}>
          {saving ? (
            <div className={`flex justify-center items-center`}>
              <FaSpinner className={`animate-spin`} />
              <span className={`ml-2`}>Please wait...</span>
            </div>
          ) : (
            `SAVE ALL CHANGES`
          )}
        </button>
      </div>

      <div className={styles.fileList}>
        <h3>Previous newslettters</h3>

        <div className={styles.fileListHeader}>
          <ul className={styles.fileListCol}>
            <li>File name</li>
            <li>Date added</li>
            <li>Category</li>
            <li></li>
          </ul>
        </div>

        {loading ? (
          <div className={`py-16`}>
            <LargeSpinner />
          </div>
        ) : (
          <div className={styles.fileListBody}>
            {newsLetter
              .slice(
                currentPage * newsLetterPerPage - newsLetterPerPage,
                currentPage * newsLetterPerPage
              )
              .map((values, index) => (
                <ul className={styles.fileListCol} key={index}>
                  <li>
                    <div className={styles.serviceName}>
                      <div className={styles.fileIconContainer}>
                        <div className={styles.fileIcon}>
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            fontSize="24"
                            height="1em"
                            width="1em"
                            data-prefix="far"
                            data-icon="file-pdf"
                            className="svg-inline--fa fa-file-pdf fa-w-12"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path
                              fill="currentColor"
                              d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div className={styles.fileContent}>
                        <span>{values.title ? values.title : "FILE"}</span>
                        <span>
                          {values.type ? values.type : "PDF"} /{" "}
                          {values.size ? `${values.size} Kb` : "1O KB"}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>{values.date}</li>
                  <li>
                    {CATEGORIES.find((c) => c.value === values.category).label}
                  </li>
                  <li className="text-right">
                    <Popup
                      pinned
                      basic
                      eventsEnabled
                      position="bottom right"
                      style={{
                        marginTop: "-3px",
                        marginRight: "-0.4px",
                        padding: 0,
                        border: 0,
                        boxShadow: "none",
                        borderTopRightRadius: 0,
                        zIndex: 999,
                      }}
                      content={
                        <CogOptions
                          onEdit={() =>
                            history.push({
                              pathname: `/edit-newsletter`,
                              state: { newsletter: values },
                            })
                          }
                          onDelete={() => confirmDeleteNewsLetterDoc(values.id)}
                          onDownload={() =>
                            DownloadFile(values.document_url, values.label)
                          }
                        />
                      }
                      hoverable
                      trigger={
                        <button
                          className={`${
                            values.id === activeNewsletter?.id
                              ? styles.cogActive
                              : styles.cogNormal
                          } p-4 rounded`}
                        >
                          <FaCog />
                        </button>
                      }
                      onClose={() => setActiveNewsletter(undefined)}
                      onOpen={() => setActiveNewsletter(values)}
                    />
                  </li>
                </ul>
              ))}
          </div>
        )}

        {!loading && newsLetter.length > 0 && (
          <div className={`${styles.pagination} ${styles.flex}`}>
            <Pagination
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              lastPage={Math.ceil(newsLetter.length / newsLetterPerPage)}
            />
          </div>
        )}
      </div>

      <NewsletterFileDeleteModal
        showModal={showNewsLetterDelete}
        deleteDoc={DeleteFile}
        closeModal={closeDel}
      />

      <FileDeleteModal
        showModal={showModal}
        deleteDoc={deleteFile}
        closeModal={closeModal}
      />

      <SuccessModal
        visibility={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />
    </UserLayout>
  );
};

export default Newsletter;
