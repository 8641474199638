import isEmpty from 'lodash/isEmpty';

import moment from "moment";

import {
    DOCUMENT_TYPE,
    REPORTS,
    DOC_CATEGORY,
    REPORT_STRING,
    RISK_ASSESSMENT,
    SERVICE_SPECIFIC_TYPE,
    FILE_UPLOAD_TYPE,
    DOCUMENT_TYPE_STRING
} from './constant';

export const mapDocument = async (docs) => {

    if (docs.length === 0) return;

    let newDocArray = [];

    for (let index = 0; index < docs.length; index++) {

        const element = docs[index];

        try {

            let serviceDoc = {
                fileName: "",
                fileSize: 0,
                fileType: "",
                file: "",
                availability: "",
                upload_or_generate:
                    element.upload_or_generate == 1 ? "Upload" : "Generate",
                id: element.id,
                // document_id: element.document_id,
                label: element.label,
                selected: false,
                service_specific_type: element.service_specific_type,
                service_type_id: !isEmpty(element.service_type_id)
                    ? element.service_type_id
                    : [],
                category: element.category,
                service_id: getServiceIds(element.services),
                excluded_services: typeof element.excluded_services === "string"
                    ? JSON.parse(element.excluded_services).map((id) => ({ id }))
                    : element.excluded_services,
                services: element.services,
                servicesCount: !isEmpty(element.services)
                    ? element.services.length
                    : 0,
                content: element.content,
                description: element.description,
                document_type_id: element.document_type_id,
                file_title: element.file_title,
                file_version: element.file_version,
                notify_service: element.notify_service,
                nqa_area_id:
                    typeof element.nqa_area_id === "string"
                        ? JSON.parse(element.nqa_area_id)
                        : element.nqa_area_id,
                risk_assesment_type: element.risk_assesment_type,
                date: moment(element.date).format("YYYY-MM-DD"),
                attachments: [],
                file_uploaded_id: "",
                fileUrl: "",
                raw_uploaded_file: element.uploaded_file,
                webinar_title: "",
                webinar_link: "",
                webinar_description: "",
                global_webinar: "",
                belongsIn: mapBelongsIn(element),
                categoryName: mapCategory(element)
            };

            if (element.uploaded_file) {
                serviceDoc.fileName = element.uploaded_file.document_name;
                serviceDoc.fileSize = element.uploaded_file.file_size;
                serviceDoc.fileType = element.uploaded_file.file_type;
                serviceDoc.label = element.uploaded_file.label;
                serviceDoc.availability = element.uploaded_file.availability;
                serviceDoc.file_uploaded_id = element.uploaded_file.id;
                serviceDoc.fileUrl = element.uploaded_file.document_url;
                serviceDoc.webinar_title = element.uploaded_file.webinar_title ? element.uploaded_file.webinar_title : "";
                serviceDoc.webinar_link = element.uploaded_file.webinar_link ? element.uploaded_file.webinar_link : "";
                serviceDoc.webinar_description = element.uploaded_file.webinar_description ? element.uploaded_file.webinar_description : "";
                serviceDoc.global_webinar = element.uploaded_file.global_webinar || 0;
            }

            newDocArray.push(serviceDoc);

        } catch (error) {

            console.log(error);

        }
    }

    return newDocArray;
}

const getServiceIds = (arr) => {
    let ids = [];

    if (!arr || arr.length < 1) return;
    ids = arr.map(i => { return i.id });
    return ids;
};

const mapCategoryWithNQA = (doc, nqaArr, selectedNQA) => {
    let nqa = "";
    let category = "";

    if (nqaArr.indexOf(selectedNQA) > -1) nqa = `NQA${selectedNQA}`;

    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.POLICY) category = DOCUMENT_TYPE_STRING.POLICY;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.PROCEDURE) category = DOCUMENT_TYPE_STRING.PROCEDURE;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.WEBINAR) category = DOCUMENT_TYPE_STRING.WEBINAR;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.TRAINING_MODULE) category = DOCUMENT_TYPE_STRING.TRAINING_MODULE;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.FEEDBACK) category = DOCUMENT_TYPE_STRING.FEEDBACK;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.MISC) category = DOCUMENT_TYPE_STRING.MISC;

    return `${nqa} ${category}`;
}

const mapCategory = (doc) => {
    let nqa = "";
    let category = "";

    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.POLICY) category = DOCUMENT_TYPE_STRING.POLICY;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.PROCEDURE) category = DOCUMENT_TYPE_STRING.PROCEDURE;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.WEBINAR) category = DOCUMENT_TYPE_STRING.WEBINAR;
    if (doc.document_type_id && doc.document_type_id == DOCUMENT_TYPE.TRAINING_MODULE) category = DOCUMENT_TYPE_STRING.TRAINING_MODULE;

    return category;
}

const SERVICE_SPECIFIC_DOCUMENT = "Service-specific document";
const GLOBAL_WEBINAR = "Global";

 
const mapBelongsIn = (doc) => {

    let doctype = [];
    let category = "";

    if (doc.category == DOC_CATEGORY.NQA_DOCUMENT) {
        if (doc.uploaded_file && !doc.uploaded_file.global_webinar) {
            if (doc.service_specific_type && doc.service_type_id.length > 0) {
                doctype = doc.service_type_id.map(item => { return item.name });
            }

            if (!doc.service_specific_type && doc.service_type_id.length > 0) {
                doctype = doc.service_type_id.map(item => { return item.name });
            }

        } else {
            doctype[0] = GLOBAL_WEBINAR;
        }
    }

    else if (category == DOC_CATEGORY.FOCUS_OF_THE_MONTH) {

    }
    else {
        doctype[0] = SERVICE_SPECIFIC_DOCUMENT;
    }
   
    return doctype[0];
}
 

export const mapDocTypeAndCategory = (doc, selectedNQA) => {

    let doctype = [];
    let category = "";
    let nqa = "";
    let nqaArr = [];

    if (typeof doc.nqa_area_id === "string" && doc.nqa_area_id != "[]") {
        nqaArr = JSON.parse(doc.nqa_area_id);
    }
    else {
        nqaArr = doc.nqa_area_id;
    }

    if (doc.category == DOC_CATEGORY.NQA_DOCUMENT) {
        if (doc.global_webinar == 0) {
            if (doc.service_specific_type && doc.service_type_id.length > 0) {
                doctype = doc.service_type_id.map(item => { return item.name });
            }

            if (!doc.service_specific_type && doc.service_type_id.length > 0) {
                doctype = doc.service_type_id.map(item => { return item.name });
            }

        } else {
            doctype[0] = GLOBAL_WEBINAR;
        }

        if (nqaArr.length > 0 && nqaArr.indexOf(selectedNQA) > -1) {
            category = mapCategoryWithNQA(doc, nqaArr, selectedNQA);
        }
    }

    else if (category == DOC_CATEGORY.FOCUS_OF_THE_MONTH) {

    }
    else {

        doctype[0] = SERVICE_SPECIFIC_DOCUMENT;

        if (doc.service_specific_type < SERVICE_SPECIFIC_TYPE.RISK_ASSESSMENT) {
            if (doc.service_specific_type == REPORTS.REPORT) category = REPORT_STRING.REPORT;
            if (doc.service_specific_type == REPORTS.AP_REPORT) category = REPORT_STRING.AP_REPORT;
            if (doc.service_specific_type == REPORTS.MENTOR_REPORT) category = REPORT_STRING.MENTOR_REPORT;
        }

        else if (doc.service_specific_type == SERVICE_SPECIFIC_TYPE.RISK_ASSESSMENT) {
            if (nqaArr.length > 0 && nqaArr.indexOf(selectedNQA) > -1) {
                if (nqaArr.indexOf(selectedNQA) > -1 && selectedNQA == 2) category = RISK_ASSESSMENT.NQA_2;
                if (nqaArr.indexOf(selectedNQA) > -1 && selectedNQA == 3) category = RISK_ASSESSMENT.NQA_3;
            }
        }

        else {
            if (doc.service_specific_type === REPORTS.FOM) category = REPORT_STRING.FOM;
            if (nqaArr.length > 0 && nqaArr.indexOf(selectedNQA) > -1) {
                category = mapCategoryWithNQA(doc, nqaArr, selectedNQA);
            }
        }
    }
    return { category, doctype };
};


export const fileOrigin = (doc) => {
    let label = "";
    if (!doc.webinar_link) {
        if (doc.upload_or_generate === "Upload") {
            label = "Uploaded";
        } else {
            label = "Generated";
        }
    } else {
        label = "Link";
    }
    return label;
}
