type RequiredProps = { customText?: string; className?: string };

const Required = (props: RequiredProps) => {
  return (
    <div>
      <span
        className={`${
          props.className || ""
        } text-red-400 italic text-base text-left`}
      >
        {props.customText || "This field is required."}
      </span>
    </div>
  );
};

export default Required;
