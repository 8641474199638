import React, { useCallback, useReducer, useState } from "react";
import styles from "./Login.module.css";
import { FaSpinner, FaArrowCircleRight, FaInfoCircle } from "react-icons/fa";
import InputComponent from "../../components/InputComponent";
import { Link } from "react-router-dom";
import LoginRight from "../../static/svg/loginRight.svg";
import QIPBanner from "../../static/svg/qipBanner.svg";
import { useStoreActions } from "../../hooks";
import { isEmpty } from "lodash";
import IncorrectCreds from "../../components/Modals/CredentialErrorModal";
import { fetchAuthObject } from "../../api/requests";
import { setUserToken, setUserLoggedIn } from "../../services/auth";
import UserLayout from "../../components/userLayout/UserLayout";

const loginReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      const updatedInput = {
        ...state.input,
        [action.field]: action.value,
      };

      const updatedValidation = {
        ...state.validation,
        [action.field]: action.valid,
      };

      const formValidity = Object.values(updatedValidation).every(Boolean);

      return {
        input: updatedInput,
        validation: updatedValidation,
        formValidation: formValidity,
      };
    }
    default:
      return state;
  }
};

const Login = () => {
  //   const loginAction = useStoreActions((actions) => actions.authStore.login);

  //   useStoreActions((state) => state.appStore.setFirstSignUp(false));

  const [incorrectCredsModalVis, setIncorrectCredsModalVis] = useState(false);
  const [error, setError] = useState(false);

  const initialLoginState = {
    input: {
      username: "",
      password: "",
    },
    validation: {
      username: true,
      password: true,
    },
    formValidation: true,
  };

  const [loginLoading, setLoading] = useState(false);

  const [loginState, loginDispatch] = useReducer(
    loginReducer,
    initialLoginState
  );

  const changeInputDispatch = useCallback((field, value) => {
    let valid = false;

    if (value) valid = true;
    const action = {
      type: "LOGIN",
      field,
      value,
      valid,
    };
    loginDispatch(action);
  }, []);

  const onLogin = async () => {
    const loginCreds = {
      ...loginState.input,
    };
    try {
      setLoading(true);
      const response = await fetchAuthObject({
        email: loginState.input.username,
        password: loginState.input.password,
      });

      if (response.status) {
        setUserLoggedIn(response.user);
        setUserToken(response.auth.accessToken, window.location.reload());
      } else {
        setError(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onConfirmIncorrect = () => {
    setIncorrectCredsModalVis(false);
  };

  return (
    <UserLayout>
      {" "}
      <div className={`${styles.mainContainer} -mt-32`}>
        {incorrectCredsModalVis && (
          <IncorrectCreds
            visibility={incorrectCredsModalVis}
            onOpenModal={() => setIncorrectCredsModalVis(true)}
            onCloseModal={onConfirmIncorrect}
          />
        )}
        {loginLoading ? (
          // <div className={styles.spinnerDiv}>
          <div
            className={`w-full h-screen flex flex-col justify-center items-center`}
          >
            <FaSpinner
              className={`animate-spin text-evidenceType font-bold text-6xl`}
            />

            <span className={`block`}>Loading data...</span>
          </div>
        ) : (
          <div className={`${styles.cardContainer} block screen992:flex `}>
            <div className={`${styles.cardLeft} p-12`}>
              <h2 className={`${styles.headerText} my-10`}>
                Administrator&nbsp;login
              </h2>

              <InputComponent
                fieldName="username"
                label="Your username"
                type="text"
                textArea={false}
                className={`${styles.inputField} `}
                placeholder={`Username`}
                inputValue={loginState.input.username}
                setInputValue={changeInputDispatch}
              />

              <InputComponent
                fieldName="password"
                label="Password"
                type="password"
                textArea={false}
                className={`${styles.inputField} `}
                placeholder={`************`}
                inputValue={loginState.input.password}
                setInputValue={changeInputDispatch}
              />

              <div className={`h-4 ml-2 pt-1 italic text-red-400`}>
                {error && `Invalid Username or Password`}
              </div>

              <button
                className={`${styles.loginButton} px-8 flex justify-center items-center my-7`}
                onClick={onLogin}
              >
                <FaArrowCircleRight />{" "}
                <span className={`ml-2`}>LOGIN&nbsp;NOW</span>
              </button>

              <div
                className={styles.forgotPasswordGroup}
                style={{ display: "none" }}
              >
                {/* <Icon className={styles.forgotPasswordIcon} name="info circle" /> */}
                <FaInfoCircle className={styles.forgotPasswordIcon} />
                <Link
                  className={styles.forgotPasswordText}
                  to="/qip/auth/forgot-password"
                >
                  I forgot my password
                </Link>
              </div>
            </div>

            {/* <div
            className={`${styles.cardRight} hidden screen992:block bg-cover bg-center relative`}
            // style={{ backgroundImage: `url(${LoginRight})` }}
          >
            <div
              className={`absolute top-0 right-0 mt-8 mr-8 z-50`}
              style={{
                width: "174px",
                height: "33px",
                // backgroundImage: `url(${QIPBanner})`,
              }}
            >
              {" "}
            </div>
          </div> */}
          </div>
        )}
      </div>
    </UserLayout>
  );
};

export default Login;
