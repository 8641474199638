import React, { useEffect, useState } from "react";
import styles from "./table/Pagination.module.css";

const Pagination = ({
  rpp,
  dataCount,
  currPage,
  data,
  setCurrPage,
  setTrimmedData,
}) => {
  const [maxButton, setMaxButton] = useState(3);

  const [pages, setPages] = useState([]);

  

  const initializePages = () => {

    setTrimmedData([]);

    setPages([]);

    let trimStart = (currPage - 1) * rpp;

    let trimEnd = trimStart + rpp;

    let trimmedData = data.slice(trimStart, trimEnd);

    let pages = [];

    for (let i = 1; i <= Math.ceil(dataCount / rpp); i++) {

      pages.push(i);

    }

    setPages([...pages]);

    setTrimmedData([...trimmedData]);
  };

  const renderPageButtons = () => {

    let newPages = [];

    let maxLeft = currPage - Math.floor(maxButton / 2);

    let maxRight = currPage + Math.floor(maxButton / 2);

    if (maxLeft < 1) {

      maxLeft = 1;

      maxRight = maxButton;

    }

    if (maxRight > pages.length) {

      maxLeft = pages.length - (maxButton - 1);

      maxRight = pages.length;

      if (maxLeft < 1) {

        maxLeft = 1;

      }

    }

    for (let page = maxLeft; page <= maxRight; page++) {

      newPages.push(page);

    }

    return newPages.map((number, i) => {
      return (
        <a
          key={number}
          onClick={() => setCurrPage(number)}
          className={`${number == currPage ? styles.active : null
            } flex items-center justify-center`}
        >
          {number}
        </a>
      );
    });
  };

  useEffect(() => {

    initializePages();

  }, [currPage]);

  useEffect(() => {

    initializePages();

  }, [data]);

  return (
    <div className={`${styles.pagination} flex`}>
      <a
        onClick={() => setCurrPage(1)}
        className={`flex items-center justify-center`}
      >
        &laquo;
      </a>

      {renderPageButtons()}

      {/* <a
        onClick={() => setCurrPage(1)}
        className={`flex items-center justify-center`}
      >
        ...
      </a>

      <a
        onClick={() => setCurrPage(1)}
        className={`flex items-center justify-center`}
      >
        {pages[pages.length]}
        {console.log(pages[pages.length])}
      </a> */}

      <a
        onClick={() => setCurrPage(pages.length)}
        className={`flex items-center justify-center`}
      >
        &raquo;
      </a>
    </div>
  );
};

export default Pagination;
