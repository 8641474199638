import React from "react";
import PropTypes from "prop-types";

import styles from "./DeleteUserSuccessModal.module.css";
import { Button, Modal } from "semantic-ui-react";
import {
	FaTimes
} from "react-icons/fa";

const DeleteUserSuccessModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	message = "This user is now permanently deleted from QIP Portal",
	onConfirm,
}) => {
	return (
		<Modal
			size="tiny"
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<button className={`${styles.closeModal}`}
					onClick={onCloseModal}
				>
					<FaTimes className={`ml-2`} size={16} />
				</button>

				<div className={`${styles.modalTitle}`}>
					<h1>{message}</h1>
				</div>

				<div class="modalContent">

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={onConfirm}
							color='blue'>OK</Button>
						<Button
							onClick={onCloseModal}
							color='grey'>UNDO</Button>
					</div>

				</div>

			</div>

		</Modal>
	);
}

DeleteUserSuccessModal.propTypes = {
	visibility: PropTypes.bool.isRequired,
	onOpenModal: PropTypes.func.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	onConfirmRemoveUser: PropTypes.func.isRequired,
};

export default DeleteUserSuccessModal;