import React from 'react';

const OOSH = () => {
    return (
        <svg viewBox="0 0 36 37" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.9669V28.0599C0 29.0739 0.673156 29.9997 1.66906 30.3523L17.041 35.8365C17.3453 35.9423 17.668 36.004 18 36.004C18.332 36.004 18.6455 35.9511 18.959 35.8365L34.3309 30.3523C35.3268 29.9997 36 29.0739 36 28.0599V2.9669C36 1.93532 35.3176 1.00954 34.3033 0.656862L33.5102 0.383537C33.3627 0.330635 33.1967 0.330635 33.0492 0.383537L19.125 5.0477L15.0861 3.69871C14.8924 3.63699 14.6895 3.65462 14.5143 3.75161L11.3145 5.53263C11.1025 5.64725 10.9734 5.86768 10.9734 6.10574V11.5722L9.84836 10.3291C9.64549 10.1086 9.3043 10.0381 9.02766 10.1615L7.875 10.6817V4.89781L10.7797 3.45183C11.0287 3.3284 11.167 3.08152 11.1486 2.81701C11.1301 2.55251 10.9457 2.32327 10.6875 2.24391L4.07582 0.0396763C3.92828 -0.0132254 3.77152 -0.0132254 3.6332 0.0396763L1.70594 0.656862C0.682377 1.00072 0 1.9265 0 2.9669ZM2.14857 1.90887L3.83607 1.37104L8.6957 2.98454L6.84221 3.91032C6.61168 4.02494 6.47336 4.25418 6.47336 4.49223V11.7309C6.47336 11.9602 6.59324 12.1718 6.79611 12.2864C6.99898 12.4099 7.24795 12.4187 7.46004 12.3217L9.11988 11.5634L11.1117 13.7677C11.2961 13.9793 11.6096 14.0498 11.877 13.9528C12.1445 13.8558 12.3289 13.609 12.3289 13.3356V6.48486L14.9109 5.0477L18.8852 6.37024C19.0328 6.42315 19.1895 6.42315 19.3371 6.37024L33.2613 1.70608L33.8238 1.90005C34.2941 2.05876 34.6076 2.48197 34.6076 2.95809V28.0511C34.6076 28.5184 34.3033 28.9416 33.8422 29.1091L18.4703 34.5933C18.166 34.6991 17.8156 34.6991 17.5113 34.5933L2.14857 29.1091C1.6875 28.9416 1.3832 28.5184 1.3832 28.0511V2.9669C1.3832 2.49079 1.69672 2.05876 2.14857 1.90887Z" />
            <path d="M22.4725 18.2126C22.7491 18.3978 23.0718 18.4948 23.413 18.4948C23.6067 18.4948 23.8003 18.4595 23.9847 18.3978L31.537 15.7351C32.1825 15.5058 32.6159 14.9151 32.6159 14.2626V9.93353C32.6159 9.41333 32.3485 8.93721 31.9151 8.64625C31.4817 8.35529 30.9007 8.28476 30.4028 8.4611L22.8505 11.1238C22.205 11.3531 21.7716 11.9438 21.7716 12.5962V16.9254C21.7716 17.4367 22.0298 17.9217 22.4725 18.2126ZM23.1548 12.5962C23.1548 12.4904 23.2194 12.4023 23.33 12.3582L30.8823 9.69547C30.91 9.68665 30.9468 9.67784 30.9745 9.67784C31.0298 9.67784 31.0759 9.69547 31.122 9.72192C31.1958 9.76601 31.2327 9.84536 31.2327 9.92471V14.2538C31.2327 14.3596 31.1682 14.4478 31.0575 14.4919L23.5052 17.1546C23.4222 17.1811 23.33 17.1722 23.2655 17.1282C23.1917 17.0841 23.1548 17.0047 23.1548 16.9165V12.5962Z" />
        </svg>
    )
}

export default OOSH;