import { useState, useRef, useEffect } from "react";
import { FaFilePdf, FaTimesCircle } from "react-icons/fa";
import styles from "./Newsletter.module.css";
import UserLayout from "../../components/userLayout/UserLayout";
import { FaSpinner } from "react-icons/fa";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import Thumbnail, { IThumbnail } from "./Thumbnail";
import { CATEGORIES } from "./categories";
import { UpdateNewsletter } from "api/requests";
import FileDeleteModal from "./Modal/deleteFileModal";
import SuccessModal from "./Modal/successModal";
import Required from "components/Required";

type Inputs = {
  title: string;
  date: string;
  category: string;
};

type File = {
  name: string;
  type: string;
  size: string;
  base64: string | ArrayBuffer | null;
};

const EditNewsletter = () => {
  const location = useLocation<any>();
  const history = useHistory();
  const inputNewsletterFileRef = useRef<HTMLInputElement>(null);
  const [newsletter, setNewsletter] = useState(location.state?.newsletter);
  const [inputs, setInputs] = useState<Inputs>({} as Inputs);
  const [uploadFile, setUploadFile] = useState<File>({} as File);
  const [notify, setNotify] = useState(0);
  const [saving, setSaving] = useState(false);
  const [thumbnail, setThumbnail] = useState<IThumbnail>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [fileRemoved, setFileRemoved] = useState(false);
  const [thumbnailRemoved, setThumbnailRemoved] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  console.log({ newsletter });

  useEffect(() => {
    async function init() {
      setInputs({
        title: newsletter.title,
        date: moment(newsletter.date, "DD-MM-YYYY").format("YYYY-MM-DD"),
        category: newsletter.category,
      });
      setNotify(newsletter.notify_service);
      setUploadFile({
        name: newsletter.document_name,
        type: newsletter.type,
        size: newsletter.size,
        base64: newsletter.document_url,
      });

      newsletter.thumbnail &&
        setThumbnail({
          thumbnail_base64: null,
          thumbnail_label: newsletter.thumbnail.thumbnail_label,
          thumbnail_type: newsletter.thumbnail.thumbnail_type,
          thumbnail_size: newsletter.thumbnail.thumbnail_size,
          thumbnail_url: newsletter.thumbnail.thumbnail_url,
        });

      !newsletter.thumbnail && setThumbnailRemoved(true);
      !newsletter.document_url && setFileRemoved(true);
    }

    init();
  }, [newsletter]);

  if (!location.state?.newsletter) {
    return <Redirect to="/newsletter" />;
  }

  const handeInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleNotify = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    target.checked ? setNotify(1) : setNotify(0);

  const handleFileUpload = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target;

    let allFiles: File[] = [];
    for (var i = 0; i < (files?.length || 0); i++) {
      const file = files?.[i];
      if (!file) break;

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        allFiles.push({
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + " kB",
          base64: reader.result,
        });

        setUploadFile(allFiles[0]);
      };
    }
  };

  const onDeleteFile = () => {
    setUploadFile({} as File);
    setOpenDeleteModal(false);
    setFileRemoved(true);
  };

  const handleSave = async () => {
    const incomplete =
      !inputs.title ||
      !inputs.category ||
      (fileRemoved && !uploadFile.base64) ||
      (thumbnailRemoved && !thumbnail?.thumbnail_base64);
    if (incomplete) {
      setShowValidation(true);
      return;
    }

    const file = fileRemoved
      ? {
          base64_image: uploadFile.base64,
          file_name: uploadFile.name,
          label: uploadFile.name,
          type: uploadFile.type,
          size: uploadFile.size,
        }
      : {};

    const thumbnailObj = thumbnailRemoved ? thumbnail : {};

    const payload = {
      newsletter_id: newsletter.id,
      title: inputs.title,
      date: inputs.date,
      notify_service: notify,
      category: inputs.category,
      ...file,
      ...thumbnailObj,
    };
    try {
      setSaving(true);
      const response = await UpdateNewsletter(payload as any);
      setSaving(false);
      response.status === 200 && setShowSuccessModal(true);
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  return (
    <UserLayout headerText="Edit a newsletter">
      <div className={styles.uploadNewsLetter}>
        <h2>Uploaded newsletter</h2>

        <p>
          Upload a newsletter to the portal by adding a PDF file, giving it a
          title and date added to help user’s identify the details quickly.
        </p>

        <div className={styles.inputContainer}>
          <div
            className={styles.uploadFile}
            style={uploadFile.name ? { display: "none" } : { display: "block" }}
          >
            <label className={styles.blueButton}>
              <input
                ref={inputNewsletterFileRef}
                type="file"
                accept=".pdf"
                onChange={handleFileUpload}
              />{" "}
              BROWSE COMPUTER
            </label>
          </div>
          {showValidation && !uploadFile.base64 && (
            <div className="text-center -mt-10 mb-10">
              <Required />
            </div>
          )}

          <div
            className={`${styles.uploadedFile} ${styles.fileOriginUpload}`}
            style={uploadFile.name ? { display: "block" } : { display: "none" }}
          >
            <span>Uploaded file</span>
            <ul>
              <li>
                <div className={styles.uploadedFileContent}>
                  <div className={styles.fileIcon}>
                    <FaFilePdf size={24} />
                  </div>
                  <div className={styles.fileInfo}>
                    <span className={styles.fileName}>
                      {uploadFile.name ? uploadFile.name.split(".")[0] : null}
                    </span>
                    <span className={styles.fileSize}>
                      {uploadFile.type?.toUpperCase()} / {uploadFile.size} Kb
                    </span>
                  </div>
                </div>
                <div className={styles.uploadedFileActionButtons}>
                  <button
                    className={`${styles.removeFile} ${styles.blueButton}`}
                    onClick={() => setOpenDeleteModal(true)}
                  >
                    REMOVE FILE
                    <FaTimesCircle size={19} />
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.inputRow}>
            <div className={styles.inputGroup}>
              <label>
                Newsletter Title <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                name="title"
                value={inputs.title}
                placeholder="Type a memorable name for the file"
                onChange={handeInputChange}
              />
              {showValidation && !inputs.title && <Required />}
            </div>
            <div className={styles.inputGroup}>
              <label>Date added</label>
              <input
                type="date"
                name="date"
                value={inputs.date}
                placeholder="Choose a date"
                onChange={handeInputChange}
              />
            </div>
            <div
              className={`${styles.inputGroup} ${styles.selectWrap} flex flex-col`}
            >
              <label>
                Choose a category <span className="text-red-400">*</span>
              </label>
              <select
                className={`flex-1 ${styles.selectCategories} ${
                  inputs.category ? "" : styles.noCategory
                }`}
                name="category"
                value={inputs.category}
                onChange={handeInputChange}
              >
                <option selected disabled>
                  Select
                </option>
                {CATEGORIES.map((c) => (
                  <option value={c.value}>{c.label}</option>
                ))}
              </select>
              {showValidation && !inputs.category && <Required />}
            </div>
          </div>
          <Thumbnail
            thumbnail={thumbnail}
            onChange={(t) => {
              t === undefined && setThumbnailRemoved(true);
              setThumbnail(t);
            }}
          />
          {showValidation && !thumbnail && (
            <div className="text-center">
              <Required />
            </div>
          )}
          <div className={styles.inputNotify}>
            <label className={styles.switch}>
              <input
                type="checkbox"
                checked={!!notify}
                onChange={handleNotify}
              />
              <span className={`${styles.slider} ${styles.round}`}></span>
            </label>

            <h3>Notify center about new newsletter</h3>

            <p>
              Tip before center update: Check this option only if you are adding
              a new document or editing an existing one.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap max-w-6xl justify-center m-auto">
        <div className={`${styles.btnSave} mx-3 lg:flex-1`}>
          <button disabled={saving} onClick={handleSave}>
            {saving ? (
              <div className={`flex justify-center items-center`}>
                <FaSpinner className={`animate-spin`} />
                <span className={`ml-2`}>Please wait...</span>
              </div>
            ) : (
              `SAVE ALL CHANGES`
            )}
          </button>
        </div>
        <div className={`mx-3 lg:flex-1 ${styles.btnExit}`}>
          <button onClick={history.goBack}>EXIT WITHOUT SAVING</button>
        </div>
      </div>

      <FileDeleteModal
        showModal={openDeleteModal}
        deleteDoc={onDeleteFile}
        closeModal={() => setOpenDeleteModal(false)}
      />
      <SuccessModal
        customText="Newsletter updated successfully."
        visibility={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
      />
    </UserLayout>
  );
};

export default EditNewsletter;
