import * as React from "react";
import { useStoreActions } from "../../hooks";

import { generatePlainText, generateHash } from "../../services/crypto";
import { setUserToken, setUserLoggedIn } from "../../services/auth";
import { FaSpinner } from "react-icons/fa";

const { useEffect } = React;

export default function HubAdminRedirect() {
  const url = new URL(window.location.href);

  const cipherToken = decodeURIComponent(
    url.searchParams.get("accessToken") || ""
  );
  const tokenHash = decodeURIComponent(url.searchParams.get("tokenHash") || "");
  const cipherAuth = decodeURIComponent(url.searchParams.get("auth") || "");
  const authHash = decodeURIComponent(url.searchParams.get("authHash") || "");
  const viewerCipher = decodeURIComponent(url.searchParams.get("viewer") || "");
  const viewerHash = decodeURIComponent(
    url.searchParams.get("viewerHash") || ""
  );
  const counter = url.searchParams.get("counter");

  const initDetails = async () => {
    localStorage.clear();

    const viewer = generatePlainText(viewerCipher);
    const accessToken = generatePlainText(cipherToken);
    const authUser = generatePlainText(cipherAuth);

    const vHash = generateHash(viewer);
    const tHash = generateHash(accessToken);
    const aHash = generateHash(authUser);

    if (tHash !== tokenHash && aHash !== authHash && vHash === viewerHash) {
      window.location.assign("/auth");
    } else {
      setUserToken(accessToken, () => {});

      setUserLoggedIn(JSON.parse(authUser));
    }
  };

  useEffect(() => {
    initDetails();
    window.location.assign("/");
  }, [counter]);

  return (
    <div
      className={`w-full h-screen flex flex-col justify-center items-center`}
    >
      <FaSpinner className={`animate-spin text-qipGreen font-bold text-6xl`} />

      <div>Please wait...</div>
    </div>
  );
}
