
import React from "react";
import styles from "./CancelSubscriptionModal.module.css";
import {
	FaTimes
} from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import Button from '../../../_base/button';

const CancelSubscriptionModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	onUndo,
}) => {

	
	const undoService = (e) => {
		e.preventDefault();
		 onUndo(false); 
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>To manage subscriptions (cancel and </h1>
					<h1>delete) you will need to visit The Hub</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							// onClick={onCloseModal}
							className={`${styles.btnGreen}`}>OK</Button>					 
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default CancelSubscriptionModal;