import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";

import { setAxios } from "../../services/axios";
import {
  fetchAuthObject,
  fetchUserCenters,
  registerUser,
  getAdminAttr,
  fetchServiceDetails,
  createServiceCenter,
  fetchAuthUser,
} from "../../api/requests";
import {
  AuthObject,
  LoginCreds,
  ServiceDetails,
  SignUpData,
  AdminAttr,
} from "../../types";

export interface AuthModel {
  isLoggedIn: Computed<AuthModel, boolean>;
  isAdmin: boolean;
  setIsAdmin: Action<AuthModel, boolean>;
  // user?: User;
  // setUser: Action<AuthModel, User>;
  authObject?: AuthObject;
  adminAttr?: AdminAttr;
  setAuthObject: Action<AuthModel, AuthObject>;
  loadAuthObject: Thunk<
    AuthModel,
    void,
    Record<string, unknown>,
    Record<string, unknown>,
    Promise<boolean>
  >;
  setAdminAttr: Action<AuthModel, AdminAttr>;
  services?: ServiceDetails[];
  selectedService?: ServiceDetails;
  setSelectedService: Action<AuthModel, ServiceDetails>;
  setServices: Action<AuthModel, ServiceDetails[]>;
  fetchServices: Thunk<AuthModel, number>;
  login: Thunk<AuthModel, LoginCreds>;
  clearSession: Action<AuthModel>;
  logout: Thunk<AuthModel>;
  signUpCreds?: SignUpData;
  signUp: Thunk<AuthModel, SignUpData>;
  setSignUpCreds: Action<AuthModel, SignUpData | undefined>;
  fetchAdminAttr: Thunk<AuthModel, { memberId: number }>;
  // fetchUserDetails: Thunk<AuthModel, number>;
}

const authStore: AuthModel = {
  isAdmin: false,
  setIsAdmin: action((state, payload) => {
    state.isAdmin = payload;
  }),

  isLoggedIn: computed((state) => {
    if (state.authObject) return true;

    return false;
  }),
  setAdminAttr: action((state, payload) => {
    console.log("attr ", payload);
    state.adminAttr = { ...payload };
  }),
  fetchAdminAttr: thunk(async (actions, payload) => {
    try {
      const adminUser = await getAdminAttr(payload.memberId);
      actions.setAdminAttr(adminUser);
    } catch (error) {
      console.log(error);
    }
  }),

  // setUser: action((state, payload) => {
  //   state.user = {
  //     ...state.user,
  //     ...payload,
  //   };
  // }),

  setSelectedService: action((state, payload) => {
    state.selectedService = payload;
  }),

  setServices: action((state, payload) => {
    state.services = payload;
  }),

  fetchServices: thunk(async (actions, payload) => {
    const userId = payload;

    try {
      const services = await fetchUserCenters(userId);

      if (services.length > 0) {
        actions.setServices(services);

        const selectedServiceId = localStorage.getItem("selectedService");

        const setService: any = selectedServiceId
          ? services.find((s: any) => s.id === Number(selectedServiceId))
          : services[0];

        actions.setSelectedService(setService);
      }
    } catch (error) {
      console.log(error);
      // window.location.reload();
    }
  }),

  setAuthObject: action((state, payload) => {
    localStorage.setItem("qip_portal_token", JSON.stringify(payload));
    state.authObject = payload;
  }),

  loadAuthObject: thunk(async (actions) => {
    const cachedObject = localStorage.getItem("qip_portal_token");

    if (cachedObject) {
      const authObject: AuthObject = JSON.parse(cachedObject);
      //TODO: request for another access token if token is expired

      setAxios(authObject.accessToken);
      actions.setAuthObject(authObject);
      await actions.fetchAdminAttr({ memberId: authObject.userId });
      await actions.fetchServices(authObject.userId);

      document.body.classList.add("loggedIn");
      document.body.classList.remove("loggedOut");

      return true;
    }

    document.body.classList.add("loggedOut");
    document.body.classList.remove("loggedIn");

    return false;
  }),

  login: thunk(async (actions, payload) => {
    try {
      const authObject = await fetchAuthObject(payload); // api request for login

      const authUser = await fetchAuthUser(authObject.accessToken);

      if (authUser.user_type !== "SUPER_ADMIN") {
        return false;
      } else {
        actions.setAuthObject(authObject);
        return true;
      }
    } catch (error) {
      return false;
    }
  }),

  clearSession: action((state) => {
    setAxios("");
    // state.user = undefined;
    state.services = undefined;
    state.selectedService = undefined;
    state.signUpCreds = undefined;
    state.authObject = undefined;
    document.body.classList.add("loggedOut");
    document.body.classList.remove("loggedIn");
  }),

  logout: thunk(async (actions) => {
    localStorage.removeItem("qip_portal_token");
    localStorage.clear();
    actions.clearSession();
  }),

  signUp: thunk(async (actions, payload) => {
    try {
      const registerResponse = await registerUser(payload);

      if (registerResponse.status === 201) {
        actions.setSignUpCreds({
          ...payload,
          userId: registerResponse.userId,
          centerId: registerResponse.centerId,
        });
      }

      return true;
    } catch (error) {
      console.log(error);

      return false;
    }
  }),

  setSignUpCreds: action((state, payload) => {
    state.signUpCreds = payload;
  }),
};

export default authStore;
