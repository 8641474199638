import * as React from "react";

import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

import styles from "./CredentialError.module.css";
import SkipButton from "../../SkipButton";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#ffffff",
    // width: "700px",
    padding: theme.spacing(4, 6, 7),
  },
}));

function CredentialError({ visibility, onOpenModal, onCloseModal }) {
  const classes = useStyles();

  return (
    <Modal className={`${classes.modal} outline-none`} open={visibility}>
      <div className={`${classes.paper}   ${styles.modalContainer}`}>
        <text className={styles.headerText}>
          <span className={styles.headerError}>Error:</span> Your username or
          password is incorrect.
          <p>Please try again.</p>
        </text>
        <SkipButton buttonText="Ok" onClick={onCloseModal} />
      </div>
    </Modal>
  );
}

CredentialError.propTypes = {
  visibility: PropTypes.bool.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default CredentialError;
