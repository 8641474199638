import React from "react";
import isEmpty from "lodash/isEmpty";

const TextInput = ({
  onChange,
  className,
  type = "text",
  placeholder = "",
  maxLength = 524288,
  textLabel = "",
  value = "",
  style = {},
  errors = {},
  name = "",
  errorStyle = "",
  required = false,
  ...rest
}) => {
  return (
    <div className={style}>
      {!required ? (
        <label>
          {textLabel} {rest.extra ? rest.extra : ""}
        </label>
      ) : (
        <label>
          {textLabel}{" "}
          <span>
            '<span className={`text-red-500 text-2xl`}>*</span>'
          </span>
        </label>
      )}
      <input
        className={`${className}`}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        name={name}
        {...rest}
      />
      {!isEmpty(errors) && errors[name] && (
        <span className={`text-red-400 text-base py-1 px-2`}>
          {errors[name]}
        </span>
      )}
    </div>
  );
};

export default TextInput;
