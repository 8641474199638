import React from "react";

const Button = ({ onClick, className, children, disable, ...rest }) => {
  return (
    <button
      disabled={disable}
      className={className}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button;
