import axios from "../../services/axios";
import {
  ServiceSpecificDocument,
  UploadedDocument,
  INqaDocuments,
  UpdateDocument,
  UpdateDocWebinarOrTm,
  UpdateDocPolicyOrProcedure,
} from "../../types/document/document";
import { AxiosStatic } from "axios";

let config = {
  headers: {
    Accept: "application/json",
  },
};

export async function documentUpdate(payload: UpdateDocument): Promise<any> {
  const response = await axios.put("/api/document-update", payload, config);
  return response;
}

export async function addServiceSpecificDocument(
  payload: ServiceSpecificDocument
): Promise<AxiosStatic> {
  return await axios.post("/api/save-document", payload, config);
}

export async function updateServiceSpecificDocument(
  payload: ServiceSpecificDocument
): Promise<ServiceSpecificDocument> {
  return await axios.put(
    `/api/service-document-update/${payload.id}`,
    payload,
    config
  );
}

export async function getServiceSpecificDocument(
  serviceId: Number
): Promise<any[]> {
  const { data, status } = await axios.post(
    `/api/get-service-specific-document`,
    serviceId,
    config
  );

  if (status == 200 || status == 201) return data;

  return [];
}

//Edit service
export async function deleteMultipleDocument(
  documentId: any
): Promise<boolean> {
  const response = await axios.delete(`/api/document-deletemultiple`, {
    data: { document_id: documentId },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function deleteWebinarFile(documentId: number): Promise<boolean> {
  const response = await axios.get(`/api/webinar-file/${documentId}/delete`);
  return response.status === 200 || response.status === 201;
}

//Documents tab

//Multiple delete

export async function documentSoftRemoveMultiple(
  documentIds: any
): Promise<boolean> {
  const response = await axios.delete(`/api/soft-delete-multiple-documents`, {
    data: { document_id: documentIds },
  });

  if (response.status == 200) return true;

  return false;
}

export async function documentUndoSoftRemoveMultiple(
  payload: any
): Promise<boolean> {
  const response = await axios.put(
    `/api/undo-delete-multiple-documents`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function documentPermanentRemoveMultiple(
  documentIds: any
): Promise<boolean> {
  const response = await axios.delete(
    `/api/permanent-delete-multiple-document`,
    { data: { document_id: documentIds } }
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

//Single delete
export async function deleteSingleDocument(documentId: any): Promise<boolean> {
  const response = await axios.delete(
    `/api/delete-document-file/${documentId}`
  );

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function documentAvailability(
  data: any,
  serviceId: number
): Promise<boolean> {
  let payload = {
    service_id: serviceId,
    id: data.id,
    availability: data.availability === "ENABLE" ? "DISABLE" : "ENABLE",
  };

  let { status } = await axios.put(
    `/api/document-availability`,
    payload,
    config
  );

  if (status == 200 || status == 201) return true;

  return false;
}

export async function getDocumentByServiceId(
  serviceId: Number
): Promise<INqaDocuments[]> {
  const { status, data } = await axios.get(
    `/api/document-by-service/${serviceId}`
  );

  if (status == 200 || status == 201) return data;

  return [];
}

export async function getAllDocument(): Promise<any[]> {
  const response = await axios.get(`/api/document-get-all`);

  let uploadedDocs: Array<any> = [];

  if (response.status == 200 || response.status == 201) {
    let { data } = response;

    return data;
  }

  return [];
}

export async function getSpecificDocument(
  documentId: Number
): Promise<ServiceSpecificDocument[]> {
  const response = await axios.get(`/api/document-get/${documentId}`);

  return documentsMapper(response);
}

export async function fetchDocuments(): Promise<ServiceSpecificDocument[]> {
  const response = await axios.get(`/api/document-get-all`);

  return documentsMapper(response);
}

const documentsMapper = (response: any) => {
  return response.data.map((document: any) => ({
    id: document.id,
    services: document.services,
    upload_or_generate: document.upload_or_generate,
    category: document.category,
    file_title: document.file_title,
    file_version: document.file_version,
    date: document.date,
    description: document.description,
    service_specific_type: document.service_specific_type,
    nqa_area_id: document.nqa_area_id,
    document_type_id: document.document_type_id,
    notify_service: document.notify_service,
    service_type_id: document.service_type_id,
    content: document.content,
  }));
};

export async function collectionLoad(payload: any): Promise<boolean> {
  const { status } = await axios.post(`/api/collection-load`, payload, config);

  if (status == 200 || status == 201) return true;

  return false;
}

export async function collectionUnload(payload: any): Promise<boolean> {
  const { status } = await axios.post(
    `/api/collection-unload`,
    payload,
    config
  );

  if (status == 200 || status == 201) return true;

  return false;
}

export async function getCollectionCount(serviceId: Number): Promise<any> {
  const { status, data } = await axios.get(
    `/api/collection-count/${serviceId}`
  );

  if (status == 200 || status == 201) {
    return data;
  }

  return null;
}

export async function documentAddCollection(payload: any): Promise<Boolean> {
  const response = await axios.put(
    `/api/document-addcollection`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function getCollection(serviceTypeId: Number): Promise<any[]> {
  const response = await axios.get(`/api/collections-get/${serviceTypeId}`);

  let uploadedDocs: Array<any> = [];

  if (response.status == 200 || response.status == 201) {
    let { data } = response;

    data.forEach((item: any) => {
      let docObject = {
        document_url: item.document_url,
        document_name: item.document_name,
        file_title: item.file_title,
        file_version: item.file_version,
        document_id: item.document_id,
        label: item.label,
        upload_or_generate: "Upload",
      };

      uploadedDocs.push(docObject);
    });
  }

  return uploadedDocs;
}

export async function filterDocumentByServices(payload: any): Promise<any[]> {
  let response = await axios.post(`/api/filter-documents-by-services`, payload);

  if (response.status == 200 || response.status == 201) {
    let { data } = response;

    return data;
  }

  return [];
}

export async function filterDocumentByCollection(payload: any): Promise<any[]> {
  let response = await axios.post(
    `/api/filter-documents-by-collection`,
    payload
  );

  if (response.status == 200 || response.status == 201) {
    let { data } = response;

    return data;
  }

  return [];
}

export async function filterDocumentByFileType(payload: any): Promise<any[]> {
  /**
   * file_type: [1,2,3,4,5,6],
   * 1- generated, 2 - word, 3 - image, 4- video(webinar), 5- excel, 6-pdf
   *
   */

  let response = await axios.get(`/api/filter-documents-by-filetype`, {
    params: { file_type: payload },
  });

  if (response.status == 200 || response.status == 201) {
    let { data } = response;

    return data;
  }

  return [];
}

// export async function removeFromCollection(payload: any): Promise<Boolean> {

//   const response = await axios.put(`/api/remove-from-a-collection`, payload, config);

//   if (response.status == 200 || response.status == 201) return true;

//   return false;
// }

export async function addFromACollection(payload: any): Promise<Boolean> {
  const response = await axios.put(
    `/api/add-from-a-collection`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function removeFromACollection(payload: any): Promise<Boolean> {
  const response = await axios.put(
    `/api/remove-from-a-collection`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function addDocumentToCollection(payload: any): Promise<Boolean> {
  const response = await axios.put(
    `/api/add-document-to-collection`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function confirmAddServiceToDocuments(
  payload: any
): Promise<Boolean> {
  const response = await axios.post(
    `/api/add-document-to-collection`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function confirmRemoveServiceToDocuments(
  payload: any
): Promise<Boolean> {
  const response = await axios.post(
    `/api/confirm-remove-services-documents`,
    payload,
    config
  );

  if (response.status == 200 || response.status == 201) return true;

  return false;
}

export async function documentDeleteFile(fileId: any): Promise<Boolean> {
  const { status, data } = await axios.delete(
    `/api/document-delete-file/${fileId}`
  );

  if (status == 200 || status == 201) return true;

  return false;
}

export async function saveDocumentWebinar(payload: any): Promise<AxiosStatic> {
  return await axios.post("/api/document-webinar-add", payload, config);
}

export async function updateDocumentWebinar(
  payload: any
): Promise<AxiosStatic> {
  return await axios.put("/api/document-update-webinar", payload, config);
}
export async function updateDocWebinarOrTm(
  payload: UpdateDocWebinarOrTm
): Promise<any> {
  const response = await axios
    .put("/api/document-update-webinar", payload, config)
    .catch(console.error);
  return response;
}

export async function updateDocPolicyOrProcedure(
  payload: UpdateDocPolicyOrProcedure
): Promise<any> {
  const response = await axios
    .put("/api/document-service-specific-update", payload, config)
    .catch(console.error);
  return response;
}

export const updateSpecificDoc = {
  updateDocWebinarOrTm,
  updateDocPolicyOrProcedure,
};

export const updateNqaDoc = {
  documentUpdate,
  updateDocWebinarOrTm,
};
