import React, { useState, useEffect } from "react";
import styles from "../CreateDocument.module.css";
import { isEmpty } from "lodash";
import { FaComment } from "react-icons/fa";

const SpecificServiceDocument = ({
  fieldsChecked = false,
  setServiceSpecificType,
  ServiceSpecificType,
  setNqaAreaID,
  NqaAreaID,
  setDocumentTypeID,
  DocumentTypeID,
}) => {
  useEffect(() => {
    setNqaAreaID([]);
    setDocumentTypeID(0);
  }, [ServiceSpecificType]);

  const onDocumentFilterChange = (e, serviceTypeValue) => {
    setServiceSpecificType(serviceTypeValue);
  };

  const SpecificServiceonClickNqaChange = (e, value) => {
    e.preventDefault();
    let nqa = NqaAreaID;
    const index = nqa.indexOf(value);

    if (index > -1) {
      nqa.splice(index, 1);
    } else {
      nqa.splice(nqa.length, 0, value);
    }

    setNqaAreaID([...NqaAreaID]);
  };

  const ServiceSpecificDocTypeChange = (e, dValue) => {
    setDocumentTypeID(dValue);
  };

  return (
    <>
      <div className={`${styles.nqaDocument}`}>
        <h2>Add new service-specific document</h2>

        <p>Create a document that is intended only for a single service.</p>

        <ul className={styles.specificDocumentFilter}>
          <li
            value="1"
            onClick={(e) => onDocumentFilterChange(e, 1)}
            className={ServiceSpecificType == 1 ? `${styles.active}` : null}
          >
            Reports
          </li>
          <li
            value="2"
            onClick={(e) => onDocumentFilterChange(e, 2)}
            className={ServiceSpecificType == 2 ? `${styles.active}` : null}
          >
            AP Reports
          </li>
          <li
            value="3"
            onClick={(e) => onDocumentFilterChange(e, 3)}
            className={ServiceSpecificType == 3 ? `${styles.active}` : null}
          >
            Mentor Report
          </li>
          <li
            value="6"
            onClick={(e) => onDocumentFilterChange(e, 6)}
            className={ServiceSpecificType == 6 ? `${styles.active}` : null}
          >
            FOM Report
          </li>
          <li
            value="4"
            onClick={(e) => onDocumentFilterChange(e, 4)}
            className={ServiceSpecificType == 4 ? `${styles.active}` : null}
          >
            Risk Assessment
          </li>
          <li
            value="5"
            onClick={(e) => onDocumentFilterChange(e, 5)}
            className={ServiceSpecificType == 5 ? `${styles.active}` : null}
          >
            NQA
          </li>
        </ul>
        {fieldsChecked && ServiceSpecificType === 0 && (
          <div className={`text-red-400 py-1 ml-2 `}>
            Please select document filter.
          </div>
        )}

        <div
          className={`${styles.chooseNQADocument2} mt-3`}
          style={
            ServiceSpecificType == 4
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <ul>
            <li
              value="2"
              onClick={(e) => SpecificServiceonClickNqaChange(e, 2)}
              className={NqaAreaID.indexOf(2) > -1 ? styles.active : null}
            >
              NQA2
            </li>
            <li
              value="3"
              onClick={(e) => SpecificServiceonClickNqaChange(e, 3)}
              className={NqaAreaID.indexOf(3) > -1 ? styles.active : null}
            >
              NQA3
            </li>
          </ul>
        </div>

        <div
          className={styles.chooseNQADocument}
          style={
            ServiceSpecificType == 5
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <ul>
            <li
              onClick={(e) => SpecificServiceonClickNqaChange(e, 1)}
              className={NqaAreaID.indexOf(1) > -1 ? styles.active : null}
            >
              NQA1
            </li>
            <li
              onClick={(e) => SpecificServiceonClickNqaChange(e, 2)}
              className={NqaAreaID.indexOf(2) > -1 ? styles.active : null}
            >
              NQA2
            </li>
            <li
              onClick={(e) => SpecificServiceonClickNqaChange(e, 3)}
              className={NqaAreaID.indexOf(3) > -1 ? styles.active : null}
            >
              NQA3
            </li>
            <li
              onClick={(e) => SpecificServiceonClickNqaChange(e, 4)}
              className={NqaAreaID.indexOf(4) > -1 ? styles.active : null}
            >
              NQA4
            </li>
            <li
              onClick={(e) => SpecificServiceonClickNqaChange(e, 5)}
              className={NqaAreaID.indexOf(5) > -1 ? styles.active : null}
            >
              NQA5
            </li>
            <li
              onClick={(e) => SpecificServiceonClickNqaChange(e, 6)}
              className={NqaAreaID.indexOf(6) > -1 ? styles.active : null}
            >
              NQA6
            </li>
            <li
              onClick={(e) => SpecificServiceonClickNqaChange(e, 7)}
              className={NqaAreaID.indexOf(7) > -1 ? styles.active : null}
            >
              NQA7
            </li>
          </ul>
          {fieldsChecked && isEmpty(NqaAreaID) && (
            <div className={`text-red-400 py-1 px-2`}>
              Please select one or more NQAs
            </div>
          )}
        </div>

        <div
          className={`${styles.chooseDocumentType}`}
          style={
            ServiceSpecificType == 5
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <span className={styles.label}>Choose document type</span>
          <ul>
            <li
              onClick={(e) => ServiceSpecificDocTypeChange(e, 1)}
              className={DocumentTypeID == 1 ? styles.active : null}
            >
              <svg
                fontSize="18"
                height="1em"
                width="1em"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="info-circle"
                className="svg-inline--fa fa-info-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                ></path>
              </svg>
              Policy
            </li>
            <li
              onClick={(e) => ServiceSpecificDocTypeChange(e, 2)}
              className={DocumentTypeID == 2 ? styles.active : null}
            >
              <svg
                fontSize="18"
                height="1em"
                width="1em"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="file-alt"
                className="svg-inline--fa fa-file-alt fa-w-12"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path
                  fill="currentColor"
                  d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                ></path>
              </svg>
              Procedure
            </li>
            <li
              onClick={(e) => ServiceSpecificDocTypeChange(e, 3)}
              className={DocumentTypeID == 3 ? styles.active : null}
            >
              <svg
                fontSize="18"
                height="1em"
                width="1em"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="play-circle"
                className="svg-inline--fa fa-play-circle fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
                ></path>
              </svg>
              Webinar
            </li>
            <li
              onClick={(e) => ServiceSpecificDocTypeChange(e, 4)}
              className={DocumentTypeID == 4 ? styles.active : null}
            >
              <svg
                fontSize="18"
                height="1em"
                width="1em"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.42188 5.48828L9.61328 9.17969C9.85938 9.28516 10.1055 9.28516 10.3516 9.17969L18.543 5.48828C19.1406 5.20703 19.1406 4.32812 18.543 4.04688L10.3516 0.355469C10.2461 0.285156 10.1055 0.285156 10 0.285156C9.85938 0.285156 9.71875 0.285156 9.61328 0.355469L1.42188 4.04688C0.824219 4.32812 0.824219 5.20703 1.42188 5.48828ZM18.543 8.58203L16.5039 7.63281L10.8086 10.2344C10.5625 10.3398 10.2812 10.4102 10 10.4102C9.68359 10.4102 9.40234 10.3398 9.15625 10.2344L3.46094 7.63281L1.42188 8.58203C0.824219 8.82812 0.824219 9.70703 1.42188 9.98828L9.61328 13.6797C9.85938 13.7852 10.1055 13.7852 10.3516 13.6797L18.543 9.98828C19.1406 9.70703 19.1406 8.82812 18.543 8.58203ZM18.543 13.082L16.5039 12.1328L10.8086 14.7344C10.5625 14.8398 10.2812 14.9102 10 14.9102C9.68359 14.9102 9.40234 14.8398 9.15625 14.7344L3.46094 12.1328L1.42188 13.082C0.824219 13.3281 0.824219 14.207 1.42188 14.4883L9.61328 18.1797C9.85938 18.2852 10.1055 18.2852 10.3516 18.1797L18.543 14.4883C19.1406 14.207 19.1406 13.3281 18.543 13.082Z"
                  fill="currentColor"
                />
              </svg>
              Training modules
            </li>
            <li
              onClick={(e) => ServiceSpecificDocTypeChange(e, 6)}
              className={DocumentTypeID === 6 ? styles.active : null}
            >
              <FaComment size={18} />
              Feedback
            </li>
            <li
              onClick={(e) => ServiceSpecificDocTypeChange(e, 7)}
              className={DocumentTypeID === 7 ? styles.active : null}
            >
              Miscellaneous
            </li>
          </ul>
          {fieldsChecked && DocumentTypeID === 0 && (
            <div className={`text-red-400 py-1 px-2`}>
              Please select document type
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SpecificServiceDocument;
