import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreateUser from "../../../pages/CreateUser/CreateUser";

export default function AddNewUserRoute() {
  const match = useRouteMatch();
 
  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <CreateUser />} />
    </Switch>
  );
}
