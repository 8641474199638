import React, { useState, useEffect } from "react";
import styles from "./RemoveFromServicesModal.module.css";

import {
	FaTimes,
	FaSearch,
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import {
	selectServicesDocuments,
} from '../../../../api/services/requests';

import Button from '../../../_base/button';

import LargeSpinner from '../../../spinner/LargeSpinner';
 
const RemoveFromServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	uploaded_documents,
	setHeaderActionsPayloadPayload,
	setServices,
	services,
	setShowRemoveFromServicesConfirmationModal
}) => {

	const [loading, setLoading] = useState(true);
	const [keyword, setKeyword] = useState('');
	const [backup, setBackUp] = useState([]);

	useEffect(() => {

		fetchService();

	}, [uploaded_documents]);


	const fetchService = async () => {

		let payload = {
			document_id: [],
			services_to_remove: [],
		}

		uploaded_documents.forEach(item => {

			if (item.selected) {

				if (payload.document_id.indexOf(item.id) == -1) {

					payload.document_id.push(item.id);

				}

				if (item.services.length > 0) {

					item.services.forEach(s => {

						if (payload.services_to_remove.indexOf(s.id) == -1) {

							payload.services_to_remove.push(s.id);

						}

					});

				}

			}

		});

		try {

			let response = await selectServicesDocuments(payload);

			let newDataArr = [];

			response.forEach(item => {
				newDataArr.push({
					document_count: item.document_count,
					id: item.id,
					service_name: item.service_name,
					selected: true
				});
			});

			setServices(newDataArr);

			setBackUp(newDataArr);

			setLoading(false);

			// setPayload(payload);

			setHeaderActionsPayloadPayload(payload);

		} catch (error) {

			setLoading(false);

		}
	}

	const addToRemoveServices = (serviceId, e) => {

		e.preventDefault();

		services.forEach(item => {

			if (item.id == serviceId) {

				item.selected = !item.selected;

			}

		});

		setServices([...services]);

	}

	const search = (e) => {

		e.preventDefault();

		if (keyword === "" || keyword == null) {

			setServices([...backup]);;

			return;
		}

		let searchResult = [];

		backup.forEach(s => {

			if (s.service_name != null && s.service_name != undefined && s.service_name != "") {

				let n = s.service_name.toLowerCase();
				let k = keyword.toLowerCase();

				if (n.includes(k)) {
					searchResult.push(s);
				}
			}
		});

		setServices([...searchResult]);

	}

	const mapServices = () => {
		return services.map((item, i) => {
			return (
				<li key={item.id}
					className={item.selected ? styles.selected : ""}
					onClick={(e) => addToRemoveServices(item.id, e)}
				>{item.service_name}
					<span>{`${item.document_count} docs`}<label className={`${styles.checkbox}`}>
						<input type="checkbox" /><span className={`${styles.checkmark}`}>
						</span>
					</label>
					</span>
				</li>
			);
		});
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Choose one or more file types</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.search}`}>
						<input
							type="text"
							value={keyword}
							name="search"
							id="s"
							placeholder="Type the service name" 
							onChange={(e) => setKeyword(e.target.value)}
							/>
						<button type="submit" 
							onClick={(e) => search(e)}
							className="flex items-center justify-center">
							<FaSearch size={17} />
						</button>
					</div>

					{
						loading && <div>
							<LargeSpinner />
						</div>
					}

					{
						!loading && services.length > 0 && <ul>
							{mapServices()}
						</ul>
					}

					{
						!loading && services.length == 0 &&
						<span
							className={`flex justify-center py-4 ml-2 text-2xl text-gray-400`}>No service available.</span>
					}

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => setShowRemoveFromServicesConfirmationModal(true)}
							className={`${styles.btnGreen}`}>UNLINK DOCUMENT
						</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default RemoveFromServicesModal;