import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { FaTimesCircle, FaSpinner } from "react-icons/fa";
import { Redirect, useLocation, useHistory } from "react-router-dom";
import UserLayout from "components/userLayout/UserLayout";
import SpecificServiceDocument from "components/services/SpecificServiceDocument";
import SpecificServiceUploadType from "pages/AddNewDocument/SpecificServiceDocument/SpecificDocumentUploadType";
import styles from "./EditServiceDocument.module.css";
import createDocStyles from "pages/AddNewDocument/CreateDocument.module.css";
import ServiceModal from "components/popups/createNewDocument/specificDocumentChooseServiceModal";
import NqaDocumentWebinarType from "pages/AddNewDocument/NQADocument/NqaDocumentWebinarType";
import { deleteWebinarFile, updateSpecificDoc } from "api/documents/requests";
import { sweetAlert } from "components/_base/alerts/sweetAlert";
import NqaDocumentGenerateType from "../AddNewDocument/NQADocument/NqaDocumentGenerateType";
import LinkOrDocument from "pages/AddNewDocument/LinkOrDocument";

const DOC_TYPE = {
  POLICY: 1,
  PROCEDURE: 2,
  WEBINAR: 3,
  TRAINING: 4,
  RISK: 5,
  FEEDBACK: 6,
  MISC: 7,
};

const TYPE = {
  RISK: 4,
  NQA: 5,
};

export default function EditServiceDocument() {
  const history = useHistory();
  const location = useLocation();
  const [serviceDoc, setServiceDoc] = useState(location.state?.doc);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [excludedServices, setExcludedServices] = useState([]);
  const [selectedServiceName, setSelectedServiceName] = useState("");
  const [showServicesModal, setServicesShowModal] = useState(false);
  const [fieldsChecked, setFieldsChecked] = useState(false);
  const [saving, setSaving] = useState(false);
  const [nqaServices, setNqaServices] = useState([]);
  const [originalDocRemoved, setOriginalDocRemoved] = useState(false);
  const isTypeMedia =
    serviceDoc.document_type_id === DOC_TYPE.WEBINAR ||
    serviceDoc.document_type_id === DOC_TYPE.TRAINING;

  const [generateFiles, setGenerateFiles] = useState([]);
  const [editorValue, setEditorValue] = useState("");

  const [isExternalLink, setIsExternalLink] = useState(true);

  useEffect(() => {
    !serviceDoc.fileUrl?.length && setOriginalDocRemoved(true);
    if (serviceDoc.file_uploaded_id) {
      setUploadFiles({
        name: serviceDoc.label,
        type: serviceDoc.fileType,
        size: serviceDoc.fileSize,
        base64: serviceDoc.file,
      });
    } else {
      setOriginalDocRemoved(true);
    }
    setSelectedServiceName(serviceDoc.services?.[0]?.name);
    setSelectedServices([serviceDoc.services?.[0]?.id]);

    const { webinar_link, webinar_title, webinar_description } =
      serviceDoc.raw_uploaded_file;
    setServiceDoc({
      ...location.state?.doc,
      webinar_link,
      webinar_title,
      webinar_description,
    });

    setGenerateFiles([
      {
        name: serviceDoc.fileName,
        type: serviceDoc.fileType,
        size: serviceDoc.fileSize,
        url: serviceDoc.fileUrl,
      },
    ]);

    setIsExternalLink(!!webinar_link);
  }, []);

  if (!location.state?.doc) {
    return <Redirect to="/nqa-documents" />;
  }

  const onSaveChanges = async () => {
    const apiMethod = isTypeMedia
      ? "updateDocWebinarOrTm"
      : "updateDocPolicyOrProcedure";
    if (saving) return;
    setSaving(true);

    const { service_specific_type } = serviceDoc;

    const commonFields = {
      id: serviceDoc.raw_uploaded_file.document_id,
      category: serviceDoc.category,
      service_specific_type,
      file_title: serviceDoc.file_title,
      date: serviceDoc.date,
      services: selectedServices,
      document_type_id:
        service_specific_type === TYPE.NQA ? serviceDoc.document_type_id : null,
      ...(service_specific_type === TYPE.NQA ||
      service_specific_type === TYPE.RISK
        ? { nqa_area_id: serviceDoc.nqa_area_id }
        : { nqa_area_id: [] }),
    };

    const payloadDoc = {
      ...commonFields,
      file_version: serviceDoc.file_version,
      content: serviceDoc.content,
      description: serviceDoc.description,
      ...(serviceDoc.upload_or_generate === "Generate"
        ? { upload_or_generate: 2 }
        : {}),
      ...(originalDocRemoved
        ? {
            attachments: [
              {
                base64_image: uploadFiles.base64,
                file_name: uploadFiles.name,
              },
            ],
          }
        : {}),
    };

    const payloadWebinarOrTm = {
      ...commonFields,
      ...(originalDocRemoved
        ? {
            base64_image: uploadFiles.base64 || "",
            label: uploadFiles.name || "",
          }
        : {}),
      service_type_id: selectedServices,
      notify_service: serviceDoc.notify_service,
      webinar_link: serviceDoc.webinar_link,
      webinar_title: serviceDoc.webinar_title,
      webinar_description: serviceDoc.webinar_description,
    };

    const { status, data } = await updateSpecificDoc[apiMethod](
      isTypeMedia ? payloadWebinarOrTm : payloadDoc
    );

    setSaving(false);

    if (status === 200) {
      serviceDoc.upload_or_generate === "Generate" &&
        setGenerateFiles([
          {
            name: data?.attachments?.document_name,
            type: data?.attachments?.file_type,
            size: data?.attachments?.file_size,
            url: data?.attachments?.document_url,
          },
        ]);
      sweetAlert("success", "Document updated succesfully");
    } else {
      sweetAlert("error", "Error on updating");
    }
  };

  return (
    <UserLayout headerText={`Edit a Service-specific Document`}>
      <div
        className={`${createDocStyles.addServiceDocu} ${styles.serviceDocumentContent}`}
      >
        <SpecificServiceDocument
          hideSubheader
          header="Edit a Service-specific document"
          setSpecificDocState={setServiceDoc}
          specificDocState={serviceDoc}
        />
        {isTypeMedia ? (
          <div className={`${createDocStyles.addServiceDocu} mt-10`}>
            <div className={`${createDocStyles.inputContainer}`}>
              {serviceDoc.document_type_id === DOC_TYPE.WEBINAR ? (
                <NqaDocumentWebinarType
                  hideServiceSelector
                  fieldsChecked={fieldsChecked}
                  SelectedService={setExcludedServices}
                  docId={serviceDoc.document_type_id}
                  UploadFiles={setUploadFiles}
                  UploadInputs={(data) =>
                    setServiceDoc({
                      ...serviceDoc,
                      webinar_link: data.webinar_link,
                      webinar_title: data.webinar_title,
                      date: data.date,
                      webinar_description: data.webinar_description,
                      file_title: data.webinar_file_title,
                    })
                  }
                  serviceTypeID={serviceDoc.service_type_id}
                  setNqaServices={setNqaServices}
                  fillInputs={serviceDoc}
                  fillUploadFile={uploadFiles}
                  onRemoveDoc={() => {
                    deleteWebinarFile(serviceDoc.raw_uploaded_file.document_id);
                    setOriginalDocRemoved(true);
                  }}
                />
              ) : (
                <div className="px-10 mt-10">
                  <LinkOrDocument
                    isExternalLink={isExternalLink}
                    setIsExternalLink={setIsExternalLink}
                    formInputs={serviceDoc}
                    setFormInputs={(data) =>
                      setServiceDoc({
                        ...serviceDoc,
                        webinar_link: data.webinar_link,
                        webinar_title: data.webinar_title,
                        date: data.date,
                        webinar_description: data.webinar_description,
                        file_title: data.webinar_file_title || data.name,
                      })
                    }
                    file={uploadFiles}
                    onFileChange={(data) => {
                      if (data === undefined) {
                        setOriginalDocRemoved(true);
                      }
                      const uploaded = data
                        ? {
                            ...uploadFiles,
                            ...data,
                            label: data.name,
                          }
                        : {};
                      setUploadFiles(uploaded);
                    }}
                    showValidation={fieldsChecked}
                  />
                </div>
              )}
            </div>
          </div>
        ) : serviceDoc.upload_or_generate === "Upload" ? (
          <div className={`${createDocStyles.inputContainer}`}>
            <SpecificServiceUploadType
              setUploadFiles={setUploadFiles}
              uploadFiles={uploadFiles}
              setUploadInputs={setServiceDoc}
              uploadInputs={serviceDoc}
              fillInputs
              onRemoveDoc={() => setOriginalDocRemoved(true)}
            />
          </div>
        ) : (
          <div
            className={createDocStyles.addServiceDocu}
            style={{ marginTop: "30px" }}
          >
            <div
              className={`${createDocStyles.inputContainer} ${createDocStyles.generateFileTabContent}`}
              style={{ display: "block" }}
            >
              <NqaDocumentGenerateType
                editData={{
                  generateFiles,
                  generateInputs: serviceDoc,
                  docState: serviceDoc,
                }}
                GenerateFiles={setGenerateFiles}
                GenerateInputs={setServiceDoc}
                EditorValue={(content) =>
                  setServiceDoc((cur) => ({ ...cur, content }))
                }
                setNqaServices={setNqaServices}
                serviceTypeID={serviceDoc.service_type_id}
                globalWebinar={location.state.doc.global_webinar}
                hideServiceSelector
              />
            </div>
          </div>
        )}
        <div
          className={`${createDocStyles.chooseServices} ${createDocStyles.fileOriginGenerate}`}
        >
          <p>Choose one service to attach this document</p>
          <ul className={createDocStyles.selectedServices}>
            {selectedServices.length > 0 && (
              <li onClick={() => setSelectedServices([])}>
                {selectedServiceName}
                <FaTimesCircle size={19} />
              </li>
            )}
          </ul>
          <div className={createDocStyles.inputActionButton}>
            <button
              onClick={() => setServicesShowModal(true)}
              className={`${createDocStyles.chooseServicesBtn} ${createDocStyles.blueButton}`}
            >
              CHOOSE SERVICE
            </button>
            {fieldsChecked && isEmpty(selectedServices) && (
              <div className="text-red-400">
                Please select a service to attach this document
              </div>
            )}
            <ServiceModal
              setServicesShowModal={setServicesShowModal}
              showServicesModal={showServicesModal}
              setSelectedServicesSpecific={setSelectedServices}
              setGetName={setSelectedServiceName}
              preselectService={selectedServices[0]}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-wrap max-w-6xl justify-center m-auto">
        {uploadFiles.size || isTypeMedia ? (
          <div className={`mx-3 lg:flex-1 ${createDocStyles.btnSave}`}>
            <button
              className={createDocStyles.uploadFileTabContent}
              onClick={onSaveChanges}
              disabled={saving}
            >
              {saving ? (
                <div className="flex justify-center items-center">
                  <FaSpinner className="animate-spin" />
                  <span className="ml-2"> Please wait...</span>
                </div>
              ) : (
                <> SAVE ALL CHANGES </>
              )}
            </button>
          </div>
        ) : (
          <div className={`mx-3 lg:flex-1 ${styles.btnInvalid}`}>
            <button>CAN'T SAVE YET. UPLOAD A FILE FIRST</button>
          </div>
        )}
        <div className={`mx-3 lg:flex-1 ${styles.btnExit}`}>
          <button
            onClick={history.goBack}
            className={styles.uploadFileTabContent}
          >
            EXIT WITHOUT SAVING
          </button>
        </div>
      </div>
    </UserLayout>
  );
}
