import React from "react";
import PropTypes from "prop-types";

import styles from "./UserConfirmationModal.module.css";
 
import { Button as Btn, Modal } from "semantic-ui-react";
import {
	FaTimes
} from "react-icons/fa";

const UserConfirmationModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	message = "Are you sure you want to permanently delete this user?",
	onConfirm,
	OkButtonText = "REMOVE"
}) => {
	return (
		<Modal
			size="tiny"
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>
			<Modal.Content>
				<div className={`${styles.modalContainer}`}>

					<button className={`${styles.closeModal}`}
						onClick={onCloseModal}
					>
						<FaTimes className={`ml-2`} size={16} />
					</button>

					<div className={`${styles.modalTitle}`}>
						<h1>{message}</h1>
					</div>

					<div className={`${styles.modalContent}`}>

						<div className={`${styles.actionBtn}`}>
							<Btn
								onClick={onConfirm}
								color='olive'>{OkButtonText}</Btn>
							<Btn
								onClick={onCloseModal}
								color='red'>CANCEL</Btn>
						</div>

					</div>

				</div>
			</Modal.Content>

		</Modal>
	);
}

UserConfirmationModal.propTypes = {
	visibility: PropTypes.bool.isRequired,
	// onOpenModal: PropTypes.func.isRequired,
	onCloseModal: PropTypes.func.isRequired,
	// onConfirmRemoveUser: PropTypes.func.isRequired,
};

export default UserConfirmationModal;