import React, { useState, useEffect } from "react";
import styles from "./RemoveFileFromACollectionModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import LongDayCare from '../../../_base/svg/LongDayCare';
import FamilyDayCare from '../../../_base/svg/FamilyDayCare';
import OOSH from '../../../_base/svg/OOSH';
import PreSchool from '../../../_base/svg/PreSchool';

import {
	removeFromACollection
} from '../../../../api/documents/requests';

import { sweetAlert } from "../../../_base/alerts/sweetAlert";

const RemoveFileFromACollectionModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	document,
	fetchAllDocs,
	setSelectedDoc
}) => {

	const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {

		console.log(document);

		if (document.service_type_id) {

			let idArr = [];

			if (typeof (document.service_type_id) == "string") {

				let arr = JSON.parse(document.service_type_id);

				if (arr.length == 0) return;

				arr.forEach((item, i) => {

					idArr.push(item.id);

				});

			} else {

				if (document.service_type_id.length > 0) {

					document.service_type_id.forEach((item, i) => {

						idArr.push(item.id);

					});
				}
			}

			setSelectedServiceTypes(idArr);

		}
	}, []);

	const addToSelectedCollection = (id, e) => {

		e.preventDefault();

		let newArr = [...selectedServiceTypes];

		const index = newArr.indexOf(id);

		if (index > -1) {

			newArr.splice(index, 1)

		} else {

			newArr = [...newArr, id];
		}

		setSelectedServiceTypes(newArr);
	}

	const onRemoveFromACollection = async (e) => {

		e.preventDefault();

		setLoading(true);

		try {
			let payload = {
				document_id: document.id,
				service_type_id: selectedServiceTypes
			}

			const response = await removeFromACollection(payload);

			if (response) {
				onCloseModal();
				sweetAlert("success", "Document successfully remove from a collection.");
				setSelectedDoc(null);
				fetchAllDocs();
			}

			setLoading(false);

		} catch (error) {
			setLoading(false);
			onCloseModal();
			console.log(error);
		}
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Remove from a collection</h1>
					<p>Choose which collections this documents should be out.</p>
				</div>

				<div className={`${styles.modalContent}`}>

					<ul>
						<li className={selectedServiceTypes.indexOf(1) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(1, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<LongDayCare />
								<span>Long daycare collection</span>
							</div>
							<label className={styles.checkbox}><input type="checkbox" /><span className={styles.checkmark}></span></label>
						</li>
						<li className={selectedServiceTypes.indexOf(2) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(2, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<FamilyDayCare />
								<span>Family Daycare collection</span>
							</div>
							<label className={styles.checkbox}><input type="checkbox" /><span className={styles.checkmark}></span></label>
						</li>
						<li className={selectedServiceTypes.indexOf(3) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(3, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<PreSchool />
								<span>Pre-school collection</span>
							</div>
							<label className={styles.checkbox}><input type="checkbox" /><span className={styles.checkmark}></span></label>
						</li>
						<li className={selectedServiceTypes.indexOf(4) > -1 ? styles.active : ""}
							onClick={(e) => addToSelectedCollection(4, e)}
						>
							<div className={`${styles.collectionInfo}`}>
								<OOSH />
								<span>OOSH collection</span>
							</div>
							<label className={styles.checkbox}><input type="checkbox" /><span className={styles.checkmark}></span></label>
						</li>
					</ul>
					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => onRemoveFromACollection(e)}
							className={`${styles.btnRed}`}>REMOVE FROM COLLECTION/S
						</Button>
					</div>

				</div>

			</div>


		</Modal>
	)
}

export default RemoveFileFromACollectionModal;