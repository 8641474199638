 
export const requiredValidation = (specificDocState, setErrors) => {

    let err = {
        file_title: [],
        file_version: [],
        date: [],
        webinar_link: [],
        webinar_title: [],
        service_specific_type: [],
        document_type: [],
        nqa_area_id: [],
        attachments: {}
    };

    if(!specificDocState.id && specificDocState.attachments.length < 1)
    {
        err.attachments = ["Please select file."];
    }

    if (!specificDocState.service_specific_type) {
        err.service_specific_type = ["Please select service specific type!"];
    }

    if ((specificDocState.service_specific_type >= 4) && specificDocState.nqa_area_id.length < 1) {
        err.nqa_area_id = ["Please select NQA!"];
    }

    if (specificDocState.service_specific_type == 5 && (specificDocState.document_type_id < 1 || isNaN(specificDocState.document_type_id))) {
        err.document_type = ["Please select document type!"];
    }

    if (!specificDocState.service_specific_type) {
        err.file_version = ["Please select service specific type!"];
    }

    if ((!specificDocState.file_title || /^\s*$/.test(specificDocState.file_title)) && specificDocState.document_type_id !== 4) {
        err.file_title = ["This field is required!"];
    }

    if ((!specificDocState.file_version && specificDocState.document_type_id <= 2) && /^\s*$/.test(specificDocState.file_version)) {
        err.file_version = ["This field is required!"];
    }

    if (!specificDocState.date || /^\s*$/.test(specificDocState.date)) {
        err.date = ["This field is required!"];
    }

    if (specificDocState.service_specific_type == 5
        && (specificDocState.document_type_id >= 3)
        && /^\s*$/.test(specificDocState.link)
        && specificDocState.upload_or_generate == 1) {
        err.webinar_link = ["This field is required!"];
    }

    if (specificDocState.service_specific_type == 5
        && (specificDocState.document_type_id >= 3)
        && /^\s*$/.test(specificDocState.webinar_title)
        && specificDocState.upload_or_generate == 1) {
        err.webinar_title = ["This field is required!"];
    }

    if (err.file_title.length > 0 || err.file_version.length > 0 || err.date.length > 0) {

        setErrors(err);

        return true;

    }

    return false;

}

