import axios from "../../services/axios";
import { User, UserAccess } from "../../types/user/user";

import { AxiosStatic } from "axios";

let config = {
  headers: {
    Authorization:
      "Bearer " +
      "8bbd5442978e4836a56fa5c0e2f49a7a66f08d71106ad0495ea72ad6cfb93b1dcdefb818164a2a22",
    Accept: "application/json",
  },
};

export async function approveAccess(payload: UserAccess): Promise<AxiosStatic> {
  return await axios.post("/api/access-approve", payload, config);
}

export async function createUser(payload: User): Promise<AxiosStatic> {
  return await axios.post("/api/create-user", payload, config);
}

export async function updateUser(payload: User): Promise<User> {
  return await axios.post("/api/update-user", payload, config);
}

export async function deleteUser(userId: number): Promise<boolean> {
  const response = await axios.delete(`/api/delete-user/`, {
    params: {
      userId: userId,
    },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function fetchUsers(payload?: any): Promise<User[]> {
  const response = await axios.get(`/api/fetch-users/${payload}`);

  return userMapper(response);
}

const userMapper = (response: any) => {
  return response.data.map((user: any) => ({
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    description: user.description,
    user_type: user.roles[0].name,
    notify_email: user.notify_email === 0 ? false : true,
  }));
};

export async function deleteUsers(users: Array<Number>): Promise<boolean> {
  const response = await axios.delete(`/api/delete-users/`, {
    params: {
      users: users,
    },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function usersAccessApprove(
  users: Array<Number>
): Promise<boolean> {
  const response = await axios.delete(`/api/users-access-approve/`, {
    params: {
      users: users,
    },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}

export async function usersAccessRevoke(
  users: Array<Number>
): Promise<boolean> {
  const response = await axios.delete(`/api/users-access-revoke/`, {
    params: {
      users: users,
    },
  });

  if (response.status === 200 || response.status === 201) return true;

  return false;
}
