import React, { useState, useRef, useEffect, useCallback } from "react";
import styles from "./AddNewService.module.css";

import {
    FaChevronUp,
    FaChevronDown
} from "react-icons/fa";

import {
    getCollectionCount,
    collectionLoad
} from '../../api/documents/requests';

import LongDayCare from '../_base/svg/LongDayCare';
import FamilyDayCare from '../_base/svg/FamilyDayCare';
import OOSH from '../_base/svg/OOSH';
import PreSchool from '../_base/svg/PreSchool';

import DocumentListModal from '../popups/documents/DocumentListModal';
import UnloadConfirmationModal from '../popups/documents/unloadCollectionConfirmation';

import LoaderModal from '../popups/services/loadingPopup';

import { SERVICE_TYPE } from '../../helpers/document/constant'

const PreDefinedDocumentsCollection = ({ serviceId, collectionLoaded, refreshList }) => {

    const [collectionChevron, setCollectionChevron] = useState(true);
    const [loading, setLoading] = useState(true);

    const [serviceTypeId, setServiceTypeId] = useState(null);
    const [showDocumentListModal, setShowDocumentListModal] = useState(false);
    const [serviceName, setServiceName] = useState("Pre-school");
    const [showLoader, setShowLoader] = useState(false);
    const [selectedDocumentCollection, setSelectedDocumentSelection] = useState(null);
    const [docCounter, setDocCounter] = useState(0);
    const [loaded, setLoaded] = useState(collectionLoaded || []);
    const [showUnloadModal, setShowUnloadModal] = useState(false);

    const [count, setDocCount] = useState({});

    const mountedRef = useRef(true);
 
    useEffect(() => {

        let isMounted = true;  

        getCollectionCount(serviceId).then(data => {

            if (isMounted)
            {
                setDocCount(data);
                
                setLoading(false);
            }
        });

        return () => { isMounted = false };

    }, []);

    const loadDocuments = async (id) => {

        setDocumentCount(id);

        setShowLoader(true);


        try {

            let response = await collectionLoad({ service_type_id: [id], service_id: serviceId });

            if (response) {

                setLoaded([...loaded, id]);

                refreshList();

            }

            setShowLoader(false);

        } catch (error) {

            console.log(error.response.data)

            setShowLoader(false);

        }

    }

    const unLoadDocuments = (id) => {

        setShowUnloadModal(true);

        setServiceTypeId([id])
    }

    const setDocumentCount = (serviceTypeId) => {

        if (serviceTypeId == SERVICE_TYPE.LONG_DAYCARE) 
        {
            setDocCounter(count.long_day_care);
        } 
        else if (serviceTypeId == SERVICE_TYPE.FAMILY_DAYCARE)
        {
            setDocCounter(count.family_day_care);
        } 
        else if (serviceTypeId == SERVICE_TYPE.PRE_SCHOOL)
        {
            setDocCounter(count.pre_school);
        } 
        else 
        {
            setDocCounter(count.oosh);
        }
    }

 
    return (
        <div className={`${styles.preDefinedDocuCollections} ${styles.addNewService}`}>

            {
                showDocumentListModal && <DocumentListModal
                    serviceTypeId={serviceTypeId}
                    visibility={showDocumentListModal}
                    onOpenModal={() => setShowDocumentListModal(true)}
                    onCloseModal={() => setShowDocumentListModal(false)}
                    serviceName={serviceName}
                />
            }

            {
                showUnloadModal && <UnloadConfirmationModal
                    visibility={showUnloadModal}
                    onOpenModal={() => setShowUnloadModal(true)}
                    onCloseModal={() => setShowUnloadModal(false)}
                    serviceTypeId={serviceTypeId}
                    serviceId={serviceId}
                    setLoaded={setLoaded}
                    loaded={loaded}
                    refreshList={refreshList}
                />
            }

            {
                showLoader &&
                <LoaderModal
                    visibility={showLoader}
                    count={docCounter}
                />
            }

            <div
                onClick={(e) => setCollectionChevron(!collectionChevron)}
                className={styles.accordionHeader}>

                <h2>
                    <span className={`${styles.accordionArrow} ${styles.arrowUp}`}>
                        {
                            collectionChevron ? <FaChevronUp size={20} /> :
                                <FaChevronDown size={20} />
                        }
                    </span>
                    Load pre-defined document collections
                </h2>

            </div>

            <div className={`${styles.accordionContent} ${collectionChevron ? "h-0" : "h-auto"}`}>

                <p>Please check if you selected the proper service type. Here, you can load all default NQA documentation with just one click.</p>

                <ul className={styles.fourColumns}>
                    <li className={`${loaded.includes(SERVICE_TYPE.LONG_DAYCARE) ? styles.active : ""}`}>
                        <div className={styles.collectionIcon}>
                            <LongDayCare />
                        </div>
                        <span className={styles.collectionName}>Long daycare collection</span>
                        <span className={styles.collectionDocu}>{!loading ? `${count.long_day_care} documents` : `Loading...`}</span>
                        <span className={styles.collectionDesc}>A pre-defined collection covering all neccessary NQA documentation for Long daycare centers.</span>
                        <div className={styles.collectionButton}>
                            <button
                                disabled={count.long_day_care == 0}
                                className={`${loaded.includes(SERVICE_TYPE.LONG_DAYCARE) ? styles.unLoadDocs : count.long_day_care == 0 ? styles.loadView : styles.loadDocs}`}
                                onClick={() => loaded.includes(SERVICE_TYPE.LONG_DAYCARE) ?
                                    unLoadDocuments(SERVICE_TYPE.LONG_DAYCARE) :
                                    loadDocuments(SERVICE_TYPE.LONG_DAYCARE)}
                            >{loaded.includes(SERVICE_TYPE.LONG_DAYCARE) ? "UNLOAD DOCS" : "LOAD DOCS"}</button>
                            <button className={`${styles.loadView} ml-1`}
                                onClick={() => {
                                    setShowDocumentListModal(true)
                                    setServiceTypeId(SERVICE_TYPE.LONG_DAYCARE)
                                    setServiceName("Long daycare collection")
                                }}
                            >VIEW</button>
                        </div>
                    </li>

                    <li className={`${loaded.includes(SERVICE_TYPE.FAMILY_DAYCARE) ? styles.active : ""}`} >
                        <div className={styles.collectionIcon}>
                            <FamilyDayCare />
                        </div>
                        <span className={styles.collectionName}>Family daycare collection</span>
                        <span className={styles.collectionDocu}>{!loading ? `${count.family_day_care} documents` : "Loading..."}</span>
                        <span className={styles.collectionDesc}>A pre-defined collection covering all neccessary NQA documentation for Long daycare centers.</span>
                        <div className={styles.collectionButton}>
                            <button
                                disabled={count.family_day_care == 0}
                                className={`${loaded.includes(SERVICE_TYPE.FAMILY_DAYCARE) ? styles.unLoadDocs : count.family_day_care == 0 ? styles.loadView : styles.loadDocs} mr-1`}
                                onClick={() => loaded.includes(SERVICE_TYPE.FAMILY_DAYCARE) ?
                                    unLoadDocuments(SERVICE_TYPE.FAMILY_DAYCARE) :
                                    loadDocuments(SERVICE_TYPE.FAMILY_DAYCARE)}
                            >{loaded.includes(SERVICE_TYPE.FAMILY_DAYCARE) ? "UNLOAD DOCS" : "LOAD DOCS"}</button>
                            <button className={styles.loadView}
                                onClick={() => {
                                    setShowDocumentListModal(true)
                                    setServiceTypeId(SERVICE_TYPE.FAMILY_DAYCARE)
                                    setServiceName("Family daycare collection")
                                }}
                            >VIEW</button>
                        </div>
                    </li>

                    <li className={`${loaded.includes(SERVICE_TYPE.PRE_SCHOOL) ? styles.active : ""}`} >
                        <div className={styles.collectionIcon}>
                            <PreSchool />
                        </div>
                        <span className={styles.collectionName}>Pre-school collection</span>
                        <span className={styles.collectionDocu}>{!loading ? `${count.pre_school} documents` : "Loading..."}</span>
                        <span className={styles.collectionDesc}>A pre-defined collection covering all neccessary NQA documentation for Long daycare centers.</span>
                        <div className={styles.collectionButton}>
                            <button
                                disabled={count.pre_school == 0}
                                className={`${loaded.includes(SERVICE_TYPE.PRE_SCHOOL) ? styles.unLoadDocs : count.pre_school == 0 ? styles.loadView : styles.loadDocs} mr-1`}
                                onClick={() => loaded.includes(SERVICE_TYPE.PRE_SCHOOL) ?
                                    unLoadDocuments(SERVICE_TYPE.PRE_SCHOOL) :
                                    loadDocuments(SERVICE_TYPE.PRE_SCHOOL)}
                            >{loaded.includes(SERVICE_TYPE.PRE_SCHOOL) ? "UNLOAD DOCS" : "LOAD DOCS"}</button>
                            <button className={styles.loadView}
                                onClick={() => {
                                    setShowDocumentListModal(true)
                                    setServiceTypeId(SERVICE_TYPE.PRE_SCHOOL)
                                    setServiceName("Pre-school collection")
                                }}
                            >VIEW</button>
                        </div>
                    </li>
                    <li className={`${loaded.includes(SERVICE_TYPE.OOSH) ? styles.active : ""}`} >
                        <div className={styles.collectionIcon}>
                            <OOSH />
                        </div>
                        <span className={styles.collectionName}>OOSH collection</span>
                        <span className={styles.collectionDocu}>{!loading ? `${count.oosh} documents` : "Loading..."}</span>
                        <span className={styles.collectionDesc}>A pre-defined collection covering all neccessary NQA documentation for Long daycare centers.</span>
                        <div className={styles.collectionButton}>
                            <button
                                disabled={count.oosh == 0}
                                className={`${loaded.includes(SERVICE_TYPE.OOSH) ? styles.unLoadDocs : count.oosh == 0 ? styles.loadView : styles.loadDocs} mr-1`}
                                onClick={() => loaded.includes(SERVICE_TYPE.OOSH) ?
                                    unLoadDocuments(SERVICE_TYPE.OOSH) :
                                    loadDocuments(SERVICE_TYPE.OOSH)}
                            >{loaded.includes(SERVICE_TYPE.OOSH) ? "UNLOAD DOCS" : "LOAD DOCS"}</button>
                            <button className={styles.loadView}
                                onClick={() => {
                                    setShowDocumentListModal(true)
                                    setServiceTypeId(SERVICE_TYPE.OOSH)
                                    setServiceName("OOSH collection")
                                }}
                            >VIEW</button>
                        </div>
                    </li>
                </ul>

            </div>

        </div>
    );
}

export default PreDefinedDocumentsCollection;








