import { ICenterUser } from "types";

export const setUserToken = (token: string, callback?: any) => {
  localStorage.setItem("portalAdminToken", token);
  callback();
};

export const isUserLoggedIn = () => localStorage.getItem("portalAdminToken");

export const getUserLoggedIn = (): ICenterUser => {
  const currentAuthUser = localStorage.getItem("authUser") || "{}";
  return JSON.parse(currentAuthUser);
};

export const setUserLoggedIn = (user: ICenterUser) => {
  localStorage.setItem("authUser", JSON.stringify(user));
};

export const userLogout = (callback?: any) => {
  localStorage.removeItem("portalAdminToken");

  callback();
};
