import React from "react";
import styles from "./UnloadConfirmationModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import { sweetAlert } from '../../../_base/alerts/sweetAlert';

import { collectionUnload } from '../../../../api/documents/requests';

const UnloadConfirmationModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	serviceTypeId,
	serviceId,
	setLoaded,
	loaded,
	refreshList
}) => {

	const onUnloadCollection = async (e) => {

		e.preventDefault();

		try {

			let success = await collectionUnload({ service_type_id: serviceTypeId, service_id: serviceId });

			if (success) {

				let serviceIds = loaded;

				setLoaded(serviceIds.filter(d => d != serviceTypeId));

				refreshList();

				sweetAlert("success", "Collection successfully unloaded");

				onCloseModal();

			}

		} catch (error) {

		}
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Are you sure you want to unload collection from this service?</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>

						<Button
							onClick={(e) => onUnloadCollection(e)}
							className={`${styles.btnGreen}`}>UNLOAD
						</Button>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnRed}`}>
							CANCEL
						</Button>

					</div>

				</div>

			</div>


		</Modal>
	)
}

export default UnloadConfirmationModal;