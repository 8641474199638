
import React from "react";
import styles from "./DeleteServicesConfirmationModal.module.css";
import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

const DeleteServicesConfirmationModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	onRemove,
	itemCount
}) => {
	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>
			<div className={styles.modalContainer}>

				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`Are you sure you want to permanently delete ${itemCount} selected services?`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>

						<Button
							onClick={onRemove}
							className={styles.btnGreen}>REMOVE</Button>
						<Button
							onClick={onCloseModal}
							className={styles.btnRed}>CANCEL</Button>
					</div>

				</div>
			</div>
		</Modal>
	)
}
export default DeleteServicesConfirmationModal;
