import React from "react";
import styles from "./DeleteMultipleDocumentConfirmationModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';
import { useHistory } from "react-router-dom";

const DeleteMultipleDocumentConfirmationModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	multipleDelete,
	count
}) => {

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`Are you sure you want to permanently delete ${count} ${count > 1 ? "documents" : "document"}?`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => multipleDelete(e)}
							className={`${styles.btnGreen} mr-2.5`}>
							DELETE
						</Button>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnRed}`}>
							CANCEL
						</Button>

					</div>

				</div>

			</div>


		</Modal>
	)
}

export default DeleteMultipleDocumentConfirmationModal;