import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Dashboard from "../../pages/Dashboard/Dashboard";

export default function AdminDashboard() {
  const match = useRouteMatch();
 
  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <Dashboard />} />
    </Switch>
  );
}
