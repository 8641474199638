import React, {useState } from "react";
import styles from "./DeleteMultipleDocumentUndoSuccessModal.module.css";
import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

const DeleteMultipleDocumentUndoSuccessModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	count
}) => {

	const [oldCount, setCount] = useState(count);

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<Button
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>{`${oldCount} documents retrieved!`}</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnOk}`}>OK</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default DeleteMultipleDocumentUndoSuccessModal;