import React, { useEffect } from "react";
import styles from "./DeleteDocumentConfirmationModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';
 
const DeleteDocumentConfirmationModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	setShowOKUndoDeleteModal
}) => {

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Are you sure you want to permanently delete this document for everyone?</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => setShowOKUndoDeleteModal()}
							className={`${styles.btnGreen}`}>
							DELETE
						</Button>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnRed}`}>
							CANCEL
						</Button>

					</div>

				</div>

			</div>


		</Modal>
	)
}

export default DeleteDocumentConfirmationModal;