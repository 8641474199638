import { FaDownload, FaEdit, FaTimesCircle } from "react-icons/fa";
import styles from "./Newsletter.module.css";

type CogOptionsProps = {
  onEdit: () => void;
  onDelete: () => void;
  onDownload: () => void;
};

const CogOptions = (props: CogOptionsProps) => {
  return (
    <div className={styles.cogContainer}>
      <button onClick={props.onEdit}>
        <FaEdit size={19} /> Edit newsletter
      </button>
      <button onClick={props.onDelete}>
        <FaTimesCircle size={19} /> Delete newsletter
      </button>
      <button onClick={props.onDownload}>
        <FaDownload size={19} /> Download newsletter
      </button>
    </div>
  );
};

export default CogOptions;
