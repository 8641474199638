import EditNewsletter from "pages/Newsletter/EditNewsLetter";
import { Route, Switch, useRouteMatch } from "react-router-dom";

export default function EditNewsletterRoute() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <EditNewsletter />} />
    </Switch>
  );
}
