import React from "react";

const DropDownMenuItem = ({
  description,
  onClick,
  style,
  children,
  className = "",
}) => {
  return (
    <li>
      <a className={className} onClick={onClick}>
        {children}
        {description}
      </a>
    </li>
  );
};

export default DropDownMenuItem;
