import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AddNewDocument from "../../pages/AddNewDocument";

export default function AddNewDocumentRoute() {
  const match = useRouteMatch();
  const [renderPage, setRenderPage] = React.useState(true);
  const [docTab, setDocTab] = React.useState("NqaDocument");

  React.useEffect(() => {
    if (!renderPage) setRenderPage(true);
  }, [renderPage]);

  return (
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        render={() =>
          renderPage && (
            <AddNewDocument
              docTab={docTab}
              resetDocTab={(tab) => {
                setDocTab(tab);
                setRenderPage(false);
              }}
            />
          )
        }
      />
    </Switch>
  );
}
