import React from "react";
import styles from "./DeleteServiceConfirmationModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';
import { useHistory } from "react-router-dom";

const DeleteServiceConfirmationModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	onDeleteForever,
	onArchive
}) => {

	const history = useHistory();

	const onArchiveService = (e) => {
		e.preventDefault();
		onArchive(true); 
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Are you sure you want to permanently delete this service?</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>

						<Button
							onClick={(e) => onArchiveService(e)}
							className={`${styles.btnGreen}`}>ARCHIVE
						</Button>
						<Button
							onClick={onDeleteForever}
							className={`${styles.btnGreen}`}>
							DELETE FOREVER
						</Button>
						<Button
							onClick={onCloseModal}
							className={`${styles.btnRed}`}>
							CANCEL
						</Button>

					</div>

				</div>

			</div>


		</Modal>
	)
}

export default DeleteServiceConfirmationModal;