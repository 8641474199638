import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import LoggedInPages from "./routes/LoggedInPages";
import LoggedOutPages from "./routes/LoggedOutPages";
import { isUserLoggedIn } from "./services/auth";
import HubAdminRedirect from "routes/HubAdminRedirect";

const isLoggedIn = true;

const { useEffect } = React;

function App() {
  useEffect(() => {}, []);

  return (
    <div
      className={`w-full h-full min-h-screen flex flex-col justify-between relative`}
    >
      <div className={`flex-1`}>
        <>
          <Router basename={process.env.BASENAME}>
            <Switch>
              <Route
                path={`/view-hub-admin-service`}
                render={() => {
                  return <HubAdminRedirect />;
                }}
              />
              <Route
                path="/"
                render={() => {
                  return isUserLoggedIn() ? (
                    <LoggedInPages />
                  ) : (
                    <LoggedOutPages />
                  );
                }}
              />
              {/* <Route
                path="/hmc"
                render={() => {
                  return isLoggedIn ? (
                    <LoggedInPages />
                  ) : (
                    <Redirect to="/auth" />
                  );
                }}
              /> */}
            </Switch>
          </Router>
        </>
      </div>
    </div>
  );
}

export default App;
