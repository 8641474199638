import { Action, action, Thunk, thunk } from "easy-peasy";

import { UserResponse, AdminAttr } from "../../types";
import {
  fetchCenterUsers,
  getAdminAttr,
  getPaymentMethods,
} from "../../api/requests";

export interface UserModel {
  // users: UserResponse[];
  firstSignIn: boolean;
  setFirstSign: Action<UserModel, boolean>;
  paymentMethods: any;
  setPaymentMethods: Action<UserModel, any>;
  hasPaymentMethods: boolean;
  setHasPaymentMethods: Action<UserModel, boolean>;
  fetchPaymentMethods: Thunk<UserModel, { userId: number }>;
  selectedUser?: UserResponse;
  loggedInUser?: UserResponse;
  remainingTrial: number;
  setRemainingTrial: Action<UserModel, number>;
  adminAttr?: AdminAttr;
  setAdminAttr: Action<UserModel, AdminAttr>;
  setCurrentUser: Action<UserModel, UserResponse>;
  setSelectedUser: Action<UserModel, UserResponse>;
  clearSelectedUser: Action<UserModel>;
  clearLoggedInUser: Action<UserModel>;
  clearSession: Thunk<UserModel>;
  fetchUserDetails: Thunk<UserModel, { centerId: number; userId: number }>;
  fetchAdminAttr: Thunk<UserModel, { memberId: number }>;
}

const userStore: UserModel = {
  // users: dummyUsers,
  firstSignIn: false,
  setFirstSign: action((state, payload) => {
    state.firstSignIn = payload;
  }),
  hasPaymentMethods: false,
  setHasPaymentMethods: action((state, payload) => {
    state.hasPaymentMethods = payload;
  }),
  paymentMethods: [],
  setPaymentMethods: action((state, payload) => {
    state.paymentMethods = payload;
  }),
  fetchPaymentMethods: thunk(async (actions, payload) => {
    try {
      const pms = await getPaymentMethods(payload.userId);
      if (pms.data.length > 0) {
        actions.setHasPaymentMethods(true);
        actions.setPaymentMethods(pms.data);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  remainingTrial: 0,
  setSelectedUser: action((state, payload) => {
    state.selectedUser = {
      ...state.selectedUser,
      ...payload,
    };
  }),

  setCurrentUser: action((state, payload) => {
    state.loggedInUser = {
      ...state.selectedUser,
      ...payload,
    };
  }),

  setAdminAttr: action((state, payload) => {
    state.adminAttr = {
      ...state.adminAttr,
      ...payload,
    };
  }),

  clearSelectedUser: action((state) => (state.selectedUser = undefined)),

  clearLoggedInUser: action((state) => (state.loggedInUser = undefined)),

  clearSession: thunk((actions) => {
    actions.clearSelectedUser();
    actions.clearLoggedInUser();
  }),

  fetchUserDetails: thunk(async (actions, payload) => {
    try {
      const users = await fetchCenterUsers(payload.centerId);

      const user = users.find((user) => user.id === payload.userId);

      if (user) {
        actions.setCurrentUser(user);
        actions.setSelectedUser(user);

        actions.fetchAdminAttr({ memberId: user.id });
      }
    } catch (error) {
      console.log(error);
    }
  }),
  fetchAdminAttr: thunk(async (actions, payload) => {
    try {
      const adminUser = await getAdminAttr(payload.memberId);
      actions.setAdminAttr(adminUser);
    } catch (error) {
      console.log(error);
    }
  }),
  setRemainingTrial: action((state, payload) => {
    state.remainingTrial = payload;
  }),
};

export default userStore;
