import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Login from "../../pages/Login/Login";

export default function LoginRoute() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <Login />} />
    </Switch>
  );
}
