import React, { useState, useEffect } from "react";
import styles from "./UnlinkToServicesModal.module.css";
import {
	fetchDocumentsServicesById,
	serviceUnlink
} from '../../../../api/services/requests';

import {
	FaTimes,
	FaTimesCircle,
	FaSearch,
} from "react-icons/fa";

import { Item, Modal } from "semantic-ui-react";

import Button from '../../../_base/button';
import LargeSpinner from '../../../spinner/LargeSpinner';

import { sweetAlert } from "../../../_base/alerts/sweetAlert";

const UnlinkToServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	selectedDoc,
	fetchAllDocs
}) => {

	const [services, setServices] = useState([]);
	const [loading, setLoading] = useState(true);
	const [keyword, setKeyword] = useState('');
	const [backup, setBackUp] = useState([]);

	useEffect(() => {

		getData();
	 
	}, []);

	useEffect(() => {
	 
	}, [selectedDoc])


	const search = (e) => {

		e.preventDefault();

		if (keyword === "" || keyword == null) {
			setServices([...backup]);;
			return;
		}

		let searchResult = [];

		backup.forEach(s => {

			if (s.name != null && s.name != undefined && s.name != "") {

				let n = s.name.toLowerCase();
				let k = keyword.toLowerCase();

				if (n.includes(k)) {
					searchResult.push(s);
				}
			}
		});

		setServices([...searchResult]);

	}

	const getData = async () => {

		try {

			let { data, status } = await fetchDocumentsServicesById(selectedDoc.id);

			if (status == 200 || status == 201) {

				if (data.length > 0) {

					let dataArr = [];

					data.forEach(element => {
						let object = {
							id: element.id,
							name: element.name,
							selected: false
						}

						dataArr.push(object)
					});

					setBackUp([...dataArr]);
					setServices([...dataArr]);

				}
			}

			setLoading(false);

		} catch (error) {

			setLoading(false);

		}
	}

	const unlinkServiceFromDocument = async (serviceId) => {

		try {

			let response = await serviceUnlink({ document_id: selectedDoc.id, service_id: serviceId });

			if (response) {

				sweetAlert("success", "Service successfully unlink");

				let newServicArr = services.filter(i => i.id != serviceId);

				setServices([...newServicArr]);

				fetchAllDocs();
				
			}

		} catch (error) {

		}
	}

	const mapServices = () => {
		return services.map((item, i) => {
			return (
				<li key={item.id}>
					{item.name}
					<button onClick={() => unlinkServiceFromDocument(item.id)}>
						UNLINK
						<FaTimesCircle size={14} />
					</button>
				</li>
			)
		})
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					{!loading && services.length > 0 &&
						<h1>{`${services.length} services contain this document`}</h1>
					}
					{!loading && services.length == 0 &&
						<h1>{`No service available`}</h1>
					}
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.search}`}>
						<input type="text" value={keyword}
							name="search"
							id="s"
							placeholder="Type the service name"
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<button type="submit"
							onClick={(e) => search(e)}
							className="flex items-center justify-center">
							<FaSearch size={17} />
						</button>
					</div>

					{loading && <div>
						<LargeSpinner />
					</div>}

					{!loading && services.length > 0 && <ul>
						{mapServices()}
					</ul>
					}

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => onCloseModal(e)}
							className={`${styles.btnGreen}`}>CLOSE
						</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default UnlinkToServicesModal;