import React, { useState, useEffect } from "react";
import styles from "./AddToServicesModal.module.css";

import {
	FaTimes,
	FaSearch,
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import {
	addServicesDocuments,
	fetchServicesWithDocumentCount,
} from '../../../../api/services/requests';

import Button from '../../../_base/button';

import LargeSpinner from '../../../spinner/LargeSpinner';

const AddToServicesModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	uploaded_documents,
	setHeaderActionsPayloadPayload,
	showAddDocumentsToServicesConfirmationModal,
	setServices,
	services,
}) => {

	const [loading, setLoading] = useState(true);
	const [keyword, setKeyword] = useState('');
	const [backup, setBackUp] = useState([]);
	const [localPayload, setLocalPayload] = useState({});

	useEffect(() => {

		filterServices();

	}, []);


	const filterServices = async () => {

		try {

			let payload = {
				document_id: [],
				services_to_add: [],
			}

			const data = await fetchServicesWithDocumentCount();

			let newData = [];

			data.forEach(s => {

				let obj = {
					id: s.id,
					service_name: s.service_name,
					document_count: s.document_count,
					selected: false
				}

				uploaded_documents.forEach(d => {

					if (d.selected) {

						if (payload.document_id.indexOf(d.id) < 0) {
							payload.document_id.push(d.id);
						}

						d.services.forEach(service => {

							if (service.id == s.id) {
								obj.selected = true;

								if (payload.services_to_add.indexOf(service.id) < 0) {
									payload.services_to_add.push(service.id);
								}
							}

						});
					}
				});

				newData.push(obj);

			});

			setServices(newData);

			setBackUp(newData);

			setLoading(false);

			setLocalPayload(payload);

			setHeaderActionsPayloadPayload(payload);

		} catch (error) {

			// console.log(error);
		}
	}

	const addServices = (serviceId, e) => {

		e.preventDefault();

		let newServices = [...services];

		newServices.forEach(item => {

			if (item.id == serviceId) {
				item.selected = !item.selected;
			}

		});

		let payload = { ...localPayload };

		let index = payload.services_to_add.indexOf(serviceId);

		if (index < 0) {
			payload.services_to_add.push(serviceId);

		}
		else {
			payload.services_to_add.splice(index, 1);
		}

		setServices(newServices);

		setLocalPayload(payload);

		setHeaderActionsPayloadPayload(payload);

	}


	const search = (e) => {

		e.preventDefault();

		if (keyword === "" || keyword == null) {

			setServices([...backup]);;

			return;
		}

		let searchResult = [];

		backup.forEach(s => {

			if (s.service_name != null && s.service_name != undefined && s.service_name != "") {

				let n = s.service_name.toLowerCase();
				let k = keyword.toLowerCase();

				if (n.includes(k)) {
					searchResult.push(s);
				}
			}
		});

		setServices([...searchResult]);

	}

	const mapServices = () => {
		return services.map((item, i) => {
			return (
				<li key={item.id}
					className={item.selected ? styles.selected : ""}
					onClick={(e) => addServices(item.id, e)}
				>{item.service_name}
					<span>{`${item.document_count} docs`}<label className={`${styles.checkbox}`}>
						<input type="checkbox" /><span className={`${styles.checkmark}`}>
						</span>
					</label>
					</span>
				</li>
			)
		})
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Choose a service to add documents to</h1>
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.search}`}>
						<input type="text"
							value={keyword}
							name="search"
							id="s"
							placeholder="Type the service name" 
							onChange={(e) => setKeyword(e.target.value)}
							/>
						<button type="submit"
							onClick={(e) => search(e)}
							className="flex items-center justify-center">
							<FaSearch size={17} />
						</button>
					</div>

					{
						loading && <div>
							<LargeSpinner />
						</div>
					}

					{
						!loading && services.length > 0 && <ul>
							{mapServices()}
						</ul>
					}

					{
						!loading && services.length == 0 &&
						<span
							className={`flex justify-center py-4 ml-2 text-2xl text-gray-400`}>No service available.</span>
					}


					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => showAddDocumentsToServicesConfirmationModal()}
							className={`${styles.btnGreen}`}>ADD DOCUMENTS
						</Button>
					</div>

				</div>

			</div>

		</Modal>
	)
}

export default AddToServicesModal;