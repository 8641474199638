import React, { useState, useRef, useEffect } from "react";

import PropTypes from "prop-types";
import { FaSearch, FaTimes } from "react-icons/fa";
import { Modal, Header, List, Button } from "semantic-ui-react";
import styles from "./ChooseServicesModal.module.css";
// import { Button } from "../../../_base";


function ChooseServicesModal({ visibility, onOpenModal, onCloseModal, ref }) {

	const noderef = useRef(null);

	const [selectedServices, setSelectedServices] = useState([]);
	const [searchKeyword, setSearchKeyword] = useState("");

	const showHideClassName = visibility ? "" : "hidden";

	useEffect(() => {

	}, []);

	const addSelectedServices = (e) => {
		e.preventDefault();

		//Trigger filter by services here
	}

	const servicesData = [
		{ id: 1, label: "HMC Master", docCounts: 978, selected: true },
		{ id: 2, label: "North Nowra Preschool", docCounts: 88, selected: false },
		{ id: 3, label: "Wattle Grove Long Daycare", docCounts: 12, selected: false },
		{ id: 4, label: "Innocent MInds Preschool Academy", docCounts: 621, selected: true },
		{ id: 5, label: "Little Academy Elc", docCounts: 5, selected: false },
		{ id: 6, label: "Kingdom of Angels Childcare", docCounts: 978, selected: false },
		{ id: 7, label: "he Little Kids School", docCounts: 401, selected: true },
		{ id: 8, label: "he Little Kids School", docCounts: 119, selected: false },
	];

	const setSelecteditem = (e) => {
		e.preventDefault();

		//Add service to seletected items
	}

	const renderServices = () => {
		return servicesData.map((s, i) => {
			return (
				<List.Item key={i}>{s.label}
					<span>{`${s.docCounts} docs`}
						<label className={styles.checkBox}>
							<input type="checkbox" checked={s.selected} onChange={(e) => setSelecteditem(e)} />
							<span className={`${s.selected ? styles.checkmark : ""}`}></span>
						</label>
					</span>
				</List.Item>
			);
		});
	}

	return (
		<Modal
			size="medium"
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
		>
			 
				<div className={`${styles.modalContainer} ${styles.medium}`}>
					<div className={styles.closeModal}>

						<button
							onClick={onCloseModal}
							className={styles.closeModal}
						>
							<FaTimes className={`ml-2`} size={16} />
						</button>

						<div className={styles.modalTitle}>
							<h1>Choose services for this file</h1>
						</div>

						<div className={styles.modalContent}>

							<div className={styles.search}>
								<input type="text"
									value=""
									name="search"
									id="s"
									placeholder="Type the service name"
									onChange={(e) => console.log()}
								/>
								<button type="submit" className="flex items-center justify-center">
									<FaSearch className={`ml-2`} size={16} />
								</button>
							</div>

							<List>
								{renderServices()}
							</List>

							<div className={`mt-20 text-center`}>
								<Button primary
									onClick={(e) => addSelectedServices(e)}
								>Filter Services</Button>
								{/* <Button
							style={{}}
								onClick={(e) => addSelectedServices(e)}
								children={`Filter Services`}
							/> */}
							</div>
						</div>
					</div>
				</div>		 
		</Modal>
	);
}


ChooseServicesModal.propTypes = {
	visibility: PropTypes.bool.isRequired,
	onOpenModal: PropTypes.func.isRequired,
	onCloseModal: PropTypes.func.isRequired,
};


export default ChooseServicesModal;