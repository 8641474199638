import React, { useState, useRef, useEffect } from "react";
import addservicestyles from "../AddNewService/AddNewService.module.css";
import styles from "./EditService.module.css";
import UserLayout from "../../components/userLayout/UserLayout";
import { useHistory } from "react-router-dom";

import Button from "../../components/_base/button";

import LargeSpinner from "../../components/spinner/LargeSpinner";

import isEmpty from "lodash/isEmpty";

import { sweetAlert } from "../../components/_base/alerts/sweetAlert";

import { updateService, fetchServiceById } from "../../api/services/requests";

import { fetchCenterUsers } from "../../api/requests";

import fileDownload from "js-file-download";

import axios from "axios";

import {
  documentAvailability,
  deleteMultipleDocument,
  documentAddCollection,
  filterDocumentByServices,
  filterDocumentByCollection,
  deleteSingleDocument,
} from "../../api/documents/requests";

import {
  getDocumentByServiceId,
  getServiceSpecificDocument,
} from "../../api/documents/requests";

import ColorPickerModal from "../../components/popups/colorPickerModal";

import FilterByCollectionsModal from "../../components/popups/documents/filterByCollectionsModal";
import FilterByFileTypeModal from "../../components/popups/documents/filterByFileTypeModal";

import Pagination from "../../components/Pagination/Pagination";

import SpecificServiceDocument from "../../components/services/SpecificServiceDocument";
import UploadServiceSpecificDocument from "../../components/services/UploadServiceSpecificDocument";
import GenerateServiceSpecificDocument from "../../components/services/GenerateServiceSpecificDocument";
import Branding from "../../components/services/Branding";
import PreDefinedDocumentsCollection from "../../components/services/PreDefinedDocumentsCollection";

import ServiceDetails from "../../components/services/ServiceDetails";

import AddServiceSuccessModal from "../../components/popups/createService/addServiceSuccessModal";
import DeleteDocumentConfirmationModal from "../../components/popups/services/deleteDocumentConfirmation";
import OKUndoDeleteDocumentModal from "../../components/popups/services/oKUndoDeleteDocumentModal";

import UploadOrGenerate from "../../components/services/UploadOrGenerate";

import {
  FaAngleDown,
  FaFileDownload,
  FaCheckCircle,
  FaCog,
  FaFile,
  FaFileAlt,
  FaFolderPlus,
  FaTimesCircle,
  FaEyeSlash,
  FaEdit,
  FaSpinner,
  FaEye,
} from "react-icons/fa";

import { useLocation } from "react-router-dom";

import { headerSort } from "../../helpers/headerSort";

import moment from "moment";

import {
  mapDocument,
  mapDocTypeAndCategory,
} from "../../helpers/document/documentHelper";

import { EditorState } from "draft-js";

import UploadFileIcon from "../../components/document/documentIcon/UploadFileIcon";

import {
  DOC_CATEGORY,
  GLOBAL_WEBINAR,
  DOCUMENT_TYPE,
} from "../../helpers/document/constant";

import redirect from "../../helpers/redirect";

const EditService = () => {
  const inputRef = useRef();

  const history = useHistory();

  const location = useLocation();

  const [uploaded_documents, setUploaded_Documents] = useState([]);

  const [uploaded_documentsBckup, setUploaded_DocumentsBckup] = useState([]);

  const [serviceTypeId, setServiceTypeId] = useState(null);

  const [serviceSpecificTypeId, setServiceSpecificTypeId] = useState(0);

  const [collectionLoaded, setCollectionLoaded] = useState([]);

  const [serviceDetailsChevron, setServiceDetailsChevron] = useState(true);

  const [nqaFilter, setNqaFilter] = useState(true);

  const [errors, setErrors] = useState({});

  //Modals

  const [showColorPickerModal, setShowColorPickerModal] = useState(false);

  const [showFilterByCollectionsModal, setShowFilterByCollectionsModal] =
    useState(false);

  const [showFilterByFileTypeModal, setShowFilterByFileTypeModal] =
    useState(false);

  const [brandColor, setBrandColor] = useState("#000000" || "");

  const [loading, setLoading] = useState(true);

  //document

  const [fileType, setFileType] = useState("Upload");

  const [documentTypeId, setDocumentTypeId] = useState(null);

  const [showDocumentActions, setShowDocumentActions] = useState(false);

  const [selectedDoc, setSelectedDoc] = useState({});

  const [headerShowDocumentActions, setHeaderShowDocumentActions] =
    useState(false);

  const [showDocumentFilterMenu, setShowDocumentFilterMenu] = useState(false);

  const [showAddServiceSuccess, setShowAddServiceSuccess] = useState(false);

  const [activeNQATab, setActiveNQATab] = useState(1);

  const [headerDocumentFilter, setHeaderDocumentFilter] = useState(null);

  const [selectedCollection, setSelectedCollection] = useState({
    collectionId: null,
    collectionItems: [],
  });

  //pagination config
  const [rpp, setRpp] = useState(10);
  const [currPage, setCurrentPage] = useState(1);
  const [trimmedDocuments, setTrimmedDocuments] = useState([]);

  const [asc, setAsc] = useState(true);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [serviceDocuments, setServiceDocuments] = useState([]);

  const [
    showDeleteDocumentConfirmationModal,
    setShowDeleteDocumentConfirmationModal,
  ] = useState(false);

  const [showOKUndoDeleteModal, setShowOKUndoDeleteModal] = useState(false);

  const [totalFileCount, setTotalFileCount] = useState(0);

  const [disableDocTypes, setDisableDocTypes] = useState([]);

  const [loadingViewer, setLoadingViewer] = useState(false);

  const [specificDocState, setSpecificDocState] = useState({
    notify_service: false,
    content: "",
    description: "",
    date: "",
    file_version: "",
    file_title: "",
    service_id: [],
    id: null,
    document_type_id: 0,
    nqa_area_id: [],
    service_specific_type: 0,
    upload_or_generate: 1,
    attachments: [],
    base64_image: "",
    label: "",
    category: "",
    service_type_id: "",
    webinar_link: "",
    webinar_title: "",
    webinar_description: "",
    services: "",
  });

  const [serviceState, setServiceState] = useState({
    name: "",
    website_link: "",
    primary_email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postal: "",
    description: "",
    logo_url: "",
    logo_name: "",
    brand_color: "",
    service_type_id: "",
    is_archive: false,
    id: null,
    primary_email: "",
  });

  const [selectAll, setSelectAll] = useState(false);

  const [toggleFetchDocs, setToggleFetchDocs] = useState(false);

  useEffect(() => {
    // fetcAllServiceSpecificDocument();
    getServicebyId();
  }, []);

  useEffect(() => {
    fetchDocumentByServiceId();
  }, [toggleFetchDocs]);

  useEffect(() => {}, [specificDocState]);

  useEffect(() => {}, [fileType]);

  useEffect(() => {
    mapDocumentsToActiveTab(1);
  }, [uploaded_documentsBckup]);

  const getServicebyId = async () => {
    try {
      let { data, status } = await fetchServiceById(location.state.service.id);

      if (status == 200 || status == 201) {
        setServiceState({
          ...serviceState,
          name: data.name,
          website_link: data.website_link,
          phone: data.phone,
          address: data.address,
          city: data.city,
          state: data.state,
          postal: data.postal,
          description: data.description,
          service_type_id: parseInt(data.service_type_id),
          is_archive: data.is_archive,
          id: data.id,
          primary_email: data.primary_email,
          logo_name: data.logo_name,
          logo_url: data.logo_url,
        });

        if (
          data.collection_loaded &&
          typeof data.collection_loaded === "string"
        ) {
          if (data.collection_loaded != "[]") {
            setCollectionLoaded(JSON.parse(data.collection_loaded) || []);
          }
        }

        setServiceTypeId(parseInt(data.service_type_id));
      }
    } catch (error) {}
  };

  const fetchDocumentByServiceId = async () => {
    try {
      let data = await getDocumentByServiceId(location.state.service.id);

      if (data.length > 0) {
        let newDocArray = await mapDocument(data);

        setUploaded_DocumentsBckup([...newDocArray]);

        setTotalFileCount(newDocArray.length);

        mapDocumentsToActiveTab(1);
      }

      setLoading(false);
    } catch (error) {}
  };

  const handleUpdateService = async (e) => {
    e.preventDefault();

    setErrors({});

    setLoading(true);

    try {
      const { status } = await updateService(serviceState);

      if (status == 200) {
        sweetAlert("success", "Service successfully updated.");

        setLoading(false);
      }
    } catch (error) {
      if (error.response.data.errors) {
        setErrors(error.response.data.errors);
      }

      setLoading(false);
    }
  };

  const setMenu = (doc) => {
    setSelectedDoc({ ...doc });
    setShowDocumentActions(true);
  };

  const deleteDoc = async (data, isMultiple) => {
    try {
      let result = null;

      if (isMultiple) {
        let selectedDocsId = uploaded_documents
          .filter((d) => d.selected)
          .map((a) => {
            return a.id;
          });

        if (selectedDocsId.length == 0) {
          sweetAlert("error", "Please select document.");
          return;
        } else {
          result = await deleteMultipleDocument(selectedDocsId);
        }
      } else {
        result = await deleteMultipleDocument([data.id]);
      }

      if (result) {
        sweetAlert("success", "Document successfully deleted.");

        if (!isMultiple) {
          setUploaded_Documents(
            uploaded_documents.filter((d) => d.id !== data.id)
          );
        } else {
          let selected = uploaded_documents.filter((d) => d.selected);
          setUploaded_Documents(
            uploaded_documents.filter((d) => !selected.includes(d))
          );
        }
      }
    } catch (error) {}
  };

  const addToCollection = async () => {
    let docIds = [];

    docIds = uploaded_documents
      .filter((d) => d.selected && d.global_webinar != GLOBAL_WEBINAR.GLOBAL)
      .map((obj) => obj.id);

    if (docIds.length == 0) {
      sweetAlert("error", "Please select document.");
      return;
    }

    let payload = {
      service_type_id: Number(serviceTypeId),
      document_id: docIds,
    };

    try {
      let result = await documentAddCollection(payload);

      if (result) {
        sweetAlert("success", "Document successfully added to collection.");
      }
    } catch (error) {}
  };

  const docAvailability = async (doc) => {
    try {
      let result = await documentAvailability(doc, serviceState.id);

      let status = "";

      if (result) {
        let data = [...uploaded_documents];

        data.forEach((d) => {
          if (d.id == doc.id) {
            status = d.availability == "ENABLE" ? "DISABLE" : "ENABLE";
            d.availability = d.availability == "ENABLE" ? "DISABLE" : "ENABLE";
          }
        });

        sweetAlert("success", `Document successfully ${status.toLowerCase()}.`);

        setUploaded_Documents([...data]);
      }
    } catch (error) {}
  };

  const onMenuClick = (name, data, isMultiple, e) => {
    e.preventDefault();

    if (name === "delete") {
      deleteDoc(data, isMultiple);
    }

    if (name === "enable") {
      docAvailability(data);
    }

    if (name === "add") {
      addToCollection();
    }

    if (isMultiple) {
      setShowDocumentActions(false);
      // setMultipleUsers(true);
    } else {
      setHeaderShowDocumentActions(false);
      setSelectedDoc(data);
      // setMultipleUsers(true);
    }
  };

  const selectAllDocs = () => {
    let docArr = uploaded_documents;

    docArr.forEach((d, i) => {
      if (!selectAll) {
        d.selected = true;
      } else {
        d.selected = false;
      }
    });

    setSelectAll(!selectAll);

    setUploaded_Documents([...docArr]);
  };

  const addToSelected = (docId) => {
    let docArr = [...uploaded_documents];

    docArr.forEach((d, i) => {
      if (d.id == docId) {
        d.selected = !d.selected;
      }
    });

    setUploaded_Documents(docArr);
  };

  const sorting = (headerName) => {
    let { data, toAsc } = headerSort(headerName, uploaded_documents, asc);

    setAsc(toAsc);

    setUploaded_Documents(data);
  };

  const filterDocuments = (e) => {
    e.preventDefault();

    if (selectedCollection.collectionId == 1) {
      filterDocumentsByServicesId();
    }

    if (selectedCollection.collectionId == 3) {
      filterDocumentsByCollectionId();
    }
  };

  /*Unused for now */
  const filterDocumentsByCollectionId = async () => {
    setLoading(true);

    try {
      if (
        uploaded_documentsBckup.length <= 0 ||
        selectedCollection.collectionItems.length <= 0
      )
        return;

      let filteredData = [];

      uploaded_documentsBckup.forEach((d) => {
        if (d.service_type_id.length > 0) {
          d.service_type_id.forEach((i) => {
            if (selectedCollection.collectionItems.includes(i.id)) {
              filteredData.push(d);
            }
          });
        }
      });

      if (filteredData.length <= 0) return;

      setUploaded_Documents(filteredData);

      mapDocumentsToActiveTab(1);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const filterDocumentsByServicesId = async () => {
    setLoading(true);

    try {
      let ids = selectedCollection.filter((sc) => sc.selected);

      let payload = { service_id: ids };

      let data = await filterDocumentByServices(payload);

      if (data.length > 0) {
        let docArr = await mapDocument(data);

        setUploaded_Documents([...docArr]);
      } else {
        sweetAlert("info", " No result found");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const mapDocType = (doc) => {
    if (doc.availability !== "ENABLE") {
      return <FaEyeSlash size={20} style={{ fill: "#FF5F5F" }} />;
    } else {
      let docTypes = mapDocTypeAndCategory(doc, activeNQATab).doctype;

      if (docTypes.length < 1) return "";

      return docTypes.map((item, i) => {
        return (
          <span key={i}>
            {item}
            <br />
          </span>
        );
      });
    }
  };

  const mapServiceType = (serviceTypeId, e) => {
    e.preventDefault();

    setServiceState({ ...serviceState, service_type_id: serviceTypeId });
  };

  const setSelectedDocument = (doc, e) => {
    e.preventDefault();

    setFileType(doc.upload_or_generate);

    let uploaded_files = [];

    if (uploaded_documents.length > 0) {
      uploaded_documents.forEach((d) => {
        if (doc.id == d.id && doc.fileUrl) {
          uploaded_files.push({
            file_name: d.fileName,
            file_size: d.fileSize,
            file_url: d.fileUrl,
            file_id: d.file_uploaded_id,
            file_label: d.label,
            file_type: d.fileType,
          });
        }
      });
    }

    setSpecificDocState({
      ...specificDocState,
      notify_service: doc.notify_service === "Upload" ? 1 : 2,
      content: doc.content,
      description: doc.description,
      date: doc.date,
      file_version: doc.file_version,
      file_title: doc.file_title,
      service_id: doc.service_id,
      id: doc.id,
      document_type_id: doc.document_type_id,
      nqa_area_id: doc.nqa_area_id,
      service_specific_type: doc.service_specific_type,
      upload_or_generate: doc.upload_or_generate === "Upload" ? 1 : 2,
      attachments: uploaded_files || [],
      webinar_description: doc.webinar_description,
      webinar_link: doc.webinar_link,
      webinar_title: doc.webinar_title,
    });

    // window.scrollTo(0,document.body.scrollHeight - (document.body.scrollHeight * 0.3));

    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "auto",
      /* you can also use 'auto' behaviour 
         in place of 'smooth' */
    });
  };

  const onMouseLeave = () => {
    // setSelectedDoc({});
    setShowDocumentActions(false);
    setHeaderShowDocumentActions(false);
  };

  const mapDocumentsToActiveTab = (activeTab) => {
    let docArr = [];

    if (uploaded_documentsBckup.length > 0) {
      if (activeTab != 8) {
        docArr = uploaded_documentsBckup.filter(
          (d) => d.nqa_area_id.indexOf(activeTab) > -1
        );
      } else {
        docArr = uploaded_documentsBckup.filter(
          (d) =>
            d.service_specific_type &&
            (d.service_specific_type < 4 || d.service_specific_type === 6)
        );
      }

      if (docArr.length > 0) {
        docArr = docArr
          .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
          .reverse();
      }

      setCurrentPage(1);

      setUploaded_Documents(docArr);
    }

    setActiveNQATab(activeTab);
  };

  const onGetUserCenters = async (centerId) => {
    try {
      setLoadingViewer(true);
      const response = await fetchCenterUsers(centerId);

      const user = response.filter((u) => u.type.toLowerCase() === "admin");

      if (user) {
        redirect(user[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingViewer(false);
    }
  };

  const onDownloadFile = (fileUrl, fileName) => {
    if (!fileName || !fileUrl) {
      sweetAlert("error", "Please check file");
      return;
    }
    fileName = fileName.replace(/ /g, "_");
    axios
      .get(fileUrl, {
        responseType: `blob`,
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  };

  return (
    <UserLayout headerText={``}>
      {!loading && showFilterByCollectionsModal && (
        <FilterByCollectionsModal
          visibility={showFilterByCollectionsModal}
          onOpenModal={() => setShowFilterByCollectionsModal(true)}
          onCloseModal={() => setShowFilterByCollectionsModal(false)}
          setSelectedCollection={setSelectedCollection}
          selectedCollection={selectedCollection}
          filterDocuments={filterDocuments}
        />
      )}

      {!loading && showFilterByFileTypeModal && (
        <FilterByFileTypeModal
          visibility={showFilterByFileTypeModal}
          onOpenModal={() => setShowFilterByFileTypeModal(true)}
          onCloseModal={() => setShowFilterByFileTypeModal(false)}
          setSelectedCollection={setSelectedCollection}
          selectedCollection={selectedCollection}
          filterDocuments={filterDocuments}
        />
      )}

      {showAddServiceSuccess && !loading && (
        <AddServiceSuccessModal
          visibility={showAddServiceSuccess}
          onOpenModal={() => setShowAddServiceSuccess(true)}
          onCloseModal={() => setShowAddServiceSuccess(false)}
        />
      )}

      {showColorPickerModal && (
        <ColorPickerModal
          visibility={showColorPickerModal}
          onOpenModal={() => setShowColorPickerModal(true)}
          onCloseModal={() => setShowColorPickerModal(false)}
          setBrandColor={setBrandColor}
          brandColor={brandColor}
        />
      )}

      {!loading && showDeleteDocumentConfirmationModal && (
        <DeleteDocumentConfirmationModal
          visibility={showDeleteDocumentConfirmationModal}
          onCloseModal={() => setShowDeleteDocumentConfirmationModal(false)}
          onOpenModal={() => setShowDeleteDocumentConfirmationModal(true)}
          setShowOKUndoDeleteModal={() => setShowOKUndoDeleteModal(true)}
        />
      )}

      {!loading && showOKUndoDeleteModal && (
        <OKUndoDeleteDocumentModal
          visibility={showOKUndoDeleteModal}
          onCloseModal={() => setShowOKUndoDeleteModal(false)}
          onOpenModal={() => setShowOKUndoDeleteModal(true)}
          documentId={selectedDoc.id}
          // refresh={() => fetchDocumentByServiceId()}
          refreshList={() => setToggleFetchDocs(!toggleFetchDocs)}
          closeOkUndo={() => setShowDeleteDocumentConfirmationModal(false)}
        />
      )}

      <div
        className={`${styles.topHeaderContainer} flex items-center justify-between`}
      >
        <h1 className={`${styles.contentHeader}`}> Edit {serviceState.name}</h1>
        <div
          className={`${styles.viewAsService} ${
            serviceState.name ? "" : "hidden"
          } `}
        >
          <a
            onClick={() => {
              onGetUserCenters(serviceState.id);
            }}
            className={`${styles.blueButton} ${
              loadingViewer ? `pointer-events-none` : `pointer-events-auto`
            }`}
          >
            {loadingViewer ? (
              <div className={`flex justify-center items-center`}>
                {" "}
                <FaSpinner className={`animate-spin text-2xl`} />{" "}
                <span className={`ml-2`}>PLEASE WAIT...</span>
              </div>
            ) : (
              `VIEW AS SERVICE`
            )}
          </a>
        </div>
      </div>

      <div className={`${styles.collectionHeader}`}>
        <div className={`${styles.collectionHeaderIcon}`}>
          <FaFileAlt size={35} />
        </div>
        <div>
          <h2>
            {uploaded_documentsBckup.length} files in {serviceState.name}
          </h2>
          <p>You are viewing all documents for this service.</p>
        </div>
      </div>

      <div className={`${styles.documentsList}`}>
        <div
          className={`${nqaFilter == true ? "" : styles.tabHeaderReports} ${
            styles.tabHeader
          }`}
        >
          <ul>
            <li
              className={`${activeNQATab == 1 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(1);
                setNqaFilter(true);
              }}
            >
              NQA1
            </li>
            <li
              className={`${activeNQATab == 2 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(2);
                setNqaFilter(true);
              }}
            >
              NQA2
            </li>
            <li
              className={`${activeNQATab == 3 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(3);
                setNqaFilter(true);
              }}
            >
              NQA3
            </li>
            <li
              className={`${activeNQATab == 4 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(4);
                setNqaFilter(true);
              }}
            >
              NQA4
            </li>
            <li
              className={`${activeNQATab == 5 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(5);
                setNqaFilter(true);
              }}
            >
              NQA5
            </li>
            <li
              className={`${activeNQATab == 6 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(6);
                setNqaFilter(true);
              }}
            >
              NQA6
            </li>
            <li
              className={`${activeNQATab == 7 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(7);
                setNqaFilter(true);
              }}
            >
              NQA7
            </li>
            <li
              className={`${activeNQATab == 8 ? styles.active : ""}`}
              onClick={() => {
                mapDocumentsToActiveTab(8);
                setNqaFilter(false);
              }}
            >
              Reports
            </li>
          </ul>
        </div>
        {nqaFilter && (
          <div className={`${styles.filterContainer}`}>
            <div className={`${styles.filterActions}`}>
              <div className={`${styles.actions}`}>
                <span className={`${styles.label}`}>Filters</span>
                <div className={`flex`}>
                  <Button
                    className={`flex justify-center items-center mr-1.5`}
                    onClick={() => {
                      setSelectedCollection({
                        collectionId: 2,
                        collectionItems: [],
                      });
                      setShowFilterByFileTypeModal(true);
                    }}
                  >
                    <FaFile className={`mr-1.5`} size={15} />
                    <span>Choose file type</span>
                  </Button>
                  {/* <Button
										className={`flex justify-center items-center`}
										onClick={() => {
											setSelectedCollection({
												collectionId: 3,
												collectionItems: [],
											});
											setShowFilterByCollectionsModal(true);
										}}
									>
										<FaBuffer className={`mr-1.5`} size={15} />
										<span>Choose collection</span>
									</Button> */}
                </div>
              </div>
            </div>
          </div>
        )}

        {loading && (
          <div className={`py-32`}>
            <LargeSpinner />
          </div>
        )}

        {!loading && uploaded_documents.length == 0 && (
          <div className={`px-10 flex justify-center`}>
            <span className={`text-gray-400 text-2xl py-6`}>
              No documents found
            </span>
          </div>
        )}

        {!loading && uploaded_documents.length > 0 && (
          <div className={`${styles.fileList}`}>
            <div className={`${styles.fileListHeader}`}>
              <ul className={`${styles.fileListCol}`}>
                <li>
                  <label
                    className={`${styles.checkbox} ${
                      selectAll ? styles.selectedAll : ""
                    }`}
                  >
                    <span
                      className={`${styles.checkmark}`}
                      onClick={() => selectAllDocs()}
                    ></span>
                  </label>
                </li>
                <li onClick={() => sorting("filename")}>
                  File name
                  <FaAngleDown size={12} />
                </li>
                <li onClick={() => sorting("upload_or_generate")}>
                  File type
                  <FaAngleDown size={12} />
                </li>
                {/* <li>
                  Author
                  <FaAngleDown size={12} />
                </li> */}
                <li onClick={() => sorting("categoryName")}>
                  Categories
                  <FaAngleDown size={12} />
                </li>
                <li className={`${styles.headerFilter}`}>
                  <div
                    className={`flex flex-row items-center`}
                    onClick={() => sorting("belongsIn")}
                    // onClick={() =>
                    //   setShowDocumentFilterMenu(!showDocumentFilterMenu)
                    // }
                  >
                    Document
                    <FaAngleDown className={`mr-1.5`} size={12} />
                  </div>

                  <ul
                    className={`${styles.serviceTypeFilter} ${
                      showDocumentFilterMenu ? "block" : "hidden"
                    }`}
                  >
                    <li
                      className={`${
                        headerDocumentFilter == 1 ? styles.selected : ""
                      }`}
                      onClick={() => setHeaderDocumentFilter(1)}
                    >
                      Collection file
                      <FaCheckCircle size={20} />
                    </li>
                    <li
                      className={`${
                        headerDocumentFilter == 2 ? styles.selected : ""
                      }`}
                      onClick={() => setHeaderDocumentFilter(2)}
                    >
                      Service file
                      <FaCheckCircle size={20} />
                    </li>
                    <li
                      className={`${
                        headerDocumentFilter == 3 ? styles.selected : ""
                      }`}
                      onClick={() => setHeaderDocumentFilter(3)}
                    >
                      Standalone file
                      <FaCheckCircle size={20} />
                    </li>
                    <li>
                      <button onClick={() => setHeaderDocumentFilter(null)}>
                        CLEAR FILTER
                      </button>
                    </li>
                  </ul>
                </li>
                <li>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      setHeaderShowDocumentActions(!headerShowDocumentActions);
                      setShowDocumentActions(false);
                    }}
                    className={`${
                      headerShowDocumentActions
                        ? styles.active
                        : styles.notActive
                    }`}
                  >
                    <FaCog size={19} />
                  </Button>
                  <ul
                    className={`${styles.settingMenu} ${
                      headerShowDocumentActions ? "block" : "hidden"
                    }`}
                  >
                    <li>
                      <a onClick={(e) => onMenuClick("delete", null, true, e)}>
                        <FaTimesCircle size={19} />
                        Delete file
                      </a>
                    </li>
                    <li>
                      <a onClick={(e) => onMenuClick("add", null, true, e)}>
                        <FaFolderPlus size={19} />
                        Add to collection
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className={`${styles.fileListBody}`}>
              {uploaded_documents
                .slice(currPage * rpp - rpp, currPage * rpp)
                .map((doc, i) => {
                  return (
                    // <ul className={`${styles.selected} ${styles.fileListCol}`} key={i}>

                    <ul
                      className={`${styles.fileListCol} ${
                        doc.selected ? styles.selected : ""
                      }`}
                      key={i}
                    >
                      <li>
                        <label className={`${styles.checkbox}`}>
                          <span
                            className={`${styles.checkmark}`}
                            onClick={(e) => addToSelected(doc.id)}
                          ></span>
                        </label>
                      </li>
                      <li>
                        <div className={`${styles.serviceName}`}>
                          <div className={`${styles.fileIconContainer} mr-2`}>
                            <UploadFileIcon doc={doc} />
                          </div>
                          <div className={`${styles.fileContent}`}>
                            <span>
                              {doc.document_type_id &&
                              doc.document_type_id >= DOCUMENT_TYPE.WEBINAR &&
                              doc.document_type_id < DOCUMENT_TYPE.FEEDBACK
                                ? doc.webinar_title
                                : doc.file_title}
                            </span>
                            <span
                              className={`${
                                doc.fileType != "" ? "block " : "hidden"
                              }`}
                            >
                              {doc.fileType && doc.fileType + " / "}
                              {doc.fileSize && doc.fileSize + " kb"}
                            </span>
                          </div>
                        </div>
                      </li>
                      <li>
                        {doc.upload_or_generate === "Upload"
                          ? "Uploaded"
                          : "Generated"}
                      </li>
                      {/* <li>{""}</li> */}
                      <li>
                        {mapDocTypeAndCategory(doc, activeNQATab).category}
                      </li>
                      <li>{mapDocType(doc)}</li>
                      <li
                        data-info={doc}
                        onMouseEnter={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setMenu(doc);
                        }}
                        onMouseLeave={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onMouseLeave();
                        }}
                      >
                        <Button
                          className={`${
                            showDocumentActions &&
                            doc.file_uploaded_id == selectedDoc.file_uploaded_id
                              ? styles.active
                              : styles.notActive
                          }`}
                        >
                          <FaCog size={19} />
                        </Button>

                        <ul
                          className={`${styles.settingMenu} ${
                            showDocumentActions &&
                            doc.file_uploaded_id == selectedDoc.file_uploaded_id
                              ? "block"
                              : "hidden"
                          }`}
                        >
                          {doc.availability === "ENABLE" && (
                            <li>
                              <a
                                onClick={(e) => {
                                  doc.category === DOC_CATEGORY.SERVICE_SPECIFIC
                                    ? setSelectedDocument(doc, e)
                                    : history.push({
                                        pathname: `/edit-document`,
                                        state: { doc: doc },
                                      });
                                }}
                              >
                                <FaEdit size={19} />
                                Edit file
                              </a>
                            </li>
                          )}
                          <li>
                            <a
                              onClick={(e) =>
                                onMenuClick("enable", doc, false, e)
                              }
                            >
                              {doc.availability === "ENABLE" ? (
                                <FaEyeSlash size={19} />
                              ) : (
                                <FaEye size={19} />
                              )}

                              {`${
                                doc.availability === "ENABLE"
                                  ? "Disable file"
                                  : "Enable file"
                              }`}
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={(e) => {
                                setSelectedDoc(doc);
                                setShowDeleteDocumentConfirmationModal(true);
                              }}
                            >
                              <FaTimesCircle size={19} />
                              Delete file
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={(e) =>
                                onDownloadFile(doc.fileUrl, doc.fileName)
                              }
                            >
                              <FaFileDownload size={19} />
                              Download file
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  );
                })}
            </div>
            {!loading && uploaded_documents.length > 0 && (
              <div className={`${styles.pagination} ${styles.flex}`}>
                <Pagination
                  currentPage={currPage}
                  onPageChange={setCurrentPage}
                  lastPage={Math.ceil(uploaded_documents.length / rpp)}
                />
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className={`${addservicestyles.addServiceDocu} ${addservicestyles.addNewService}`}
      >
        {!loading && (
          <SpecificServiceDocument
            header={
              specificDocState.upload_or_generate == 1
                ? specificDocState.id
                  ? "Edit service-specific document"
                  : "Add new service-specific document"
                : specificDocState.id
                ? "Edit document"
                : "Add new documentation"
            }
            setSpecificDocState={setSpecificDocState}
            specificDocState={specificDocState}
            errors={errors}
            setErrors={setErrors}
          />
        )}

        {!loading &&
        (specificDocState.document_type_id < 3 ||
          specificDocState.document_type_id > 5) ? (
          <UploadOrGenerate
            setSpecificDocState={setSpecificDocState}
            specificDocState={specificDocState}
          />
        ) : null}

        {!loading && specificDocState.upload_or_generate == 1 && (
          <UploadServiceSpecificDocument
            serviceStateId={serviceState.id}
            setSpecificDocState={setSpecificDocState}
            specificDocState={specificDocState}
            // refreshList={() => fetchDocumentByServiceId()}
            refreshList={() => setToggleFetchDocs(!toggleFetchDocs)}
            setShowAddServiceSuccess={setShowAddServiceSuccess}
            isEdit={true}
            setErrors={setErrors}
            errors={errors}
          />
        )}

        {!loading && specificDocState.upload_or_generate == 2 && (
          <GenerateServiceSpecificDocument
            serviceStateId={serviceState.id}
            setSpecificDocState={setSpecificDocState}
            specificDocState={specificDocState}
            // refreshList={() => fetchDocumentByServiceId()}
            refreshList={() => setToggleFetchDocs(!toggleFetchDocs)}
            setShowAddServiceSuccess={setShowAddServiceSuccess}
            isEdit={true}
            setErrors={setErrors}
            errors={errors}
          />
        )}
      </div>

      {!loading && (
        <ServiceDetails
          serviceState={serviceState}
          setServiceState={setServiceState}
          serviceTypeId={serviceTypeId}
          mapServiceType={mapServiceType}
          serviceDetailsChevron={serviceDetailsChevron}
          setServiceDetailsChevron={setServiceDetailsChevron}
          cb={handleUpdateService}
          loading={loading}
        />
      )}

      {!loading && (
        <Branding serviceId={serviceState.id} service={serviceState} />
      )}

      {!loading && (
        <PreDefinedDocumentsCollection
          serviceId={serviceState.id}
          collectionLoaded={collectionLoaded}
          // refreshList={() => fetchDocumentByServiceId()}
          refreshList={() => setToggleFetchDocs(!toggleFetchDocs)}
        />
      )}

      {/* <div className={`${styles.btnSave}`}>
				<button>SAVE ALL CHANGES</button>
				<button data-toggle="modal" data-target="#changeServiceDetailsConfirmationModal">SAVE ALL CHANGES</button>
			</div> */}
    </UserLayout>
  );
};

export default EditService;
