import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
};
axios.interceptors.response.use(
  response => {
    return response;
  },
);

export function setAxios(token: string) {
  if (!axios.defaults.headers.common) return;
  if (token) axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
  else delete axios.defaults.headers.common['Authorization'];
}

export default axios;
