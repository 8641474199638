import React from "react";
import styles from "../CreateDocument.module.css";
import { isEmpty } from "lodash";

const years = [
  {
    year: new Date().getFullYear(),
  },
  {
    year: new Date().getFullYear() + 1,
  },
];

const months = [
  {
    name: "Jan",
  },
  {
    name: "Feb",
  },
  {
    name: "Mar",
  },
  {
    name: "Apr",
  },
  {
    name: "May",
  },
  {
    name: "Jun",
  },
  {
    name: "Jul",
  },
  {
    name: "Aug",
  },
  {
    name: "Sep",
  },
  {
    name: "Oct",
  },
  {
    name: "Nov",
  },
  {
    name: "Dec",
  },
];

const FocusOfTheMonth = ({
  fieldsChecked = false,
  fomInputs,
  setFomDataInputs,
  setToUpdateFomDetails,
  setToUpdateFomFile,
  setFomFieldsChecked,
}) => {
  const onSetFomInputs = (key, input) => {
    console.log("test ", input);
    setFomDataInputs({
      ...fomInputs,
      [key]: input,
    });
  };

  return (
    <>
      <div style={{ paddingTop: "60px" }} className={`${styles.titleContent}`}>
        <h2>Choose a year and month</h2>
      </div>
      <div className={`${styles.focusMonth} ${styles.optionsList}`}>
        <div className={`${styles.YearList}`}>
          <ul>
            {years.map((year) => {
              return (
                <li
                  onClick={() => {
                    onSetFomInputs("year", year.year);
                    setFomFieldsChecked(false);
                    setToUpdateFomDetails(false);
                    setToUpdateFomFile(false);
                  }}
                  className={fomInputs.year === year.year ? styles.active : ""}
                >
                  {year.year}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.MonthList}>
          <ul>
            {months.map((month) => {
              return (
                <li
                  onClick={() => {
                    onSetFomInputs("month", month.name);
                    setFomFieldsChecked(false);
                    setToUpdateFomDetails(false);
                    setToUpdateFomFile(false);
                  }}
                  className={
                    fomInputs.month === month.name ? styles.active : ""
                  }
                >
                  {month.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.inputFull}>
          <div className={styles.inputGroup}>
            <label>Monthly focus e.g. NQA1</label>
            <input
              onChange={(e) => {
                setToUpdateFomDetails(true);
                onSetFomInputs("focus", e.target.value);
              }}
              type="text"
              value={fomInputs.focus}
              placeholder="e.g. NQA1"
            />
            {fieldsChecked && !fomInputs.focus && (
              <text className={`py-1 px-2 text-red-400`}>
                This field is required.
              </text>
            )}
          </div>
        </div>

        <div className={styles.inputFull}>
          <div className={styles.inputGroup}>
            <label>Description</label>
            <textarea
              onChange={(e) => {
                setToUpdateFomDetails(true);
                onSetFomInputs("description", e.target.value);
              }}
              value={fomInputs.description}
              className={styles.inputDesc}
              placeholder="Enter a description"
            ></textarea>
          </div>
        </div>
      </div>
    </>
  );
};

export default FocusOfTheMonth;
