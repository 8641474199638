
export const serviceDocumentPayload = (originalObject, serviceStateId, content) => {

    let newObj = Object.assign({}, originalObject);

    newObj.notify_service = newObj.notify_service ? 1 : 2;

    newObj.service_id = [serviceStateId];

    newObj.services = [serviceStateId];

    newObj.content = content;

    if (newObj.service_specific_type == 4) {
        newObj.nqa_area_id = newObj.nqa_area_id.filter(n => n == 2 || n == 3);
    }

    if (newObj.service_specific_type < 4) {
        newObj.nqa_area_id = [];
    }

    if (newObj.service_specific_type != 5) {
        newObj.document_type_id = "";
    }

    let uploadDocumentPayload = {
        notify_service: newObj.notify_service,
        content: newObj.content,
        description: newObj.description,
        date: newObj.date,
        file_version: newObj.file_version,
        file_title: newObj.file_title,
        service_id: newObj.service_id,
        id: newObj.id,
        document_type_id: newObj.document_type_id,
        nqa_area_id: newObj.nqa_area_id,
        service_specific_type: newObj.service_specific_type,
        upload_or_generate: newObj.upload_or_generate,
        attachments: newObj.attachments,
        category: 3,
        service_type_id: newObj.service_type_id,
        is_webinar: false
    }

    let webinarPayload = {
        base64_image: newObj.attachments.length > 0 ? newObj.attachments[0].base64_image : null,
        category: 3,
        date: newObj.date,
        document_type_id: newObj.document_type_id,
        file_title: newObj.file_title,
        label: newObj.attachments.length > 0 ? newObj.attachments[0].file_name : "",
        notify_service: newObj.notify_service,
        nqa_area_id: newObj.nqa_area_id,
        service_specific_type: newObj.service_specific_type,
        services: newObj.services,
        webinar_description: newObj.webinar_description,
        webinar_link: newObj.webinar_link,
        webinar_title: newObj.webinar_title,
        id: newObj.id,
        service_type_id: [],
        is_webinar: true
    }

    if (!isNaN(uploadDocumentPayload.id)) {

        let newItems = [];

        if (uploadDocumentPayload.attachments.length > 0) {
            newItems = uploadDocumentPayload.attachments.filter(i => isNaN(i.file_id));
        }

        if (newItems.length < 1) {
            delete uploadDocumentPayload.attachments;
        }
    }

    if (!isNaN(webinarPayload.id)) {
        if (webinarPayload.document_type_id >= 3) {
            if (!webinarPayload.base64_image) {
                delete webinarPayload.base64_image;
            }
        }
    }
        

    if (newObj.document_type_id <= 2 || newObj.document_type_id >= 6) 
    {
        return uploadDocumentPayload;
    }
    else
    {
        return webinarPayload;
    }
}