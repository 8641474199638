import React, { useEffect, useState, useRef, useCallback } from "react";
import styles from "./AddDocumentToACollectionModal.module.css";

import {
	FaTimes
} from "react-icons/fa";

import { Modal } from "semantic-ui-react";

import Button from '../../../_base/button';

import LongDayCare from '../../../_base/svg/LongDayCare';
import FamilyDayCare from '../../../_base/svg/FamilyDayCare';
import OOSH from '../../../_base/svg/OOSH';
import PreSchool from '../../../_base/svg/PreSchool';

import LargeSpinner from '../../../spinner/LargeSpinner';
import AddDocumentToACollectionModalSuccess from '../addDocumentToACollectionModalSuccess';
// import { sweetAlert } from "../../../_base/alerts/sweetAlert";

import {
	getCollectionCount,
	addDocumentToCollection
} from '../../../../api/documents/requests';

const AddDocumentToACollectionModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	docId,
	fetchAllDocs
}) => {

	const [loading, setLoading] = useState(true);

	const [count, setDocCount] = useState({ long_day_care: 0, pre_school: 0, family_day_care: 0, oosh: 0 });
	const [selectedServiceType, setSelectedServiceType] = useState(0);
	const [showAddDocumentToACollectionModalSuccess, setShowAddDocumentToACollectionModalSuccess] = useState(false);
	const [docsCount, setDocsCount] = useState(0);

	const mountedRef = useRef(true);

	const fetchCollectionCount = useCallback(async () => {

		setLoading(true);

		try {

			let count = await getCollectionCount();

			if (!mountedRef.current) return null;

			setDocCount({
				...count,
				long_day_care: count.long_day_care,
				family_day_care: count.family_day_care,
				pre_school: count.pre_school,
				oosh: count.oosh
			});

			setLoading(false);

		} catch (error) {

			setLoading(false);

		}
	}, [mountedRef]);

	useEffect(() => {

		fetchCollectionCount();

		return () => {
			mountedRef.current = false;
		};

	}, [fetchCollectionCount]);



	const onAddDocumentToACollection = async (e) => {

		e.preventDefault();

		try {

			let payload = {
				document_id: docId,
				service_type_id: selectedServiceType
			}

			const response = await addDocumentToCollection(payload);

			if (response) {
				// onCloseModal();
				// sweetAlert("success", "Document successfully added to a collection.");
				setShowAddDocumentToACollectionModalSuccess(true)

			}

		} catch (error) {
			onCloseModal();
			console.log(error);
		}
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			{
				showAddDocumentToACollectionModalSuccess && <AddDocumentToACollectionModalSuccess
					visibility={showAddDocumentToACollectionModalSuccess}
					onOpenModal={() => setShowAddDocumentToACollectionModalSuccess(true)}				 
					onCloseModal={() => setShowAddDocumentToACollectionModalSuccess(false)}
					count={docsCount}
					fetchAllDocs={fetchAllDocs}
				/>
			}


			<div className={`${styles.modalContainer} ${styles.medium}`}>
				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					<h1>Add documents to collection</h1>
					<p>Add this document/s to a pre-defined default collections based on service center types.</p>
				</div>

				<div className={`${styles.modalContent}`}>

					{loading && <div>
						<LargeSpinner />
					</div>}

					{!loading && <ul>
						<li className={selectedServiceType == 1 ? styles.active : ""}
							onClick={(e) => {
								setSelectedServiceType(1)
								setDocsCount(count.long_day_care)
							}}
						>
							<div className={`${styles.collectionInfo}`}>
								<LongDayCare />
								<span>Long daycare collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.long_day_care} docs`}</span>
							</div>
						</li>
						<li className={selectedServiceType == 2 ? styles.active : ""}
							onClick={(e) => {
								setSelectedServiceType(2)
								setDocsCount(count.family_day_care)
							}}
						>
							<div className={`${styles.collectionInfo}`}>
								<FamilyDayCare />
								<span>Family Daycare collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.family_day_care} docs`}</span>
							</div>
						</li>
						<li className={selectedServiceType == 3 ? styles.active : ""}
							onClick={(e) => {
								setSelectedServiceType(3)
								setDocsCount(count.pre_school)
							}}
						>
							<div className={`${styles.collectionInfo}`}>
								<PreSchool />
								<span>Pre-school collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.pre_school} docs`}</span>
							</div>
						</li>
						<li className={selectedServiceType == 4 ? styles.active : ""}
							onClick={(e) => {
								setSelectedServiceType(4)
								setDocsCount(count.oosh)
							}}
						>
							<div className={`${styles.collectionInfo}`}>
								<OOSH />
								<span>OOSH collection</span>
							</div>
							<div className={`${styles.numberOfDocs}`}>
								<span>{`${count.oosh} docs`}</span>
							</div>
						</li>
					</ul>}

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={(e) => onAddDocumentToACollection(e)}
							className={`${styles.btnGreen}`}>ASSIGN DOCUMENTS
						</Button>
					</div>

				</div>

			</div>


		</Modal>
	)
}

export default AddDocumentToACollectionModal;