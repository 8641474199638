import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import UpdateUser from "../../../pages/UpdateUser/UpdateUser";

export default function UpdateUserRoute() {
  const match = useRouteMatch();
 
  return (
    <Switch>
      <Route exact path={`${match.path}`} render={() => <UpdateUser />} />
    </Switch>
  );
}
