import React from 'react';

const Icon = ({
    svgSize = "17",
    dataIcon,
    className,
    pathD,
    viewBox,
    rest
}) => {
    return (
        <svg
            style={{
                height: svgSize,
                width: svgSize,
                fontSize: svgSize
            }}
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon={dataIcon}
            className={className}
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            {...rest}>
            <path fill="currentColor"
                d={pathD}>
            </path>
        </svg>
    )
}

export default Icon;