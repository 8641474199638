import { Action, action, computed, Computed, Thunk, thunk } from "easy-peasy";

export interface AppModel {
  setHeaderMenu: Action<AppModel, boolean>;
  headerMenu: boolean;
  hasSubExpired: boolean;
  setSubExpired: Action<AppModel, boolean>;
  banner: boolean;
  showBanner: Action<AppModel, boolean>;
  firstSignUp: boolean;
  setFirstSignUp: Action<AppModel, boolean>;
}

const appStore: AppModel = {
  firstSignUp: false,
  setFirstSignUp: action((state, payload) => {
    state.firstSignUp = payload;
  }),
  headerMenu: false,
  hasSubExpired: false,
  banner: true,
  setHeaderMenu: action((state, payload) => {
    state.headerMenu = payload;
  }),
  setSubExpired: action((state, payload) => {
    state.hasSubExpired = payload;
  }),
  showBanner: action((state, payload) => {
    state.banner = payload;
  }),
};

export default appStore;
