import React from "react";

const Checkbox = ({
    onChange,
    className,
    type,
    labelStyle,
    value,  
    spanClass,
    id,
    ...rest
}) => {

    return (
        <label className={labelStyle}>
            <input
                id={id}
                className={`${className}`}
                value={value}
                onChange={onChange}
                type={`checkbox`}
                {...rest}
            />
            <span className={`${spanClass}`}></span>
        </label>
    );
}

export default Checkbox;
