
import React from "react";
import styles from "./DeleteServicesSuccessModal.module.css";
import {
	FaTimes
} from "react-icons/fa";
import { Modal } from "semantic-ui-react";
import Button from '../../../_base/button';

const DeleteServicesSuccessModal = ({
	visibility,
	onOpenModal,
	onCloseModal,
	onUndo,
	onClickOk,
	itemCount
}) => {


	const undoService = (e) => {
		e.preventDefault();
		onUndo(false);
	}

	return (
		<Modal
			centered={true}
			open={visibility}
			onOpen={onOpenModal}
			onClose={onCloseModal}
			closeOnDimmerClick={false}
		>

			<div className={`${styles.modalContainer}`}>

				<Button
					onClick={onCloseModal}
					className={`${styles.closeModal}`}>
					<FaTimes className={`ml-2`} size={16} />
				</Button>

				<div className={`${styles.modalTitle}`}>
					{
						itemCount == 1 ?
							<h1>{`${itemCount} service is now permanently deleted from QIP Portal`}</h1> :
							<h1>{`${itemCount} services are now permanently deleted from QIP Portal`}</h1>
					}
				</div>

				<div className={`${styles.modalContent}`}>

					<div className={`${styles.actionBtn}`}>
						<Button
							onClick={() => {
								onClickOk()
								onCloseModal()
							}}
							className={`${styles.btnOk}`}>OK</Button>
						<Button
							onClick={onUndo}
							className={`${styles.btnUndo}`}>UNDO</Button>
					</div>

				</div>
			</div>

		</Modal>
	)
}

export default DeleteServicesSuccessModal;